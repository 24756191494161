import TaskAction from "./TaskAction";
import * as ApiAction from "@/../src-gen/action";

export class TaskComponent {
  flowName: string;
  taskName: string;
  componentName: string;

  actionAccessList: TaskComponentAction[];

  constructor({ flowName, taskName, componentName, actionAccessList = [] }:
    { flowName: string; taskName: string; componentName: string; actionAccessList?: TaskComponentAction[] }) {
    this.flowName = flowName;
    this.taskName = taskName;
    this.componentName = componentName;
    this.actionAccessList = actionAccessList;
  }

  getAssignMyselfActionAccessData() {
    return this.actionAccessList.find(actionAccess => actionAccess.type === TaskComponentActionType.ASSIGN_MY_SELF)
  }

  getReAssignAssignActionAccessData() {
    return this.actionAccessList.find(actionAccess => actionAccess.type === TaskComponentActionType.RE_ASSIGN)
  }


}

enum TaskComponentActionType {
  ASSIGN_MY_SELF = "Assign",
  SUSPEND = "SUSPEND",
  RE_ASSIGN="ReAssign"
}


export interface TaskComponentAction {
  disabled?: boolean;
  roleList?: string[];
  type?: TaskComponentActionType;


}

export default class Task {
  static Action = TaskAction;
  static isTaskActionable(taskState: string) {
    // taskState === "EXCEPTION_Q"
    // taskState === "EXCEPTION_Q" | taskState === "PARTIALLY_COMPLETED" || taskType !== "deferred"
    return (
      taskState === "STARTED" ||
      taskState === "PARTIALLY_COMPLETED" ||
      taskState === "EXCEPTION_Q" ||
      taskState === "EXIT_Q"
      //TODO : also check for allowed taskType (Manual / Self) - deferred is not allowed
    );
  }

  static isTaskNotActionable(taskState: string, isSuspended: boolean) {
    if (isSuspended) {
      return isSuspended;
    }
    return !this.isTaskActionable(taskState);
  }

  static isTaskOutputNotAvailable(taskOutput: any = {}) {
    return Object.keys(taskOutput).length === 0;
  }

  static isTaskOutputAvailable(taskOutput: any = {}) {
    return !this.isTaskOutputNotAvailable(taskOutput);
  }

  static mergeTaskOutputAndReturn(taskInput: any = {}, taskOutput: any = {}) {
    if (this.isTaskOutputNotAvailable(taskOutput)) {
      return {
        ...taskInput,
      };
    } else {
      return taskOutput;
    }
  }

  static isMarkCompleteEnabled(taskDetails: any) {
    if (taskDetails.isSuspended) {
      return false;
    }

    return (
      taskDetails.taskState === "STARTED" ||
      taskDetails.taskState === "PARTIALLY_COMPLETED"
    );
  }

  // getExecutiveTaskDetails(taskId: string) {
  //   ApiAction.TaskList.GetExecutiveTaskDetails.execute1(
  //     taskId,
  //     (output) => {
  //       this.loading = false;
  //     }
  //   );
  // }

  // static TASK_COMPONENT_MAP = new Map([
  //   ["Enrollment::EnrollClientFile", "EnrollClientFileTask"],
  //   ["Enrollment::ClientInfoVerification", "ClientInfoVerificationTask"],
  //   ["Enrollment::SignServiceAgreement", "SignServiceAgreementTask"],
  //   [
  //     "Enrollment::SignServiceAgreementFailed",
  //     "SignServiceAgreementFailedTask",
  //   ],
  //   ["Enrollment::EMandate", "EMandateTask"],
  //   ["Enrollment::EMandateFailed", "EMandateFailedTask"],
  //   ["Underwriting::ManualUnderwriting", "UnderwrittingTask"],
  //   ["Enrollment::CollectMSF", "CollectMSFThroughCashfreeTask"],
  //   ["Enrollment::CollectMSFThroughCashfree", "CollectMSFThroughCashfreeTask"],
  //   ["Enrollment::EnrollmentCompletion", "EnrollmentCompletionTask"],
  //   ["EMandate::CreateEMandate", "CreateEMandateTask"],
  //   ["EMandate::SendEMandateLink", "SendEMandateLinkTask"],
  //   ["EMandate::WaitForEMandate", "WaitForEMandateTask"],
  //   ["EMandate::GetEMandateStatus", "GetEMandateStatusTask"],
  //   ["Digio::GenerateSSADoc", "GenerateSSADocTask"],
  //   ["Digio::DownloadUnSignedDoc", "DownloadUnSignedDocTask"],
  //   ["Digio::UploadUnSignedDoc", "UploadUnSignedDocTask"],
  //   ["Digio::WaitForClientSign", "WaitForClientSignTask"],
  //   ["Digio::UpdateClientSignStatus", "UpdateClientSignStatusTask"],
  //   ["Digio::DownloadClientSignDoc", "DownloadClientSignDocTask"],
  //   ["Digio::UploadClientSignedDoc", "UploadClientSignedDocTask"],
  //   ["Digio::SignByFreed", "SignByFreedTask"],
  //   ["Digio::UploadFreedSignedDoc", "UploadFreedSignedDocTask"],
  //   ["Digio::ClientSignExpired", "ClientSignExpiredTask"],
  //   ["CHPP::CHPP", "CHPPTask"],
  //   ["NsfMSF::NsfMSF", "NsfMSFTask"],
  //   ["NsfMSF::NsfMSFSystemDeferred", "NsfMSFSystemDeferredTask"],
  //   ["NsfMSF::NsfMSFClientDeferred", "NsfMSFClientDeferredTask"],
  //   ["NsfMSF::NsfMSFDraftRescheduled", "NsfMSFDraftRescheduledTask"],
  //   ["NsfMSF::ReceiveManualPayment", "ReceiveManualPaymentTask"],
  //   ["NsfMSF::NsfMSFCompletion", "NsfMSFCompletionTask"],
  //   [
  //     "NsfMSF::CollectNSFMSFThroughCashfree",
  //     "CollectNSFMSFThroughCashfreeTask",
  //   ],
  //   ["ManualPayment::GenerateLink", "GenerateLinkTask"],
  //   ["ManualPayment::SendLink", "SendLinkTask"],
  //   [
  //     "ManualPayment::CheckManualPaymentReceived",
  //     "CheckManualPaymentReceivedTask",
  //   ],
  //   [
  //     "ManualPayment::PaymentReceivedConfirmation",
  //     "PaymentReceivedConfirmationTask",
  //   ],
  //   ["WelcomeCall::OnBoardingCall", "ManageClientInfoTask"],
  //   [
  //     "WelcomeCall::SignServiceAgreementFailed",
  //     "WelcomeCallSignServiceAgreementFailedTask",
  //   ],
  //   ["WelcomeCall::SignAmendment", "SignAmendmentTask"],
  //   [
  //     "WelcomeCall::ManageClientInfoCompletion",
  //     "ManageClientInfoCompletionTask",
  //   ],
  //   ["WelcomeCall::SignServiceAgreement", "WelcomeCallSSATask"],
  //   ["WelcomeCall::EMandate", "WelcomeCallEMandateTask"],
  //   [
  //     "WelcomeCall::ManageClientInfoCompletion",
  //     "ManageClientInfoCompletionTask",
  //   ],
  //   ["NsfSPA::NsfSPA", "NsfSPATask"],
  //   ["NsfSPA::NsfSPADraftRescheduled", "NsfSPADraftRescheduledTask"],
  //   ["NsfSPA::NsfSPACompletion", "NsfSPACompletionTask"],
  //   ["NsfSPA::NsfSPAClientDeferred", "NsfSPAClientDeferredTask"],
  //   ["NsfSPA::ReceiveManualPayment", "NsfSPAReceiveManualPaymentTask"],
  //   ["NsfSPA::NsfSPASystemDeferred", "NsfSPASystemDeferredTask"],
  //   ["MFC::FollowUpCall", "FollowUpCallTask"],
  //   ["MFC::MFCCompletion", "MFCCompletionTask"],
  //   ["SettlementPlan::PlanSettlement", "PlanSettlementTask"],
  //   // ["Amendment::PrepareAmendment", "PrepareAmendmentTask"],
  //   ["RefundFee::RefundFee", "RefundFeeTask"],
  //   ["RefundSPA::UpdateRefundSPAPaymentStatus", "UpdateRefundSPAPaymentStatusTaskTask"],
  //   ["CollectMSF::CollectMSFThroughCashfree", "CollectMSFThroughCashfreeTask"],
  //   // ["Amendment::ApproveAmendment", "ApproveAmendmentTask"],
  //   ["Amendment::SignedByClient", "SignedByClientTask"],
  //   ["Amendment::FollowUpAmendmentEMandate", "FollowUpAmendmentEMandateTask"],
  //   ["Amendment::PrepareAmendmentV4", "PrepareAmendmentV4Task"],
  //   ["Amendment::ApproveAmendmentV4", "ApproveAmendmentV4Task"],
  //   ["Amendment::SignedByClientV4", "SignedByClientV4Task"],
  //   ["Amendment::FollowUpAmendmentEMandateV4", "FollowUpAmendmentEMandateV4Task"],
  //   ["Retain::RetainCancelledFile", "RetainCancelledFileTask"],
  //   ["RetainPendingCancelled::RetainPendingCancelledFile", "RetainPendingCancelledFileTask"],
  //   ["Cancellation::CancellationChecklist", "CancellationChecklistTask"],
  // ]);

  // static taskList() {
  //   const flowTaskList = Object.keys(Object.fromEntries(Task.TASK_COMPONENT_MAP));
  //   return flowTaskList.map((flowTask: string) => {
  //     return flowTask.split("::")[1];
  //   }
  //   )
  // }

  static getTaskComponentMap() {
    return new Map(this.taskComponentMapList.map(taskComponentMap => [`${taskComponentMap.flowName}::${taskComponentMap.taskName}`, `${taskComponentMap.componentName}`]))
  }

  static taskComponentMapList = [
    new TaskComponent({ flowName: 'Enrollment', taskName: 'EnrollClientFile', componentName: 'EnrollClientFileTask' }),
    new TaskComponent({ flowName: 'Enrollment', taskName: 'ClientInfoVerification', componentName: 'ClientInfoVerificationTask' }),
    new TaskComponent({ flowName: 'Enrollment', taskName: 'SignServiceAgreement', componentName: 'SignServiceAgreementTask' }),
    new TaskComponent({ flowName: 'Enrollment', taskName: 'SignServiceAgreementFailed', componentName: 'SignServiceAgreementFailedTask' }),
    new TaskComponent({ flowName: 'Enrollment', taskName: 'EMandate', componentName: 'EMandateTask' }),
    new TaskComponent({ flowName: 'Enrollment', taskName: 'EMandateFailed', componentName: 'EMandateFailedTask' }),
    new TaskComponent({ flowName: 'Underwriting', taskName: 'ManualUnderwriting', componentName: 'UnderwrittingTask' }),
    new TaskComponent({ flowName: 'Enrollment', taskName: 'CollectMSF', componentName: 'CollectMSFThroughCashfreeTask' }),
    new TaskComponent({ flowName: 'Enrollment', taskName: 'CollectMSFThroughCashfree', componentName: 'CollectMSFThroughCashfreeTask' }),
    new TaskComponent({ flowName: 'Enrollment', taskName: 'EnrollmentCompletion', componentName: 'EnrollmentCompletionTask' }),
    new TaskComponent({ flowName: 'EMandate', taskName: 'CreateEMandate', componentName: 'CreateEMandateTask' }),
    new TaskComponent({ flowName: 'EMandate', taskName: 'SendEMandateLink', componentName: 'SendEMandateLinkTask' }),
    new TaskComponent({ flowName: 'EMandate', taskName: 'WaitForEMandate', componentName: 'WaitForEMandateTask' }),
    new TaskComponent({ flowName: 'EMandate', taskName: 'GetEMandateStatus', componentName: 'GetEMandateStatusTask' }),
    new TaskComponent({ flowName: 'Digio', taskName: 'GenerateSSADoc', componentName: 'GenerateSSADocTask' }),
    new TaskComponent({ flowName: 'Digio', taskName: 'DownloadUnSignedDoc', componentName: 'DownloadUnSignedDocTask' }),
    new TaskComponent({ flowName: 'Digio', taskName: 'UploadUnSignedDoc', componentName: 'UploadUnSignedDocTask' }),
    new TaskComponent({ flowName: 'Digio', taskName: 'WaitForClientSign', componentName: 'WaitForClientSignTask' }),
    new TaskComponent({ flowName: 'Digio', taskName: 'UpdateClientSignStatus', componentName: 'UpdateClientSignStatusTask' }),
    new TaskComponent({ flowName: 'Digio', taskName: 'DownloadClientSignDoc', componentName: 'DownloadClientSignDocTask' }),
    new TaskComponent({ flowName: 'Digio', taskName: 'UploadClientSignedDoc', componentName: 'UploadClientSignedDocTask' }),
    new TaskComponent({ flowName: 'Digio', taskName: 'SignByFreed', componentName: 'SignByFreedTask' }),
    new TaskComponent({ flowName: 'Digio', taskName: 'UploadFreedSignedDoc', componentName: 'UploadFreedSignedDocTask' }),
    new TaskComponent({ flowName: 'Digio', taskName: 'ClientSignExpired', componentName: 'ClientSignExpiredTask' }),
    new TaskComponent({ flowName: 'CHPP', taskName: 'CHPP', componentName: 'CHPPTask' }),
    new TaskComponent({ flowName: 'NsfMSF', taskName: 'NsfMSF', componentName: 'NsfMSFTask' }),
    new TaskComponent({ flowName: 'NsfMSF', taskName: 'NsfMSFSystemDeferred', componentName: 'NsfMSFSystemDeferredTask' }),
    new TaskComponent({ flowName: 'NsfMSF', taskName: 'NsfMSFClientDeferred', componentName: 'NsfMSFClientDeferredTask' }),
    new TaskComponent({ flowName: 'NsfMSF', taskName: 'NsfMSFDraftRescheduled', componentName: 'NsfMSFDraftRescheduledTask' }),
    new TaskComponent({ flowName: 'NsfMSF', taskName: 'ReceiveManualPayment', componentName: 'ReceiveManualPaymentTask' }),
    new TaskComponent({ flowName: 'NsfMSF', taskName: 'NsfMSFCompletion', componentName: 'NsfMSFCompletionTask' }),
    new TaskComponent({ flowName: 'NsfMSF', taskName: 'CollectNSFMSFThroughCashfree', componentName: 'CollectNSFMSFThroughCashfreeTask' }),
    new TaskComponent({ flowName: 'ManualPayment', taskName: 'GenerateLink', componentName: 'GenerateLinkTask' }),
    new TaskComponent({ flowName: 'ManualPayment', taskName: 'SendLink', componentName: 'SendLinkTask' }),
    new TaskComponent({ flowName: 'ManualPayment', taskName: 'CheckManualPaymentReceived', componentName: 'CheckManualPaymentReceivedTask' }),
    new TaskComponent({ flowName: 'ManualPayment', taskName: 'PaymentReceivedConfirmation', componentName: 'PaymentReceivedConfirmationTask' }),
    new TaskComponent({ flowName: 'WelcomeCall', taskName: 'OnBoardingCall', componentName: 'ManageClientInfoTask' }),
    new TaskComponent({ flowName: 'WelcomeCall', taskName: 'SignServiceAgreementFailed', componentName: 'WelcomeCallSignServiceAgreementFailedTask' }),
    new TaskComponent({ flowName: 'WelcomeCall', taskName: 'SignAmendment', componentName: 'SignAmendmentTask' }),
    new TaskComponent({ flowName: 'WelcomeCall', taskName: 'ManageClientInfoCompletion', componentName: 'ManageClientInfoCompletionTask' }),
    new TaskComponent({ flowName: 'WelcomeCall', taskName: 'SignServiceAgreement', componentName: 'WelcomeCallSSATask' }),
    new TaskComponent({ flowName: 'WelcomeCall', taskName: 'EMandate', componentName: 'WelcomeCallEMandateTask' }),
    new TaskComponent({ flowName: 'NsfSPA', taskName: 'NsfSPA', componentName: 'NsfSPATask' }),
    new TaskComponent({ flowName: 'NsfSPA', taskName: 'NsfSPADraftRescheduled', componentName: 'NsfSPADraftRescheduledTask' }),
    new TaskComponent({ flowName: 'NsfSPA', taskName: 'NsfSPACompletion', componentName: 'NsfSPACompletionTask' }),
    new TaskComponent({ flowName: 'NsfSPA', taskName: 'NsfSPAClientDeferred', componentName: 'NsfSPAClientDeferredTask' }),
    new TaskComponent({ flowName: 'NsfSPA', taskName: 'ReceiveManualPayment', componentName: 'NsfSPAReceiveManualPaymentTask' }),
    new TaskComponent({ flowName: 'NsfSPA', taskName: 'NsfSPASystemDeferred', componentName: 'NsfSPASystemDeferredTask' }),
    new TaskComponent({ flowName: 'MFC', taskName: 'FollowUpCall', componentName: 'FollowUpCallTask' }),
    new TaskComponent({ flowName: 'MFC', taskName: 'MFCCompletion', componentName: 'MFCCompletionTask' }),
    new TaskComponent({ flowName: 'SettlementPlan', taskName: 'PlanSettlement', componentName: 'PlanSettlementTask' }),
    new TaskComponent({ flowName: 'RefundFee', taskName: 'RefundFee', componentName: 'RefundFeeTask' }),
    new TaskComponent({ flowName: 'RefundSPA', taskName: 'UpdateRefundSPAPaymentStatus', componentName: 'UpdateRefundSPAPaymentStatusTaskTask' }),
    new TaskComponent({ flowName: 'CollectMSF', taskName: 'CollectMSFThroughCashfree', componentName: 'CollectMSFThroughCashfreeTask' }),
    new TaskComponent({ flowName: 'Amendment', taskName: 'SignedByClient', componentName: 'SignedByClientTask' }),
    new TaskComponent({ flowName: 'Amendment', taskName: 'FollowUpAmendmentEMandate', componentName: 'FollowUpAmendmentEMandateTask' }),
    new TaskComponent({ flowName: 'Amendment', taskName: 'PrepareAmendmentV4', componentName: 'PrepareAmendmentV4Task' }),
    new TaskComponent({ flowName: 'Amendment', taskName: 'ApproveAmendmentV4', componentName: 'ApproveAmendmentV4Task', actionAccessList: [{ type: TaskComponentActionType.ASSIGN_MY_SELF, roleList: ['Admin'] }] }),
    new TaskComponent({ flowName: 'Amendment', taskName: 'SignedByClientV4', componentName: 'SignedByClientV4Task' }),
    new TaskComponent({ flowName: 'Amendment', taskName: 'FollowUpAmendmentEMandateV4', componentName: 'FollowUpAmendmentEMandateV4Task' }),
    new TaskComponent({ flowName: 'Amendment', taskName: 'ApproveRetainAmendment', componentName: 'ApproveAmendmentV4Task', actionAccessList: [{ type: TaskComponentActionType.ASSIGN_MY_SELF, roleList: ['Admin'] }] }),
    
    new TaskComponent({ flowName: 'Retain', taskName: 'RetainCancelledFile', componentName: 'RetainCancelledFileTask' }),
    new TaskComponent({ flowName: 'RetainPendingCancelled', taskName: 'RetainPendingCancelledFile', componentName: 'RetainPendingCancelledFileTask' }),
    new TaskComponent({ flowName: 'Cancellation', taskName: 'CancellationChecklist', componentName: 'CancellationChecklistTask' }),
    new TaskComponent({ flowName: 'SettlementRequest', taskName: 'SettlementRequestCreditorInfo', componentName: 'SettlementRequestCreditorInfoTask' }),
    new TaskComponent({ flowName: 'SalesFeedbackQuality', taskName: 'SalesFeedback', componentName: 'SalesFeedbackTask' }),
    new TaskComponent({ flowName: 'SalesFeedbackQuality', taskName: 'ReviewSalesFeedback', componentName: 'ReviewSalesFeedbackTask' }),
    
    
    
  ]
}


