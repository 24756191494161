import FDataTableMDP from "@/components/table/FDataTableMDP";
import FCellCurrencyMDP from "@/components/table/cell/FCellCurrencyMDP";
import FCellDateMDP from "@/components/table/cell/FCellDateMDP";
import FCellStatusMDP from "@/components/table/cell/FCellStatusMDP";


export default class FEPSkipedPresentedFDataTableMDP extends FDataTableMDP {
    parent: any;
    constructor({ parent }: { parent: any }) {
        super({ itemKey: "psEntryId", disabled: parent.disabledActionBtn, title: "Skipped Payment Schedule",myRefName: "fpSkipedPresentedFDataTableMDP" });
        this.parent = parent;
        this.addColumn({
            label: "Draft Date",
            dataSelectorKey: "draftDate",
            columnCellMDP: new FCellDateMDP()
        }).addColumn({ label: "Total Amount", dataSelectorKey: "totalAmount", columnCellMDP: new FCellCurrencyMDP({}) })
            .addColumn({ label: "Settlement Amount", dataSelectorKey: "spaAmount", columnCellMDP: new FCellCurrencyMDP({}) })
            // .addColumn({ label: "Fee Amount", dataSelectorKey: "feeAmount", columnCellMDP: new FCellCurrencyMDP({}) })
            .addColumn({ label: "Status", dataSelectorKey: "status", columnCellMDP: new FCellStatusMDP({}) })
            .addColumn({ label: "Payment Status", dataSelectorKey: "paymentStatus", columnCellMDP: new FCellStatusMDP({}) });
    }

    
}