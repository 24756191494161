<template>
<div>
    <v-chip label x-small :color="textColor" v-if="type==='Normal'" class="white--text" >
    <strong>{{ selectModel(modelValue, dataSelectorKey)?'Yes':'No' }}</strong>
    </v-chip>

    <div v-else :class="`dot heartbeat`" :style="`background:${textColor};opacity:0.7`">
      
    </div>
</div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ModelVue from "../../ModelVue";
import { BooleanCellType } from "./FCellBooleanMDP";

@Component({
  components: {},
})
export default class FCellBoolean extends ModelVue {


  @Prop()
  dataSelectorKey: string;

  @Prop({
    default: BooleanCellType.Normal
  })
  type: BooleanCellType;
  
  get textColor(): string {
    return this.selectModel(this.modelValue, this.dataSelectorKey)?"green":"#F5885B"
  }
}
</script>

<style scoped>
.dot {
  position: relative;
  width: fit-content;
  border-radius: 40px;
  width: 8px;
  height: 8px;
}
.green {
  background: green;
}
.orange {
  background-color: red !important;
}
</style>
