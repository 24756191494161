<template>
  <v-app>
    <v-main>
      <div class="d-flex">
        <div
          v-show="
            $vuetify.breakpoint.lg ||
            $vuetify.breakpoint.xl ||
            $vuetify.breakpoint.md
          "
          class="image-container col-6"
        >
          <div class="text-center">
            <img
              :src="require('@/assets/images/freedLogo.svg')"
              width="200px"
            />
            <h2 class="white--text">India's 1st Debt Relief Platform</h2>
          </div>
        </div>
        <v-col>
          <v-container fill-height>
            <v-col>
              <div class="col-md-7">
                <div class="mx-2">
                  <h2 class="mx-1 mb-5 text-center primary--text">
                    FREED Partner Program
                  </h2>
                </div>
              </div>
              <v-form ref="signInRef" class="col-md-7">
                <v-text-field
                  label="Mobile Number / User Name"
                  outlined
                  dense
                  :rules="userNameRules"
                  v-model="mobileNumber"
                ></v-text-field>
                <v-text-field
                  label="Password"
                  :type="type"
                  outlined
                  dense
                  v-model="password"
                  :rules="[(v) => !!v || 'password is required']"
                  append-inner-icon="mdi-eye-off"
                >
                  <v-icon
                    slot="append"
                    @click="onEyeClick"
                    style="cursor: pointer"
                  >
                    mdi-eye-off
                  </v-icon>
                </v-text-field>
                <div class="forgotPassword">
                  <span @click="goForgotPassword">Forgot Password</span>
                </div>
                <div class="d-flex align-center justify-center">
                  <PartnerButton
                    :loading="false"
                    :btnSize="'sm'"
                    btnType="primary"
                    @click="onSignIn()"
                    >{{ "Login" }}
                    <template v-slot:icon>
                      <img :src="require('@/assets/images/Vector.png')" />
                    </template>
                  </PartnerButton>
                  <!-- <v-btn color="secondary" variant="flat" @click="onSignIn"> Login </v-btn> -->
                </div>
                <div class="info-text">
                  Don't have an account?
                  <span @click="goToHome">Register</span>
                </div>
              </v-form>
            </v-col>
          </v-container>
        </v-col>
      </div>
    </v-main>
  </v-app>
  <!-- <v-form ref="signInRef" class="col-12">
    <v-text-field
      label="Mobile Number"
      outlined
      dense
      
      v-model="mobileNumber"
    ></v-text-field>
    <v-text-field
      label="Password"
      :type="type"
      outlined
      dense
      v-model="password"
      :rules="[(v) => !!v || 'password is required']"
      append-inner-icon="mdi-eye-off"
    >
      <v-icon slot="append" @click="onEyeClick" style="cursor: pointer">
        mdi-eye-off
      </v-icon>
    </v-text-field>
    <div class="forgotPassword">
      <span @click="goForgotPassword">Forgot Password</span>
    </div>
    <div class="d-flex align-center justify-center">
      <v-btn color="secondary" variant="flat" @click="onSignIn"> Login </v-btn>
    </div>
    <div class="info-text">
      Don't have an account?
      <span @click="goToHome">Register</span>
    </div>
  </v-form> -->
  <!-- <component
                      v-if="!!loginFormMetaData"
                      :ref="loginFormMetaData.myRefName"
                      :is="loginFormMetaData.componentName"
                      v-model="loginForm"
                      v-bind="loginFormMetaData.props"
                    ></component> -->
  <!-- <v-app id="inspire">
    <v-main>
      <div class="d-flex">
        <div class="image-container col-7">
          <div class="curve"></div>
        </div>
        <v-col>
          <v-container fill-height>
            <v-col cols="8">
              <div class="col-12 ">
                <div class="mx-2">
                <h2 class="primary--text">FREED Partner Program</h2>

                <h4>You logged in as</h4>
              <div class="d-flex mt-2 ">
                    <v-btn class="flex-fill mr-2" outlined small>
                      <v-icon small class="mr-3">mdi-radiobox-blank</v-icon>
                      Partner
                    </v-btn>

                    <v-btn  class="flex-fill  ml-2" outlined small>
                      <v-icon small class="mr-3">mdi-radiobox-blank</v-icon>
                      Partner
                    </v-btn>
                </div>
              </div>
              </div>
              <component
                v-if="!!loginFormMetaData"
                :ref="loginFormMetaData.myRefName"
                :is="loginFormMetaData.componentName"
                v-model="loginForm"
                v-bind="loginFormMetaData.props"
              ></component>
            </v-col>
          </v-container>
        </v-col>
      </div> -->
  <!-- </v-row> -->
  <!-- <v-row class="align-center justify-center loginMobileView">
          <v-col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            class="align-center justify-center"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <img
                src="@/assets/images/freed-logo.svg"
                class="img-fluid logo-size"
                alt="..."
                width="120"
              />
            </div>
          </v-col>
          <v-col xs="12" sm="8" md="4">
            <v-tabs v-model="selectedLoginScreen">
              <v-tab class="col-6">Partner</v-tab>
              <v-tab class="col-6">Affiliate</v-tab>
            </v-tabs>

            <v-tabs-items v-model="selectedLoginScreen">
              <v-tab-item>
                <v-card flat outlined>
                  <v-toolbar color="primary" flat dark>
                    <v-avatar size="40px">
                      <v-icon>mdi-account-circle-outline</v-icon>
                    </v-avatar>
                    <v-toolbar-title>Partner Login</v-toolbar-title>

                    <v-spacer></v-spacer>
                    <v-list-item-avatar>
                      <v-img src="@/assets/images/freed-small-logo.svg" />
                    </v-list-item-avatar>
                  </v-toolbar>
                  <v-card-text>
                    <v-form ref="signInRef">
                      <v-text-field
                        label="Mobile Number"
                        outlined
                        dense
                        :rules="mobileRules"
                        v-model="mobileNumber"
                      ></v-text-field>
                      <v-text-field
                        label="Password"
                        :type="type"
                        outlined
                        dense
                        v-model="password"
                        :rules="[(v) => !!v || 'password is required']"
                        append-inner-icon="mdi-eye-off"
                      >
                        <v-icon
                          slot="append"
                          @click="onEyeClick"
                          style="cursor: pointer"
                        >
                          mdi-eye-off
                        </v-icon>
                      </v-text-field>
                      <div class="forgotPassword">
                        <span @click="goForgotPassword">Forgot Password</span>
                      </div>
                      <div class="d-flex align-center justify-center">
                        <v-btn
                          color="secondary"
                          variant="flat"
                          @click="onSignIn"
                        >
                          Login
                        </v-btn>
                      </div>
                      <div class="info-text">
                        Don't have an account?
                        <span @click="goToHome">Register</span>
                      </div>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat outlined>
                  <v-toolbar color="primary" flat dark>
                    <v-avatar size="40px">
                      <v-icon>mdi-account-circle-outline</v-icon>
                    </v-avatar>
                    <v-toolbar-title>Affiliate Login</v-toolbar-title>

                    <v-spacer></v-spacer>
                    <v-list-item-avatar>
                      <v-img src="@/assets/images/freed-small-logo.svg" />
                    </v-list-item-avatar>
                  </v-toolbar>
                  <v-card-text>
                    <component
                      v-if="!!loginFormMetaData"
                      :ref="loginFormMetaData.myRefName"
                      :is="loginFormMetaData.componentName"
                      v-model="loginForm"
                      v-bind="loginFormMetaData.props"
                    ></component>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

          </v-col>
        </v-row> -->
  <!-- </v-container> -->
  <!-- </v-main>
  </v-app> -->
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
// import store, * as Store from '@/../src-gen/store';
import * as Data from "@/../src-gen/data";
// import * as ServerData from '@/../src-gen/server-data';
import * as Action from "@/../src-gen/action";
import AffiliateLoginFFormMDP from "./AffiliateLoginFFormMDP";
import FForm from "@/components/form/FForm.vue";
import PartnerButton from "@/components/partnerButton.vue";

@Component({
  components: {
    FForm,
    PartnerButton,
  },
})
export default class Login extends Vue {
  selectedLoginScreen: number = 0;
  loginForm: Data.Login.LoginForm = new Data.Login.LoginForm();
  emailRules = [
    (v: any) => !!v.trim() || "Mobile Number / User Name is required",
    (v: any) => v.indexOf(" ") === -1 || "Provide a valid Mobile Number",
  ];

  userNameRules = [(v: any) => !!v || "Mobile Number / User Name is required"];
  mobileNumber: string = "";
  password: string = "";
  type: string = "password";
  mounted() {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("partnerId");
  }
  onEyeClick() {
    this.type = this.type === "password" ? "text" : "password";
  }
  goToHome() {
    this.$router.push({ name: "Root.Home" });
  }

  goForgotPassword() {
    this.$router.push({ name: "Root.ForgotPassword" });
  }

  onSignIn() {
    Action.Login.Login.execute2(
      this.mobileNumber,
      this.password,
      (output: any) => {
        localStorage.setItem("auth_token", output.Authorization);
        localStorage.setItem("partnerId", output.partnerId);
        // this.$router.push({ name: "Partner.Dashboard" });
        this.getPartnerDetailsAndRoute(output.partnerId);
      }
    );
  }

  getPartnerDetailsAndRoute(partnerId: string) {
    Action.Partner.FindPartnerDetails.execute1(partnerId, (output) => {
      if (
        output?.partnerType.id === Data.Partner.PARTNER_TYPE.INSTITUTIONAL.id
      ) {
        this.$router.push({
          name: "InstitutionalPartner.InstitutionDashboard",
        });
      } else {
        this.$router.push({ name: "Partner.Dashboard" });
      }
    });
  }

  public affiliateLogin() {
    // const vm = this;

    Action.Login.SpineLogin.execute(this.loginForm, (output: any) => {
      localStorage.setItem("auth_token", output.Authorization);
      this.getRoleListForUser(this.loginForm.userName);
      console.log("output Login");
    });
  }

  getLoggedInUser(userName: string) {
    Action.Login.GetUserDetails.execute1(userName, (output) => {
      console.log("output Loggedin user");
    });
  }

  getRoleListForUser(userName: string) {
    // const vm = this;
    try {
      Action.Login.GetRoleListForUser.execute(
        new Data.Login.MyAppId(),
        (output) => {
          console.log(output);
          if (output.roleList.indexOf("USER") >= 0) {
            window.localStorage.setItem("userName", this.loginForm.userName);
            console.log("output role List");
            this.getLoggedInUser(this.loginForm.userName);
            if (this.$route.query.redirect) {
              console.log("output Redirect");
              const redirect: any = this.$route.query.redirect;
              this.$router.push({ path: redirect });
            } else {
              this.$router.push({ name: "PartnerAffiliate.AffiliateHome" });
            }
          } else {
            window.localStorage.removeItem("userName");
            // Snackbar.show({
            //   text: 'Not Authorized.',
            //   pos: 'bottom-center'
            // });
          }
        }
      );
    } catch (e) {
      console.log(e, "Error is this");
    }
  }

  get loginFormMetaData(): any {
    return new AffiliateLoginFFormMDP({
      taskRoot: this,
      parent: this,
    }).getMetaData();
  }
}
</script>

<style lang="scss" scoped>
.info-text {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  color: #676767;
  > span {
    color: #f36f21;
    font-weight: bold;
    cursor: pointer;
  }
}
.forgotPassword {
  text-align: right;
  color: #00447a;
  cursor: pointer;
  margin-bottom: 15px;
}
@media screen and (max-width: 960px) {
  .loginMobileView {
    display: block;
  }
}
.loginImg {
  height: 100vh;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 25% 100%);
  position: relative;
}
.image-container {
  background-image: url("@/assets/images/LoginBanner.png");
  height: 100vh;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 40%;
}
</style>
