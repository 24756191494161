
<template>
  <span class="grey--text" v-if="selectModel(modelValue,dataSelectorKey)">
    {{selectModel(modelValue,dataSelectorKey) | date}}
  </span>
  <span v-else>
    --
  </span>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ModelVue from "../../ModelVue";

@Component({
  components: {
  },
})
export default class FCellDate extends ModelVue {
  @Prop()
  dataSelectorKey: string;
}
</script>
