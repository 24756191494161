<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="modelValue"
      transition="scale-transition"
      offset-y
      min-width="auto"
      :disabled="$props.readonly || $props.disabled"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="dateInDisplayFormat"
          append-icon="mdi-calendar"
          outlined
          readonly
          v-bind="{ ...$props, ...attrs }"
          v-on="on"
          @click:append="menu = !menu"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="modelValue"
        :min="minDate"
        :max="maxDate"
        range
        no-title
        scrollable
        @change="handleDateChange"
      >
        <!-- <template v-slot:actions="{ save, cancel }">
          <v-btn text @click="cancel">Cancel</v-btn>
          <v-btn text @click="save">OK</v-btn>
        </template> -->
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { VTextField } from "vuetify/lib/components";
import moment, { Moment } from "moment";
import FTextField from "@/components/form/field/FTextField.vue";

@Component({
  components: {
    FTextField,
  },
})
export default class FDateSelectRangeField extends VTextField {
  menu: boolean = false;
  dateFormatForDatePicker: string = "YYYY-MM-DD";

  @Prop() value!: [string, string];
  @Prop() errorMessages: any;
  @Prop() pastDaysDisabled!: boolean;
  @Prop() futureDaysDisabled!: boolean;
  @Prop() minimumDate!: Moment;
  @Prop() maximumDate!: Moment;
  @Prop({ default: "DD/MM/YYYY" }) dateDisplayFormat!: string;
  @Prop() onSelect?: (date: [string, string]) => void;

  get minDate() {
    return this.pastDaysDisabled
      ? moment().format(this.dateFormatForDatePicker)
      : this.minimumDate
      ? this.minimumDate.format(this.dateFormatForDatePicker)
      : null;
  }

  get maxDate() {
    return this.futureDaysDisabled
      ? moment().format(this.dateFormatForDatePicker)
      : this.maximumDate
      ? this.maximumDate.format(this.dateFormatForDatePicker)
      : null;
  }

  get modelValue() {
    return this.value;
  }

  set modelValue(newModelValue: [string, string]) {
    if (newModelValue) {
      this.$emit("input", newModelValue);
      if (this.onSelect) {
        this.onSelect(newModelValue);
      }
    }
  }

  get dateInDisplayFormat() {
    if (Array.isArray(this.modelValue) && this.modelValue.length === 2) {
      return `${moment(this.modelValue[0]).format(
        this.dateDisplayFormat
      )} - ${moment(this.modelValue[1]).format(this.dateDisplayFormat)}`;
    }
    return "";
  }

  handleDateChange(dates: [string, string]) {
    const [start, end] = dates;
    if (start && end) {
      const startDate = moment(start);
      const endDate = moment(end);
      this.modelValue = dates;
      (this.$refs.menu as any).save(dates);
    }
  }

  cancel() {
    this.menu = false;
  }
}
</script>

<style scoped>
/* Add any necessary styling here */
</style>
