<template>
  <!-- <v-switch v-bind="$props" v-model="modelValue"></v-switch> -->
  <div class="d-inline-flex align-center">
  <v-switch
    :input-value="modelValue"
    @change="(newValue) => modelValue = newValue"
    :false-value="falseValue"
    :true-value="trueValue"
    :inset="inset"
    :disabled="disabled"
    class="mt-0"
    :hide-details="true"
  ></v-switch>
  <label class="v-label theme--light" >
    {{label}}
  </label>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { VSwitch } from "vuetify/lib/components";

/**
 * -----------------------------------------------------------------------------------------------------
 * NOTE: 
 * 1. FSwitch extends Vue and NOT VSwitch as VSwitch $emits does not emit the right value.
 * 2. @change is used instead of @input
 * -----------------------------------------------------------------------------------------------------
 */

@Component({
  components: {
    VSwitch,
  },
})
export default class FSwitch extends Vue {

  @Prop()
  falseValue: boolean;

  @Prop()
  trueValue: boolean;

  @Prop()
  label: boolean;

  @Prop()
  inset: boolean;
  
  @Prop()
  disabled: boolean;
  // MODEL VALUE - START
  @Prop()
  value: boolean;

  @Prop()
  onChange: (item:any) => void

  

  get modelValue() {
    return this.value;
  }

  set modelValue(newModelValue: boolean) {
    this.$emit("input", newModelValue);
    if(!!this.onChange) {
      this.onChange(newModelValue)
    }
  }
 
  // MODEL VALUE - END
}
</script>
