<template>
  <div class="d-flex">
    <v-menu
      ref="dateMenu"
      v-model="dateMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateInDisplayFormat"
          persistent-hint
          prepend-inner-icon="mdi-calendar"
          v-bind="{ ...$props, ...attrs }"
          v-on="on"
          @click:append="dateMenu = !dateMenu"
          style="border-top-right-radius:0;border-bottom-right-radius:0;"
        ></v-text-field>
      </template>
      <v-card flat>
        <v-date-picker
          v-model="date"
          @change="() => handleDateChange()"
          :min="minDate"
          :max="maxDate"
          no-title
          scrollable
        ></v-date-picker>
      </v-card>
    </v-menu>

    <v-menu
      ref="timeMenu"
      v-model="timeMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="390px"
      min-width="390px"
      @input="handleDateChange()"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
        style="border-top-left-radius:0;border-bottom-left-radius:0;"
          :value="timeInDisplayFormat"
          readonly
          persistent-hint
          prepend-inner-icon="mdi-clock-outline"
          v-bind="{ ...$props, ...attrs }"
          label="Time"
          v-on="on"
          @click:append="timeMenu = !timeMenu"
        ></v-text-field>
      </template>

      <v-card flat>
        <div class="pa-4 d-flex">
          <v-select
            label="hour"
            v-model="timeObject.hour"
            class="mr-3"
            outlined
            dense
            :items="hourList"
            item-text="name"
            item-value="value"
          ></v-select>
          <v-select
            label="minute"
            v-model="timeObject.minute"
            class="mr-3"
            outlined
            dense
            :items="minuteList"
            item-text="name"
            item-value="value"
          ></v-select>
          <v-select
            v-model="timeObject.period"
            label="Format"
            outlined
            dense
            :items="['AM', 'PM']"
          ></v-select>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>
  
  <script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import FTextField from "@/components/form/field/FTextField.vue";
import moment from "moment";
import { VTextField } from "vuetify/lib/components";

@Component({
  components: {
    FTextField,
  },
})
export default class FDateTimeSelectField extends VTextField {
  dateMenu: boolean = false;
  timeMenu: boolean = false;
  date: any = null;
  dateFormatForDateTimePicker: string = "YYYY-MM-DD";
  tab: number = 0;

  // MODEL VALUE - START
  @Prop()
  value: any;

  @Prop()
  pastDaysDisabled: boolean;

  @Prop()
  futureDaysDisabled: boolean;

  @Prop({
    default: "DD/MM/YYYY hh:mm:ss A",
  })
  dateDisplayFormat: string;

  get minDate() {
    return this.pastDaysDisabled
      ? moment().format(this.dateFormatForDateTimePicker)
      : null;
  }

  get maxDate() {
    return this.futureDaysDisabled
      ? moment().format(this.dateFormatForDateTimePicker)
      : null;
  }
  get modelValue() {
    this.date = this.value;
    console.log(this.value,"In getter")
    return this.value || "";
  }

  set modelValue(newModelValue: string) {
    console.log(newModelValue,"In setter")
    this.$emit("input", new Date(newModelValue).toISOString());
  }

  get dateInDisplayFormat() {
    return this.modelValue
      ? moment(this.modelValue).format(this.dateDisplayFormat)
      : "";
  }

  get timeInDisplayFormat() {
    return `${this.timeObject.hour}:${this.timeObject.minute
      .toString()
      .padStart(2, "0")}:${this.timeObject.period}`;
  }

  timeObjectLocal: any = {
    hour: null,
    minute: null,
    period: null,
  };
  get timeObject() {
    const hour = !!this.modelValue ? moment(this.modelValue).local().hour() : 5;
    this.timeObjectLocal = {
      hour: hour % 12 || 12,
      minute: !!this.modelValue ? moment(this.modelValue).local().minute() : 30,
      period: hour >= 12 ? "PM" : "AM",
    };

    return this.timeObjectLocal;
  }

  get24HourTime() {
    const time = `${this.timeObject.hour}:${this.timeObject.minute} ${this.timeObject.period}`;
    console.log(time,"time with period")
    const formattedTime = moment(time, "hh:mm A").format("HH:mm");
    console.log(formattedTime);
    return formattedTime;
  }

  set timeObject(newVal: any) {
    this.timeObjectLocal = newVal;
  }
  handleDateChange() {
    if (this.date) {
      this.dateMenu = false;
      const date = new Date(this.date);
      const time: any = this.get24HourTime() || "";
      const hours = time.match(/^(\d+)/)[1];
      const mins = time.match(/:(\d+)/)[1];
      console.log(hours, mins, "hours,Mins");
      date.setHours(hours);
      date.setMinutes(mins);
      this.modelValue = date.toISOString();
    }
  }

  get hourList() {
    const hourList = [];
    for (let i = 1; i <= 12; i++) {
      hourList.push({ value: i, name: i.toString().padStart(2, "0") });
    }
    return hourList;
  }

  get minuteList() {
    const minList = [];
    for (let i = 0; i <= 59; i++) {
      minList.push({ value: i, name: i.toString().padStart(2, "0") });
    }
    return minList;
  }
  // MODEL VALUE - END
}
</script>