<template>
  <v-navigation-drawer
    class="sideNavDrawer"
    expand-on-hover
    :mini-variant.sync="mini"
    fixed
  >
    <v-list class="list-item" nav dense>
      <v-list-item
        link
        v-for="item in items"
        :key="item.title"
        @click="takeAction(item.routerName)"
        v-bind:class="{
          'list-item-active': currentRouterName === item.routerName,
        }"
      >
        <v-icon
          class="mr-2"
          :color="item.routerName == currentRouterName ? 'secondary' : ''"
          >{{ item.icon }}</v-icon
        >
        <v-list-item-title class="activeColor">
          {{ item.title }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import store, * as Store from '@/../src-gen/store';
import * as Data from '@/../src-gen/data';
import * as ServerData from '@/../src-gen/server-data';
import * as Action from '@/../src-gen/action';
// import RouterUtil from "@/router/RouterUtil";
@Component({})
export default class SideNav extends Vue {
  @Store.Getter.Partner.ApplicantDetails.PartnerInfo
  PartnerInfo: Data.Partner.PartnerSummary;
  public mini = true;
  get items() {
    const item = [
      {
        title: 'Dashboard',
        icon: 'mdi-view-dashboard',
        routerName: 'InstitutionalPartner.InstitutionDashboard',
      },
      {
        title: 'Client File Search',
        icon: 'mdi-file-search',
        routerName: 'InstitutionalPartner.ClientFileSearch',
      },
      {
        title: 'Monthly Sales',
        icon: 'mdi-finance',
        routerName: 'InstitutionalPartner.MonthlySales',
      },
      {
        title: "Monthly Collection",
        icon: "mdi-hand-coin",
        routerName: "InstitutionalPartner.MonthlyCollection",
      },
      {
        title: "Unregistered Customers",
        icon: "mdi-account-alert",
        routerName: "InstitutionalPartner.UnregisteredCustomers",
      },
    ];
    return item;
  }

  get currentRouterName() {
    return this.$route.name || '';
  }

  takeAction(routerName: string) {
    this.$router.push({ name: routerName });
  }
}
</script>
<style scoped>
.sideNavDrawer {
  position: fixed !important;
  top: 50px !important;
}
.v-application .primary--text {
  color: #f56f07 !important;
  caret-color: #f56f07 !important;
}
.theme--light.v-navigation-drawer {
  background-color: #ffffff;
  position: absolute;
  /* height: calc(100vh) !important; */
  transform: inherit !important;
  border-top: 1px solid #eee;
  border-right: 1px solid #f7f8fb;
  /* width: 60px !important; */
}
.list-item-active .activeColor {
  color: #f56f07;
}

@media all and (min-device-width: 360px) and (max-device-width: 540px) {
  .theme--light.v-navigation-drawer {
    display: none;
  }
}
</style>
