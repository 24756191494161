
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class TaskWriterService {

   public static assign(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebWriter.Assign$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'valey-api/task/assign',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static reassign(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebWriter.Reassign$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'valey-api/task/reassign',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static pullTask(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebWriter.PullTask$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'bench-api/task/pull-task',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static start(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebWriter.Start$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'bench-api/task/start',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static suspend(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebWriter.Suspend$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/task/suspend',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static resume(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebWriter.Resume$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/task/resume',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static pullStartAndMerge(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebWriter.PullStartAndMerge$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/task/pull-start-and-merge',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static saveAndComplete(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebWriter.SaveAndComplete$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'bench-api/task/save-and-complete',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static complete(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebWriter.Complete$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'bench-api/task/complete',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static save(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebWriter.Save$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'bench-api/task/save',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static rescue(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebWriter.Rescue$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'valey-api/task/rescue',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static retry(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebWriter.Retry$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/task/retry',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static cancelFlow(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebWriter.CancelFlow$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/task/cancel-flow',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static cancelTask(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebWriter.CancelTask$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/task/cancel-task',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static proceed(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebWriter.Proceed$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'valey-api/task/proceed',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static addComment(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebWriter.AddComment$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/task/add-comment',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


}

