// import FFormMDP, { FFormChildMDP } from '@/components/form/FFormMDP';
// import FSelectDateFieldMDP from '@/components/form/field/FDateSelectFieldMDP';
// import FEmailFieldMDP from '@/components/form/field/FEmailFieldMDP';
// import FGompaUserRemoteAutoCompleteFieldMDP from '@/components/form/field/FGompaUserRemoteAutoCompleteMDP';
// import FPhoneFieldMDP from '@/components/form/field/FPhoneFieldMDP';
// import FSelectFieldMDP from '@/components/form/field/FSelectFieldMDP';
// import FTextFieldMDP from '@/components/form/field/FTextFieldMDP';
// import * as Data from '@/../src-gen/data';

// export default class ClientFileSearchFFormMDP extends FFormMDP {
//   childMDP = new FFormChildMDP();
//   taskRoot: any;
//   constructor({ taskRoot }: { taskRoot: any }) {
//     super({
//       myRefName: 'clientFileSearchFFormRef',
//     });
//     this.taskRoot = taskRoot;

//     this.addField(
//       new FTextFieldMDP({
//         parentMDP: this.childMDP,
//         dataSelectorKey: "clientFileNumber",
//         label: "File ID",
//         mandatory: true,
//         boundaryClass: "col-3",
//       })
//     )
//       .addField(
//         new FPhoneFieldMDP({
//           parentMDP: this.childMDP,
//           dataSelectorKey: 'mobile',
//           label: 'Mobile',
//         })
//       )
//     // .addField(
//     //   new FEmailFieldMDP({
//     //     parentMDP: this.childMDP,
//     //     dataSelectorKey: 'clEmailId',
//     //     label: 'Email',
//     //   })
//     // );

//   }

//   getMyRef(): any {
//     return this.taskRoot.$refs[this.myRefName];
//   }

//   searchClientFile() {
//     this.taskRoot.searchClientFile();
//   }
// }

import FFormMDP, { FFormChildMDP } from '@/components/form/FFormMDP';
import FSelectDateFieldMDP from '@/components/form/field/FDateSelectFieldMDP';
import FEmailFieldMDP from '@/components/form/field/FEmailFieldMDP';
import FGompaUserRemoteAutoCompleteFieldMDP from '@/components/form/field/FGompaUserRemoteAutoCompleteMDP';
import FPhoneFieldMDP from '@/components/form/field/FPhoneFieldMDP';
import FSelectFieldMDP from '@/components/form/field/FSelectFieldMDP';
import FTextFieldMDP from '@/components/form/field/FTextFieldMDP';
import * as Data from '@/../src-gen/data';
import FBtnMDP, { BtnType } from '@/components/FBtnMDP';
import { ActionType } from '@/components/table/FDataTableMDPbackup';

export default class CFSSearchFFormMDP extends FFormMDP {
  childMDP = new FFormChildMDP();
  parent: any;
  constructor({ parent }: { parent: any }) {
    super({
      myRefName: 'cfsSearchFForm',
    });
    this.parent = parent;

    this.addField(
      new FTextFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: "clientFileNumber",
        label: "File ID",
        boundaryClass: "col-3",
      })
    )
      .addField(
        new FPhoneFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: 'mobile',
          label: 'Mobile',
        })
      )
      .addAction(new FBtnMDP({
        label: "Search",
        onClick: () => this.parent.findParticularPartnerClient(),
      }));
  }

  getMyRef(): any {
    return this.parent.$refs[this.myRefName];
  }

}








