import FBtnMDP, { BtnType } from '@/components/FBtnMDP';
import FFormSubmitBtnMDP from '@/components/FFormSubmitBtnMDP';
import FFormMDP, { FFormChildMDP } from '@/components/form/FFormMDP';
import FSelectDateFieldMDP from '@/components/form/field/FDateSelectFieldMDP';

export default class DemographicFSearchFFormMDP extends FFormMDP {
  childMDP = new FFormChildMDP();
  taskRoot: any;
  parent: any;
  constructor({ taskRoot, parent }: { taskRoot: any; parent: any }) {
    super({
      myRefName: 'demographicFSearchRef',
    });
    this.parent = parent;
    this.taskRoot = taskRoot;

    this.addField(
      new FSelectDateFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: 'fromDate',
        label: 'From Date',
        futureDaysDisabled: false,
        boundaryClass: 'col-6',
      })
    )
      .addField(
        new FSelectDateFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: 'toDate',
          label: 'To Date',
          futureDaysDisabled: false,
          boundaryClass: 'col-6',
        })
      )
      .addAction(
        new FBtnMDP({
          label: 'Cancel',
          btnType: BtnType.TEXT,
          onClick: () => {
            this.closeAddForm();
          },
        })
      )
      .addAction(
        new FFormSubmitBtnMDP({
          label: 'Submit',
          onClick: () => this.taskRoot.getPartnerClientDemography(),
        })
      );
  }

  getMyRef() {
    return this.taskRoot.$refs[this.myRefName];
  }


  closeAddForm() {
    this.taskRoot.clearDemographicSearchForm();
  }
}
