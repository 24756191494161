<template>
  <v-autocomplete
    :value="modelValue"
    @input="(newValue) => (modelValue = newValue)"
    :items="fetchedItems"
    :loading="isLoading"
    :search-input.sync="searchValue"
    v-bind="this.$props"
  >
    <template v-slot:item="data">
        <span :class="`dot ${data.item.isServicedByFreed?'green':'red'}`"></span>
      <v-list-item-content class="ml-3">
        <v-list-item-title >{{ data.item.creditorName }}</v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script lang="ts">
import axios from "axios";
import { Component, Prop, Watch } from "vue-property-decorator";
import { VAutocomplete, VSelect } from "vuetify/lib";

@Component({
  components: {
    VAutocomplete,
  },
})
export default class FCreditorAutoCompleteField extends VAutocomplete {
  // MODEL VALUE - START
  @Prop()
  value: any;

//   @Prop({
//     default: "",
//   })
  queryUrl: string = "/spineapi/master/lookup-creditor?creditorName=";

  @Prop()
  onSelect: ((value: any) => void) | undefined;

  searchValue: string = "";
  isLoading: boolean = false;
  fetchedItems: any[] = [];

  @Prop({
    default: 0,
  })
  minSearchValueLength: number;

  @Watch("searchValue") onSearchValueChanged(value: string) {
    if (value?.length > this.minSearchValueLength) {
      this.fetchItems();
    } else {
      this.fetchedItems = [];
    }
  }

  mounted() {
    this.fetchItems();
  }

  get queryUrlWithSearchQuery(): string {
    return this.queryUrl + this.searchValue;
  }

  async fetchItems() {
    this.isLoading = true;
    try {
      let response = await axios.get(this.queryUrlWithSearchQuery);
      this.fetchedItems = response.data;
    } catch (e) {}
    this.isLoading = false;
  }

  get modelValue() {
    return this.value;
  }

  set modelValue(newModelValue: any) {
    this.$emit("input", newModelValue);
    if(this.onSelect) {
      this.onSelect(newModelValue)
    }
  }
  // MODEL VALUE - END
}
</script>

<style scoped>
.dot {
  position: relative;
  width: fit-content;
  
  border-radius: 40px;
  width: 8px;
  height: 8px;
}
.green {
    background: green;
}
.red {
    background: red;
}
</style>
