import { render, staticRenderFns } from "./CFTaskRedirect.vue?vue&type=template&id=c8adc968&"
import script from "./CFTaskRedirect.vue?vue&type=script&lang=ts&"
export * from "./CFTaskRedirect.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports