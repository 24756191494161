import FFieldMDP from '@/components/form/field/FFieldMDP';
import { FFormChildMDP } from '@/components/form/FFormMDP';

export default class FTextareaMDP implements FFieldMDP {
  componentName = 'FTextarea';
  dataSelectorKey: string;
  label: string;
  type: string;
  rules: string;
  mandatory: boolean;
  parentMDP: FFormChildMDP;
  boundaryClass: string;
  disabled: boolean;
  condition: boolean;
  rows: string;
  autoGrow: boolean;

  constructor({
    parentMDP,
    dataSelectorKey,
    label,
    type = 'text',
    rules = '',
    mandatory = false,
    boundaryClass = 'col-12',
    disabled = false,
    condition = true,
    rows = '2',
    autoGrow = true,
  }: {
    parentMDP: FFormChildMDP;
    dataSelectorKey: string;
    label: string;
    type?: string;
    rules?: string;
    mandatory?: boolean;
    boundaryClass?: string;
    disabled?: boolean;
    condition?: boolean;
    rows?: string;
    autoGrow?: boolean;
  }) {
    this.parentMDP = parentMDP;
    this.dataSelectorKey = dataSelectorKey;
    this.label = label;
    this.type = type;
    this.rules = rules;
    this.mandatory = mandatory;
    this.boundaryClass = boundaryClass;
    this.disabled = disabled;
    this.condition = condition;
    this.rows = rows;
    this.autoGrow = autoGrow;
  }

  getRules() {
    const required = this.mandatory ? 'required' : '';
    return `${required}|${this.rules}`;
  }

  getBoundaryClass() {
    return `${this.boundaryClass} py-0 px-2`;
  }

  getMetaData(): object {
    return {
      componentName: this.componentName,
      dataSelectorKey: this.dataSelectorKey,
      rules: this.getRules(),
      boundaryClass: this.getBoundaryClass(),
      condition: this.condition,
      props: {
        id: this.dataSelectorKey,
        label: this.label,
        type: this.type,
        outlined: this.parentMDP.outlined,
        dense: this.parentMDP.dense,
        disabled: this.disabled,
        rows: this.rows,
        autoGrow: this.autoGrow,
      },
    };
  }
}
