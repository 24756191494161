
import FSnackbar from "@/fsnackbar";
import * as Action from "@/../src-gen/action";
import FDataTableMDP, { ActionType } from "@/components/table/FDataTableMDP";
import FCellDocumentLinkMDP from "@/components/table/cell/FCellDocumentLinkMDP";
export default class FDocumentFDataTableMDP extends FDataTableMDP {
    parent: any;
    constructor({ parent }: { parent: any }) {
        super({ title: "Documents", myRefName: "SifDocumentListRef" });
        this.parent = parent;
        this.addColumn({
                label: "Document  Path",
                dataSelectorKey: "documentPath",
                hidden: true
            }).addColumn({
                label: "Document  Type",
                dataSelectorKey: "documentType",
                columnCellMDP: new FCellDocumentLinkMDP({
                    documentKeySelector: "documentPath"
                })
                // hidden: true
            }).addColumn({
                label: "Document  Details",
                dataSelectorKey: "documentDetails"
            }).addDateTimeColumn({
                label: "Uploaded  On",
                dataSelectorKey: "uploadedOn"
            }).addBooleanColumn({
                label: "Archived",
                dataSelectorKey: "archived"
            }).addAction({
                label: "Upload Document",
                type: ActionType.ADD,
                onClick: () => this.handleUploadClick(),
            }).addAction({
                label: "Delete",
                type: ActionType.DELETE,
                onClick: (item) => this.handleDelete(item)
            })
    }
    handleUploadClick() {
            return new Promise(res => {
                this.parent.showAddForm();
            })
    }

    handleDelete(item: any) {
            return new Promise(res => {
                // FSnackbar.confirm({
                        Action.ClientFile.DetachDocument.execute1(item.fiDocumentId, (output) => {
                            FSnackbar.success("Successfully removed")
                          },
                          error => {
                            console.log(error,"Error")
                          });
                // })
                res(true);
                
            })
    }
}
