<template>
  <div
    :class="[checkNumberSignflag == true ? 'increaseNumber' : 'decreaseNumber']"
    class="upDowncard"
  >
    <v-icon
      :color="checkNumberSignflag == true ? '#6fd567' : 'red'"
      x-small
      class="mr-1"
      >{{
        checkNumberSignflag == true ? 'mdi-arrow-up-thick' : 'mdi-arrow-down-thick'
      }}</v-icon
    >
    {{ removeMinu }}% Since Yesterday
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import moment from 'moment';
import ModelVue from '@/components/ModelVue';
import Helper from '@/util/Helper';

@Component({
  components: {},
})
export default class UpDowncard extends ModelVue {
  @Prop()
  data: number;

  get checkNumberSignflag() {
    return Helper.checkNumberSign(this.data);
  }

  get removeMinu() {
    return Math.abs(this.data);
  }
}
</script>

<style scoped>
.upDowncard {
  background: #ffffffb3;
  border-radius: 4px;
  padding: 3px 8px;
  display: inline-flex;
  font-weight: bold;
  font-size: 10px;
  width: 100%;
}
.increaseNumber {
  color: #6fd567;
}
.decreaseNumber {
  color: red;
}
</style>
