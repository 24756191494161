import FFormMDP, { FFormChildMDP } from '@/components/form/FFormMDP';
import FSelectDateFieldMDP from '@/components/form/field/FDateSelectFieldMDP';
import FEmailFieldMDP from '@/components/form/field/FEmailFieldMDP';
import FGompaUserRemoteAutoCompleteFieldMDP from '@/components/form/field/FGompaUserRemoteAutoCompleteMDP';
import FPhoneFieldMDP from '@/components/form/field/FPhoneFieldMDP';
import FSelectFieldMDP from '@/components/form/field/FSelectFieldMDP';
import FTextFieldMDP from '@/components/form/field/FTextFieldMDP';
import * as Data from '@/../src-gen/data';
import FBtnMDP, { BtnType } from '@/components/FBtnMDP';
import { ActionType } from '@/components/table/FDataTableMDPbackup';

export default class MonthlyCollectionFFormMDP extends FFormMDP {
  childMDP = new FFormChildMDP();
  parent: any;
  constructor({ parent }: { parent: any }) {
    super({
      myRefName: 'monthlyCollectionFFormRef',
    });
    this.parent = parent;

    this.addField(
      new FSelectFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: 'month',
        label: 'Month',
        options: monthList,
        optionLabel: "name",
        optionValue: "index",
        mandatory: true
      })
    )
      .addField(
        new FSelectFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: 'year',
          label: 'Year',
          options: yearList,
          mandatory: true
        })
      ).addAction(new FBtnMDP({
        label: "Search",
        onClick: () => this.parent.getCollectionReport(),
      }));
  }

  getMyRef(): any {
    return this.parent.$refs[this.myRefName];
  }

}

export const monthList = [{ "name": "Jan", "index": 0 }, { "name": "Feb", "index": 1 }, { "name": "Mar", "index": 2 }, { "name": "Apr", "index": 3 }, { "name": "May", "index": 4 }, { "name": "Jun", "index": 5 }, { "name": "Jul", "index": 6 }, { "name": "Aug", "index": 7 }, { "name": "Sept", "index": 8 }, { "name": "Oct", "index": 9 }, { "name": "Nov", "index": 10 }, { "name": "Dec", "index": 11 }]
export const yearList = [
  2021, 2022, 2023, 2024
]





