
import * as Data from '@/../src-gen/data';
import {ApplicantDetailsGetters} from '@/../src-gen/store/partner-store-support';

export class ApplicantDetailsInit {
    public static initState(): Data.Partner.ApplicantDetails  { return new Data.Partner.ApplicantDetails(); }
}

export class ApplicantDetailsGettersCode implements ApplicantDetailsGetters {


   public PartnerInfo(state: Data.Partner.ApplicantDetails): Data.Partner.PartnerSummary|undefined {
  return state.PartnerInfo
   }
   public GetReferralOrgCodeInfo(state: Data.Partner.ApplicantDetails): Data.Partner.GetReferralOrgCodeOutput {
    return state.GetReferralOrgCodeInfo
}

}
