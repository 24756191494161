<template>
  <div class="AffiliateHome">
   <div class="col">
      <div
        v-for="(dashboardItem, index) in dashboardItemList"
        :key="'dashboard' + index"
        class="col-4"
      >
        <v-card min-height="200px" class="mx-auto d-flex flex-column" outlined>
          <v-list-item min-height="150px" three-line>
            <v-list-item-content class="col-6">
              <v-icon style="position:absolute;" size="30">{{dashboardItem.icon}}</v-icon>
              <a @click="dashboardItem.onClick">
                <v-list-item-title class="my-1 text-caption text-center">
                  {{ dashboardItem.title }}
                </v-list-item-title>
                <v-list-item-title class="my-1 text-h4 text-center">
                  {{ dashboardItem.countText }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption text-center"
                  >{{ dashboardItem.subTitle }}
                </v-list-item-subtitle>
              </a>
            </v-list-item-content>
            <v-divider vertical class="my-2 mx-2"/>
            <v-list-item-content>
              <a
                v-for="(subItem, index) in dashboardItem.subItemList"
                @click="subItem.onClick"
                :key="'subitem' + index"
                :class="`${subItem.color}--text`"
              >
                <v-list-item-title class="d-flex text-caption align-center">
                  {{ subItem.title }} <v-spacer />
                  <div class="text-h6 mx-5">
                    {{ subItem.count }}
                  </div>
                </v-list-item-title>
                <v-divider />
              </a>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
   </div>
  </div>

</template>

<script lang="ts">

import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import store, * as Store from '@/../src-gen/store';
import * as Data from '@/../src-gen/data';
// import * as ServerData from '@/../src-gen/server-data';
import * as Action from '@/../src-gen/action';

@Component
export default class AffiliateHome extends Vue {
  @Store.Getter.ClientFile.MyClientFileStore.clientFileDashboardSummary
  clientFileDashboardSummary: Data.ClientFile.ClientFileDashboardSummary;
get dashboardItemList(): any[] {
    return [
      {
        title: "My Client Files",
        countText: this.clientFileDashboardSummary.totalCount,
        subTitle: "Files",
        onClick: () => this.openClientFilesWithQuery(),
        icon:"mdi-file-outline",
        subItemList: [
          {
            title: "Active",
            count: this.clientFileDashboardSummary.activeCFCount,
            onClick: () => this.openClientFilesWithQuery("file_active"),
            color: "green"
          },
          {
            title: "Agreement Signed",
            count: this.clientFileDashboardSummary.agreementCFCount,
            onClick: () => this.openClientFilesWithQuery("file_agreement"),
            color: "primary"
          },
           {
            title: "On Hold",
            count: this.clientFileDashboardSummary.holdCFCount,
            onClick: () => this.openClientFilesWithQuery("file_hold"),
            color: "secondary"
          },
          {
            title: "Lead",
            count: this.clientFileDashboardSummary.leadCFCount,
            onClick: () => this.openClientFilesWithQuery("file_lead"),
            color: "secondary"
          },
        ],
      },
      
    ];
  }
  openClientFilesWithQuery(query?: string) {
    this.$router.push({
      name: "PartnerAffiliate.MyClientFiles",
      query: { filter: query },
    });
  }
}

</script>

<style>
</style>
