<template>
  <div class="Dashboard">
    <v-container
      class="fill-height"
      v-bind:style="{ opacity: isActive ? 1 : 0 }"
    >
      <v-layout column>
        <div class="d-flex justify-end pb-4" v-if="!addPartnerClientForm">
          <v-btn color="primary" small @click="registerClient()"
            >Register a client</v-btn
          >
        </div>
        <v-card class="mb-5" v-if="!!addPartnerClientForm">
          <v-card-title>Add Client Details</v-card-title>
          <component
            v-if="!!addPartnerClientForm"
            :ref="addPartnerFFormMetaData.myRefName"
            :is="addPartnerFFormMetaData.componentName"
            v-model="addPartnerInput"
            v-bind="addPartnerFFormMetaData.props"
          ></component>
        </v-card>
        <div v-if="PartnerInfo?.uniqueReferralCode">
          <section class="referral-container">
            <section class="referrelColorText">
              Unique Referrel ID :<br />
            </section>
            <span class="referral-link">{{ referrelId }}</span>
            <div class="sharingLink">
              <span>
                <v-icon
                  v-if="copied"
                  size="16"
                  color="green"
                  @click="copyToClipBoard(referrelId)"
                  >mdi-check</v-icon
                >
                <v-icon v-else size="16" @click="copyToClipBoard(referrelId)"
                  >mdi-content-copy</v-icon
                >
              </span>
              <span>
                <v-icon size="16" @click="shareTheId(referrelId)"
                  >mdi-share-variant</v-icon
                >
              </span>
            </div>
          </section>
        </div>
        <div class="mt-5">
          <section class="calculator-container" @click="gotoCalculator">
            <v-icon small color="primary" class="mr-2">mdi-calculator</v-icon>
            <section>Calculator</section>
          </section>
        </div>
        <section class="infoContainer">
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <div class="graphContainer">
                <div id="referencePiechart"></div>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <div class="box_container">
                <v-row>
                  <v-col cols="6">
                    <div class="item">
                      <p>
                        Total Earnings
                        <span v-if="PartnerInfo?.totalEarnings">{{
                          PartnerInfo?.totalEarnings | toINR
                        }}</span>
                        <span v-else>{{ 0 | toINR }}</span>
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="item">
                      <p>
                        Current Month Earnings
                        <span v-if="PartnerInfo?.currentEarnings">{{
                          PartnerInfo?.currentEarnings | toINR
                        }}</span>
                        <span v-else>{{ 0 | toINR }}</span>
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="item">
                      <p>
                        Total Debt Enrolled<span>{{
                          PartnerInfo?.totalDebtEnrolled | toINR
                        }}</span>
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="item">
                      <p>
                        Success Rate <span>{{ conversionRate }}%</span>
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </section>
        <v-tabs
          background-color="transparent"
          flat
          color="secondary"
          centered
          v-model="activeTab"
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab
            :active="activeTab === tabIndx"
            v-for="(tab, tabIndx) in filteredTabList"
            :key="tab.tabName"
          >
            {{ tab.tabName }}
            <!-- <v-icon>mdi-phone</v-icon> -->
          </v-tab>
        </v-tabs>
        <div v-if="activeTab == 0">
          <div class="dashboardHeading">My Client</div>
          <template>
            <v-sheet rounded outlined class="mb-3">
              <v-card flat class="col py-2">
                <v-data-table
                  :single-select="singleSelect"
                  item-key="id"
                  :headers="headers"
                  :items="clientFileLocal"
                  :items-per-page="10"
                >
                  <template v-slot:[`item.clientFileNumber`]="{ item }">
                    <v-btn
                      text
                      small
                      color="secondary"
                      @click="gotoClientFileInfo(item)"
                      >{{ item.clientFileNumber }}</v-btn
                    >
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                      outlined
                      x-small
                      label
                      :color="getColor(item.status.id)"
                    >
                      {{ item.status.name }}
                    </v-chip>
                  </template>
                </v-data-table>
              </v-card>
            </v-sheet>
          </template>
        </div>
        <div v-if="activeTab == 1">
          <v-card v-if="!!verifyOTPForm">
            <component
              v-if="!!verifyOTPForm"
              :ref="verifyOTPFFormMetaData.myRefName"
              :is="verifyOTPFFormMetaData.componentName"
              v-model="validateOTPInput"
              v-bind="verifyOTPFFormMetaData.props"
            ></component>
          </v-card>
          <div class="d-flex justify-space-between align-center">
            <div class="dashboardHeading">Unverified Client</div>
            <v-btn
              v-if="!verifyOTPForm"
              outlined
              color="primary"
              small
              @click="verifyMobile()"
              :disabled="!getterenerateOTP"
              >Verify Mobile</v-btn
            >
          </div>

          <template>
            <v-sheet rounded outlined class="mb-3">
              <v-card flat class="col py-2">
                <v-data-table
                  @input="handleSelectChange"
                  item-key="mobile"
                  :show-select="showCheckbox"
                  :single-select="true"
                  :headers="unverifiedHeaders"
                  :items="unverifiedFileList"
                  :items-per-page="10"
                >
                  <template v-slot:[`item.registeredOn`]="{ item }">
                    <span class="grey--text">
                      {{ item.registeredOn | date }}</span
                    >
                  </template>
                  <template v-slot:[`item.mobile`]="{ item }">
                    {{ item.mobile | phone }}
                  </template>
                  <template v-slot:[`item.emailId`]="{ item }">
                    <span class="caption mr-2">{{ item.emailId }}</span>
                  </template>
                </v-data-table>
              </v-card>
            </v-sheet>
          </template>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import store, * as Store from '@/../src-gen/store';
import * as Data from '@/../src-gen/data';
// import * as ServerData from '@/../src-gen/server-data';
import * as Action from '@/../src-gen/action';
import AddPartnerFFormMDP from './AddPartnerFFormMDP';
import FForm from '@/components/form/FForm.vue';
import FSnackbar from '@/fsnackbar';
import VerifyOTPFFormMDP from './VerifyOTPFFormMDP';
import Helper from '@/util/Helper';
declare var anychart: any;

@Component({
  components: {
    FForm,
  },
})
export default class Home extends Vue {
  @Store.Getter.Partner.ApplicantDetails.PartnerInfo
  PartnerInfo: Data.Partner.PartnerSummary;

  @Store.Getter.Partner.ApplicantDetails.GetReferralOrgCodeInfo
  referralOrgCodeInfo: Data.Partner.GetReferralOrgCodeOutput;

  addPartnerInput: Data.Partner.AddPartnerInput =
    new Data.Partner.AddPartnerInput();

  referralOrgCodeInput: Data.Partner.GetReferralOrgCodeInput =
    new Data.Partner.GetReferralOrgCodeInput();

  sendOTPInput: Data.Partner.SendOTPInput = new Data.Partner.SendOTPInput();

  validateOTPInput: Data.Partner.ValidateAndRegisterPartnerClientFileInput =
    new Data.Partner.ValidateAndRegisterPartnerClientFileInput();

  onboardedFileInput: Data.Partner.OnboardedFileInput =
    new Data.Partner.OnboardedFileInput();

  colorCodeData = Data.Color.CLIENT_FILE_STATUS;

  files: any = [];
  unverifiedFileList: any = [];
  conversionRate = 0;
  activeTab = 0;
  addPartnerClientForm: boolean = false;
  multiSelect: boolean = false;
  showCheckbox: boolean = true;
  verifyOTPForm: boolean = false;
  getterenerateOTP: boolean = false;

  headers = [
    {
      text: 'S. No.',
      align: 'start',
      value: 'sno',
    },
    {
      text: 'Client ID',
      value: 'clientFileNumber',
    },
    { text: 'Name', value: 'clientFullName' },
    { text: 'Status', value: 'status' },
  ];

  unverifiedHeaders = [
    {
      text: 'Client Name',
      align: 'start',
      value: 'fullName',
    },
    { text: 'Email ID', value: 'emailId' },
    { text: 'Mobile', value: 'mobile' },
    { text: 'Program Code', value: 'programCode.name' },
    { text: 'Registered On', value: 'registeredOn' },
  ];

  filteredTabList = [
    {
      tabName: 'My Client',
    },
    {
      tabName: 'Unverified Client',
    },
  ];

  goto(routerName: string) {
    if (this.$route.name === routerName) {
      // do nothing
    } else {
      this.$router.push({ name: routerName });
    }
  }

  gotoClientFileInfo(item: any) {
    this.$router.push({
      name: 'Partner.ClientFileInfo',
      params: {
        clientFileId: item.clientFileId,
      },
    });
  }

  get isActive() {
    return (
      this?.PartnerInfo?.status.id === Data.Partner.PARTNER_STATUS.ACTIVE.id
    );
  }
  copied: boolean = false;
  get referrelId() {
    if (this?.PartnerInfo?.uniqueReferralCode)
      return `${process.env.VUE_APP_REFERRAL_URL}?fppUrc=${this.PartnerInfo.uniqueReferralCode}`;
    else return '';
  }
  singleSelect: boolean = false;

  copyToClipBoard(textToCopy: string) {
    if (!!this.referrelId) {
      this.copied = true;
      Helper.copyToClipBoard(this.referrelId);
      setTimeout(() => {
        this.copied = false;
      }, 1000);
    }
  }

  shareTheId(referralId: string) {
    navigator.share({ url: referralId });
  }
  mounted() {
    this.getFileList();
    this.getUnverifiedFileList();
    setTimeout(() => {
      this.getReferralOrgCode();
    }, 2000);
  }
  get partnerId() {
    return localStorage.getItem('partnerId') || '';
  }

  gotoCalculator() {
    this.$router.push({ name: 'Root.PartnerCalculator' });
  }

  get addPartnerFFormMetaData(): any {
    return new AddPartnerFFormMDP({ root: this }).getMetaData();
  }

  get verifyOTPFFormMetaData(): any {
    return new VerifyOTPFFormMDP({ root: this }).getMetaData();
  }
  getFileList() {
    this.onboardedFileInput.partnerId = this.partnerId;
    this.onboardedFileInput.fromDate = Helper.dateMTDWithCurrent().startOfYear;
    this.onboardedFileInput.toDate = Helper.dateMTDWithCurrent().currentDate;
    Action.Partner.GetOnboardedFileList.execute(
      this.onboardedFileInput,
      (output) => {
        this.files = output;
        let active = 0;
        let inactive = 0;
        if (this.files.length !== 0) {
          this.files.forEach((element: any) => {
            if (element.status.id === 'ACTIVE') {
              active += 1;
            } else {
              inactive += 1;
            }
          });
          this.conversionRate = Math.ceil((active / this.files.length) * 100);
          this.drawPiechart(active, inactive);
        }
      }
    );
  }

  getReferralOrgCode() {
    this.referralOrgCodeInput.uniqueReferralCode =
      this.PartnerInfo.uniqueReferralCode;
    Action.Partner.GetReferralOrgCode.execute(
      this.referralOrgCodeInput,
      (output) => {}
    );
  }

  get clientFileLocal() {
    return this.files.map((item: any, index: number) => {
      return { ...item, sno: index + 1 };
    });
  }

  getFileListUpdate() {
    this.onboardedFileInput.partnerId = this.partnerId;
    this.onboardedFileInput.fromDate = Helper.dateMTDWithCurrent().startOfYear;
    this.onboardedFileInput.toDate = Helper.dateMTDWithCurrent().currentDate;
    Action.Partner.GetOnboardedFileList.execute(
      this.onboardedFileInput,
      (output) => {
        this.files = output;
      }
    );
  }

  handleSelectChange(newVal: any) {
    if (newVal.length) {
      this.getterenerateOTP = true;
      this.sendOTPInput.mobile = newVal[0]['mobile'];
      this.sendOTPInput.uniqueReferralCode = newVal[0]['uniqueReferralCode'];
      this.validateOTPInput.partnerClientId = newVal[0]['partnerClientId'];
    } else {
      this.getterenerateOTP = false;
    }
  }

  registerClient() {
    this.resetRegisterClientform();
    this.addPartnerClientForm = true;
  }

  get noteData() {
    const noteData = {
      totalDebt:
        Number(this.addPartnerInput.totalCreditCardDebt) +
        Number(this.addPartnerInput.totalPersonalLoanDebt),
      creditCardLoanDebt: this.addPartnerInput.totalCreditCardDebt,
      personalLoanDebt: this.addPartnerInput.totalPersonalLoanDebt,
      emiMissed: this.addPartnerInput.missedEmiInLast3Months,
    };
    return JSON.stringify(noteData);
  }

  // get addPartnerInputLocal() {
  //   this.addPartnerInput.note = this.noteData;
  //   this.addPartnerInput.uniqueReferralCode =
  //     this.PartnerInfo.uniqueReferralCode;
  //   if (this.addPartnerInput.emiMissed == true) {
  //     this.addPartnerInput.programCode = Data.Partner.PROGRAM_CODE.DRP;
  //   }
  //   if (this.addPartnerInput.emiMissed == false) {
  //     this.addPartnerInput.programCode = Data.Partner.PROGRAM_CODE.DCP;
  //   }
  //   return this.addPartnerInput;
  // }

  resetRegisterClientform() {
    this.addPartnerClientForm = false;
    this.addPartnerInput = new Data.Partner.AddPartnerInput();
  }

  addPartnerClient() {
    this.addPartnerInput.note = this.noteData;
    this.addPartnerInput.uniqueReferralCode =
      this.PartnerInfo.uniqueReferralCode;
    Action.Partner.AddPartnerClient.execute(this.addPartnerInput, (output) => {
      this.addPartnerClientForm = false;
      this.activeTab = 1;
      this.getUnverifiedFileList();
      FSnackbar.success('Succesfully added client');
    });
  }

  verifyMobile() {
    this.verifyOTPForm = true;
  }

  resetOTP() {
    this.validateOTPInput =
      new Data.Partner.ValidateAndRegisterPartnerClientFileInput();
  }

  resetform() {
    this.verifyOTPForm = false;
    this.validateOTPInput =
      new Data.Partner.ValidateAndRegisterPartnerClientFileInput();
  }

  sendOTP() {
    Action.Partner.SendOTP.execute(this.sendOTPInput, (output) => {
      FSnackbar.success('Succesfully send a otp');
    });
  }

  validateAndRegisterPartnerClientFile() {
    Action.Partner.ValidateAndRegisterPartnerClientFile.execute(
      this.validateOTPInput,
      (output) => {
        this.verifyOTPForm = false;
        this.getterenerateOTP = false;
        this.activeTab = 0;

        FSnackbar.success('Succesfully Validate OTP');
        setTimeout(() => {
          this.resetOTP();
          this.getFileListUpdate();
          this.getUnverifiedFileList();
        }, 3000);
      }
    );
  }

  getUnverifiedFileList() {
    Action.Partner.GetUnverifiedFileList.execute1(this.partnerId, (output) => {
      this.unverifiedFileList = output;
    });
  }

  drawPiechart(active: number = 0, inActive: number = 0) {
    anychart.licenseKey('freed.care-d6091ff-f670e3b3');
    const chart = anychart.pie();
    var credits = chart.credits();

    credits.enabled(false);
    var data = [
      {
        x: 'Active Clients',
        value: active,
        fill: '#f36f21',
        state: 'selected',
        season: active,
        selected: { outline: { enabled: false } },
      },
      {
        x: 'Leads',
        value: inActive,
        fill: '#00447a',
        fontColor: '#fff',
        state: 'selected',
        season: inActive,
        selected: { outline: { enabled: false } },
      },
      // {
      //   x: 'Total Clients',
      //   value: this.files.length,
      //   fill: '#15a0c0',
      //   fontColor: '#fff',
      //   state: 'selected',
      //   season: this.files.length,
      //   selected: { outline: { enabled: false } },
      // },
    ];
    chart.data(data);
    chart.radius('100%');
    // chart.legend().enabled(false);
    chart.container('referencePiechart');
    chart.labels().format('{%season}');
    chart.draw();
  }

  getColor(selectedStatus: string) {
    try {
      return this.colorCodeData.withId(selectedStatus)?.name || 'purple';
    } catch (e) {
      return 'default';
    }
  }
}
</script>

<style lang="scss" scoped>
.Dashboard {
  background: #f8f9fa;
  min-height: 100vh;
}
.dashboardHeading {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  padding: 15px 0;
}
.list-item {
  margin: 0 25px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  > div {
    width: 100%;
    padding: 15px 20px;
    background: #eff0f3;
    border-radius: 0 50px 50px 0;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
  }
}
.header {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  padding: 5px 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  img {
    width: 100px;
  }
}
.menu {
  background: #dee5f8;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}
.profile-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.referral-container {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #004479;
  background: #ffffff;
  padding: 5px 20px;
  border-radius: 8px;
  border: 1px solid #004479;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    span {
      display: inline-block;
      background: #e9eaea;
      width: 25px;
      height: 25px;
      border-radius: 50px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  section {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    gap: 3px;
    span {
      display: inline-block;
      max-width: calc(100vw * 0.5);
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 400;
      overflow: hidden;
    }
  }
}
.sharingLink {
  position: absolute;
  right: 15px;
}
.referrelColorText {
  color: rgba(0, 0, 0, 0.87);
}
.infoContainer {
  background: #ffffff;
  justify-content: space-between;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #dfdfe0;
  margin-top: 20px;
  .item {
    width: 100%;
    background: #e4e5ea;
    padding: 10px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    height: 100%;
    align-items: center;
    p {
      margin-bottom: 5px;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
    }
    span {
      display: block;
      text-align: center;
      font-weight: 400;
    }
  }
}
.graphContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-bottom: 15px;
  position: relative;
  > div {
    width: 100%;
    height: 200px;
    // border-radius: 50%;
    // background: #15a0c0;
  }
}
.calculator-container {
  background: #fff;
  border-radius: 8px;
  padding: 5px 20px;
  color: #004479;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #004479;
  cursor: pointer;
  align-items: center;
  display: flex;
}

.box_container {
  margin: 20px 0;
}
@media screen and (max-width: 650px) {
  .referral-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    div {
      width: 100%;
      justify-content: flex-end;
    }
    section {
      span {
        max-width: calc(100vw * 0.45);
      }
    }
  }
}
</style>
