
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class PaymentSchedulePlanWriterService {

   public static draftPSPlanForPM(
           axiosInstance: AxiosInstance, input: ServerData.PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Input,
           f: (output: ServerData.PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/paymentscheduleplan/draft-p-s-plan-for-p-m',
            input.toJson()
        ).then((response) => ServerData.PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static recalculatePSPlanForPM(
           axiosInstance: AxiosInstance, input: ServerData.PaymentSchedulePlanWebWriter.RecalculatePSPlanForPM$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/paymentscheduleplan/recalculate-p-s-plan-for-p-m',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


}

