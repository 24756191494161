<template>
  <div ref="documentListRef">
    <component
      v-if="uploadDocumentDialog"
      :is="uploadDocumentFormMetaData.componentName"
      :ref="uploadDocumentFormMetaData.myRefName"
      v-model="uploadDocumentForm"
      v-bind="uploadDocumentFormMetaData.props"
    ></component>

    <component
      v-if="!!fDocumentFDataTableMetaData"
      :is="fDocumentFDataTableMetaData.componentName"
      :ref="fDocumentFDataTableMetaData.myRefName"
      v-model="fileDocumentList"
      v-bind="fDocumentFDataTableMetaData.props"
    ></component>

    <v-col class="col-12">
      <!--ACTION START-->
      <div
        class="d-flex flex-row align-start flex-wrap justify-space-around pa-2 my-5"
        v-if="!disabled"
      >
        <component
          v-for="(actionMetaData, index) of actionMetaDataListFiltered"
          :key="'action' + index"
          :is="actionMetaData.componentName"
          :ref="actionMetaData.myRefName"
          v-bind="actionMetaData.props"
        ></component>
      </div>
      <!--ACTION END-->
    </v-col>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import * as Data from "@/../src-gen/data";
import * as Action from "@/../src-gen/action";
import store, * as Store from "@/../src-gen/store";
import FForm from "@/components/form/FForm.vue";

import FBtn from "@/components/FBtn.vue";
import * as Snackbar from "node-snackbar";
import axios from "axios";
import FDocumentFDataTableMDP from "./FDocumentFDataTableMDP";
import FDataTable from "@/components/table/FDataTable.vue";
import ModelVue from "@/components/ModelVue";

@Component({
  components: {
    FForm,
    FBtn,
    FDataTable,
  },
})
export default class FDocument extends ModelVue {
  @Store.Getter.ClientFile.ClientFileSummary.clientFileBasicInfo
  clientFileBasicInfo: Data.ClientFile.ClientFileBasicInfo;

  uploadDocumentForm = new Data.ClientFile.UploadDocumentForm();
  uploadedDocument: Data.Spine.FileDocument = new Data.Spine.FileDocument();
  fileType: string = "Active";

  uploadDocumentDialog = false;

  presignedUrl: string;

  @Prop()
  uploadDocumentFormMetaData: any;

  @Prop()
  actionMetaDataList: any[];

  @Prop()
  disabled: boolean;

  @Prop()
  taskRoot: any;

  get clientFileNumber(): string {
    return this.$route.params.clientFileNumber;
  }

  //MetaData
  get fDocumentFDataTableMetaData() {
    return new FDocumentFDataTableMDP({ parent: this }).getMetaData();
  }
  //MetaData

  showAddForm() {
    this.closeDialogs();
    this.uploadDocumentDialog = true;
  }

  closeAndClearAllForms() {
    this.closeDialogs();
    this.resetForms();
  }
  closeDialogs() {
    this.uploadDocumentDialog = false;
  }
  resetForms() {
    this.uploadDocumentForm = new Data.ClientFile.UploadDocumentForm();
    this.uploadedDocument = new Data.Spine.FileDocument();
  }

  mounted() {}

  get actionMetaDataListFiltered() {
    return this.actionMetaDataList.filter(
      (actionMetaData) =>
        actionMetaData.condition === undefined ||
        actionMetaData.condition === true
    );
  }

  getFileNameFromDocPath(key: string) {
    return key.split("/").pop();
  }

  getPresignedURLAndUpload() {
    const fileName = this.generateRandomUrl(this.uploadDocumentForm.fileDoc);
    Action.Spine.GetFiPresignedURLForUpload.execute2(
      this.clientFileBasicInfo.clientFileNumber,
      fileName,
      (output) => {
        this.presignedUrl = output.url;
        this.uploadedDocument.documentPath = output.docUploadedPath;
        this.uploadFile();
      }
    );
  }

  async uploadFile() {
    const options: any = {
      headers: {
        "Content-Type": this.uploadDocumentForm.fileDoc?.type,
      },
    };
    //const axiosResponse = await axios.put(
const axiosResponse = await axios.create().put(
      this.presignedUrl,
      this.uploadDocumentForm.fileDoc,
      options
    );
    this.attachAndSaveUploadedFile();
  }

  attachAndSaveUploadedFile() {
    this.uploadedDocument.documentType = this.uploadDocumentForm.docType;
    this.uploadedDocument.uploadedOn = new Date();
    // this.uploadedDocument.documentDetails =
    //   this.uploadDocumentForm.documentDetails;
    const input = Data.Spine.AttachDocumentInput.fromJson(
      this.uploadedDocument
    );
    input.documentDetails = this.uploadDocumentForm.documentDetails;
    input.clientFileId = this.taskRoot.clientFileBasicInfo.clientFileId;
    input.taskId = this.taskRoot.taskId;
    Action.Spine.AttachDocument.execute(input, (output) => {
      Snackbar.show({
        text: "Succesfully Added",
        pos: "bottom-center",
      });
      this.closeAndClearAllForms();
    });
  }

  generateRandomUrl(file: File | null) {
    if (file) {
      const dateValue = new Date().valueOf();
      return dateValue + file.name;
    }
    return "";
  }

  get fileDocumentList() {
    let docList = [];
    if (this.fileType === "All") {
      docList = this.modelValue;
    } else if (this.fileType === "Active") {
      docList = (this.modelValue as Data.ClientFile.FiDocument[]).filter(
        (item) => item.archived === false
      );
    } else {
      docList = (this.modelValue as Data.ClientFile.FiDocument[]).filter(
        (item) => item.archived === true
      );
    }
    return docList;
  }
}
</script>
