
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class ClientReaderService {

   public static getClientBasicInfo(
           axiosInstance: AxiosInstance, input: ServerData.ClientWebReader.GetClientBasicInfo$Input,
           f: (output: ServerData.SpineData$ClientBasicInfo) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/client/get-client-basic-info',
            {params : input.toJson()}
        ).then((response) => ServerData.SpineData$ClientBasicInfo.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getClientFileBasicInfoList(
           axiosInstance: AxiosInstance, input: ServerData.ClientWebReader.GetClientFileBasicInfoList$Input,
           f: (output: ServerData.SpineData$ClientFileBasicInfo[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/client/get-client-file-basic-info-list',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.SpineData$ClientFileBasicInfo.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getClCreditorList(
           axiosInstance: AxiosInstance, input: ServerData.ClientWebReader.GetClCreditorList$Input,
           f: (output: ServerData.SpineData$ClCreditor[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/client/get-cl-creditor-list',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.SpineData$ClCreditor.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static searchClient(
           axiosInstance: AxiosInstance, input: ServerData.ClientWebReader.SearchClient$Input,
           f: (output: ServerData.SpineData$ClientSearch[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/client/search-client',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.SpineData$ClientSearch.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


}

