<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-row class="align-center justify-center loginMobileView">
          <v-col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            class="align-center justify-center"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <img
                src="@/assets/images/freed-logo.svg"
                class="img-fluid logo-size"
                alt="..."
                width="120"
              />
            </div>
          </v-col>
          <v-col xs="12" sm="8" md="4">
            <v-card flat outlined>
              <v-toolbar color="primary" flat dark>
                <v-avatar size="40px">
                  <v-icon>mdi-account-circle-outline</v-icon>
                </v-avatar>
                <v-toolbar-title>Forgot Password</v-toolbar-title>

                <v-spacer></v-spacer>
                <v-list-item-avatar>
                  <v-img src="@/assets/images/freed-small-logo.svg" />
                </v-list-item-avatar>
              </v-toolbar>
              <v-card-text>
                <v-form ref="signInRef">
                  <v-text-field
                    label="Mobile Number / User Name"
                    outlined
                    dense
                    :rules="userNameRules"
                    v-model="mobileNumber"
                  ></v-text-field>
                  <v-text-field
                    v-if="validateOTPState"
                    label="OTP"
                    :type="type"
                    outlined
                    dense
                    v-model="verifyOTP"
                  ></v-text-field>
                  <div class="d-flex align-center justify-center">
                    <v-btn
                      color="secondary"
                      variant="flat"
                      @click="sendOTP"
                      v-if="!validateOTPState"
                    >
                      Send OTP
                    </v-btn>
                    <v-btn
                      color="secondary"
                      variant="flat"
                      @click="validateOTP"
                      v-if="validateOTPState"
                    >
                      Verify OTP
                    </v-btn>
                  </div>
                  <div class="info-text">
                    Don't have an account?
                    <span @click="goToHome">Register</span>
                  </div>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
// import store, * as Store from '@/../src-gen/store';
import * as Data from "@/../src-gen/data";
// import * as ServerData from '@/../src-gen/server-data';
import * as Action from "@/../src-gen/action";
import * as Snackbar from "node-snackbar";

@Component({
  components: {},
})
export default class ForgotPassword extends Vue {
  userNameRules = [
    (v: any) => !!v || "Mobile Number / User Name is required",
    // (v: any) => {
    //   const value = v?.replace(" ", "");
    //   return (!!value && value.length === 10) || "Please Enter valid  Number";
    // },
  ];
  mobileNumber: string = "";
  verifyOTP: string = "";
  type: string = "password";

  validateOTPState: boolean = false;

  goToHome() {
    this.$router.push({ name: "Root.Home" });
  }

  sendOTP() {
    Action.Login.SendOTP.execute1(this.mobileNumber, (output: any) => {
      Snackbar.show({
        text: "Succesfully send OTP.",
        pos: "bottom-center",
      });
      this.validateOTPState = true;
    });
  }

  validateOTP() {
    Action.Login.ValidateOTP.execute2(
      this.mobileNumber,
      this.verifyOTP,
      (output: any) => {
        Snackbar.show({
          text: "Succesfully verify OTP.",
          pos: "bottom-center",
        });
        this.$router.push({ name: "Root.ResetPassword", query: { mobileNumber: this.mobileNumber} });
      }
    );
  }
}
</script>

<style lang="scss" scoped>
.info-text {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  color: #676767;
  > span {
    color: #f36f21;
    font-weight: bold;
    cursor: pointer;
  }
}
@media screen and (max-width: 960px) {
  .loginMobileView {
    display: block;
  }
}
</style>
