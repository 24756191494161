import FColumnCellMDP from '../FColumnCellMDP';
export default class FCellNumberMDP implements FColumnCellMDP {
    rounded: boolean | undefined;
    constructor({rounded=false,}: {rounded?: boolean;}) {
        this.rounded = rounded;
    }
    componentName: string = "FCellNumber";
    getMetaData(): object {
        return {
            componentName: this.componentName,
            props: {
                rounded: this.rounded,
            }
        }
    }

}