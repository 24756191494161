import MDP from "../MDP";


export default class FChartLineMDP implements MDP {
  dataSelectorKey: string;
  legend: string;
  type?: string;

  constructor(props: {
    dataSelectorKey: string;
    legend: string;
    type?: string;
  }) {
    this.dataSelectorKey = props.dataSelectorKey;
    this.legend = props.legend;
    this.type = props.type;
  }

  getMetaData(): object {
    return {
      legend: this.legend,
      value: this.dataSelectorKey,
      type: this.type,
    };
  }
}
