<template>
  <div class="PartnerLearningvideo">
    <header class="header">
      <img :src="require('@/assets/images/freed-logo.svg')" @click="gotoHome" />
    </header>
    <v-container>
      <v-card-title>Revenue calculator</v-card-title>
      <div class="row">
        <div class="col-6">
          <v-card class="pa-2" height="130">
            <div class="calculatorSction">
              <div class="headfont12">1<sup>st</sup> MSF commission</div>
              <div class="msfAMount">₹1500</div>
            </div>
          </v-card>
        </div>
        <div class="col-6">
          <v-card class="pa-2" height="130">
            <div class="calculatorSction">
              <div class="headfont12">MSF commission per month</div>
              <div class="msfAMount">₹50</div>
            </div>
          </v-card>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <v-text-field
            label="Sales Avg Monthly Count"
            v-model="sales"
            outlined
            hide-details="auto"
            dense
          ></v-text-field>
        </div>
        <div class="col-4">
          <v-btn color="primary" @click="generateTable">Generate</v-btn>
        </div>
      </div>

      <template>
        <div class="mt-5">
          <v-data-table
            :headers="headers"
            :items="monthSalesList"
            :items-per-page="24"
            class="elevation-1"
          ></v-data-table>
        </div>
      </template>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
// import store, * as Store from '@/../src-gen/store';
// import * as Data from '@/../src-gen/data';
// import * as ServerData from '@/../src-gen/server-data';
// import * as Action from '@/../src-gen/action';

@Component({
  components: {},
})
export default class PartnerLearningvideo extends Vue {
  headers = [
    {
      text: 'Month',
      align: 'start',
      value: 'month',
    },
    { text: 'Sales', value: 'sales' },
    { text: 'Total sale till month', value: 'clientBase' },
    { text: 'Monthly Earnings', value: 'monthlyEarnings' },
  ];
  sales: number = 0;
  month: number = 24;
  amount: number = 1500;
  msfAmount: number = 50;
  clientBase: number = 0;
  monthSalesList: any = [];

  gotoHome() {
    const isLogin = localStorage.getItem('auth_token');

    isLogin === ''
      ? this.$router.push({ name: 'Root.Home' })
      : this.$router.push({ name: 'Partner.Dashboard' });
  }

  generateTable() {
    this.monthSalesList = [];
    this.clientBase = 0;
    for (let i = 0; i < this.month; i++) {
      let earnings = this.amount * this.sales + this.msfAmount * i * this.sales;
      this.clientBase += Number(this.sales);
      let object = {
        month: i + 1,
        sales: this.sales,
        clientBase: this.clientBase,
        monthlyEarnings: earnings,
      };
      this.monthSalesList.push(object);
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  background: #ffffff;
  top: 0;
  padding: 5px 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  z-index: 888;
  img {
    width: 100px;
  }
}
.initial {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calculatorSction {
  font-size: 14px;
  display: block;
  text-align: center;
}
.msfAMount {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  font-size: 18px;
  font-weight: bold;
  color: #004479;
  margin-top: 15px;
}
.headfont12 {
  font-size: 12px;
  font-weight: bold;
}
</style>
