import FColumnCellMDP from '../FColumnCellMDP';
export default class FCellDocumentLinkMDP implements FColumnCellMDP {
    documentKeySelector?: string;
    placeholder?: string;
    constructor({
        documentKeySelector,
        placeholder
    }: {  documentKeySelector?: string;placeholder?: string }) {
        this.documentKeySelector = documentKeySelector;
        this.placeholder = placeholder;
    }
    // dataSelectorKey: string;
    componentName: string = "FCellDocumentLink";
    getMetaData(): object {
        return {
            componentName: this.componentName,
            props: {
                documentKeySelector: this.documentKeySelector,
                placeholder: this.placeholder
            }
        }
    }

}