<template>
  <div class="monthlyBilling">
    <div class="pa-3 fadeIn">
      <component
        v-if="!loader"
        :ref="CFLFDataTableMetaData.myRefName"
        :value="partnerClientListForRangeInfo.PartnerClientList"
        :is="CFLFDataTableMetaData.componentName"
        v-bind="CFLFDataTableMetaData.props"
      ></component>
      <FLoader v-else />
      <f-pagination
        v-if="partnerClientListForRangeInput.count > 0"
        :total="partnerClientListForRangeInfo.totalCount"
        :count="partnerClientListForRangeInput.count"
        :value="partnerClientListForRangeInput.offset"
        @input="handlePaginationChange"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
import * as Action from "@/../src-gen/action";
import ModelVue from "@/components/ModelVue";
import FDataTable from "@/components/table/FDataTable.vue";
import FForm from "@/components/form/FForm.vue";
import CFLFDataTableMDP from "./ClientFileLookup/CFLFDataTableMDP";
import FPagination from "@/components/FPagination.vue";
import FLoader from "@/components/FLoader.vue";

@Component({ components: { FDataTable, FForm, "f-pagination": FPagination, FLoader } })
export default class ClientFileLookup extends ModelVue {
  loader: boolean = false;
  partnerClientListForRangeInput: Data.Insight.GetPartnerClientListForRangeInput =
    new Data.Insight.GetPartnerClientListForRangeInput();
  partnerClientListForRangeInfo: Data.Insight.PartnerClientListForRangeInfo =
    new Data.Insight.PartnerClientListForRangeInfo();

  searchParamDetails: any = this.$route.params;

  get CFLFDataTableMetaData() {
    return new CFLFDataTableMDP({ parent: this }).getMetaData();
  }

  getPartnerClientListForRange() {
    this.loader = true;
    Action.Insight.GetPartnerClientListForRange.execute(this.partnerClientListForRangeInput, (output) => {
      this.loader = false;
      this.partnerClientListForRangeInfo = output;
    }, (error) => {
      this.loader = false;
      console.log("error", error);
    })
  }

  mounted() {
    if (this.searchParamDetails) {
      this.partnerClientListForRangeInput.fromDate = this.searchParamDetails.dateRange[0];
      this.partnerClientListForRangeInput.toDate = this.searchParamDetails.dateRange[1];
      this.partnerClientListForRangeInput.offset = 0
      this.partnerClientListForRangeInput.count = 20
      if(this.searchParamDetails.clientFileStatus !== ""){
        this.partnerClientListForRangeInput.filterBy = this.searchParamDetails.clientFileStatus;
      }
      this.getPartnerClientListForRange();
    }
  }

  handlePaginationChange(newVal: any) {
    console.log("newVal", newVal);
    this.partnerClientListForRangeInput.offset = newVal.offset;
    this.partnerClientListForRangeInput.count = newVal.count;
    this.getPartnerClientListForRange();
  }
}
</script>

<style>
</style>
