<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-row class="align-center justify-center loginMobileView">
          <v-col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            class="align-center justify-center"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <img
                src="@/assets/images/freed-logo.svg"
                class="img-fluid logo-size"
                alt="..."
                width="120"
              />
            </div>
          </v-col>
          <v-col xs="12" sm="8" md="4">
            <v-card flat outlined>
              <v-toolbar color="primary" flat dark>
                <v-avatar size="40px">
                  <v-icon>mdi-account-circle-outline</v-icon>
                </v-avatar>
                <v-toolbar-title>Reset Password</v-toolbar-title>

                <v-spacer></v-spacer>
                <v-list-item-avatar>
                  <v-img src="@/assets/images/freed-small-logo.svg" />
                </v-list-item-avatar>
              </v-toolbar>
              <v-card-text>
                <v-form ref="signInRef">
                  <v-text-field
                    label="Mobile Number"
                    outlined
                    dense
                    :rules="mobileRules"
                    v-model="mobileNumber"
                  ></v-text-field>
                  <v-text-field
                    label="Password"
                    type="password"
                    outlined
                    dense
                    :rules="passwordRules"
                    v-model="resetPasswordInput.newPassword"
                  ></v-text-field>
                  <v-text-field
                    label="Confirm Password"
                    type="password"
                    outlined
                    dense
                    :rules="confirmPasswordRules"
                    v-model="confirmPassword"
                  ></v-text-field>
                  <div class="d-flex align-center justify-center">
                    <v-btn color="secondary" variant="flat" @click="onSignIn">
                      Submit
                    </v-btn>
                  </div>
                  <div class="info-text">
                    Don't have an account?
                    <span @click="goToHome">Register</span>
                  </div>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
// import store, * as Store from '@/../src-gen/store';
import * as Data from "@/../src-gen/data";
// import * as ServerData from '@/../src-gen/server-data';
import * as Action from "@/../src-gen/action";
import * as Snackbar from "node-snackbar";

@Component({
  components: {},
})
export default class ResetPassword extends Vue {
  mobileNumber = this.$route.query.mobileNumber.toString();
  // newPassword: string = "";
  confirmPassword: string = "";
  type: string = "password";

  resetPasswordInput: Data.Login.ResetPasswordInput =
    new Data.Login.ResetPasswordInput();

  passwordRules = [(v: any) => !!v || !!v.trim() || "Password  is required"];
  confirmPasswordRules = [
    (v: any) => !!v || !!v.trim() || "Confirm Password  is required",
    (v: any) =>
      v === this.resetPasswordInput.newPassword.trim() ||
      "Confirm Password  doesn't match",
  ];

  mobileRules = [
    (v: any) => !!v || "Mobile Number is required",
    (v: any) => {
      const value = v?.replace(" ", "");
      return (!!value && value.length === 10) || "Please Enter valid  Number";
    },
  ];

  //   mounted() {
  //     localStorage.removeItem("auth_token");
  //     localStorage.removeItem("partnerId");
  //   }
  onEyeClick() {
    this.type = this.type === "password" ? "text" : "password";
  }
  goToHome() {
    this.$router.push({ name: "Root.Home" });
  }

  goForgotPassword() {
    this.$router.push({ name: "Root.ForgotPassword" });
  }

  onSignIn() {
    Action.Login.ResetPassword.execute2(
      this.mobileNumber,
      this.resetPasswordInput.newPassword,
      (output: any) => {
        Snackbar.show({
          text: "Succesfully updated password.",
          pos: "bottom-center",
        });
        this.$router.push({ name: "Login.Login" });
      }
    );
  }
}
</script>

<style lang="scss" scoped>
.info-text {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  color: #676767;
  > span {
    color: #f36f21;
    font-weight: bold;
    cursor: pointer;
  }
}
.forgotPassword {
  text-align: right;
  color: #00447a;
  cursor: pointer;
  margin-bottom: 15px;
}
@media screen and (max-width: 960px) {
  .loginMobileView {
    display: block;
  }
}
</style>
