import MDP from '../MDP';

export default class FPointLabelsMDP implements MDP {
  show?: boolean;
  currencySymbols?: boolean;
  percentageValue?: boolean;

  constructor(props: {
    show?: boolean;
    currencySymbols?: boolean;
    percentageValue?: boolean;
  }) {
    this.show = props.show;
    this.currencySymbols = props.currencySymbols;
    this.percentageValue = props.percentageValue;
  }

  getMetaData(): object {
    return {
      show: this.show,
      currencySymbols: this.currencySymbols,
      percentageValue: this.percentageValue,
    };
  }
}
