
import FAgreementListMDP from "@/components/generic/file/agreementList/FAgreementListMDP";




export default class ECFTSSAStepMDP extends FAgreementListMDP {
    
    constructor({ taskRoot, parent }:{ taskRoot: any, parent: any }) {
        super({taskRoot, parent})
    }

}

