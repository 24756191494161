<template>
  <div class="partnerFormContainer container">
    <div class="bannerTitle">FREED Partner Program</div>
    <div class="bannerSubTitle">{{ content[carousel].subTitle }}</div>
    <div class="partnerInputForm-mobile">
      <v-card style="padding: 20px; border-radius: 12px">
        <v-card-title class="formTitle justify-center pa-1">
          Sign Up & Join Now</v-card-title
        >
        <div class="">
          <v-form ref="signUpRef">
            <v-text-field
              label="Name"
              outlined
              dense
              :rules="firstNameRules"
              v-model="signUpInput.firstName"
            ></v-text-field>
            <v-text-field
              label="E-mail"
              outlined
              dense
              :rules="emailRules"
              v-model="signUpInput.emailId"
            ></v-text-field>
            <v-text-field
              label="Contact Number"
              outlined
              dense
              :rules="mobileRules"
              v-model="signUpInput.mobile"
            ></v-text-field>
            <v-select
              outlined
              dense
              label="Select State"
              :rules="stateRequired"
              :items="state"
              v-model="signUpInput.state"
            ></v-select>
            <v-select
              dense
              outlined
              label="Select Age Range"
              :rules="agrRangeRequired"
              item-text="name"
              item-value="id"
              return-object
              :items="getAgeRange"
              v-model="signUpInput.ageRange"
            ></v-select>
            <v-text-field
              label="Password"
              type="password"
              outlined
              dense
              :rules="passwordRules"
              v-model="signUpInput.password"
            ></v-text-field>
            <v-text-field
              label="Confirm Password"
              type="password"
              outlined
              dense
              :rules="confirmPasswordRules"
              v-model="confirmPassword"
            ></v-text-field>

            <div class="check-box-container">
              <div>
                <v-checkbox color="primary" v-model="checked" />
                <p class="help-text-terms">
                  I have read & agree to the
                  <span class="term-link" @click="closeModel(true)">
                    Terms & Conditions
                  </span>
                </p>
              </div>
            </div>
          </v-form>
          <button
            type="button"
            class="btn btn-secondary calculator-btn"
            @click="onSignUp"
            :disabled="!checked"
          >
            Become a FREED Partner
            <div class="icon-container">
              <img
                src="@/assets/images/paper-plane-blue.svg"
                class="img-fluid w-100e"
                alt="..."
              />
            </div>
          </button>
          <div class="formFooter">
            Already Registered?
            <div class="term-link" @click="goToLogin">Login</div>
          </div>
        </div>
      </v-card>
      <TermsAndCondition :open="open" @close="closeModel" />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import store, * as Store from '@/../src-gen/store';
import * as Data from '@/../src-gen/data';
import * as Action from '@/../src-gen/action';
import Snackbar from 'node-snackbar';
import TermsAndCondition from './termsAndConditions.vue';

@Component({
  components: {
    TermsAndCondition,
  },
})
export default class SignupForm extends Vue {
  open = false;
  @Prop({ default: 0 })
  carousel: any;
  ageRangeList: any = Data.Partner.AGE_RANGE.list();
  checked: boolean = false;
  confirmPassword: string = '';
  state: string[] = [];
  content = [
    { subTitle: 'Make Money Helping People' },
    { subTitle: 'Work Part-Time Earn Full-Time' },
    { subTitle: 'Transparent Weekly Payout' },
  ];
  signUpInput: Data.Partner.SignUpInput = new Data.Partner.SignUpInput();
  emailRules = [
    (v: any) => !!v.trim() || 'Email is required',
    (v: any) => v.indexOf(' ') === -1 || 'Provide a valid Email',
    (v: any) => /.+@.+\..+/.test(v) || 'Provide a valid Email',
  ];
  // stateRequired = [(v: any) => !!v.trim() || 'State is required'];
  stateRequired = [(v: any) => (v && !!v.trim()) || 'State is required'];

  agrRangeRequired = [(v: any) => !!v || 'Age range is required'];
  firstNameRules = [(v: any) => !!v.trim() || 'Name is required'];
  mobileRules = [
    (v: any) => !!v || 'Mobile Number is required',
    (v: any) => {
      const value = v?.replace(' ', '');
      return (!!value && value.length === 10) || 'Please Enter valid  Number';
    },
  ];
  passwordRules = [(v: any) => !!v || !!v.trim() || 'Password  is required'];
  confirmPasswordRules = [
    (v: any) => !!v || !!v.trim() || 'Confirm Password  is required',
    (v: any) =>
      v === this.signUpInput.password || "Confirm Password  doesn't match",
  ];
  goToLogin() {
    this.$router.push({ name: 'Login.Login' });
  }
  closeModel(model: boolean = false) {
    this.open = model;
  }
  get getAgeRange() {
    return this.ageRangeList.sort((a: any, b: any) => {
      const c = '';
      const [first] = a.name.includes('+')
        ? a.name.split('+')
        : a.name.split('-');
      const [second] = b.name.includes('+')
        ? b.name.split('+')
        : b.name.split('-');

      return first - second;
    });
  }
  mounted() {
    this.getStateList();
  }
  getStateList() {
    Action.Partner.GetStateList.execute((output) => {
      this.state = output.stateNameList;
    });
  }

  onSignUp() {
    const valid = (this.$refs.signUpRef as any).validate();
    if (valid && this.checked) {
      const [firstName = '', lastName = ''] =
        this.signUpInput.firstName.split(' ');
      this.signUpInput.firstName = firstName;
      this.signUpInput.lastName = lastName;
      Action.Partner.SignUp.execute(this.signUpInput, (output) => {
        Snackbar.show({
          text: 'Register Successful! Please Log In',
          pos: 'bottom-center',
        });
        this.$router.push({ name: 'Login.Login' });
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.formFooter {
  display: flex;
  justify-content: center;
}
.partnerFormContainer {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 0px;
  z-index: 109;
}

@media screen and (max-width: 960px) {
  .partnerFormContainer {
    position: absolute;
    left: 0px;
    bottom: 175px;
    z-index: 109;
    padding-right: 15px;
  }
}
.help-text-terms {
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 3px;
}
@media screen and (max-width: 600px) {
  .help-text-terms {
    font-size: 13px;
  }
}
.check-box-container {
  position: relative;
  height: 34px;
  > div {
    top: -25px;
    display: flex;
    align-items: center;
    position: absolute;
    widows: 100%;
  }
  p {
    width: 100%;
  }
}
.term-link {
  color: #f36f21;
  cursor: pointer;
  margin-left: 3px;
}

.formFooter .term-link {
  font-weight: bold;
}
</style>
