<template>
  <div>
    <strong v-if="enableDecimal">

    {{ (inrValue|| 0) | inrWithDecimal }}
    </strong>
    <strong v-else>
      {{(inrValue|| 0) | toINR}}
    </strong>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ModelVue from "../../ModelVue";

@Component({
  components: {},
})
export default class FCellCurrency extends ModelVue {
  @Prop()
  dataSelectorKey: string;

  @Prop()
  rounded: boolean;
  
  @Prop()
  enableDecimal: boolean;

  get inrValue() {
    return this.rounded
      ? Math.round(this.selectModel(this.modelValue, this.dataSelectorKey))
      : this.selectModel(this.modelValue, this.dataSelectorKey);
  }
}
</script>
