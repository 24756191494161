import FFieldMDP from "@/components/form/field/FFieldMDP";
import { FFormChildMDP } from "@/components/form/FFormMDP";
import FNumberTextFieldMDP from "./FNumberTextFieldMDP";

export default class FPhoneFieldMDP extends FNumberTextFieldMDP {
  constructor(props: {
    parentMDP: FFormChildMDP;
    dataSelectorKey: string;
    label: string;
    type?: string;
    rules?: string;
    mandatory?: boolean;
    boundaryClass?: string;
    disabled?: boolean;
    condition?: boolean;
    mask?: string;
    unmask?: string;
    prefix?: string;
  }) {
    super({
      ...props,
      mask: "##### #####",
      unmask: "##########",
      prefix: "+91",
      rules: props.rules || "min:10"
    });
    // this.defaultValue = defaultValue;
  }
}
