
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class GompaReaderService {

   public static getUserDetails(
           axiosInstance: AxiosInstance, input: ServerData.GompaWebReader.GetUserDetails$Input,
           f: (output: ServerData.SpineData$GompaUserDetails) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/gompa/get-user-details',
            {params : input.toJson()}
        ).then((response) => ServerData.SpineData$GompaUserDetails.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getUserOrgCode(
           axiosInstance: AxiosInstance, input: ServerData.GompaWebReader.GetUserOrgCode$Input,
           f: (output: ServerData.GompaWebReader.GetUserOrgCode$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/gompa/get-user-org-code',
            {params : input.toJson()}
        ).then((response) => ServerData.GompaWebReader.GetUserOrgCode$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


}

