<template>
  <div class="row summaryTab">
    <!-- <v-toolbar flat> -->
    <!-- {{fileSummary?.msfSummary}} -->
    <div class="col">
      <v-sheet rounded outlined color="primary" class="mb-3">
        <v-card flat class="col py-1">
          <div class="d-flex align-center">
            <div
              class="font-weight-bold secondary--text d-flex align-center mx-3"
            >
              {{ clientFileBasicInfo.clientFileNumber }}
              <f-copy
                boundaryClass="mx-1"
                v-model="clientFileBasicInfo.clientFileNumber"
              ></f-copy>
            </div>

            <div class="font-weight-bold text-body-1 d-flex align-center mx-3">
              <a class="purple--text" @click="gotoClient()">
                {{ clientFileBasicInfo.clientBasicInfo.fullName }}
              </a>
              <f-copy
                boundaryClass="mx-1"
                v-model="clientFileBasicInfo.clientBasicInfo.fullName"
              >
              </f-copy>
            </div>

            <div class="text-body-1 d-flex align-center mx-3">
              {{ clientFileBasicInfo?.clientBasicInfo?.mobile | phone }}
              <f-copy
                boundaryClass="mx-1"
                v-model="clientFileBasicInfo.clientBasicInfo.mobile"
              ></f-copy>
            </div>

            <v-spacer />
            <!-- <v-btn-toggle dense group>
              <v-btn
                @click="goto(quickNav.routerName)"
                small
                v-for="quickNav in quickNavList"
                :key="quickNav.name"
                class="pa-0 mx-0"
              >
                <f-tooltip :tooltipText="quickNav.name" :icon="quickNav.icon">
                <v-icon color="secondary" small> {{ quickNav.icon }}</v-icon>
                </f-tooltip>
                
              </v-btn>
            </v-btn-toggle> -->

            <v-spacer />
            

            <v-chip
              @click="gotoEmandateList"
              x-small
              v-if="fileSummary.eMandateActive"
              class="mx-2"
              color="green"
              outlined
              label
              >EMandate Active</v-chip
            >

            <v-chip
              @click="gotoEmandateList"
              x-small
              v-else
              class="mx-2"
              color="secondary lighten-3"
              label
              >EMandate Pending</v-chip
            >

            <v-chip
            :color="serviceStatusColor"
            outlined
            x-small
            class="mx-2"
            label
            v-if="fileSummary.serviceStatus?.id==='NO_SERVICE'"
            >
            Subscription Inactive
            </v-chip>

            <v-chip
              class="mx-2"
              v-if="fileSummary.ppCode"
              x-small
              color="secondary"
              outlined
              label
              >{{ fileSummary.ppCode }}</v-chip
            >

            <v-chip
              class="mx-2"
              v-if="clientFileBasicInfo.programCode"
              x-small
              label
              outlined
              >{{ clientFileBasicInfo.programCode }}</v-chip
            >

            <v-chip class="mx-2" x-small label>{{
              fileSummary.clientFileStatus.name
            }}</v-chip>
            <!-- <v-btn icon @click="expandHeader = !expandHeader">
              <v-icon size="20" color="secondary">
                mdi-arrow-down-drop-circle
              </v-icon>
            </v-btn> -->
          </div>
          <!-- <v-divider /> -->
          
        </v-card>
      </v-sheet>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
import * as Action from "@/../src-gen/action";
import FBtn from "@/components/FBtn.vue";

import FTextField from "@/components/form/field/FTextField.vue";

import FCellStatus from "@/components/table/cell/FCellStatus.vue";

import FHoverCopy from "@/components/FHoverCopy.vue";
import FCopy from "@/components/FCopyBtn.vue";

import Helper from "@/util/Helper";
import FTooltip from "@/components/FTooltip.vue";
@Component({
  components: {
    "f-btn": FBtn,
    "f-text-field": FTextField,
    
    FCellStatus,
    FHoverCopy,
    FCopy,
    FTooltip,
  },
})
export default class CFSummary extends Vue {
  @Store.Getter.Login.LoginDetails.roleList
  roleList: string[];

  @Store.Getter.ClientFile.ClientFileSummary.fileSummary
  fileSummary: Data.ClientFile.FileSummary;

  @Store.Getter.ClientFile.ClientFileSummary.clientFileBasicInfo
  clientFileBasicInfo: Data.ClientFile.ClientFileBasicInfo;

  // @Store.Getter.ClientFile.ClientFileSummary.showAmeyoSideBar
  // showAmeyoSideBar: boolean;

  expandHeader: boolean = true;

  serviceStatusColorCode =  Data.Color.SERVICE_STATUS;
  get quickNavList() {
    return [
      {
        name: "Creditors",
        icon: "mdi-credit-card",
        routerName: "Root.CFile.CFInfo.CFCreditorInfo",
      },
      {
        name: "Budget",
        icon: "mdi-wallet",
        routerName: "Root.CFile.CFInfo.CFBudgetInfo",
      },
      {
        name: "Payment Plan",
        icon: "mdi-calendar-blank",
        routerName: "Root.CFile.CFInfo.CFPaymentPlanInfo",
      },
      {
        name: "Profile",
        icon: "mdi-account",
        routerName: "Root.CFile.CFInfo.CFPersonalInfo",
      },

      {
        name: "Documents",
        icon: "mdi-file-document",
        routerName: "Root.CFile.CFInfo.CFDocumentInfo",
      },
    ];
  }

  minHeight = 80;
  showHide: boolean = false;
  clientFileId = this.$route.params.clientFileId;
  @Store.Getter.ClientFile.ClientFileSummary.fiEMandateList
  fiEMandateList: Data.ClientFile.FiEMandateList[];

  msfScheduleStatusColorcode = Data.Color.PS_ENTRY_STATUS;


  // openNavShow(phoneNumber: string) {
  //   if (this.isAmeyoToolbarDialogRole()) {
  //     this.callByAmeyo(phoneNumber);
  //     if (this.showAmeyoSideBar === false) {
  //       Store.Mutation.ClientFile.ClientFileSummary.TOGGLE_AMEYO_SIDE_BAR(
  //         !this.showAmeyoSideBar
  //       );
  //     }
  //   }
  // }

  get serviceStatusColor() {
    return Helper.getColor(this.fileSummary?.serviceStatus?.id, Data.Color.SERVICE_STATUS)
  }

  gotoCreditor() {
    this.$router.push({ name: "Root.CFile.CFInfo.CFCreditorInfo" });
  }

  gotoProfile() {
    this.$router.push({ name: "Root.CFile.CFInfo.CFPersonalInfo" });
  }

  gotoPaymentPlan() {
    this.$router.push({ name: "Root.CFile.CFInfo.CFPaymentPlanInfo" });
  }

  gotoMSFPaymentPlan() {
    this.$router.push({ name: "Root.CFile.CFInfo.CFPaymentPlanMSFInfo" });
  }

  goto(routerName: string) {
    this.$router.push({ name: routerName });
  }

  gotoPayment() {
    this.$router.push({ name: "Root.CFile.CFPayment.CFPaymentList" });
  }

  gotoEmandateList() {
    this.$router.push({ name: "Root.CFile.CFPayment.CFEMandateList" });
  }

  gotoClient() {
    const clientId = this.clientFileBasicInfo.clientBasicInfo.clientId;
    this.$router.push({
      name: "Root.Client.ClientDetails",
      params: { clientId: clientId },
    });
  }

  copyToClipBoard(text: string) {
    Helper.copyToClipBoard(text);
  }

  isCS() {
    return this.roleList?.includes("CS");
  }

  isCSManager() {
    return this.roleList?.includes("CSManager");
  }

  isAmeyoToolbarDialogRole() {
    return this.isCS() || this.isCSManager();
  }
}
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 4px;
}
.summaryTab :deep(.v-expansion-panel-content__wrap) {
  padding: 0px !important;
}
</style>



