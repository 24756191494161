
import axios, { AxiosError, AxiosInstance } from 'axios';
import * as RemoteApiPoint from './../../src/remote-api-point';
import {EventBus} from './../../src/eventbus';
import * as Store from './../../src-gen/store';
import * as Data from './../data';
import * as ServerData from './../server-data';
import BenchReaderService from './../service/benchapi/BenchReaderService';
import TaskReaderService from './../service/spineapi/TaskReaderService';
import PaymentSchedulePlanReaderService from './../service/spineapi/PaymentSchedulePlanReaderService';
import TaskWriterService from './../service/valeyapi/TaskWriterService';

// tslint:disable



export class GetBenchTaskSummary {

    

    

    public static execute( f: (output: Data.TaskList.CountSummary) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {

    const input = new ServerData.BenchWebReader.GetBenchTaskSummary$Input();

    

        BenchReaderService.getBenchTaskSummary(axoisInstance, this.mapInput(input),
            ((output: ServerData.BenchData$BenchTaskSummary) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.TaskList.BenchTaskSummary.SET_COUNT_SUMMARY(mappedOutput);
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.BenchWebReader.GetBenchTaskSummary$Input): ServerData.BenchWebReader.GetBenchTaskSummary$Input {
        return new GetBenchTaskSummary$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.BenchData$BenchTaskSummary): Data.TaskList.CountSummary {
        return new GetBenchTaskSummary$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetBenchTaskSummary$Mapper {

    public mapInput(input: ServerData.BenchWebReader.GetBenchTaskSummary$Input): ServerData.BenchWebReader.GetBenchTaskSummary$Input {
        return input; 
    }

    public mapOutput(output: ServerData.BenchData$BenchTaskSummary): Data.TaskList.CountSummary {
        return Data.TaskList.CountSummary.fromJson(output.toJson());
    }

}

class GetBenchTaskSummary$MapperCode extends GetBenchTaskSummary$Mapper {

}

       


export class GetToBePulledTaskList {

    

    

    public static execute( f: (output: Data.TaskList.TaskListByCid[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    const input = new ServerData.TaskWebReader.GetToBePulledTaskList$Input();

    

        TaskReaderService.getToBePulledTaskList(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$TaskListByCid[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.TaskWebReader.GetToBePulledTaskList$Input): ServerData.TaskWebReader.GetToBePulledTaskList$Input {
        return new GetToBePulledTaskList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$TaskListByCid[]): Data.TaskList.TaskListByCid[] {
        return new GetToBePulledTaskList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetToBePulledTaskList$Mapper {

    public mapInput(input: ServerData.TaskWebReader.GetToBePulledTaskList$Input): ServerData.TaskWebReader.GetToBePulledTaskList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$TaskListByCid[]): Data.TaskList.TaskListByCid[] {
        return  output.map((x) => Data.TaskList.TaskListByCid.fromJson(x.toJson()));
    }

}

class GetToBePulledTaskList$MapperCode extends GetToBePulledTaskList$Mapper {

}

       


export class GetTaskListByCid {

    
    public static execute1(cid: string, f: (output: Data.TaskList.GetTaskListByCidGrid[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetTaskListByCid.execute(new ServerData.TaskWebReader.GetTaskListByCid$Input(cid), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.TaskWebReader.GetTaskListByCid$Input, f: (output: Data.TaskList.GetTaskListByCidGrid[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        TaskReaderService.getTaskListByCid(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$TaskListByCid[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.TaskList.BenchTaskSummary.SET_CF_TASK_LIST(mappedOutput.map((x) => Data.TaskList.GetTaskListByCidGrid.fromJson(x.toJson())));
             f(mappedOutput);
             EventBus.$emit('TaskList::GetTaskListByCid', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: Data.TaskList.GetTaskListByCidGrid[]) => void): (output: Data.TaskList.GetTaskListByCidGrid[]) => void {
        EventBus.$on('TaskList::GetTaskListByCid', f);
        return f;
    }

    public static notInterested(f: (output: Data.TaskList.GetTaskListByCidGrid[]) => void): void {
        EventBus.$off('TaskList::GetTaskListByCid', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('TaskList::GetTaskListByCid');
    }
    

    private static mapInput(input: ServerData.TaskWebReader.GetTaskListByCid$Input): ServerData.TaskWebReader.GetTaskListByCid$Input {
        return new GetTaskListByCid$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$TaskListByCid[]): Data.TaskList.GetTaskListByCidGrid[] {
        return new GetTaskListByCid$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetTaskListByCid$Mapper {

    public mapInput(input: ServerData.TaskWebReader.GetTaskListByCid$Input): ServerData.TaskWebReader.GetTaskListByCid$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$TaskListByCid[]): Data.TaskList.GetTaskListByCidGrid[] {
        return  output.map((x) => Data.TaskList.GetTaskListByCidGrid.fromJson(x.toJson()));
    }

}

class GetTaskListByCid$MapperCode extends GetTaskListByCid$Mapper {

}

       


export class GetActiveTaskListAllocated {

    

    

    public static execute( f: (output: Data.TaskList.GetActiveTaskListAllocatedGrid[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    const input = new ServerData.TaskWebReader.GetActiveTaskListAllocated$Input();

    

        TaskReaderService.getActiveTaskListAllocated(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$ActiveTaskAllocated[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.TaskList.MyTaskStore.UPDATE_MY_ACTIVE_TASK_LIST(mappedOutput.map((x) => Data.TaskList.GetActiveTaskListAllocatedGrid.fromJson(x.toJson())));
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.TaskWebReader.GetActiveTaskListAllocated$Input): ServerData.TaskWebReader.GetActiveTaskListAllocated$Input {
        return new GetActiveTaskListAllocated$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$ActiveTaskAllocated[]): Data.TaskList.GetActiveTaskListAllocatedGrid[] {
        return new GetActiveTaskListAllocated$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetActiveTaskListAllocated$Mapper {

    public mapInput(input: ServerData.TaskWebReader.GetActiveTaskListAllocated$Input): ServerData.TaskWebReader.GetActiveTaskListAllocated$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$ActiveTaskAllocated[]): Data.TaskList.GetActiveTaskListAllocatedGrid[] {
        return  output.map((x) => Data.TaskList.GetActiveTaskListAllocatedGrid.fromJson(x.toJson()));
    }

}

class GetActiveTaskListAllocated$MapperCode extends GetActiveTaskListAllocated$Mapper {

}

       


export class GetAccountBalanceList {

    

    

    public static execute( f: (output: Data.TaskList.MISAccountBalanceEntryObj) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    const input = new ServerData.PaymentSchedulePlanWebReader.GetAccountBalanceList$Input();

    

        PaymentSchedulePlanReaderService.getAccountBalanceList(axoisInstance, this.mapInput(input),
            ((output: ServerData.PaymentSchedulePlanWebReader.GetAccountBalanceList$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.PaymentSchedulePlanWebReader.GetAccountBalanceList$Input): ServerData.PaymentSchedulePlanWebReader.GetAccountBalanceList$Input {
        return new GetAccountBalanceList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PaymentSchedulePlanWebReader.GetAccountBalanceList$Output): Data.TaskList.MISAccountBalanceEntryObj {
        return new GetAccountBalanceList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetAccountBalanceList$Mapper {

    public mapInput(input: ServerData.PaymentSchedulePlanWebReader.GetAccountBalanceList$Input): ServerData.PaymentSchedulePlanWebReader.GetAccountBalanceList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.PaymentSchedulePlanWebReader.GetAccountBalanceList$Output): Data.TaskList.MISAccountBalanceEntryObj {
        return Data.TaskList.MISAccountBalanceEntryObj.fromJson(output.toJson());
    }

}

class GetAccountBalanceList$MapperCode extends GetAccountBalanceList$Mapper {

}

       


export class GetAllocatedTaskList {

    

    

    public static execute( f: (output: Data.TaskList.AllocatedTaskGrid[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {

    const input = new ServerData.BenchWebReader.GetAllocatedTaskList$Input();

    

        BenchReaderService.getAllocatedTaskList(axoisInstance, this.mapInput(input),
            ((output: ServerData.BenchData$AllocatedTask[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.BenchWebReader.GetAllocatedTaskList$Input): ServerData.BenchWebReader.GetAllocatedTaskList$Input {
        return new GetAllocatedTaskList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.BenchData$AllocatedTask[]): Data.TaskList.AllocatedTaskGrid[] {
        return new GetAllocatedTaskList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetAllocatedTaskList$Mapper {

    public mapInput(input: ServerData.BenchWebReader.GetAllocatedTaskList$Input): ServerData.BenchWebReader.GetAllocatedTaskList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.BenchData$AllocatedTask[]): Data.TaskList.AllocatedTaskGrid[] {
        return  output.map((x) => Data.TaskList.AllocatedTaskGrid.fromJson(x.toJson()));
    }

}

class GetAllocatedTaskList$MapperCode extends GetAllocatedTaskList$Mapper {

}

       


export class GetStartedTaskList {

    

    

    public static execute( f: (output: Data.TaskList.StartedTaskGrid[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {

    const input = new ServerData.BenchWebReader.GetStartedTaskList$Input();

    

        BenchReaderService.getStartedTaskList(axoisInstance, this.mapInput(input),
            ((output: ServerData.BenchData$StartedTask[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.BenchWebReader.GetStartedTaskList$Input): ServerData.BenchWebReader.GetStartedTaskList$Input {
        return new GetStartedTaskList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.BenchData$StartedTask[]): Data.TaskList.StartedTaskGrid[] {
        return new GetStartedTaskList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetStartedTaskList$Mapper {

    public mapInput(input: ServerData.BenchWebReader.GetStartedTaskList$Input): ServerData.BenchWebReader.GetStartedTaskList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.BenchData$StartedTask[]): Data.TaskList.StartedTaskGrid[] {
        return  output.map((x) => Data.TaskList.StartedTaskGrid.fromJson(x.toJson()));
    }

}

class GetStartedTaskList$MapperCode extends GetStartedTaskList$Mapper {

}

       


export class GetCompletedTaskList {

    

    
    public static execute2(fromDate: string, toDate: string, f: (output: Data.TaskList.CompletedTaskGrid[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {
      GetCompletedTaskList.execute(new ServerData.BenchWebReader.GetCompletedTaskList$Input(fromDate, toDate), f, eh, axoisInstance);
    } 

    public static execute(input: ServerData.BenchWebReader.GetCompletedTaskList$Input, f: (output: Data.TaskList.CompletedTaskGrid[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {

    

    

        BenchReaderService.getCompletedTaskList(axoisInstance, this.mapInput(input),
            ((output: ServerData.BenchData$CompletedTask[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.TaskList.MyTaskStore.UPDATE_COMPLETED_TASK_LIST(mappedOutput.map((x) => Data.TaskList.CompletedTaskGrid.fromJson(x.toJson())));
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.BenchWebReader.GetCompletedTaskList$Input): ServerData.BenchWebReader.GetCompletedTaskList$Input {
        return new GetCompletedTaskList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.BenchData$CompletedTask[]): Data.TaskList.CompletedTaskGrid[] {
        return new GetCompletedTaskList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetCompletedTaskList$Mapper {

    public mapInput(input: ServerData.BenchWebReader.GetCompletedTaskList$Input): ServerData.BenchWebReader.GetCompletedTaskList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.BenchData$CompletedTask[]): Data.TaskList.CompletedTaskGrid[] {
        return  output.map((x) => Data.TaskList.CompletedTaskGrid.fromJson(x.toJson()));
    }

}

class GetCompletedTaskList$MapperCode extends GetCompletedTaskList$Mapper {

}

       


export class GetSuspendedTaskList {

    

    

    public static execute( f: (output: Data.TaskList.SuspendedTaskAllocated[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    const input = new ServerData.TaskWebReader.GetSuspendedTaskListAllocated$Input();

    

        TaskReaderService.getSuspendedTaskListAllocated(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$SuspendedTaskAllocated[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.TaskList.MyTaskStore.UPDATE_MY_SUSPENDED_TASK_LIST(mappedOutput.map((x) => Data.TaskList.SuspendedTaskAllocated.fromJson(x.toJson())));
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.TaskWebReader.GetSuspendedTaskListAllocated$Input): ServerData.TaskWebReader.GetSuspendedTaskListAllocated$Input {
        return new GetSuspendedTaskList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$SuspendedTaskAllocated[]): Data.TaskList.SuspendedTaskAllocated[] {
        return new GetSuspendedTaskList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetSuspendedTaskList$Mapper {

    public mapInput(input: ServerData.TaskWebReader.GetSuspendedTaskListAllocated$Input): ServerData.TaskWebReader.GetSuspendedTaskListAllocated$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$SuspendedTaskAllocated[]): Data.TaskList.SuspendedTaskAllocated[] {
        return  output.map((x) => Data.TaskList.SuspendedTaskAllocated.fromJson(x.toJson()));
    }

}

class GetSuspendedTaskList$MapperCode extends GetSuspendedTaskList$Mapper {

}

       


export class GetExecutiveTaskDetails {

    
    public static execute1(taskId: string, f: (output: Data.TaskList.ExecutiveTaskDetails) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetExecutiveTaskDetails.execute(new ServerData.TaskWebReader.GetExecutiveTaskDetails$Input(taskId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.TaskWebReader.GetExecutiveTaskDetails$Input, f: (output: Data.TaskList.ExecutiveTaskDetails) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        TaskReaderService.getExecutiveTaskDetails(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$ExecutiveTaskDetails) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.TaskList.Summary.SET_EXECUTIVE_TASK_DETAILS(mappedOutput);
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.TaskWebReader.GetExecutiveTaskDetails$Input): ServerData.TaskWebReader.GetExecutiveTaskDetails$Input {
        return new GetExecutiveTaskDetails$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$ExecutiveTaskDetails): Data.TaskList.ExecutiveTaskDetails {
        return new GetExecutiveTaskDetails$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetExecutiveTaskDetails$Mapper {

    public mapInput(input: ServerData.TaskWebReader.GetExecutiveTaskDetails$Input): ServerData.TaskWebReader.GetExecutiveTaskDetails$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$ExecutiveTaskDetails): Data.TaskList.ExecutiveTaskDetails {
        return Data.TaskList.ExecutiveTaskDetails.fromJson(output.toJson());
    }

}

class GetExecutiveTaskDetails$MapperCode extends GetExecutiveTaskDetails$Mapper {

}

       


export class ExitCheckWithOutput {

    

    
    public static execute2(taskId: string, outputData: string, f: (output: Data.TaskList.ExitCheckWithOutputConstraint[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {
      ExitCheckWithOutput.execute(new Data.TaskList.ExitCheckWithOutputForm(taskId, outputData), f, eh, axoisInstance);
    } 

    public static execute(input: Data.TaskList.ExitCheckWithOutputForm, f: (output: Data.TaskList.ExitCheckWithOutputConstraint[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {

    

    

        BenchReaderService.exitCheckWithOutput(axoisInstance, this.mapInput(input),
            ((output: ServerData.BenchWebReader.ExitCheckWithOutput$Output[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.TaskList.ExitCheckWithOutputForm): ServerData.BenchWebReader.ExitCheckWithOutput$Input {
        return new ExitCheckWithOutput$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.BenchWebReader.ExitCheckWithOutput$Output[]): Data.TaskList.ExitCheckWithOutputConstraint[] {
        return new ExitCheckWithOutput$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class ExitCheckWithOutput$Mapper {

    public mapInput(input: Data.TaskList.ExitCheckWithOutputForm): ServerData.BenchWebReader.ExitCheckWithOutput$Input {
        return ServerData.BenchWebReader.ExitCheckWithOutput$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.BenchWebReader.ExitCheckWithOutput$Output[]): Data.TaskList.ExitCheckWithOutputConstraint[] {
        return  output.map((x) => Data.TaskList.ExitCheckWithOutputConstraint.fromJson(x.toJson()));
    }

}

class ExitCheckWithOutput$MapperCode extends ExitCheckWithOutput$Mapper {

}

       


export class GetTaskCommentList {

    
    public static execute1(taskId: string, f: (output: Data.TaskList.Comment[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {
      GetTaskCommentList.execute(new ServerData.BenchWebReader.GetTaskCommentList$Input(taskId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.BenchWebReader.GetTaskCommentList$Input, f: (output: Data.TaskList.Comment[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {

    

    

        BenchReaderService.getTaskCommentList(axoisInstance, this.mapInput(input),
            ((output: ServerData.BenchData$Comment[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.TaskList.MyTaskStore.UPDATE_TASK_COMMENT_LIST(mappedOutput.map((x) => Data.TaskList.Comment.fromJson(x.toJson())));
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.BenchWebReader.GetTaskCommentList$Input): ServerData.BenchWebReader.GetTaskCommentList$Input {
        return new GetTaskCommentList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.BenchData$Comment[]): Data.TaskList.Comment[] {
        return new GetTaskCommentList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetTaskCommentList$Mapper {

    public mapInput(input: ServerData.BenchWebReader.GetTaskCommentList$Input): ServerData.BenchWebReader.GetTaskCommentList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.BenchData$Comment[]): Data.TaskList.Comment[] {
        return  output.map((x) => Data.TaskList.Comment.fromJson(x.toJson()));
    }

}

class GetTaskCommentList$MapperCode extends GetTaskCommentList$Mapper {

}

       


export class PullTask {

    

    
    public static execute2(taskId: string, userName: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {
      PullTask.execute(new ServerData.TaskWebWriter.PullTask$Input(taskId, userName), f, eh, axoisInstance);
    } 

    public static execute(input: ServerData.TaskWebWriter.PullTask$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {

    

    

        TaskWriterService.pullTask(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('TaskList::PullTask', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('TaskList::PullTask', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('TaskList::PullTask', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('TaskList::PullTask');
    }
    

    private static mapInput(input: ServerData.TaskWebWriter.PullTask$Input): ServerData.TaskWebWriter.PullTask$Input {
        return new PullTask$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new PullTask$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class PullTask$Mapper {

    public mapInput(input: ServerData.TaskWebWriter.PullTask$Input): ServerData.TaskWebWriter.PullTask$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class PullTask$MapperCode extends PullTask$Mapper {

}

       


export class Start {

    
    public static execute1(taskId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {
      Start.execute(new ServerData.TaskWebWriter.Start$Input(taskId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.TaskWebWriter.Start$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {

    

    

        TaskWriterService.start(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('TaskList::Start', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('TaskList::Start', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('TaskList::Start', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('TaskList::Start');
    }
    

    private static mapInput(input: ServerData.TaskWebWriter.Start$Input): ServerData.TaskWebWriter.Start$Input {
        return new Start$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new Start$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class Start$Mapper {

    public mapInput(input: ServerData.TaskWebWriter.Start$Input): ServerData.TaskWebWriter.Start$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class Start$MapperCode extends Start$Mapper {

}

       


export class Suspend {

    

    

    public static execute(input: Data.TaskList.SuspendTaskInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        TaskWriterService.suspend(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('TaskList::Suspend', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('TaskList::Suspend', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('TaskList::Suspend', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('TaskList::Suspend');
    }
    

    private static mapInput(input: Data.TaskList.SuspendTaskInput): ServerData.TaskWebWriter.Suspend$Input {
        return new Suspend$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new Suspend$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class Suspend$Mapper {

    public mapInput(input: Data.TaskList.SuspendTaskInput): ServerData.TaskWebWriter.Suspend$Input {
        return ServerData.TaskWebWriter.Suspend$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class Suspend$MapperCode extends Suspend$Mapper {

}

       


export class Resume {

    
    public static execute1(taskId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      Resume.execute(new ServerData.TaskWebWriter.Resume$Input(taskId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.TaskWebWriter.Resume$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        TaskWriterService.resume(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('TaskList::Resume', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('TaskList::Resume', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('TaskList::Resume', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('TaskList::Resume');
    }
    

    private static mapInput(input: ServerData.TaskWebWriter.Resume$Input): ServerData.TaskWebWriter.Resume$Input {
        return new Resume$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new Resume$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class Resume$Mapper {

    public mapInput(input: ServerData.TaskWebWriter.Resume$Input): ServerData.TaskWebWriter.Resume$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class Resume$MapperCode extends Resume$Mapper {

}

       


export class PullStartAndMerge {

    
    public static execute1(taskId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      PullStartAndMerge.execute(new ServerData.TaskWebWriter.PullStartAndMerge$Input(taskId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.TaskWebWriter.PullStartAndMerge$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        TaskWriterService.pullStartAndMerge(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('TaskList::PullStartAndMerge', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('TaskList::PullStartAndMerge', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('TaskList::PullStartAndMerge', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('TaskList::PullStartAndMerge');
    }
    

    private static mapInput(input: ServerData.TaskWebWriter.PullStartAndMerge$Input): ServerData.TaskWebWriter.PullStartAndMerge$Input {
        return new PullStartAndMerge$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new PullStartAndMerge$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class PullStartAndMerge$Mapper {

    public mapInput(input: ServerData.TaskWebWriter.PullStartAndMerge$Input): ServerData.TaskWebWriter.PullStartAndMerge$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class PullStartAndMerge$MapperCode extends PullStartAndMerge$Mapper {

}

       


export class SaveAndComplete {

    

    
    public static execute2(taskId: string, outputJson: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {
      SaveAndComplete.execute(new Data.TaskList.SaveCompleteForm(taskId, outputJson), f, eh, axoisInstance);
    } 

    public static execute(input: Data.TaskList.SaveCompleteForm, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {

    

    

        TaskWriterService.saveAndComplete(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('TaskList::SaveAndComplete', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('TaskList::SaveAndComplete', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('TaskList::SaveAndComplete', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('TaskList::SaveAndComplete');
    }
    

    private static mapInput(input: Data.TaskList.SaveCompleteForm): ServerData.TaskWebWriter.SaveAndComplete$Input {
        return new SaveAndComplete$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new SaveAndComplete$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class SaveAndComplete$Mapper {

    public mapInput(input: Data.TaskList.SaveCompleteForm): ServerData.TaskWebWriter.SaveAndComplete$Input {
        return ServerData.TaskWebWriter.SaveAndComplete$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class SaveAndComplete$MapperCode extends SaveAndComplete$Mapper {

}

       


export class Complete {

    
    public static execute1(taskId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {
      Complete.execute(new ServerData.TaskWebWriter.Complete$Input(taskId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.TaskWebWriter.Complete$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {

    

    

        TaskWriterService.complete(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('TaskList::Complete', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('TaskList::Complete', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('TaskList::Complete', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('TaskList::Complete');
    }
    

    private static mapInput(input: ServerData.TaskWebWriter.Complete$Input): ServerData.TaskWebWriter.Complete$Input {
        return new Complete$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new Complete$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class Complete$Mapper {

    public mapInput(input: ServerData.TaskWebWriter.Complete$Input): ServerData.TaskWebWriter.Complete$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class Complete$MapperCode extends Complete$Mapper {

}

       


export class Save {

    

    
    public static execute2(taskId: string, dataJson: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {
      Save.execute(new Data.TaskList.SaveForm(taskId, dataJson), f, eh, axoisInstance);
    } 

    public static execute(input: Data.TaskList.SaveForm, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.BenchApi): void {

    

    

        TaskWriterService.save(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('TaskList::Save', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('TaskList::Save', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('TaskList::Save', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('TaskList::Save');
    }
    

    private static mapInput(input: Data.TaskList.SaveForm): ServerData.TaskWebWriter.Save$Input {
        return new Save$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new Save$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class Save$Mapper {

    public mapInput(input: Data.TaskList.SaveForm): ServerData.TaskWebWriter.Save$Input {
        return ServerData.TaskWebWriter.Save$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class Save$MapperCode extends Save$Mapper {

}

       


export class Rescue {

    

    
    public static execute2(taskId: string, outputJson: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.ValeyApi): void {
      Rescue.execute(new ServerData.TaskWebWriter.Rescue$Input(taskId, outputJson), f, eh, axoisInstance);
    } 

    public static execute(input: ServerData.TaskWebWriter.Rescue$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.ValeyApi): void {

    

    

        TaskWriterService.rescue(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('TaskList::Rescue', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('TaskList::Rescue', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('TaskList::Rescue', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('TaskList::Rescue');
    }
    

    private static mapInput(input: ServerData.TaskWebWriter.Rescue$Input): ServerData.TaskWebWriter.Rescue$Input {
        return new Rescue$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new Rescue$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class Rescue$Mapper {

    public mapInput(input: ServerData.TaskWebWriter.Rescue$Input): ServerData.TaskWebWriter.Rescue$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class Rescue$MapperCode extends Rescue$Mapper {

}

       


export class Retry {

    
    public static execute1(taskId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      Retry.execute(new ServerData.TaskWebWriter.Retry$Input(taskId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.TaskWebWriter.Retry$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        TaskWriterService.retry(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('TaskList::Retry', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('TaskList::Retry', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('TaskList::Retry', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('TaskList::Retry');
    }
    

    private static mapInput(input: ServerData.TaskWebWriter.Retry$Input): ServerData.TaskWebWriter.Retry$Input {
        return new Retry$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new Retry$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class Retry$Mapper {

    public mapInput(input: ServerData.TaskWebWriter.Retry$Input): ServerData.TaskWebWriter.Retry$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class Retry$MapperCode extends Retry$Mapper {

}

       


export class CancelFlow {

    

    
    public static execute2(taskId: string, reason: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      CancelFlow.execute(new Data.TaskList.CancelFlowAndCancelTaskInput(taskId, reason), f, eh, axoisInstance);
    } 

    public static execute(input: Data.TaskList.CancelFlowAndCancelTaskInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        TaskWriterService.cancelFlow(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('TaskList::CancelFlow', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('TaskList::CancelFlow', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('TaskList::CancelFlow', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('TaskList::CancelFlow');
    }
    

    private static mapInput(input: Data.TaskList.CancelFlowAndCancelTaskInput): ServerData.TaskWebWriter.CancelFlow$Input {
        return new CancelFlow$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new CancelFlow$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class CancelFlow$Mapper {

    public mapInput(input: Data.TaskList.CancelFlowAndCancelTaskInput): ServerData.TaskWebWriter.CancelFlow$Input {
        return ServerData.TaskWebWriter.CancelFlow$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class CancelFlow$MapperCode extends CancelFlow$Mapper {

}

       


export class CancelTask {

    

    
    public static execute2(taskId: string, reason: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      CancelTask.execute(new Data.TaskList.CancelFlowAndCancelTaskInput(taskId, reason), f, eh, axoisInstance);
    } 

    public static execute(input: Data.TaskList.CancelFlowAndCancelTaskInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        TaskWriterService.cancelTask(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('TaskList::CancelTask', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('TaskList::CancelTask', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('TaskList::CancelTask', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('TaskList::CancelTask');
    }
    

    private static mapInput(input: Data.TaskList.CancelFlowAndCancelTaskInput): ServerData.TaskWebWriter.CancelTask$Input {
        return new CancelTask$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new CancelTask$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class CancelTask$Mapper {

    public mapInput(input: Data.TaskList.CancelFlowAndCancelTaskInput): ServerData.TaskWebWriter.CancelTask$Input {
        return ServerData.TaskWebWriter.CancelTask$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class CancelTask$MapperCode extends CancelTask$Mapper {

}

       


export class Proceed {

    
    public static execute1(taskId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.ValeyApi): void {
      Proceed.execute(new ServerData.TaskWebWriter.Proceed$Input(taskId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.TaskWebWriter.Proceed$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.ValeyApi): void {

    

    

        TaskWriterService.proceed(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('TaskList::Proceed', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('TaskList::Proceed', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('TaskList::Proceed', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('TaskList::Proceed');
    }
    

    private static mapInput(input: ServerData.TaskWebWriter.Proceed$Input): ServerData.TaskWebWriter.Proceed$Input {
        return new Proceed$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new Proceed$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class Proceed$Mapper {

    public mapInput(input: ServerData.TaskWebWriter.Proceed$Input): ServerData.TaskWebWriter.Proceed$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class Proceed$MapperCode extends Proceed$Mapper {

}

       


export class AddComment {

    

    
    public static execute2(taskId: string, comment: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      AddComment.execute(new Data.TaskList.AddCommentForm(taskId, comment), f, eh, axoisInstance);
    } 

    public static execute(input: Data.TaskList.AddCommentForm, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        TaskWriterService.addComment(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('TaskList::AddComment', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('TaskList::AddComment', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('TaskList::AddComment', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('TaskList::AddComment');
    }
    

    private static mapInput(input: Data.TaskList.AddCommentForm): ServerData.TaskWebWriter.AddComment$Input {
        return new AddComment$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new AddComment$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class AddComment$Mapper {

    public mapInput(input: Data.TaskList.AddCommentForm): ServerData.TaskWebWriter.AddComment$Input {
        return ServerData.TaskWebWriter.AddComment$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class AddComment$MapperCode extends AddComment$Mapper {

}

       

     