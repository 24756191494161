
import * as LoginXX from './action/login-action'; 
import * as PartnerXX from './action/partner-action'; 
import * as InsightXX from './action/insight-action'; 
import * as SpineXX from './action/spine-action'; 
import * as ClientFileXX from './action/clientfile-action'; 
import * as ColorXX from './action/color-action'; 
import * as TaskListXX from './action/tasklist-action'; 
import * as ClientXX from './action/client-action'; 
import * as FiNoteXX from './action/finote-action'; 

export const Login = LoginXX; 
export const Partner = PartnerXX; 
export const Insight = InsightXX; 
export const Spine = SpineXX; 
export const ClientFile = ClientFileXX; 
export const Color = ColorXX; 
export const TaskList = TaskListXX; 
export const Client = ClientXX; 
export const FiNote = FiNoteXX; 

