<template>
  <v-btn
    v-bind="$props"
    @click="buttonClicked()"
    :loading="buttonLoading"
    small
    >{{ label }}</v-btn
  >
</template>
<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { VBtn } from 'vuetify/lib/components';

@Component({
  components: {
    VBtn,
  },
})
export default class FFormSubmitBtn extends VBtn {
  @Prop()
  label: string;

  @Prop()
  onFormSubmit: () => void;

  buttonLoading: boolean = false;

  buttonClicked() {
    this.buttonLoading = true;
    this.onFormSubmit();
    setTimeout(() => {
      this.buttonLoading = false;
    }, 500);
  }
}
</script>
