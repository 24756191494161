import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from './views/custom/Home.vue';
import SmileRouter from './../src-gen/smile-router';

// import Login from '@/views/Login.vue';
// import * as toastr from 'toastr';
// import store, * as Store from '@/../src-gen/store';
// import * as Data from '@/../src-gen/data';
// import * as Action from '@/../src-gen/action';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [...SmileRouter];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

/*  for Gompa Integration using Login ADS   -- uncomment this
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authenticated)) {

    const userLocal = new UserLocal();

    if (userLocal.absent()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {

      Store.Mutation.Login.LoginDetails.SET_USER_NAME(new Data.Login.LoginForm(userLocal.userName!));
      if (to.meta.roles) {

        userLocal.matchOnRoleList( backendRoleList => {

         const matched =  (to.meta.roles as string[]).some(role => backendRoleList.includes(role));
         if (matched) {
           next();
         } else {
          toastr['error']('Forbidden : You are not authorized');
          next(false);
         }
        });

      } else {
        next();
      }

    }
  } else {
    next();
  }
});

class UserLocal  {

  public userName: string|null;


  constructor() {
   this.userName =  window.localStorage.getItem('userName');
  }
  public absent(): boolean {
   return this.userName == null;
  }


  public matchOnRoleList(f: (roleList: string[]) => void): void  {

    const state = store.state as any;

    if (state.Login) {
      const roleList =  state.Login.LoginDetails.roleList;
      f(roleList);
    } else {
      Action.Login.GetRoleListForUser.execute(new Data.Login.MyAppId(), output => {
          f(output.roleList);
      });
    }
  }
}

*/

export default router;
