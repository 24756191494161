
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class DocumentUploadWriterService {

   public static attachDocument(
           axiosInstance: AxiosInstance, input: ServerData.DocumentUploadWebWriter.AttachDocument$Input,
           f: (output: ServerData.DocumentUploadWebWriter.AttachDocument$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/documentupload/attach-document',
            input.toJson()
        ).then((response) => ServerData.DocumentUploadWebWriter.AttachDocument$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static detachDocument(
           axiosInstance: AxiosInstance, input: ServerData.DocumentUploadWebWriter.DetachDocument$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/documentupload/detach-document',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


}

