<template>
  <div class="pa-3 fadeIn">
    <v-row>
      <v-col cols="12" sm="12" md="5">
        <div class="card-container">
          <div class="headContainer">
            <p class="card_heading">Daily Conversion</p>
            <f-tooltip tooltipText="Showing data as per today.">
              <v-icon color="colorText" small>
                mdi-information-variant-circle-outline</v-icon
              >
            </f-tooltip>
          </div>
          <div class="setions">
            <div
              class="subcontainer bgPink"
              @click="gotoClientFileLookup(dateRangeInputCurrent.dateRange)"
            >
              <div class="cardIcon bgDrakPink">
                <img src="@/assets/images/TotalSales.svg" />
              </div>

              <div class="showNumberContainer">
                <div>
                  <p class="cardSubheading">Total Footfalls</p>
                  <UpDowncard
                    :data="conversionSummaryInfo.percentageDifferenceLeads"
                  />
                </div>
                <p class="cardAmount">
                  <AnimatedNumber
                    :number="conversionSummaryInfo.todaysLeadCount"
                  ></AnimatedNumber>
                </p>
              </div>
            </div>
            <div
              class="subcontainer bgBlue"
              @click="
                gotoClientFileLookup(
                  dateRangeInputCurrent.dateRange,
                  'ONBOARDED'
                )
              "
            >
              <div class="cardIcon bgDrakBlue">
                <img src="@/assets/images/MTDConversion.svg" />
              </div>
              <div class="showNumberContainer">
                <div>
                  <p class="cardSubheading">Onboarded</p>
                  <UpDowncard
                    :data="
                      conversionSummaryInfo.percentageDifferenceOnBoardingConversion
                    "
                  />
                </div>
                <p class="cardAmount">
                  <AnimatedNumber
                    :number="
                      conversionSummaryInfo.todaysOnBoardingDoneConversion
                    "
                  ></AnimatedNumber>
                </p>
              </div>
            </div>
            <div
              class="subcontainer bgGreen"
              @click="
                gotoClientFileLookup(dateRangeInputCurrent.dateRange, 'MANDATE')
              "
            >
              <div class="cardIcon bgDrakGreen">
                <img src="@/assets/images/TotalSales.svg" />
              </div>
              <div class="showNumberContainer">
                <div>
                  <p class="cardSubheading">Mandate</p>
                  <UpDowncard
                    :data="
                      conversionSummaryInfo.percentageDifferenceEmandateConversion
                    "
                  />
                </div>
                <p class="cardAmount">
                  <AnimatedNumber
                    :number="conversionSummaryInfo.todaysEmandateDoneConversion"
                  ></AnimatedNumber>
                </p>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="7">
        <div class="card-container">
          <div class="headContainer">
            <p class="card_heading">MTD Conversion</p>
            <f-tooltip tooltipText="Showing data as per today.">
              <v-icon color="colorText" small>
                mdi-information-variant-circle-outline</v-icon
              >
            </f-tooltip>
          </div>
          <div class="setions">
            <div
              class="subcontainer bgPink"
              @click="gotoClientFileLookup(dateRangeInput.dateRange)"
            >
              <div class="cardIcon bgDrakPink">
                <img src="@/assets/images/TotalFootfalls.svg" />
              </div>
              <div class="showNumberContainer">
                <div>
                  <p class="cardSubheading">Total Footfalls</p>
                </div>
                <p class="cardAmount">
                  <AnimatedNumber
                    :number="conversionSummaryInfo.mtdLeadCount"
                  ></AnimatedNumber>
                </p>
              </div>
            </div>
            <div
              class="subcontainer bgBlue"
              @click="
                gotoClientFileLookup(dateRangeInput.dateRange, 'ONBOARDED')
              "
            >
              <div class="cardIcon bgDrakPink">
                <img src="@/assets/images/MTDConversion.svg" />
              </div>
              <div class="showNumberContainer">
                <div>
                  <p class="cardSubheading">Onboarded</p>
                </div>
                <p class="cardAmount">
                  <AnimatedNumber
                    :number="conversionSummaryInfo.mtdOnBoardingDoneConversion"
                  ></AnimatedNumber>
                </p>
              </div>
            </div>
            <div
              class="subcontainer bgGreen"
              @click="gotoClientFileLookup(dateRangeInput.dateRange, 'MANDATE')"
            >
              <div class="cardIcon bgDrakGreen">
                <img src="@/assets/images/TotalFootfalls.svg" />
              </div>
              <div class="showNumberContainer">
                <div>
                  <p class="cardSubheading">Mandate</p>
                </div>

                <p class="cardAmount">
                  <AnimatedNumber
                    :number="conversionSummaryInfo.mtdEmandateDoneConversion"
                  ></AnimatedNumber>
                </p>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
import AnimatedNumber from "../../components/AnimatedNumber.vue";
import Helper from "@/util/Helper";
import UpDowncard from "./UpDowncard.vue";
import FTooltip from "@/components/FTooltip.vue";
import * as Action from "@/../src-gen/action";

@Component({
  components: {
    "f-tooltip": FTooltip,
    AnimatedNumber,
    UpDowncard,
  },
})
export default class ConversionSummaryInfoMobile extends Vue {
  @Store.Getter.Insight.InsightStore.conversionSummaryOutput
  conversionSummaryInfo: Data.Insight.ConversionSummaryOutput;

  partnerClientCountInput: Data.Insight.GetPartnerClientCountInput =
    new Data.Insight.GetPartnerClientCountInput();

  partnerClientCountInputForCurrentDate: Data.Insight.GetPartnerClientCountInput =
    new Data.Insight.GetPartnerClientCountInput();

  partnerClientCountOutput: Data.Insight.GetPartnerClientCountOutput =
    new Data.Insight.GetPartnerClientCountOutput();

  partnerClientCountOutputForCurrentDate: Data.Insight.GetPartnerClientCountOutput =
    new Data.Insight.GetPartnerClientCountOutput();

  numtt: number = 1000000;

  dateRangeInput: any = { dateRange: [] };
  dateRangeInputCurrent: any = { dateRange: [] };

  get currentDate() {
    return Helper.dateMTDWithCurrent().currentDate;
  }
  get monthStartDate() {
    return Helper.dateMTDWithCurrent().startOfMonth;
  }

  goto(filterType: string, clientFileStatus: string) {
    this.$router.push({
      name: "InstitutionalPartner.InstitutionClientFile",
      params: { filterType: filterType, clientFileStatus: clientFileStatus },
    });
  }

  gotoClientFileLookup(dateRange: string, clientFileStatus?: string) {
    // const status = clientFileStatus == 'ONBOARDED' && Data.Insight.CF_OM_FILTER.ONBOARDED;
    this.$router.push({
      name: "InstitutionalPartner.ClientFileLookup",
      params: {
        dateRange: dateRange,
        clientFileStatus: clientFileStatus || "",
      },
    });
  } 

  mounted() {
    this.partnerClientCountInput.fromDate = this.dateRangeInput.dateRange[0];
      this.partnerClientCountInput.toDate = this.dateRangeInput.dateRange[1];
      this.dateRangeInput.dateRange = [this.monthStartDate, this.currentDate];
      this.dateRangeInputCurrent.dateRange = [this.currentDate, this.currentDate];
      this.partnerClientCountInputForCurrentDate.fromDate =
        this.dateRangeInputCurrent.dateRange[0];
      this.partnerClientCountInputForCurrentDate.toDate =
        this.dateRangeInputCurrent.dateRange[1];
  }
}
</script>

<style scoped>
p {
  margin: 0;
}

.bgDrakPink {
  background: #e8657f;
}
.bgDrakBlue {
  background: #699ddc;
}
.bgDrakGreen {
  background: #6fd567;
}
.bgDrakLightBlue {
  background: #6dc8de;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
}

.bgGreen {
  background: #e2fbe8;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
}
.bgLightBlue {
  background: #e0f8fd;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
}
.bgBlue {
  background: #ecf5fc;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
}
.bgPink {
  background: #fae3e6;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
}

.bgLightBlue:hover {
  background: #d4f2f8;
}
.bgGreen:hover {
  background: #d9f8e0;
}
.bgBlue:hover {
  background: #e5f2fd;
}
.bgPink:hover {
  background: #fcdde2;
}

.card-container {
  background: #ffffff;
  padding: 15px;
  border-radius: 12px;
  border: 1px solid #f7f8fb;
  min-height: 200px;
}
.card_heading {
  font-size: 14px;
  font-weight: bold;
  color: #4e4e4e;
}
.setions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin-top: 15px;
}
.subcontainer {
  flex: 1;
  padding: 10px 15px;
  border-radius: 4px;
}
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.divider {
  width: 1px;
  height: 45px;
  background: #ccc;
  margin: 10px 0;
}
.heading {
  font-size: 14px;
  font-weight: bold;
  color: #00447a !important;
}
.amount {
  font-size: 20px;
  font-weight: bold;
  color: #f36f21 !important;
}
.cardAmount {
  font-size: 20px;
  color: #2e2e2e;
  font-weight: bold;
  margin: 0;
}
.cardSubheading {
  font-size: 16px;
  color: #232b4d;
}
.cardDis {
  font-size: 14px;
  color: #5599ff;
}

.cardIcon {
  width: 45px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.cardIcon > img {
  width: 16px;
  height: 16px;
}
.headContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.viewReport {
  font-size: 12px;
  color: #5599ff;
  background: #f7f8fb;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

@media all and (min-device-width: 360px) and (max-device-width: 540px) {
  .setions {
    flex-direction: column;
  }
  .subcontainer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 30px;
  }
  .showNumberContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    width: 100%;
    justify-content: space-between;
  }
}
</style>
