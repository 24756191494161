<template>
  <div class="monthlyBilling">
    <div class="pa-3 fadeIn">
      <component
        :ref="CFSSearchFFormMetaData.myRefName"
        :value="selectModel(findParticularPartnerClientInput, undefined)"
        :is="CFSSearchFFormMetaData.componentName"
        v-bind="CFSSearchFFormMetaData.props"
        @input="
          (newValue) =>
            updateModel(findParticularPartnerClientInput, newValue, undefined)
        "
      ></component>
      <component
        v-if="!loader"
        :ref="CFSFDataTableMetaData.myRefName"
        :value="findParticularPartnerClientArrayOutput"
        :is="CFSFDataTableMetaData.componentName"
        v-bind="CFSFDataTableMetaData.props"
      ></component>
      <!-- <v-card outlined elevation="0" flat v-else class="text-center my-5 py-5 text--disabled body-2">
        No data available //&& findParticularPartnerClientArrayOutput.length
      </v-card> -->
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import * as Data from "@/../src-gen/data";
import * as Action from "@/../src-gen/action";
import ModelVue from "@/components/ModelVue";
import FDataTable from "@/components/table/FDataTable.vue";
import FForm from "@/components/form/FForm.vue";
import FSearchGrid from "@/components/generic/FSearchGrid.vue";
import ExportDownloadDateSearchFFormMDP from "./ExportDownloadDateSearchFFormMDP";
// import CFFSearchGridMDP from "../CFFSearchGridMDP";
import CFSFDataTableMDP from "./ClientFileSearch/CFSFDataTableMDP";
import CFSSearchFFormMDP from "./ClientFileSearch/CFSSearchFFormMDP";

@Component({ components: { FDataTable, FForm, FSearchGrid } })
export default class ClientFileSearch extends ModelVue {
  loader: boolean = false;
  exportDownloadForm: boolean = false;

  findParticularPartnerClientArrayOutput: any = [];

  findParticularPartnerClientInput: Data.Insight.FindParticularPartnerClientInput =
    new Data.Insight.FindParticularPartnerClientInput();

  findParticularPartnerClientOutput: Data.Insight.FindParticularPartnerClientOutput =
    new Data.Insight.FindParticularPartnerClientOutput();

  get exportDownloadFSearchGridMetaData() {
    return new ExportDownloadDateSearchFFormMDP({
      taskRoot: this,
    }).getMetaData();
  }

  get CFSSearchFFormMetaData() {
    return new CFSSearchFFormMDP({ parent: this }).getMetaData();
  }

  get CFSFDataTableMetaData() {
    return new CFSFDataTableMDP({
      parent: this,
    }).getMetaData();
  }

  findParticularPartnerClient() {
    this.loader = true;
    Action.Insight.FindParticularPartnerClient.execute(
      this.findParticularPartnerClientInput,
      (output) => {
        if (output.isPartnerClientDataExist) {
          this.loader = false;
          this.findParticularPartnerClientArrayOutput = [
            output.mayBePartnerClient,
          ];
          this.findParticularPartnerClientOutput = output;
        } else {
          this.loader = false;
          this.findParticularPartnerClientArrayOutput = [];
        }
      },
      (error) => {
        this.loader = false;
        console.log("error", error);
      }
    );
  }
}
</script>

<style>
</style>
