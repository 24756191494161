
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class PaymentReaderService {

   public static getCashfreePaymentStatus(
           axiosInstance: AxiosInstance, input: ServerData.PaymentWebReader.GetCashfreePaymentStatus$Input,
           f: (output: ServerData.PaymentWebReader.GetCashfreePaymentStatus$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/payment/get-cashfree-payment-status',
            {params : input.toJson()}
        ).then((response) => ServerData.PaymentWebReader.GetCashfreePaymentStatus$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getFiPaymentList(
           axiosInstance: AxiosInstance, input: ServerData.PaymentWebReader.GetFiPaymentList$Input,
           f: (output: ServerData.SpineData$FiPayment[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/payment/get-fi-payment-list',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.SpineData$FiPayment.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getQrCodeStatusListForClient(
           axiosInstance: AxiosInstance, input: ServerData.PaymentWebReader.GetQrCodeStatusListForClient$Input,
           f: (output: ServerData.PaymentWebReader.GetQrCodeStatusListForClient$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/payment/get-qr-code-status-list-for-client',
            {params : input.toJson()}
        ).then((response) => ServerData.PaymentWebReader.GetQrCodeStatusListForClient$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getPaymentTransactionList(
           axiosInstance: AxiosInstance, input: ServerData.PaymentWebReader.GetPaymentTransactionList$Input,
           f: (output: ServerData.SpineData$FiPaymentTransaction[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/payment/get-payment-transaction-list',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.SpineData$FiPaymentTransaction.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


}

