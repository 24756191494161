import MDP from "../MDP";
import FInfoTextMDP from "./info/FInfoTextMDP";

export default class FTableInfoMDP implements MDP {
    label: string = "";
    infoMDP: MDP;
    value: string | undefined;
    position?: InfoPosition;
    condition?: boolean = true;
    constructor({
        label,infoMDP,value,position=InfoPosition.Header,
        condition=true
    }: {
        label: string;
        infoMDP?: MDP;
        value?: string;
        position?: InfoPosition;
        condition?: boolean
    }) {
        this.label = label;
        this.infoMDP = infoMDP || new FInfoTextMDP();
        this.value = value;
        this.position = position;
        this.condition = condition;
    }
    getMetaData(): object {
        return {
            label: this.label,
            infoMetaData: this.infoMDP.getMetaData(),
            value: this.value,
            position: this.position,
            condition: this.condition
        }
    }
}

export enum InfoPosition {
    Header = "Header",
    Footer = "Footer"
}
