import FCashfreeListMDP from "@/components/generic/file/cashfreeList/FCashfreeListMDP";


export default class ECFTCashfreeStepMDP extends FCashfreeListMDP {
    
    constructor({ taskRoot, parent }:{ taskRoot: any, parent: any }) {
        super({taskRoot, parent})
    }

}

