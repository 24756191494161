import FBtnMDP, { BtnType } from '@/components/FBtnMDP';
import FFormSubmitBtnMDP from '@/components/FFormSubmitBtnMDP';
import FFormMDP, { FFormChildMDP } from '@/components/form/FFormMDP';
import FOTPMDP from '@/components/form/field/FOTPMDP';

export default class VerifyOTPFFormMDP extends FFormMDP {
  childMDP = new FFormChildMDP();
  root: any;
  // parent: any;
  constructor({ root }: { root: any }) {
    super({
      myRefName: 'addPartnerFFormRef',
    });
    this.root = root;
    // this.parent = parent;

    this.addField(
      new FOTPMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: 'otp',
        label: 'OTP',
        mandatory: true,
      })
    )
      .addAction(
        new FBtnMDP({
          label: 'cancel',
          btnType: BtnType.TEXT,
          onClick: () => this.root.resetform(),
        })
      )
      .addAction(
        new FBtnMDP({
          label: 'Generate OTP',
          onClick: () => this.generateOTP(),
        })
      )
      .addAction(
        new FFormSubmitBtnMDP({
          label: 'Verify',
          onClick: () => this.root.validateAndRegisterPartnerClientFile(),
          btnType: BtnType.FILLED,
        })
      );
  }

  getMyRef(): any {
    return this.root.$refs[this.myRefName];
  }

  generateOTP() {
      this.root.sendOTP();
  }
}
