import MDP from '@/components/MDP';
import FBtnMDP from './FBtnMDP';

export default class FPartnerButtonMDP extends FBtnMDP {
  componentName = 'FPartnerButton';
  
}

export enum BtnType {
  TEXT = 'TEXT',
  OUTLINED = 'OUTLINED',
  FILLED = 'FILLED',
}

export enum BtnColor {
  PRIMARY = 'primary',
  SECONADRY = 'secondary',
}
