<template>
  <v-app>
    <div class="hero-container">
      <div class="container-wrapper">
        <template>
          <v-sheet height="100vh" style="position: relative">
            <NavHeader :drawer="drawer" @closeSideNav="closeSideNav" />
            <SideNav :drawer="drawer" @closeSideNav="closeSideNav" />
            <router-view />
          </v-sheet>
        </template>
      </div>
      <OnboardingCard />
      <OnboardingSuccessCard />
    </div>
  </v-app>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import store, * as Store from '@/../src-gen/store';
import * as Data from '@/../src-gen/data';
import * as Action from '@/../src-gen/action';
import SideNav from '../components/partner/side-nav.vue';
import Header from '../components/header.vue';
import OnboardingCard from '../components/OnboardingCard.vue';
import OnboardingSuccessCard from '../components/onBoardingSuccess.vue';

@Component({
  components: {
    SideNav,
    NavHeader: Header,
    OnboardingCard,
    OnboardingSuccessCard,
  },
})
export default class PartnerLayout extends Vue {
  @Store.Getter.Partner.ApplicantDetails.PartnerInfo
  PartnerInfo: Data.Partner.PartnerSummary;
  partnerStatusEnum: any = Data.Partner.PARTNER_STATUS.SIGNED_UP;
  get dialog() {
    return (
      this.PartnerInfo?.status?.id == Data.Partner.PARTNER_STATUS.SIGNED_UP.id
    );
  }
  drawer: boolean = false;
  closeSideNav(drawer: boolean) {
    this.drawer = drawer;
  }
  get isAuthenticated() {
    return localStorage.getItem('auth_token') || '';
  }
  get partnerId() {
    return localStorage.getItem('partnerId') || '';
  }
  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push({ name: 'Login.Login' });
    } else {
      this.getPartner();
    }
  }
  getPartner() {
    Action.Partner.FindPartnerDetails.execute1(this.partnerId, (output) => {});
  }
}
</script>
<style scoped>
.hero-container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #eeeeee;
  position: relative;
}
.container-wrapper {
  /* background-color: #ffffff; */
  background-color: #f2f3f7;
  width: 100vw;
}

@media only screen and (max-width: 450px) {
  .container-wrapper {
    width: 100%;
  }
}
</style>
