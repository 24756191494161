import FChartLineMDP from './FChartLineMDP';
import FXAxisMDP from './FXAxisMDP';
import FYAxisMDP from './FYAxisMDP';
import FPointLabelsMDP from './FPointLabelsMDP';
import MDP from '../MDP';

export default class FC3ChartMDP implements MDP {
  componentName = 'FC3Chart';
  dataSelectorKeyList: FChartLineMDP[] = [];
  myRefName: string;
  type: string;
  chartTitle?: string | undefined;
  xAxis?: FXAxisMDP;
  yAxis?: FYAxisMDP;
  pointLabels?: FPointLabelsMDP;
  legendShow?: boolean;
  colorPattern?: string[] = [];

  constructor({
    myRefName,
    type,
    chartTitle,
    xAxis,
    yAxis,
    pointLabels = new FPointLabelsMDP({ show: true }),
    legendShow = true,
  }: {
    myRefName: string;
    dataSelectorKey?: string;
    type: string;
    chartTitle?: string;
    xAxis?: FXAxisMDP;
    yAxis?: FYAxisMDP;
    pointLabels?: FPointLabelsMDP;
    legendShow?: boolean;
    colorPattern?: string[];
  }) {
    this.myRefName = myRefName;
    this.type = type;
    this.chartTitle = chartTitle;
    this.xAxis = xAxis;
    this.yAxis = yAxis;
    this.pointLabels = pointLabels;
    this.legendShow = legendShow;
    this.colorPattern = [];
  }

  // addLine(newField: {
  //   dataSelectorKey: string;
  //   legend: string;
  //   type?: string;
  // }) {
  //   if (!newField.type) {
  //     newField.type = this.type;
  //   }
  //   this.dataSelectorKeyList.push(new FChartLineMDP(newField));
  //   return this;
  // }

  // addPlot(){

  // }

  getMetaData(): object {
    return {
      myRefName: this.myRefName,
      componentName: this.componentName,
      props: {
        myRefName: this.myRefName,
        dataSelectorKeyMetaDataList: this.dataSelectorKeyList.map(
          (item) => item
        ),
        chartTitle: this.chartTitle,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        pointLabels: this.pointLabels,
        type: this.type,
        legendShow: this.legendShow,
        colorPattern: this.colorPattern
      },
    };
  }
}

export enum ChartType {
  LINE = 'line',
  AREA = 'area',
  AREA_SPLINE = 'area-spline',
  SPLINE = 'spline',
  BAR = 'bar',
  PIE = 'pie',
  DONUT = 'donut',
}

export enum XAxisLabelPosition {
  INNER_CENTER = 'inner-center',
  INNER_RIGHT = 'inner-right',
  INNNER_LEFT = 'inner-left',
  OUTER_CENTER = 'outer-center',
  OUTER_RIGHT = 'outer-right',
  OUTER_LEFT = 'outer-left',
}

export enum YAxisLabelPosition {
  INNER_TOP = 'inner-top',
  INNER_MIDDLE = 'inner-middle',
  INNNER_BOTTOM = 'inner-bottom',
  OUTER_TOP = 'outer-top',
  OUTER_MIDDLE = 'outer-middle',
  OUTER_BOTTOM = 'outer-bottom',
}

export enum LegendPosition {
  BOTTOM = 'bottom',
  RIGHT = 'right',
}
