<template>
<span>
  <span v-if="selectModel(modelValue,dataSelectorKey)">
  <a class="mr-1" :target="openInNewTab?'_blank':''" v-if=" urlLinkActionType=='Url'" :href="selectModel(modelValue,dataSelectorKey)">
    {{placeholder}}
  </a>
  <v-btn outlined color="primary" @click="openLink" v-else x-small>{{placeholder}}</v-btn>
  </span>


  <div v-else>--</div>
</span>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ModelVue from "../../ModelVue";
import { UrlLinkActionType } from "./FCellUrlLinkMDP";

@Component({
  components: {
  },
})
export default class FCellUrlLink extends ModelVue {
 @Prop({
  default: "Url"
 })
 placeholder: string;
  
  @Prop()
  dataSelectorKey: string;
  
  @Prop()
  openInNewTab: boolean;
  @Prop({default: UrlLinkActionType.Url})
  urlLinkActionType: UrlLinkActionType;

  openLink() {
    window.open(this.selectModel(this.modelValue,this.dataSelectorKey),this.openInNewTab?"_blank":"_self");
  }
}
</script>
