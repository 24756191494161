import FBtnMDP from "@/components/FBtnMDP";
import MDP from "@/components/MDP";



export default class FAgreementListMDP implements MDP {
    componentName: string = "FAgreementList";
    actionList: FBtnMDP[] = [];
    taskRoot: any;
    parent: any;
    screenType: ScreenType;
    constructor({ taskRoot, parent, screenType=ScreenType.Task }:{ taskRoot: any; parent: any;screenType?: ScreenType }) {
        this.taskRoot = taskRoot;
        this.parent = parent;
        this.screenType = screenType;
    }

    addAction(newAction: FBtnMDP) {
        this.actionList.push(newAction);
        return this;
    }

    getMetaData(): object {
        return {
            componentName: this.componentName,
            props: {
                actionMetaDataList: this.actionList.map((action) =>
                    action.getMetaData(),
                ),
                taskRoot: this.taskRoot,
                screenType: this.screenType
            }
        }
    }
}

export enum ScreenType {
    Info="Info", Task="Task"
}

