<template>
  <v-app-bar flat dense class="grey lighten-2">
    <v-avatar
      @click="$router.push({ name: 'PartnerAffiliate.AffiliateHome' })"
      size="30"
      class="ma-2"
    >
      <v-img src="@/assets/freed-small-logo.svg" size="30" />
    </v-avatar>
    <v-btn text @click="$router.push({ name: 'PartnerAffiliate.MyClientFiles' })">
      <v-icon small class="pr-1">mdi-file-account</v-icon>
      My Files
    </v-btn>
    <v-btn
      text
      @click="$router.push({ name: 'PartnerAffiliate.TaskList.TaskAssignedToMe' })"
    >
      <v-icon small class="pr-1">mdi-checkbox-marked-circle-outline</v-icon>
      My Tasks
    </v-btn>
    <!-- <v-btn
      text
      @click="$router.push({ name: 'Root.MyTicket.ActiveTicketList' })"
    >
      <v-icon small class="pr-1">mdi-ticket-confirmation</v-icon>
      My Tickets
    </v-btn> -->

    <!-- <v-btn outlined small class="ml-3"  @click="$router.push({name: 'Root.MyTicket.ActiveTicketList'})">
      <v-icon small class="pr-1">mdi-plus-circle</v-icon>
      Create
    </v-btn>   -->
    <v-menu bottom close-on-click offset-y min-width="130">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          small
          class="elevation-0 ml-3"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon small class="pr-1">mdi-plus-circle</v-icon>
          Create
        </v-btn>
      </template>

      <v-list dense>
        <!-- <v-list-item @click="goto('Root.DRPPaymentCalculator')">
          <v-list-item-title>
            <v-icon small class="me-2">mdi-account-plus</v-icon>
            DRP Client File
          </v-list-item-title>
        </v-list-item> -->
        <v-list-item @click="goto('PartnerAffiliate.DRPPaymentCalculator')">
          <v-list-item-title>
            <v-icon small class="me-2">mdi-account-plus</v-icon>
            Client File
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-spacer></v-spacer>
    <!-- <v-spacer></v-spacer> -->
    <!-- <app-bar-notification-menu /> -->
    <!-- <v-btn
      @click="openInNewTab('Root.DRPPaymentCalculator')"
      color="primary"
      icon
      class="mr-2"
      small
      ><v-icon>mdi-calculator</v-icon>
    </v-btn> -->
    <!-- <app-bar-notification-menu /> -->
    <app-bar-user-menu />
    
  </v-app-bar>
</template>

<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import Helper from "../../util/Helper";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
import * as Action from "@/../src-gen/action";

import FClientFileAutoCompleteField from "@/components/form/field/FClientFileAutoCompleteField.vue";
import AppBarUserMenu from "./AppBarUserMenu.vue";

@Component({
  components: {
    AppBarUserMenu,
    
    FClientFileAutoCompleteField,
  },
})
export default class AppBar extends Vue {
  @Store.Getter.Login.LoginDetails.roleList
  roleList: string[];

  
  selectedClientFileNumber: string = "";
 

 

  get isSalesRep() {
    return this.roleList.includes("SalesRep");
  }

  get isSalesLead() {
    return this.roleList.includes("SalesLead");
  }


  goto(routerName: string) {
    this.$router.push({ name: routerName });
  }

  openInNewTab(route: string) {
    const routeData = this.$router.resolve({ name: route });
    const routeHref = routeData.href.split("/").slice(-3).join("/");
    window.open(routeHref, "_blank");
  }

}
</script>

<style scoped></style>
