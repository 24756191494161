
import * as Data from '@/../src-gen/data';
import {GenericStoreMutations} from '@/../src-gen/store/spine-store-support';

export class GenericStoreMutationsCode implements GenericStoreMutations   {


   public showConfirmation(state: Data.Spine.GenericStore, confirmationAlertInfo: Data.Spine.ConfirmationAlertInfo  ): void  {
    console.log(confirmationAlertInfo,"confirmationAlertInfo")
     state.confirmationAlertInfo = confirmationAlertInfo;
   }


   public hideConfirmation(state: Data.Spine.GenericStore  ): void  {
    state.confirmationAlertInfo = new Data.Spine.ConfirmationAlertInfo();
   }


}

