<template>
  <div>
    <v-tabs
      background-color="transparent"
      flat
      color="secondary"
      centered
      :value="activeTab"
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab
        :active="activeTab === tabIndx"
        @click="goto(tab.routerName)"
        v-for="(tab, tabIndx) in filteredTabList"
        :key="tab.tabName"
      >
        {{ tab.tabName }}
        <!-- <v-icon>mdi-phone</v-icon> -->
      </v-tab>
    </v-tabs>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import store, * as Store from '@/../src-gen/store';
import * as Data from "@/../src-gen/data";

import * as Action from "@/../src-gen/action";
import smileRouter from "@/../src-gen/smile-router";
import RouterUtil from "@/util/RouterUtil";


@Component
export default class FTab extends Vue {
  activeTab = 0;

  @Prop({default: []})
  tabList: any[];

  @Store.Getter.Login.LoginDetails.roleList
  loggedInUserRoleList: string[];

  mounted() {
    const ret = this.filteredTabList
      .map((tab) => tab.routerName)
      .indexOf(this.$route.name!);
    this.activeTab = ret;
  }

  goto(routerName: string) {
    if (this.$route.name === routerName) {
      // do nothing
    } else {
      this.$router.push({ name: routerName });
    }
  }
  get filteredTabList() {
    return this.tabList.filter(item => {
      return RouterUtil.isAuthorizedRouter(item.routerName, this.loggedInUserRoleList)
    });
  }
}
</script>
