
<template>
  <div class="onboardingModal" v-if="dialog">
    <div class="onboardingContent">
      <div class="onboardingHeading">On-Boarding Call Pending!!</div>
      <div class="description">
        Hello, just one more step. Request an onboarding call by clicking on the
        button below.
      </div>
      <div class="requestBtn" @click="requestOnBoarding">
        Request Onboarding Call
      </div>
    </div>
  </div>
</template>
  <script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
// import * as ServerData from '@/../src-gen/server-data';
import * as Action from "@/../src-gen/action";

@Component({
  components: {},
})
export default class OnboardingCard extends Vue {
  @Store.Getter.Partner.ApplicantDetails.PartnerInfo
  PartnerInfo: Data.Partner.PartnerSummary;
  partnerStatusEnum: any = Data.Partner.PARTNER_STATUS.SIGNED_UP;
  get dialog() {
    return (
      this.PartnerInfo?.status?.id == Data.Partner.PARTNER_STATUS.SIGNED_UP.id
    );
  }
  get partnerId() {
    return localStorage.getItem("partnerId") || "";
  }
  requestOnBoarding() {
    Action.Partner.RequestOnboarding.execute1(this.partnerId, (output) => {
      this.getPartner();
    });
  }
  getPartner() {
    Action.Partner.FindPartnerDetails.execute1(this.partnerId, (output) => {});
  }
}
</script>

<style lang="scss" scoped>
.onboardingModal {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboardingContent {
  background: #fff;
  width: 350px;
  margin: 0 auto;
  padding: 15px;
  border-radius: 30px;
  text-align: center;
  padding-bottom: 40px;
  position: relative;
}

.onboardingHeading {
  font-size: 28px;
  font-weight: bold;
  color: #000;
}

.description {
  font-size: 16px;
  color: #000;
  padding: 30px 0;
}

.requestBtn {
  background: #00447a;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 8px 0;
  cursor: pointer;
}
</style>
