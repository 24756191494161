
import * as Data from '@/../src-gen/data';
import {ClientFileSummaryGetters} from '@/../src-gen/store/clientfile-store-support';

export class ClientFileSummaryInit {
    public static initState(): Data.ClientFile.ClientFileSummary  { return new Data.ClientFile.ClientFileSummary(); }
}

export class ClientFileSummaryGettersCode implements ClientFileSummaryGetters {
  fiPaymentTransactionList(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiPaymentTransaction[] {
    return state.fiPaymentTransactionList
  }
  fiSignAgreementList(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiSSASummary[] {
    return state.fiSignAgreementList
  }
  fiPaymentList(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiPayment[] {
    return state.fiPaymentList
  }
   personalInfo(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.ClPersonalInfo | undefined {
    return state.personalInfo
   }
   fiBankInfo(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiBankInfo | undefined {
    return state.fiBankInfo
   }
   fiCreditorInfo(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiCreditorInfo | undefined {
    const fiCreditorInfo: any = state.fiCreditorInfo || new Data.ClientFile.FiCreditorInfo();
    // fiCreditorInfo.creditorList = state.fiCreditorInfo?.creditorList.filter(creditor => creditor.ineligible == false)
    // fiCreditorInfo.includedCreditorList = state.fiCreditorInfo?.creditorList;
    return fiCreditorInfo
   }
   fiDocumentList(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiDocument[] {
    return state.fiDocumentList
   }
   fiPaymentPlanInfo(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiPaymentPlanInfo | undefined {
    return state.fiPaymentPlanInfo
   }
   budgetInfo(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.BudgetInfo | undefined {
    return state.budgetInfo
   }
   public clientFileBasicInfo(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.ClientFileBasicInfo {
     return state.clientFileBasicInfo;
   }


   public fiEMandateList(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiEMandateList[] {
     return state.fiEMandateList
   }

   public clCreditorList(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.ClCreditor[] {
    return state.clCreditorList;
  }


  public fiEMandateSummaryList(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiEMandateSummary[] {
    return state.fiEMandateSummaryList
  }

  public fileSummary(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FileSummary | undefined {
    return state.fileSummary
  }

  public fiClientFileEnrollmentSummary(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.ClientFileEnrollmentSummary {
    return state.fiClientFileEnrollmentSummary;
  }

  public msfCashfreePaymentList(state: Data.ClientFile.ClientFileSummary): Data.ClientFile.FiPayment[] {
    console.log(state.fiPaymentList,"state.fiPaymentList")
    return state.fiPaymentList.filter(fiPayment => {
      return fiPayment.paymentProvider.id === Data.ClientFile.PAYMENT_PROVIDER.CASHFREE.id && !!fiPayment.msfAmount && fiPayment.msfAmount > 0
    })
  }
}
