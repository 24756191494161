
<template>
  <div >
    <f-confirmation />
    <app-bar></app-bar>
    <v-main  fluid class="grey lighten-4 partnerAffiliateLayout">
    <v-container fluid class="pa-0">
    <router-view />
    </v-container>
    </v-main>
  </div>
</template>

<script lang="ts">
import AppBar from "@/components/bar/appBar.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
import * as Action from "@/../src-gen/action";
import FConfirmation from "@/components/generic/FConfirmation.vue";
@Component({
  components: {
    AppBar,
    FConfirmation
  },
})
export default class PartnerAffiliateLayout extends Vue {
  mounted() {
    this.getLoggedInUser();
    this.getMyClientFileList();
  }

  getMyClientFileList() {
    Action.ClientFile.GetMyClientFileList.execute((output) => {});
  }
  getLoggedInUser() {
    const userName: any = localStorage.getItem("userName");
    Action.Login.GetUserDetails.execute1(userName, (output) => {
      this.getRoleListForUser();
    });
  }

  getRoleListForUser() {
    Action.Login.GetRoleListForUser.execute(
      new Data.Login.MyAppId(),
      (output: any) => {},
      (error) => {}
    );
  }
}
</script>


<style scoped>
.partnerAffiliateLayout {
  min-height: 600px !important;
}
</style>

