<template>
  <div class="ClientFileInfo">
    <v-container>
      <!-- BREADCRUMBS -->
      <v-breadcrumbs :items="breadcrumbList" class="pa-0 pb-1 px-1">
        <template v-slot:item="{ item }">
          <v-btn
            small
            text
            :color="item.routerName ? '' : 'primary'"
            class="pa-1"
            @click="goto(item.routerName)"
            >{{ item.title }}</v-btn
          >
        </template>
      </v-breadcrumbs>
      <!-- BREADCRUMBS -->
      <div class="row">
        <div class="col">
          <v-sheet rounded outlined class="mb-3">
            <v-card
              flat
              class="d-flex col py-2 align-center justify-space-between"
            >
              <div>
                <div class="secondary--text d-flex align-center mx-3 mb-1">
                  <v-icon color="secondary" class="mr-2" size="18">
                    mdi-file-account</v-icon
                  >
                  {{
                    onboardedClientFileDetails.clientFileDetails
                      ?.clientFileNumber
                  }}
                </div>
                <div class="d-flex align-center mx-3 mb-1">
                  <v-icon class="mr-2" size="18"> mdi-account</v-icon>
                  {{ clientBasicInfo.fullName }}
                </div>

                <div class="d-flex align-center mx-3 mb-1">
                  <v-icon class="mr-2" size="16"> mdi-phone-in-talk </v-icon>
                  {{ clientBasicInfo.mobile | phone }}
                </div>
              </div>
              <div class="content_container">
                <h5 class="primary--text">
                  Program Duration: {{ tenor }} Months
                </h5>
              </div>
            </v-card>
          </v-sheet>
        </div>
      </div>
      <template>
        <v-sheet rounded outlined class="mb-3">
          <v-card flat class="col py-2">
            <v-data-table
              item-key="id"
              :headers="headers"
              :items="clientFileDetailsListLocal"
              :items-per-page="10"
            >
              <template v-slot:[`item.clientFileNumber`]="{ item }">
                <v-btn
                  text
                  small
                  color="secondary"
                  @click="gotoClientFileInfo(item)"
                  >{{ item.clientFileNumber }}</v-btn
                >
              </template>
              <template v-slot:[`item.draftDate`]="{ item }">
                <span class="grey--text">
                  {{ item.draftDate | date }}
                </span>
              </template>
              <template v-slot:[`item.statusUpdatedOn`]="{ item }">
                <span class="grey--text">
                  {{ item.statusUpdatedOn | datetime }}
                </span>
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                <strong>{{ item.amount | toINR }}</strong>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip outlined x-small label :color="getColor(item.status)">
                  {{ item.status }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </v-sheet>
      </template>
    </v-container>
  </div>
</template>

<script lang="ts">
import { params } from 'vee-validate/dist/types/rules/alpha';
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
// import store, * as Store from '@/../src-gen/store';
import * as Data from '@/../src-gen/data';
// import * as ServerData from '@/../src-gen/server-data';
import * as Action from '@/../src-gen/action';

@Component({
  components: {},
})
export default class ClientFileInfo extends Vue {
  onboardedClientFileDetails: Data.Partner.OnboardedClientFileDetails =
    new Data.Partner.OnboardedClientFileDetails();

  colorCodeData = Data.Color.PS_ENTRY_STATUS;

  currentRouteName: string = this.$route.name as string;
  clientFileId = this.$route.params.clientFileId;

  headers = [
    {
      text: 'S. No.',
      align: 'start',
      value: 'sno',
    },
    {
      text: 'Draft Date',
      value: 'draftDate',
    },
    { text: 'Status Update On', value: 'statusUpdatedOn' },
    { text: 'Amount', value: 'amount' },
    { text: 'Status', value: 'status' },
  ];

  breadcrumbList = [
    {
      title: 'Home',
      routerName: 'Partner.Dashboard',
    },
    {
      title: 'Client Details',
    },
  ];

  mounted() {
    this.getFileListUpdate();
  }

  get clientFileDetailsListLocal() {
    return this.onboardedClientFileDetails.msfPaymentList.map(
      (item: any, index: number) => {
        return { ...item, sno: index + 1 };
      }
    );
  }

  get tenor() {
    return this.onboardedClientFileDetails.psPlan?.tenor || 0;
  }

  get clientBasicInfo() {
    return this.onboardedClientFileDetails.clientFileDetails?.clientBasicInfo;
  }

  getFileListUpdate() {
    Action.Partner.GetOnboardedClientDetails.execute1(
      this.clientFileId,
      (output) => {
        this.onboardedClientFileDetails = output;
      }
    );
  }

  goto(routerName: string) {
    this.$router.push({ name: routerName });
  }

  getColor(selectedStatus: string) {
    try {
      return this.colorCodeData.withId(selectedStatus)?.name || 'purple';
    } catch (e) {
      return 'default';
    }
  }
}
</script>

<style lang="scss" scoped>
.ClientFileInfo {
  background: #f8f9fa;
  min-height: 100vh;
}
</style>
