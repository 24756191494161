
import Vuex, { GetterTree, MutationTree } from 'vuex';
import {namespace} from 'vuex-class';
import * as Data from './../data';
// tslint:disable


import {ApplicantDetailsInit} from './../../src/section/partnerapp/store/partner/ApplicantDetailsGettersCode';
import {ApplicantDetailsGettersCode} from './../../src/section/partnerapp/store/partner/ApplicantDetailsGettersCode';
import {ApplicantDetailsMutationsCode} from './../../src/section/partnerapp/store/partner/ApplicantDetailsMutationsCode';
import {ApplicantDetailsGetters} from './partner-store-support';
import {ApplicantDetailsMutations} from './partner-store-support';


import store from './../store';


const applicantDetailsGetters: ApplicantDetailsGetters = new ApplicantDetailsGettersCode();
const ApplicantDetailsGetterTree: GetterTree<Data.Partner.ApplicantDetails, any> = {
  PartnerInfo : (state: Data.Partner.ApplicantDetails) => applicantDetailsGetters.PartnerInfo(state),
GetReferralOrgCodeInfo : (state: Data.Partner.ApplicantDetails) => applicantDetailsGetters.GetReferralOrgCodeInfo(state)
};

const applicantDetailsMutations: ApplicantDetailsMutations = new ApplicantDetailsMutationsCode();
const ApplicantDetailsMutationTree: MutationTree<Data.Partner.ApplicantDetails> = {
  updatePartnerInfo : (state: Data.Partner.ApplicantDetails, PartnerInfo: Data.Partner.PartnerSummary|undefined ) => applicantDetailsMutations.updatePartnerInfo(state , PartnerInfo  ) ,
updateReferralOrgCode : (state: Data.Partner.ApplicantDetails, GetReferralOrgCodeInfo: Data.Partner.GetReferralOrgCodeOutput ) => applicantDetailsMutations.updateReferralOrgCode(state , GetReferralOrgCodeInfo  ) 
};

const storeApplicantDetails = {
  namespaced: true,
  state: ApplicantDetailsInit.initState(),
  mutations : ApplicantDetailsMutationTree,
  getters: ApplicantDetailsGetterTree
};

const ApplicantDetailsSpace = namespace('Partner/ApplicantDetails');
const ApplicantDetailsGetter = {
    PartnerInfo : ApplicantDetailsSpace.Getter('PartnerInfo') ,
GetReferralOrgCodeInfo : ApplicantDetailsSpace.Getter('GetReferralOrgCodeInfo') 
};

class ApplicantDetailsMutationAPI {

  public static UPDATE_PARTNER_INFO(PartnerInfo: Data.Partner.PartnerSummary|undefined ): void {
    store.commit('Partner/ApplicantDetails/updatePartnerInfo' , PartnerInfo );
  }


  public static UPDATE_REFERRAL_ORG_CODE(GetReferralOrgCodeInfo: Data.Partner.GetReferralOrgCodeOutput ): void {
    store.commit('Partner/ApplicantDetails/updateReferralOrgCode' , GetReferralOrgCodeInfo );
  }

}




export const PartnerStoreInfo = {
     namespaced: true,
     modules: {
      ApplicantDetails: storeApplicantDetails
    }
};


export const PartnerGetter = {
ApplicantDetails : ApplicantDetailsGetter
};

export const PartnerMutation = {
ApplicantDetails : ApplicantDetailsMutationAPI
};

