
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class GompaWriterService {

   public static login(
           axiosInstance: AxiosInstance, input: ServerData.GompaWebWriter.Login$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'partnerprogramapi/gompa/login',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getRolesForAppId(
           axiosInstance: AxiosInstance, input: ServerData.GompaWebWriter.GetRolesForAppId$Input,
           f: (output: ServerData.GompaWebWriter.GetRolesForAppId$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/gompa/get-roles-for-app-id',
            {params : input.toJson()}
        ).then((response) => ServerData.GompaWebWriter.GetRolesForAppId$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static loginToSpine(
           axiosInstance: AxiosInstance, input: ServerData.GompaWebWriter.LoginToSpine$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/gompa/login-to-spine',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static sendOTP(
           axiosInstance: AxiosInstance, input: ServerData.GompaWebWriter.SendOTP$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'partnerprogramapi/gompa/send-o-t-p',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static validateOTP(
           axiosInstance: AxiosInstance, input: ServerData.GompaWebWriter.ValidateOTP$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'partnerprogramapi/gompa/validate-o-t-p',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static resetPassword(
           axiosInstance: AxiosInstance, input: ServerData.GompaWebWriter.ResetPassword$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'partnerprogramapi/gompa/reset-password',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static signUp(
           axiosInstance: AxiosInstance, input: ServerData.GompaWebWriter.SignUp$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'partnerprogramapi/gompa/sign-up',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static changeDetails(
           axiosInstance: AxiosInstance, input: ServerData.GompaWebWriter.ChangeDetails$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'partnerprogramapi/gompa/change-details',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getLeadSquareId(
           axiosInstance: AxiosInstance, input: ServerData.GompaWebWriter.GetLeadSquareId$Input,
           f: (output: ServerData.GompaWebWriter.GetLeadSquareId$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/gompa/get-lead-square-id',
            input.toJson()
        ).then((response) => ServerData.GompaWebWriter.GetLeadSquareId$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


}

