<template>
  
    <router-view />
  
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TaskListLayout extends Vue {}
</script>
