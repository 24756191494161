<template>
  <span>
    <span>
      <v-icon
        v-if="selectModel(modelValue, dataSelectorKey) == true"
        color="green"
        >mdi-check-circle</v-icon
      >
      <v-icon v-else color="error lighten-2">mdi-close-circle</v-icon>
    </span>
  </span>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModelVue from '../../ModelVue';

@Component({
  components: {},
})
export default class FCellBooleanCheck extends ModelVue {
  @Prop()
  dataSelectorKey: string;

  get textColor(): string {
    return this.selectModel(this.modelValue, this.dataSelectorKey)
      ? 'green'
      : 'orange';
  }
}
</script>
