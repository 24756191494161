import FColumnCellMDP from '../FColumnCellMDP';
export default class FCellAmountPaymentMDP implements FColumnCellMDP {
  dataSelectorKeyStatus: string;
  paymentDataSelectorKey: string;
  constructor(props: {
    dataSelectorKeyStatus: string;
    paymentDataSelectorKey: string;
  }) {
    this.dataSelectorKeyStatus = props.dataSelectorKeyStatus;
    this.paymentDataSelectorKey = props.paymentDataSelectorKey;
  }
  componentName: string = 'FCellAmountPayment';
  getMetaData(): object {
    return {
      componentName: this.componentName,
      props: {
        dataSelectorKeyStatus: this.dataSelectorKeyStatus,
        paymentDataSelectorKey: this.paymentDataSelectorKey,
      },
    };
  }
}
