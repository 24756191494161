<template>
  <span>
    <v-icon v-if="!selectModel(modelValue, dataSelectorKeyStatus)" color="error lighten-2"
      >mdi-close-circle</v-icon
    >

    <v-chip v-else outlined small class="mx-1" color="green">
      <strong>
        {{ selectModel(modelValue, dataSelectorKey) | toINR }}
      </strong>
    </v-chip>
  </span>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModelVue from '../../ModelVue';

@Component({
  components: {},
})
export default class FCellAmountPayment extends ModelVue {
  @Prop()
  dataSelectorKey: string;

  @Prop()
  dataSelectorKeyStatus: boolean;

  @Prop()
  paymentDataSelectorKey: string;

  // textColor(item: any): string {
  //   if (this.dataSelectorKeyStatus) {
  //     return item == true ? 'green' : 'red';
  //   }
  //   return 'default';
  // }
}
</script>
