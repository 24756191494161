
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class PartnerMasterReaderService {

   public static getStateList(
           axiosInstance: AxiosInstance, input: ServerData.PartnerMasterWebReader.GetStateList$Input,
           f: (output: ServerData.PartnerMasterWebReader.GetStateList$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'partnerprogramapi/partnermaster/get-state-list',
            {params : input.toJson()}
        ).then((response) => ServerData.PartnerMasterWebReader.GetStateList$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


}

