import moment from 'moment';
import FSnackbar from '@/fsnackbar';
import * as Action from '@/../src-gen/action';
import MyRouter from './MyRouter';

export default class Helper {
  static ANYCHART_LICENSE_KEY = 'freed.care-d6091ff-f670e3b3';
  static DATE_FORMAT = 'YYYY-MM-DD';
  static Router = MyRouter;

  static downloadFile(url: string, fileName: string) {
    fetch(url, {
      method: 'get',
      mode: 'no-cors',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.blob())
      .then((res) => {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', fileName);
        const href = URL.createObjectURL(res);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      });
  }

  static downloadFileFromInsight(url: string, fileName: string) {
    // Action.Insight.SpaSavingPercentageDump.execute((output: string) => {
    //   console.log(typeof(output),"Output")
    //   const res = new Blob([output]);
    //   const aElement = document.createElement('a');
    //   aElement.setAttribute('download', fileName);
    //   const href = URL.createObjectURL(res);
    //   aElement.href = href;
    //   aElement.setAttribute('target', '_blank');
    //   aElement.click();
    //   URL.revokeObjectURL(href);
    // })
    fetch(url, {
      method: 'get',
      referrerPolicy: 'no-referrer',
      headers: {
        Authorization: localStorage.getItem('auth_token') || '',
      },
    })
      .then((res) => res.blob())
      .then((res) => {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', fileName);
        const href = URL.createObjectURL(res);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
        FSnackbar.success('Succesfully Download.');
      });
  }

  static copyToClipBoard(textToCopy: string) {
    // Clipboard API is supported
    navigator.clipboard.writeText(textToCopy).then(
      function () {
        console.log('Async: Copying to clipboard was successful!');
        FSnackbar.success('Succesfully copied');
      },
      function (err) {
        FSnackbar.error('Error copying');
      }
    );
  }

  static generateRandomUrl(file: File | null) {
    if (file) {
      const dateValue: string = new Date().valueOf().toString();
      return dateValue.slice(-4) + file.name;
    }
    return '';
  }

  static getColor(value: any, colorCodeData: any) {
    try {
      return colorCodeData.withId(value)?.name || 'purple';
    } catch (e) {
      return 'default';
    }
  }

  static currencyIND(value: any) {
    const val = value
      ? Number(value).toLocaleString('en-IN', {
          maximumFractionDigits: +'0',
          minimumFractionDigits: +'0',
        })
      : 0;
    return '₹' + val;
  }

  static numberIND(value: any) {
    const val = value
      ? Number(value).toLocaleString('en-IN', {
          maximumFractionDigits: +'0',
          minimumFractionDigits: +'0',
        })
      : 0;
    return val;
  }

  static date12MonthsAgoWithCurrent() {
    const moment = require('moment');
    const currentDate = moment();
    const date12MonthsAgo = moment().subtract(12, 'months');
    let date = {
      fromDate: date12MonthsAgo.format('YYYY-MM-DD'),
      toDate: currentDate.format('YYYY-MM-DD'),
    };
    return date;
  }

  static compareJSONObjects(oldObj: any, newObj: any) {
    const diff: any = {
      changedKeyList: [],
      addedKeyList: [],
      removedKeyList: [],
    };

    const compareObjects = (oldObj: any, newObj: any, currPath: string) => {
      const oldKeys = Object.keys(oldObj);
      const newKeys = Object.keys(newObj);
      const allKeys = Array.from(new Set([...oldKeys, ...newKeys]));

      for (const key of allKeys) {
        const oldValue = oldObj[key];
        const newValue = newObj[key];
        const newPath: any = currPath ? `${currPath}.${key}` : key;

        if (
          !!oldValue &&
          !!newValue &&
          typeof oldValue === 'object' &&
          typeof newValue === 'object'
        ) {
          compareObjects(oldValue, newValue, newPath);
        } else if (oldValue !== newValue) {
          if (oldKeys.includes(key) && newKeys.includes(key)) {
            diff.changedKeyList.push(newPath);
          } else if (oldKeys.includes(key)) {
            diff.removedKeyList.push(newPath);
          } else {
            diff.addedKeyList.push(newPath);
          }
        }
      }
    };

    compareObjects(oldObj, newObj, '');

    return diff;
  }

  static dateMTDWithCurrent() {
    const moment = require('moment');
    const currentDate = moment();
    const yesterdayDate = moment().subtract(1, 'days');
    const startOfMonth = moment().startOf('month');
    const startOfYear = moment().startOf('year');
    const sevenDaysAgo = moment().subtract(6, 'days');
    const thirtyDaysAgo = moment().subtract(30, 'days');
    const yearAgo = moment().subtract(12, 'months');

    let date = {
      startOfMonth: startOfMonth.format('YYYY-MM-DD'),
      startOfYear: startOfYear.format('YYYY-MM-DD'),
      currentDate: currentDate.format('YYYY-MM-DD'),
      last7DaysToDate: sevenDaysAgo.format('YYYY-MM-DD'),
      thirtyDaysAgo: thirtyDaysAgo.format('YYYY-MM-DD'),
      yearAgo: yearAgo.format('YYYY-MM-DD'),
      yesterdayDate: yesterdayDate.format('YYYY-MM-DD'),
    };

    return date;
  }

  static previousValue: number | null = null;

  static calculatePositivePercentage(value: number): string {
    let a: number, b: number;

    if (Helper.previousValue === null) {
      // This is the first time the function is called
      a = value;
      b = value;
      Helper.previousValue = value;
    } else {
      a = Helper.previousValue;
      b = value;
      Helper.previousValue = value;
    }

    const percentageChange = ((b - a) / a) * 100;

    // Ensure the percentage is positive
    const positivePercentageChange = 100 - Math.abs(percentageChange);
    const formattedValue = Helper.numberIND(value);

    return value == 0
      ? '0'
      : positivePercentageChange.toFixed() + `% (${formattedValue})`; // Fixed to 2 decimal places
  }

  static checkNumberSign(number: number) {
    if (number > 0) {
      return true;
    } else if (number < 0) {
      return false;
    } else {
      return true;
    }
  }
}
