
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class ClientFileWriterService {

   public static attachDocument(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebWriter.AttachDocument$Input,
           f: (output: ServerData.ClientFileWebWriter.AttachDocument$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/clientfile/attach-document',
            input.toJson()
        ).then((response) => ServerData.ClientFileWebWriter.AttachDocument$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static detachDocument(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebWriter.DetachDocument$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/clientfile/detach-document',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static parseCreditReport(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebWriter.ParseCreditReport$Input,
           f: (output: ServerData.ClientFileWebWriter.ParseCreditReport$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/clientfile/parse-credit-report',
            input.toJson()
        ).then((response) => ServerData.ClientFileWebWriter.ParseCreditReport$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static uploadDocument(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebWriter.UploadDocument$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/clientfile/upload-document',
            input.toFormData()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static addFiCreditor(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebWriter.AddFiCreditor$Input,
           f: (output: ServerData.ClientFileWebWriter.AddFiCreditor$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/clientfile/add-fi-creditor',
            input.toJson()
        ).then((response) => ServerData.ClientFileWebWriter.AddFiCreditor$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static includeInProgram(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebWriter.IncludeInProgram$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/clientfile/include-in-program',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static excludeFromProgram(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebWriter.ExcludeFromProgram$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/clientfile/exclude-from-program',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static updateFiCreditor(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebWriter.UpdateFiCreditor$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/clientfile/update-fi-creditor',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static updateIncludeClCreditor(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebWriter.UpdateIncludeClCreditor$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/clientfile/update-include-cl-creditor',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static updateCreditInfo(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebWriter.UpdateCreditInfo$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/clientfile/update-credit-info',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static updateBudgetInfo(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebWriter.UpdateBudgetInfo$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/clientfile/update-budget-info',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static updateBankInfo(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebWriter.UpdateBankInfo$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/clientfile/update-bank-info',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static generateAgreement(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebWriter.GenerateAgreement$Input,
           f: (output: ServerData.ClientFileWebWriter.GenerateAgreement$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/clientfile/generate-agreement',
            input.toJson()
        ).then((response) => ServerData.ClientFileWebWriter.GenerateAgreement$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static initiateEMandate(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebWriter.InitiateEMandate$Input,
           f: (output: ServerData.ClientFileWebWriter.InitiateEMandate$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/clientfile/initiate-e-mandate',
            input.toJson()
        ).then((response) => ServerData.ClientFileWebWriter.InitiateEMandate$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static checkAndUpdateEMandate(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Input,
           f: (output: ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/clientfile/check-and-update-e-mandate',
            input.toJson()
        ).then((response) => ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static sendEmandate(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebWriter.SendEmandate$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/clientfile/send-emandate',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static markEMandateAsDefault(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebWriter.MarkEMandateAsDefault$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/clientfile/mark-e-mandate-as-default',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static updateExceptionTakenList(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebWriter.UpdateExceptionTakenList$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/clientfile/update-exception-taken-list',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


}

