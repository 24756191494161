<template>
  <div class="Onboarding">
    <div class="onboardingModal">
      <div class="onboardingContent">
        <div class="onboardingHeading">On-Boarding Call Pending!!</div>
        <div class="description">
          Hello, just one more step. Request an onboarding call by clicking on
          the button below.
        </div>
        <div class="requestBtn">Request Onboarding Call</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
// import store, * as Store from '@/../src-gen/store';
// import * as Data from '@/../src-gen/data';
// import * as ServerData from '@/../src-gen/server-data';
// import * as Action from '@/../src-gen/action';

@Component({
  components: {},
})
export default class Onboarding extends Vue {}
</script>

<style>
</style>
