<template>

  <PartnerButton
  class="col-12"
    :loading="buttonLoading"
    :btnSize="'sm'"
    btnType="primary"
    @click="buttonClicked()"
    >{{ label }}
    <template v-slot:icon>
      <img :src="require('@/assets/images/Vector.png')" />
    </template>
  </PartnerButton>
  
</template>
<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { VBtn } from "vuetify/lib/components";
import PartnerButton from "./partnerButton.vue";

@Component({
  components: {
    PartnerButton,
  },
})
export default class FPartnerButton extends VBtn {
  @Prop()
  label: string;

  @Prop()
  onClick: () => void;

  buttonLoading: boolean = false;

  buttonClicked() {
    this.buttonLoading = true;
    this.onClick();
    setTimeout(() => {
      this.buttonLoading = false;
    }, 500);
  }
}
</script>
