<template>
  <v-card rounded="8" flat>
    <div id="mapChart"></div>
  </v-card>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import anychart from "anychart";
import ModelVue from "../ModelVue";
import Helper from "@/util/Helper";

@Component({
  components: {},
})
export default class MapChart extends ModelVue {
  @Prop()
  dataMetaDataList: any;

  @Prop()
  tooltipLabel: string;

  mounted() {
    setTimeout(() => {
      this.mapChart();
    }, 1000);
  }

  get dataMetaDataListLocal() {
    if (this.dataMetaDataList) return this.dataMetaDataList;
  }

  set dataMetaDataListLocal(value: any) {
    this.dataMetaDataList = value;
  }

  mapChart() {
    anychart.licenseKey(Helper.ANYCHART_LICENSE_KEY);
    // create map
    var map = anychart.map();

    var credits = map.credits();
    credits.enabled(false);

    // let dataMetaDataListLocals = [
    // {id: 'N/A', value: 468},
    // {id: 'IN.BR', value: 2},
    // {id: 'IN.PY', value: 10},
    // {id: 'IN.RJ', value: 4},
    // {id: 'IN.MH', value: 8},
    // {id: 'IN.WB', value: 1},
    // {id: 'IN.MP', value: 6},
    // {id: 'IN.KL', value: 15},
    // {id: 'IN.TN', value: 9},
    // {id: 'IN.KA', value: 7},
    // {id: 'IN.HR', value: 17},

    // ]

    // create data set
    var dataSet = anychart.data.set(this.dataMetaDataListLocal);

    // create choropleth series
    const series = map.choropleth(dataSet);

    series.tooltip().padding([8, 10, 8, 10]).fontSize(14);

    series.selectionMode("none").stroke("#B9B9B9");

    series.hovered().fill("#b8b5d9");

    var scale = anychart.scales.ordinalColor([
      { less: 1 },
      { from: 1, to: 5 },
      { from: 5, to: 10 },
      { from: 10, to: 15 },
      { greater: 16 },
    ]);
    scale.colors(["#99c9f7", "#76a7d6", "#5584B1", "#446f9e", "#254E7B"]);

    // set color for choropleth series for map chart
    series.colorScale(scale);
    series.selectionMode("none").stroke("#e2e2e2");

    series.tooltip().format((e: any) => {
      // console.log('function', e);
      return (
        `Customer: ` + e.getData("value")
        // '\n' +
        // 'Credit Card: ' +
        // e.getData('ccDebtCount') +
        // '\n' +
        // 'Personal: ' +
        // e.getData('plDebtCount')
      );
    });

    // set geoIdField to 'id', this field contains in geo data meta properties
    series.geoIdField("id");

    // set map color settings
    // series.colorScale(anychart.scales.linearColor('#deebf7', '#3182bd'));
    series.hovered().fill("#addd8e");

    // set geo data, you can find this map in our geo maps collection
    // https://cdn.anychart.com/#maps-collection
    map.geoData(anychart.maps["india"]);

    // series.colorScale(anychart.scales.linearColor("#deebf7", "#00447a"));
    // map.colorRange().enabled(true);
    series.hovered().fill("#f36f21");

    //set map container id (div)
    map.container("mapChart");

    map.legend(true);

    map
      .legend()
      .itemsSourceMode("categories")
      .position("right")
      .align("top")
      .itemsLayout("vertical")
      .padding(50, 0, 0, 20)
      .paginator(false);

    //initiate map drawing
    map.draw();
  }
}
</script>

<style lang="css" scoped>
#mapChart {
  width: 100%;
  height: 340px;
  margin: 0;
  padding: 5px;
}
</style>
