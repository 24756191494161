
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class BenchReaderService {

   public static getBenchTaskSummary(
           axiosInstance: AxiosInstance, input: ServerData.BenchWebReader.GetBenchTaskSummary$Input,
           f: (output: ServerData.BenchData$BenchTaskSummary) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'bench-api/bench/get-bench-task-summary',
            {params : input.toJson()}
        ).then((response) => ServerData.BenchData$BenchTaskSummary.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getAllocatedTaskList(
           axiosInstance: AxiosInstance, input: ServerData.BenchWebReader.GetAllocatedTaskList$Input,
           f: (output: ServerData.BenchData$AllocatedTask[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'bench-api/bench/get-allocated-task-list',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.BenchData$AllocatedTask.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getStartedTaskList(
           axiosInstance: AxiosInstance, input: ServerData.BenchWebReader.GetStartedTaskList$Input,
           f: (output: ServerData.BenchData$StartedTask[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'bench-api/bench/get-started-task-list',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.BenchData$StartedTask.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getCompletedTaskList(
           axiosInstance: AxiosInstance, input: ServerData.BenchWebReader.GetCompletedTaskList$Input,
           f: (output: ServerData.BenchData$CompletedTask[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'bench-api/bench/get-completed-task-list',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.BenchData$CompletedTask.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static exitCheckWithOutput(
           axiosInstance: AxiosInstance, input: ServerData.BenchWebReader.ExitCheckWithOutput$Input,
           f: (output: ServerData.BenchWebReader.ExitCheckWithOutput$Output[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'bench-api/bench/exit-check-with-output',
            input.toJson()
        ).then((response) => (response.data as object[]).map((x) => ServerData.BenchWebReader.ExitCheckWithOutput$Output.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getTaskCommentList(
           axiosInstance: AxiosInstance, input: ServerData.BenchWebReader.GetTaskCommentList$Input,
           f: (output: ServerData.BenchData$Comment[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'bench-api/bench/get-task-comment-list',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.BenchData$Comment.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


}

