<template>
  <v-card class="pa-0 ma-0" flat height="calc(100vh - 48px)">
    <component
      v-if="!!showRegisterMyCFForm"
      :ref="registerMyCFFFormMetaData.myRefName"
      :is="registerMyCFFFormMetaData.componentName"
      :value="selectModel(registerClientFormData, undefined)"
      v-bind="registerMyCFFFormMetaData.props"
    ></component>
    <component
      v-if="!!myCFFileFDataTableMetaData"
      :ref="myCFFileFDataTableMetaData.myRefName"
      :is="myCFFileFDataTableMetaData.componentName"
      :value="selectModel(clientFileList, undefined)"
      v-bind="myCFFileFDataTableMetaData.props"
    ></component>
  </v-card>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
import * as Action from "@/../src-gen/action";

import MyCFFileFDataTableMDP from "./MyCFFileFDataTableMDP";
import FDataTable from "@/components/table/FDataTable.vue";


import FForm from "@/components/form/FForm.vue";
import ModelVue from "@/components/ModelVue";
import Helper from "@/util/Helper";
@Component({
  components: {
    FDataTable,
    FForm,
  },
})
export default class MyCFFiles extends ModelVue {
  @Store.Getter.ClientFile.MyClientFileStore.myClientFileList
  myClientFileList: Data.ClientFile.MyClientFile[];

  @Store.Getter.ClientFile.MyClientFileStore.myEMandateActiveCFList
  myEMandateActiveCFList: Data.ClientFile.MyClientFile[];

  @Store.Getter.ClientFile.MyClientFileStore.myEMandatePendingCFList
  myEMandatePendingCFList: Data.ClientFile.MyClientFile[];

  @Store.Getter.ClientFile.MyClientFileStore.myMSFPaidCFList
  myMSFPaidCFList: Data.ClientFile.MyClientFile[];

  @Store.Getter.ClientFile.MyClientFileStore.myMSFPendingCFList
  myMSFPendingCFList: Data.ClientFile.MyClientFile[];
  @Store.Getter.ClientFile.MyClientFileStore.myHoldCFList
  myHoldCFList: Data.ClientFile.MyClientFile[];

  @Store.Getter.ClientFile.MyClientFileStore.myLeadCFList
  myLeadCFList: Data.ClientFile.MyClientFile[];

  @Store.Getter.ClientFile.MyClientFileStore.myAgreementCFList
  myAgreementCFList: Data.ClientFile.MyClientFile[];

  @Store.Getter.ClientFile.MyClientFileStore.myActiveCFList
  myActiveCFList: Data.ClientFile.MyClientFile[];
  
  showRegisterMyCFForm: boolean = false;
  

  clientFileStatus = this.$route.query.clientFileStatus;
  filterQuery: any =  this.$route.query.filter;


  mounted() {
    this.getMyClientFileList();
  }

  getMyClientFileList() {
    Action.ClientFile.GetMyClientFileList.execute((output) => {
      // this.myClientFileList = output.filter((value: any) =>
      //   this.clientFileStatus === "ACTIVE"
      //     ? value.clientFileStatus.id == "ACTIVE"
      //     : value
      // );
    });
  }

  gotoFile(clientFileNumber: string) {
    Helper.Router.gotoFile({
      router: this.$router,
      clientFileNumber: clientFileNumber,
    });
  }

  gotoClient(clientId: string) {
    this.$router.push({
      name: "Root.Client.ClientDetails",
      params: { clientId: clientId },
    });
  }

  // registerClient() {
  //   Action.Client.RegisterAndAddClientFileToMyQ.execute(
  //     this.registerClientFormData,
  //     (output: any) => {
  //       setTimeout(() => {
  //         this.showRegisterMyCFForm = false;
  //       }, 500);
  //       this.gotoFile(output.clientFileNumber);
  //     }
  //   );
  // }

  // registerClient() {
  //   Action.Client.RegisterAndEnroll.execute(
  //     this.registerClientFormData,
  //     (output: any) => {
  //       setTimeout(() => {
  //         this.showRegisterMyCFForm = false;
  //         this.gotoFile(output.clientFileNumber);
  //       }, 500);
  //     }
  //   );
  // }

  openCalculatorPage() {
    this.$router.push({name:"Root.DRPPaymentCalculator"})
  }

  get myCFFileFDataTableMetaData() {
    return new MyCFFileFDataTableMDP({ parent: this }).getMetaData();
  }

  // get registerMyCFFFormMetaData() {
  //   return new RegisterMyCFFFormMDP({ root: this }).getMetaData();
  // }

  get clientFileList() {
    console.log(this.filterQuery);
    switch(this.filterQuery) {
      case 'emandate_active':  return this.myEMandateActiveCFList
      case 'emandate_pending':  return this.myEMandatePendingCFList
      case 'msf_paid':  return this.myMSFPaidCFList
      case 'msf_pending':  return this.myMSFPendingCFList
      case 'file_active':  return this.myActiveCFList
      case 'file_lead':  return this.myLeadCFList
      case 'file_agreement':  return this.myAgreementCFList
      case 'file_hold':  return this.myHoldCFList
      
      default:  return this.myClientFileList
    }
  }
  handleRegisterFormCancelClick() {
    this.showRegisterMyCFForm = false;
  }
}
</script>

<style></style>
