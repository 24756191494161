import { FFormChildMDP } from "@/components/form/FFormMDP";
import FBudgetMiniFormMDP from "@/components/form/field/FBudgetMiniFormMDP";
import FCurrencyFieldMDP from "@/components/form/field/FCurrencyFieldMDP";

export default class FDebtRepaymentsMiniFormMDP extends FBudgetMiniFormMDP {
  childMDP = new FFormChildMDP();
  taskRoot: any;
  parent: any;
  constructor({ taskRoot, parent }: { taskRoot: any; parent: any }) {
    super({
      parentMDP: new FFormChildMDP(),
      dataSelectorKey: "taskOutput.budgetInfo.debtRepayments",
      disabled: taskRoot.taskDisabled,
      label: "Debt Repayments",
      boundaryClass: "col-12",
    });
    this.taskRoot = taskRoot;
    this.parent = parent;

    this.addField(
      new FCurrencyFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: "autoLoan",
        label: "Auto Loan",
        boundaryClass: "col",
        hideDetails: true
      })
    )
      .addField(
        new FCurrencyFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "housingLoan",
          label: "Housing Loan",
          boundaryClass: "col",
          hideDetails: true
        })
      )
      .addField(
        new FCurrencyFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "loansAgainstSecurity",
          label: "Loan Against Security",
          boundaryClass: "col",
          hideDetails: true
        })
      )
      .addField(
        new FCurrencyFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "collateralizedBussinessLoan",
          label: "Collateralized Bussiness Loan",
          boundaryClass: "col",
          hideDetails: true
        })
      );
  }
}
