<template>
  <div class="hero-container">
    <div class="container-wrapper">
      <template>
        <v-sheet
          height="100vh"
          class="overflow-hidden"
          style="position: relative"
        >
          <!-- <router-view /> -->
        </v-sheet>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as Data from "@/../src-gen/data";
import * as Action from "@/../src-gen/action";

@Component({ components: {} })
export default class AdminLayout extends Vue {
  drawer: boolean = false;
  closeSideNav() {
    this.drawer = false;
  }
}
</script>
<style scoped>
.hero-container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #eeeeee;
}
.container-wrapper {
  /* background-color: #ffffff; */
  background-color: #f2f3f7;
  width: 414px;
}

@media only screen and (max-width: 450px) {
  .container-wrapper {
    width: 100%;
  }
}
</style>
