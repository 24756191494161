
import FBtnMDP, { BtnColor } from "@/components/FBtnMDP";
import FFormMDP, { FFormChildMDP } from "@/components/form/FFormMDP";
import FTextareaMDP from "@/components/form/field/FTextareaMDP";
import FSnackbar from "@/fsnackbar";
export default class UpdateExceptionTakenFFormMDP extends FFormMDP {
    childMDP = new FFormChildMDP();
    parent: any;
    taskRoot: any;
    constructor({ taskRoot, parent }: { taskRoot: any; parent: any }) {
        super({ myRefName: "UpdateExceptionTakenRef" });
        this.parent = parent;
        this.taskRoot = taskRoot;
        this

            .addField(
                new FTextareaMDP({
                    parentMDP: this.childMDP,
                    dataSelectorKey: "noteMessage",
                    label: "Reason for taking exception",
                    mandatory :true
                })
            )
            .addAction(new FBtnMDP({
                label: "Take Exception",
                onClick: () => this.parent.updateExceptionTaken(),
                color: BtnColor.SECONADRY
            }))
    }


    getMyRef() {
        return this.parent.$refs[this.myRefName];
    }

}
