// import FBtnMDP from "@/components/FBtnMDP";

import FBtnMDP from "@/components/FBtnMDP";
import FPartnerButtonMDP from "@/components/FPartnerButtonMDP";
import FFormMDP, { FFormChildMDP } from "@/components/form/FFormMDP";
import FPasswordFieldMDP from "@/components/form/field/FPasswordFieldMDP";
import FTextFieldMDP from "@/components/form/field/FTextFieldMDP";


export default class LoginFFormMDP extends FFormMDP {
  childMDP = new FFormChildMDP();
  taskRoot: any;
  parent: any;
  constructor({ taskRoot, parent }: { taskRoot: any; parent: any }) {
    super({
      myRefName: "createRequestForm",
      disabled: taskRoot.taskDisabled,
    });
    this.taskRoot = taskRoot;
    this.parent = parent;

    this
      .addField(
        new FTextFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "userName",
          label: "Username",
          mandatory: true,
          boundaryClass:"col-12"
        })
      )
      .addField(
        new FPasswordFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "password",
          label: "Password",
          mandatory: true,
          rules:"min:8"
        })
      )
      .addAction(
        new FPartnerButtonMDP({
          label: "Sign In",
          onClick: () => this.validateAndSubmit(),
          boundaryClass: "col-12 px-0"
        })
      );
  }

  getMyRef(): any {
    return this.taskRoot.$refs[this.myRefName];
  }

  validateAndSubmit() {
      this.getMyRef().submitForm(() => this.login());
  }

  login() {
      this.taskRoot.affiliateLogin();
  }
}
