<template>
  <button
    v-bind="$props"
    @click="buttonClicked"
    :class="['button-container', btnType, buttonSize, isFullWidth]"
  >
    <slot v-if="!loading"></slot>
    <v-progress-circular
      v-if="loading"
      :size="30"
      color="#fff"
      indeterminate
    ></v-progress-circular>
    <div class="icon-container">
      <slot name="icon"></slot>
    </div>
    <div class="static-icon">
      <slot name="staticIcon"></slot>
    </div>
  </button>
</template>
  
  <script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
// import store, * as Store from '@/../src-gen/store';
// import * as Data from '@/../src-gen/data';
// import * as ServerData from '@/../src-gen/server-data';
// import * as Action from '@/../src-gen/action';

@Component({})
export default class PartnerButton extends Vue {
  @Prop({ default: "primary" })
  btnType: "primary" | "secondary";
  @Prop({ default: "md" })
  btnSize: "sm" | "md";
  @Prop({ default: true })
  fullWidth: boolean;

  @Prop({ default: false })
  loading: boolean;

  @Prop()
  label: string;

  @Prop()
  onClick: () => void;

  get buttonSize() {
    return `btn-size-${this.btnSize}`;
  }
  get isFullWidth() {
    return this.fullWidth ? "full-width" : "";
  }
  buttonClicked(e?: MouseEvent) {
    e?.preventDefault();
    // if (!this.loading) {
      this.$emit("click", e);
    // }
  }
}
</script>
  
<style scoped>
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  border-radius: 8px;
  color: #ffffff;
  outline: none;
  padding: 20px 20px;
  position: relative;
  font-weight: 800;
  font-size: 18px;
  line-height: 160%;
}
.full-width {
  width: 100%;
}

.button-container:disabled {
  opacity: 0.3;
}
.btn-size-sm {
  height: 54px;
}
.btn-size-md {
  height: 64px;
}
.primary {
  background: var(--secondary-color);
}
.secondary {
  background: var(--primary-color);
}
.icon-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  animation: iconMove 3s infinite;
}
.icon-container img {
  width: 25px;
  height: 25px;
}
@keyframes iconMove {
  0% {
    right: 0px;
  }
  25% {
    right: 0px;
  }
  40% {
    right: -10px;
  }
  75% {
    right: -10px;
  }
  100% {
    right: 0px;
  }
}
.static-icon {
  position: absolute;
  right: 16px;
  top: 20px;
}

@media only screen and (max-width: 668px) {
  .button-container {
    font-size: 14px;
    height: 48px;
  }
  .static-icon {
    position: absolute;
    right: 16px;
    top: 12px;
  }
}
</style>
  