<template>
  <div>
    <strong >

    {{ selectModel(this.modelValue, this.dataSelectorKey) || 0  }}
    </strong>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ModelVue from "../../ModelVue";

@Component({
  components: {},
})
export default class FCellNumber extends ModelVue {
  @Prop()
  dataSelectorKey: string;

  @Prop()
  rounded: boolean;

}
</script>
