<template>
  <v-card flat class="py-1 d-flex align-center justify-end">
    <div class="d-flex align-center mx-5 caption">
      Rows per page:
      <v-select
        class="ml-2"
        style="width: 60px"
        dense
        hide-details
        :items="paginationItems"
        :value="countLocal"
        @input="(newVal) => pageCountChanged(newVal)"
      />
      <div class="mx-4">
        {{ firstItemIndex }} to {{ lastItemIndex }} of {{ total }}
      </div>
    </div>
    <v-pagination
      :value="modelValue"
      :length="paginationLength"
      :total-visible="5"
      @input="(newVal) => (modelValue = newVal)"
    ></v-pagination>
  </v-card>
</template>
<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { VPagination } from "vuetify/lib";
import { VBtn } from "vuetify/lib/components";
import ModelVue from "./ModelVue";
@Component({
  components: {},
})
export default class FPagination extends ModelVue {
  @Prop({ default: 0 })
  total: number;
  @Prop({ default: 10 })
  count: number;
  countLocal: number = 10;
  page: number = 1;
  get paginationItems() {
    return [10, 20, 50, 100];
  }
  mounted() {
    this.countLocal = this.count;
  }
  get modelValue(): any {
    let offset = 1;
    if (this.value > 0) {
      offset = Math.ceil((this.value + 1) / this.countLocal);
    }
    return offset;
  }
  set modelValue(newVal: number) {
    const offset = (newVal - 1) * this.countLocal;
    this.$emit("input", { offset, count: this.countLocal });
  }
  get firstItemIndex() {
    return (this.modelValue - 1) * this.countLocal + 1;
  }
  get lastItemIndex() {
    const lastItemIndex =
      (this.modelValue - 1) * this.countLocal + this.countLocal;
    return lastItemIndex > this.total ? this.total : lastItemIndex;
  }
  get paginationLength() {
    return Math.ceil(this.total / this.countLocal) || 0;
  }
  pageCountChanged(newVal: number) {
    this.countLocal = newVal;
    const offset = 0 * newVal;
    this.$emit("input", { offset, count: this.countLocal });
  }
}
</script>