import FChartLineMDP from './FChartLineMDP';
import FPointLabelsMDP from './FPointLabelsMDP';
import FC3ChartMDP, { ChartType } from './FC3ChartMDP';
import FXAxisMDP from './FXAxisMDP';
import FYAxisMDP from './FYAxisMDP';

export default class FLineFC3ChartMDP extends FC3ChartMDP {
  constructor({
    chartTitle,
    xAxis,
    yAxis,
    pointLabels,
    legendShow,
    colorPattern,
  }: {
    chartTitle?: string;
    xAxis: FXAxisMDP;
    yAxis?: FYAxisMDP;
    pointLabels?: FPointLabelsMDP;
    legendShow?: boolean;
    colorPattern?: string[];
  }) {
    super({
      myRefName: 'splineC3ChartRef',
      type: ChartType.LINE,
      chartTitle: chartTitle,
      xAxis: xAxis,
      yAxis: yAxis,
      pointLabels: pointLabels,
      legendShow: legendShow,
      colorPattern: colorPattern
    });
  }

  addLine(newField: {
    dataSelectorKey: string;
    legend: string;
    type?: string;
  }) {
    if (!newField.type) {
      newField.type = this.type;
    }
    this.dataSelectorKeyList.push(new FChartLineMDP(newField));
    return this;
  }
}
