
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class DspTaskWriterService {

   public static receiveFirstMSFPayment(
           axiosInstance: AxiosInstance, input: ServerData.DspTaskWebWriter.ReceiveFirstMSFPayment$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'dspapi/dsptask/receive-first-m-s-f-payment',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static updateFirstMSFPaymentStatus(
           axiosInstance: AxiosInstance, input: ServerData.DspTaskWebWriter.UpdateFirstMSFPaymentStatus$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'dspapi/dsptask/update-first-m-s-f-payment-status',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static updateMsfPaymentStatus(
           axiosInstance: AxiosInstance, input: ServerData.DspTaskWebWriter.UpdateMsfPaymentStatus$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'dspapi/dsptask/update-msf-payment-status',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static receiveMSFPayment(
           axiosInstance: AxiosInstance, input: ServerData.DspTaskWebWriter.ReceiveMSFPayment$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'dspapi/dsptask/receive-m-s-f-payment',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


}

