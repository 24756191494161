import FFormSubmitBtnMDP from "@/components/FFormSubmitBtnMDP";
import FFormDrawerMDP from "@/components/form/FFormDrawerMDP";
import { FFormChildMDP } from "@/components/form/FFormMDP";
import FCreditCardFieldMDP from "@/components/form/field/FCreditCardFieldMDP";
import FCreditorAutoCompleteFieldMDP from "@/components/form/field/FCreditorAutoCompleteFieldMDP";
import FCurrencyFieldMDP from "@/components/form/field/FCurrencyFieldMDP";
import FSelectDateFieldMDP from "@/components/form/field/FDateSelectFieldMDP";
import FSelectFieldMDP from "@/components/form/field/FSelectFieldMDP";
import FTextFieldMDP from "@/components/form/field/FTextFieldMDP";
import * as Action from "@/../src-gen/action";
import * as Data from "@/../src-gen/data";
import FSnackbar from "@/fsnackbar";

export default class FEditCreditorFFormMDP extends FFormDrawerMDP {
  childMDP = new FFormChildMDP();
  taskRoot: any;
  parent: any;
  constructor({ taskRoot, parent }: { taskRoot: any; parent: any }) {
    super({
      myRefName: "editCreditorFormRef",
      disabled: taskRoot.taskDisabled,
      onClose: () => this.parent.closeAndClearAllForms(),
      isVisible: parent.editCreditorDialog,
      title: "Edit Creditor"
    });
    this.taskRoot = taskRoot;
    this.parent = parent;

    this.addField(
      new FCreditorAutoCompleteFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: "creditorName",
        label: "Creditor",
        mandatory: true,
        boundaryClass: "col-6",
      })
    )
      .addField(
        new FCurrencyFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "creditorBalance",
          label: "Creditor Balance",
          mandatory: true,
          boundaryClass: "col-6",
        })
      )

      .addField(
        new FCurrencyFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "emiAmount",
          label: "EMI Amount",
          mandatory: true,
          boundaryClass: "col-6",
        })
      )
      .addField(
        new FSelectDateFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "lastDateOfPayment",
          label: "Last Date of Payment",
          mandatory: true,
          boundaryClass: "col-6",
          futureDaysDisabled: true,
        })
      )

      .addField(
        new FSelectFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "debtType",
          label: "Type of Debt",
          mandatory: true,
          boundaryClass: "col-6",
          options: [
            "Credit Card",
            "Personal Loans",
            "Secured",
            "Others (Unsecured)",
          ],
        })
      )

      .addField(
        new FCreditCardFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "accountNumber",
          label: "Credit Card Number",
          mandatory: true,
          boundaryClass: "col-6",
          condition: this.parent.isCreditCard(),
          rules: "min:4|max:20",
        })
      )

      .addField(
        new FTextFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "accountNumber",
          label: "Account Number",
          mandatory: true,
          boundaryClass: "col-6",
          condition: !this.parent.isCreditCard(),
          // rules: "min:9|max:20",
          rules: "min:4|max:20",
        })
      ).addField(
        new FTextFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "details",
          label: "Details",
          boundaryClass: "col-6",
        })
      )
      // .addAction(
      //   new FBtnMDP({
      //     label: "Cancel",
      //     btnType: BtnType.TEXT,
      //     onClick: () => {
      //       this.parent.closeAndClearAllForms()
      //     },
      //   })
      // )
      .addAction(
        new FFormSubmitBtnMDP({
          label: "Update Creditor",
          onClick: () => this.updateCreditor(),
        })
      );
  }

  getMyRef(): any {
    return this.parent.$refs[this.myRefName];
  }


  updateCreditor() {
    const input = Data.ClientFile.UpdateFiCreditorInput.fromJson(
      this.parent.editCreditorForm
    );
    input.clientFileId = (
      this.taskRoot as any
    ).clientFileBasicInfo.clientFileId;
    // input.taskId = this.taskRoot.taskId;
    input.skipUnderwrittingRule = true;
    Action.ClientFile.UpdateFiCreditor.execute(input, (output) => {
      this.parent.closeAndClearAllForms();
      FSnackbar.success("Succesfully Updated");
    });
  }
}
