import FBtnMDP from "../FBtnMDP";
import MDP from "../MDP";
import FFieldMDP from "./field/FFieldMDP";


export class FFormChildMDP {
  outlined = true;
  dense = true;
  boundaryClass = "col-3";

}

export default class FFormDrawerMDP implements MDP {
  componentName = "FFormDrawer";
  fieldList: FFieldMDP[] = [];
  actionList: FBtnMDP[] = [];
  myRefName: string;
  dataSelectorKey: string | undefined;
  disabled: boolean;
  readonly: boolean;
  colWidth: number;
  onClose: () => void;
  isVisible: boolean;
  title?: string;
  subTitle?: string;
  width?: string;
  zIndex?:string;
  loading?: boolean;
  onSubmit?: (actionMetaData: any) => any;
  constructor({
    myRefName,
    dataSelectorKey,
    disabled = false,
    colWidth = 12,
    readonly = false,
    onClose,
    isVisible,
    title,
    width,
    subTitle,
    zIndex="999",
    loading=false,
    onSubmit
  }: {
    myRefName: string;
    dataSelectorKey?: string;
    disabled?: boolean;
    colWidth?: number;
    readonly?: boolean;
    onClose: () => void;
    isVisible: boolean;
    title?: string;
    width?: string;
    subTitle?: string;
    zIndex?:string;
    loading?: boolean;
    onSubmit?: (actionMetaData: any) => any;
  }) {
    this.myRefName = myRefName;
    this.dataSelectorKey = dataSelectorKey;
    this.disabled = disabled
    this.colWidth = colWidth;
    this.readonly = readonly;
    this.onClose = onClose;
    this.isVisible = isVisible;
    this.title = title;
    this.width = width;
    this.subTitle = subTitle;
    this.zIndex = zIndex;
    this.loading = loading;
    this.onSubmit = onSubmit;
  }

  addField(newField: FFieldMDP) {
    this.fieldList.push(newField);
    return this;
  }

  addAction(newAction: FBtnMDP) {
    this.actionList.push(newAction);
    return this;
  }

  getFFormBoundaryClass() {
    return `col-${this.colWidth}`
  }

  getMetaData(): object {
    return {
      myRefName: this.myRefName,
      componentName: this.componentName,
      dataSelectorKey: this.dataSelectorKey,
      props: {
        myRefName: this.myRefName,
        fieldMetaDataList: this.fieldList.map((field) => field.getMetaData()),
        actionMetaDataList: this.actionList.map((action) =>
          action.getMetaData()
        ),
        disabled: this.disabled,
        boundaryClass: this.getFFormBoundaryClass(),
        readonly: this.readonly,
        onClose: this.onClose,
        isVisible: this.isVisible,
        title: this.title,
        width: this.width,
        subTitle: this.subTitle,
        zIndex: this.zIndex,
        loading: this.loading,
        onSubmit: this.onSubmit
      },
    };
  }

  getNewClass() {
    const newForm = new FFormDrawerMDP({ myRefName: this.myRefName, isVisible: this.isVisible, onClose: this.onClose, title: this.title, subTitle: this.subTitle, dataSelectorKey: this.dataSelectorKey, colWidth: this.colWidth, disabled: this.disabled, readonly: this.readonly, width: this.width });
    newForm.actionList = this.actionList;
    newForm.fieldList = this.fieldList;

    return newForm;
  }
}
