import { render, staticRenderFns } from "./side-nav.vue?vue&type=template&id=4a0700fe&scoped=true&"
import script from "./side-nav.vue?vue&type=script&lang=ts&"
export * from "./side-nav.vue?vue&type=script&lang=ts&"
import style0 from "./side-nav.vue?vue&type=style&index=0&id=4a0700fe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a0700fe",
  null
  
)

export default component.exports