import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './../src-gen/store';
import vuetify from './plugins/vuetify';

import 'node-snackbar/dist/snackbar.min.css';
import 'node-snackbar/dist/snackbar.min.js';
import './assets/css/text.css';

import CustomFilters from './custom-filters';
import Validator from './validator';
import ToggleButton from 'vue-js-toggle-button';

Vue.use(ToggleButton);
Vue.config.productionTip = false;
CustomFilters.setup();
Validator.setup();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
