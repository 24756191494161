<template>
  <v-select
    v-bind="$props"
    :value="modelValue"
    @input="(newValue) => (modelValue = newValue)"
  >
    <template v-slot:item="{ item }">
      <v-avatar :color="item['colorCode']" size="20" class="mr-4"> </v-avatar>
      {{ item["colorName"] }}
    </template>

    <template v-slot:selection="{ item }">
      <v-avatar :color="item['colorCode']" size="20" class="mr-4"> </v-avatar>
      {{ item["colorName"] }}
    </template>
  </v-select>
</template>
<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { VSelect } from "vuetify/lib";

@Component({
  components: {
    VSelect,
  },
})
export default class FSelectGapColorField extends VSelect {
  // MODEL VALUE - START
  @Prop()
  value: any;

  get modelValue() {
    return this.value;
  }

  set modelValue(newModelValue: string) {
    this.$emit("input", newModelValue);
  }
  // MODEL VALUE - END
}
</script>
