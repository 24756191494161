
import axios, { AxiosError, AxiosInstance } from 'axios';
import * as RemoteApiPoint from './../../src/remote-api-point';
import {EventBus} from './../../src/eventbus';
import * as Store from './../../src-gen/store';
import * as Data from './../data';
import * as ServerData from './../server-data';
import ClientReaderService from './../service/spineapi/ClientReaderService';
import ClientWriterService from './../service/spineapi/ClientWriterService';
import MyClientFileWriterService from './../service/spineapi/MyClientFileWriterService';
import DspFlowWriterService from './../service/dspapi/DspFlowWriterService';

// tslint:disable



export class GetClientBasicInfo {

    
    public static execute1(clientId: string, f: (output: Data.Client.ClientBasicInfo) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetClientBasicInfo.execute(new ServerData.ClientWebReader.GetClientBasicInfo$Input(clientId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientWebReader.GetClientBasicInfo$Input, f: (output: Data.Client.ClientBasicInfo) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientReaderService.getClientBasicInfo(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$ClientBasicInfo) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientWebReader.GetClientBasicInfo$Input): ServerData.ClientWebReader.GetClientBasicInfo$Input {
        return new GetClientBasicInfo$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$ClientBasicInfo): Data.Client.ClientBasicInfo {
        return new GetClientBasicInfo$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetClientBasicInfo$Mapper {

    public mapInput(input: ServerData.ClientWebReader.GetClientBasicInfo$Input): ServerData.ClientWebReader.GetClientBasicInfo$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$ClientBasicInfo): Data.Client.ClientBasicInfo {
        return Data.Client.ClientBasicInfo.fromJson(output.toJson());
    }

}

class GetClientBasicInfo$MapperCode extends GetClientBasicInfo$Mapper {

}

       


export class GetClientFileBasicInfoList {

    
    public static execute1(clientId: string, f: (output: Data.Client.ClientFileBasicInfo[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetClientFileBasicInfoList.execute(new ServerData.ClientWebReader.GetClientFileBasicInfoList$Input(clientId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientWebReader.GetClientFileBasicInfoList$Input, f: (output: Data.Client.ClientFileBasicInfo[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientReaderService.getClientFileBasicInfoList(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$ClientFileBasicInfo[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientWebReader.GetClientFileBasicInfoList$Input): ServerData.ClientWebReader.GetClientFileBasicInfoList$Input {
        return new GetClientFileBasicInfoList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$ClientFileBasicInfo[]): Data.Client.ClientFileBasicInfo[] {
        return new GetClientFileBasicInfoList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetClientFileBasicInfoList$Mapper {

    public mapInput(input: ServerData.ClientWebReader.GetClientFileBasicInfoList$Input): ServerData.ClientWebReader.GetClientFileBasicInfoList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$ClientFileBasicInfo[]): Data.Client.ClientFileBasicInfo[] {
        return  output.map((x) => Data.Client.ClientFileBasicInfo.fromJson(x.toJson()));
    }

}

class GetClientFileBasicInfoList$MapperCode extends GetClientFileBasicInfoList$Mapper {

}

       


export class SearchClient {

    

    

    public static execute(input: Data.Client.SearchClientInput, f: (output: Data.Client.SearchClientOutput[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientReaderService.searchClient(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$ClientSearch[]) => {
                      const mappedOutput = this.mapOutput(output);

           Store.Mutation.Client.ClientSearchStore.UPDATE_SEARCH_CRITERIA(input); 

           Store.Mutation.Client.ClientSearchStore.UPDATE_SEARCH_RESULT_LIST(mappedOutput.map((x) => Data.Client.SearchClientOutput.fromJson(x.toJson())));
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Client.SearchClientInput): ServerData.ClientWebReader.SearchClient$Input {
        return new SearchClient$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$ClientSearch[]): Data.Client.SearchClientOutput[] {
        return new SearchClient$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class SearchClient$Mapper {

    public mapInput(input: Data.Client.SearchClientInput): ServerData.ClientWebReader.SearchClient$Input {
        return ServerData.ClientWebReader.SearchClient$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.SpineData$ClientSearch[]): Data.Client.SearchClientOutput[] {
        return  output.map((x) => Data.Client.SearchClientOutput.fromJson(x.toJson()));
    }

}

class SearchClient$MapperCode extends SearchClient$Mapper {

}

       


export class RegisterClient {

    

    

    public static execute(input: Data.Client.RegisterClientForm, f: (output: ServerData.ClientWebWriter.RegisterClient$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientWriterService.registerClient(axoisInstance, this.mapInput(input),
            ((output: ServerData.ClientWebWriter.RegisterClient$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Client.RegisterClientForm): ServerData.ClientWebWriter.RegisterClient$Input {
        return new RegisterClient$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.ClientWebWriter.RegisterClient$Output): ServerData.ClientWebWriter.RegisterClient$Output {
        return new RegisterClient$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class RegisterClient$Mapper {

    public mapInput(input: Data.Client.RegisterClientForm): ServerData.ClientWebWriter.RegisterClient$Input {
        return ServerData.ClientWebWriter.RegisterClient$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.ClientWebWriter.RegisterClient$Output): ServerData.ClientWebWriter.RegisterClient$Output {
        return output
    }

}

class RegisterClient$MapperCode extends RegisterClient$Mapper {

}

       


export class UpdateContactInfo {

    

    

    public static execute(input: Data.Client.UpdateContactInfoInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientWriterService.updateContactInfo(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Client.UpdateContactInfoInput): ServerData.ClientWebWriter.UpdateContactInfo$Input {
        return new UpdateContactInfo$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new UpdateContactInfo$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class UpdateContactInfo$Mapper {

    public mapInput(input: Data.Client.UpdateContactInfoInput): ServerData.ClientWebWriter.UpdateContactInfo$Input {
        return ServerData.ClientWebWriter.UpdateContactInfo$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class UpdateContactInfo$MapperCode extends UpdateContactInfo$Mapper {

}

       


export class UpdateClientDetails {

    

    

    public static execute(input: Data.Client.UpdateClientDetailsInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientWriterService.updateClientDetails(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Client.UpdateClientDetailsInput): ServerData.ClientWebWriter.UpdateClientDetails$Input {
        return new UpdateClientDetails$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new UpdateClientDetails$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class UpdateClientDetails$Mapper {

    public mapInput(input: Data.Client.UpdateClientDetailsInput): ServerData.ClientWebWriter.UpdateClientDetails$Input {
        return ServerData.ClientWebWriter.UpdateClientDetails$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class UpdateClientDetails$MapperCode extends UpdateClientDetails$Mapper {

}

       


export class RegisterAndAddClientFileToMyQ {

    

    

    public static execute(input: Data.Client.RegisterAndAddClientFileForm, f: (output: ServerData.MyClientFileWebWriter.RegisterAndAddClientFileToMyQ$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        MyClientFileWriterService.registerAndAddClientFileToMyQ(axoisInstance, this.mapInput(input),
            ((output: ServerData.MyClientFileWebWriter.RegisterAndAddClientFileToMyQ$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Client.RegisterAndAddClientFileForm): ServerData.MyClientFileWebWriter.RegisterAndAddClientFileToMyQ$Input {
        return new RegisterAndAddClientFileToMyQ$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.MyClientFileWebWriter.RegisterAndAddClientFileToMyQ$Output): ServerData.MyClientFileWebWriter.RegisterAndAddClientFileToMyQ$Output {
        return new RegisterAndAddClientFileToMyQ$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class RegisterAndAddClientFileToMyQ$Mapper {

    public mapInput(input: Data.Client.RegisterAndAddClientFileForm): ServerData.MyClientFileWebWriter.RegisterAndAddClientFileToMyQ$Input {
        return ServerData.MyClientFileWebWriter.RegisterAndAddClientFileToMyQ$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.MyClientFileWebWriter.RegisterAndAddClientFileToMyQ$Output): ServerData.MyClientFileWebWriter.RegisterAndAddClientFileToMyQ$Output {
        return output
    }

}

class RegisterAndAddClientFileToMyQ$MapperCode extends RegisterAndAddClientFileToMyQ$Mapper {

}

       


export class RegisterAndEnroll {

    

    

    public static execute(input: Data.Client.RegisterAndEnrollInput, f: (output: ServerData.DspFlowWebWriter.RegisterAndEnroll$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.DspApi): void {

    

    

        DspFlowWriterService.registerAndEnroll(axoisInstance, this.mapInput(input),
            ((output: ServerData.DspFlowWebWriter.RegisterAndEnroll$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Client.RegisterAndEnrollInput): ServerData.DspFlowWebWriter.RegisterAndEnroll$Input {
        return new RegisterAndEnroll$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.DspFlowWebWriter.RegisterAndEnroll$Output): ServerData.DspFlowWebWriter.RegisterAndEnroll$Output {
        return new RegisterAndEnroll$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class RegisterAndEnroll$Mapper {

    public mapInput(input: Data.Client.RegisterAndEnrollInput): ServerData.DspFlowWebWriter.RegisterAndEnroll$Input {
        return ServerData.DspFlowWebWriter.RegisterAndEnroll$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.DspFlowWebWriter.RegisterAndEnroll$Output): ServerData.DspFlowWebWriter.RegisterAndEnroll$Output {
        return output
    }

}

class RegisterAndEnroll$MapperCode extends RegisterAndEnroll$Mapper {

}

       

     