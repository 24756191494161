<template>
  <div :ref="myRefName">
    <!-- <v-expand-transition> -->
    <v-card class="pa-3 d-flex flex-wrap" outlined dense flat>
      <div
        v-if="!showSearchGridForm"
        class="d-flex flex-fill"
        @click="showSearchGridForm = true"
      >
        Criteria :
        <v-chip label small v-if="searchCriteriaList.length == 0">
          None
        </v-chip>
        <div
          class="d-flex flex-wrap"
          v-for="(searchCriteria, index) of searchCriteriaList"
          :key="index"
        >
          <v-chip label small class="mx-2">
            {{ searchCriteria.label | title }} :
            {{ generateSearchValue(searchCriteria.value) }}
          </v-chip>
          <v-divider
            class="mx-2"
            v-if="index !== searchCriteriaList.length - 1"
            vertical
          />
        </div>
        <v-spacer />
        <v-btn
          @click="showSearchGridForm = true"
          class="outline"
          color="primary"
          small
          label
          ><v-icon small>mdi-magnify</v-icon> Modify Search</v-btn
        >
      </div>
      <div v-else class="d-flex flex-fill">
        <v-toolbar-title class="mx-3">{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          v-if="!!onClear"
          small
          outlined
          color="primary"
          @click="handleClearClick"
          >Clear</v-btn
        >
        <v-btn class="mx-3" small color="primary" @click="handleSearchClick"
          ><v-icon small>mdi-magnify</v-icon>Search</v-btn
        >
      </div>
    </v-card>
    <!-- </v-expand-transition> -->

    <v-expand-transition>
      <v-card flat v-if="!!searchFormMetaData && showSearchGridForm">
        <!-- <v-card class="d-flex flex-wrap" outlined dense flat>
        <v-card-title>Search</v-card-title>
      </v-card> -->
        <component
          :ref="searchFormMetaData.myRefName"
          :is="searchFormMetaData.componentName"
          :value="selectModel(modelValue.searchCriteria, undefined)"
          @input="
            (newValue) =>
              updateModel(modelValue.searchCriteria, newValue, undefined)
          "
          v-bind="searchFormMetaData.props"
        ></component>
      </v-card>
    </v-expand-transition>
    <component
      v-if="!!gridMetaData"
      :ref="gridMetaData.myRefName"
      :is="gridMetaData.componentName"
      :value="selectModel(modelValue.searchResultList, undefined)"
      v-bind="gridMetaData.props"
    ></component>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import FForm from '../form/FForm.vue';
import FFormMDP from '../form/FFormMDP';
import ModelVue from '../ModelVue';
import FDataTable from '../table/FDataTable.vue';

@Component({
  components: {
    FForm,
    FDataTable,
  },
})
export default class FSearchGrid extends ModelVue {
  @Prop()
  gridMetaData: any;

  @Prop()
  searchFormMetaData: any;

  @Prop({
    default: 'Search',
  })
  title: FFormMDP;

  @Prop()
  myRefName: string;

  @Prop()
  onSearch: () => void;

  @Prop()
  onClear: () => void;

  showSearchGridForm: boolean = true;

  @Prop()
  api: any;

  // get searchCriteriaList() {
  //   const o: any = Object.fromEntries(
  //     Object.entries(this.modelValue.searchCriteria).filter(
  //       ([_, v]) => (!!v && v != "") || typeof(v)==="boolean"
  //     )
  //   );
  //   return Object.keys(o).map((item) => ({
  //     label: item,
  //     value: o[item],
  //   }));
  // }

  get searchCriteriaList() {
    const fieldList: any[] =
      this.searchFormMetaData?.props?.fieldMetaDataList || [];
    const o: any = Object.fromEntries(
      Object.entries(this.modelValue.searchCriteria).filter(([_, v]) => {
        const fieldIndex = fieldList.findIndex(
          (item) => item.dataSelectorKey === _
        );
        console.log(_, fieldIndex, '_Field index');
        return (!!v && v != '' && fieldIndex != -1) || typeof v === 'boolean';
      })
    );
    return Object.keys(o).map((item) => ({
      label: item,
      value: o[item],
    }));
  }

  mounted() {
    this.showSearchGridForm = this.searchCriteriaList.length > 0 ? false : true;
  }

  handleSearchClick() {
    const searchFormRef: any = this.$refs[this.searchFormMetaData.myRefName];
    searchFormRef.submitForm(() => {
      this.showSearchGridForm = false;
      if (this.api) {
        this.api.execute(this.modelValue.searchCriteria, (output: any) => {});
      }

      if (!!this.onSearch) {
        this.onSearch();
      }
    });
  }

  clearSelectedItemsFromGrid() {
    (this.$refs[this.gridMetaData.myRefName] as any)?.clearSelectedItems();
  }

  handleClearClick() {
    if (this.onClear) {
      this.onClear();
    }
  }

  generateSearchValue(value: any) {
    let val = value;
    if (Array.isArray(val)) {
      val = `${val[0]} + ${val.length - 1 > 0 ? `${val.length - 1} more` : ''}`;
    }
    if (typeof val === 'boolean') {
      val = val ? 'Yes' : 'No';
    }
    return val;
  }
}
</script>
