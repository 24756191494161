<template>
  <v-chip :color="color" outlined label class="d-flex py-5 mb-2 align-start">
    <div class="text-body-2">
        {{$props.label}} : 
    </div>
    <div class="text-body-1">
        <strong>
        {{modelValue | toINR}}
        </strong>
    </div>
  </v-chip>
</template>
<script lang="ts">
import FSnackbar from "@/fsnackbar";
import axios from "axios";
import { Component, Prop } from "vue-property-decorator";
import { VTextField } from "vuetify/lib/components";
import FBtn from "../../FBtn.vue";
import ModelVue from "../../ModelVue";
// const ifsc =require("ifsc");

@Component({
  components: {
  },
})
export default class FLabelField extends ModelVue {
@Prop({
    default: "default"
})
color: string;
}
</script>
