var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.boundaryClass},[_c('ValidationObserver',{ref:_vm.myRefName,scopedSlots:_vm._u([{key:"default",fn:function({}){return [_c('v-form',{staticClass:"d-flex flex-row align-start flex-wrap justify-start pt-2",attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly}},[_vm._l((_vm.fieldMetaDataListFiltered),function(fieldMetaData,indx){return _c('div',{key:indx,class:fieldMetaData.boundaryClass},[_c('ValidationProvider',{attrs:{"vid":fieldMetaData.props.id,"name":fieldMetaData.props.label,"rules":fieldMetaData.props.disabled ? '' : fieldMetaData.rules},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c(fieldMetaData.componentName,_vm._b({ref:fieldMetaData.myRefName,refInFor:true,tag:"component",attrs:{"value":_vm.selectModel(_vm.modelValue, fieldMetaData.dataSelectorKey),"error-messages":errors},on:{"input":(newValue) =>
                _vm.updateModel(
                  _vm.modelValue,
                  newValue,
                  fieldMetaData.dataSelectorKey
                )}},'component',fieldMetaData.props,false))]}}],null,true)})],1)}),(_vm.showHiddenFields)?_vm._l((_vm.hiddenFieldMetaDataList),function(fieldMetaData,indx){return _c('div',{key:'hidden' + indx,class:fieldMetaData.boundaryClass},[_c(fieldMetaData.componentName,_vm._b({ref:fieldMetaData.myRefName,refInFor:true,tag:"component",attrs:{"value":_vm.selectModel(_vm.modelValue, fieldMetaData.dataSelectorKey)},on:{"input":(newValue) =>
                _vm.updateModel(
                  _vm.modelValue,
                  newValue,
                  fieldMetaData.dataSelectorKey
                )}},'component',fieldMetaData.props,false))],1)}):_vm._e(),(_vm.hiddenFieldMetaDataList.length > 0)?_c('v-btn',{staticClass:"text-center",attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showHiddenFields = !_vm.showHiddenFields}}},[_vm._v(_vm._s(_vm.showHiddenFields ? "Show less" : "Show more"))]):_vm._e()],2),(!_vm.disabled)?_c('div',{staticClass:"d-flex flex-row align-start flex-wrap justify-space-around pa-2"},_vm._l((_vm.actionMetaDataListFiltered),function(actionMetaData,indx){return _c('div',{key:indx,class:actionMetaData.boundaryClass},[_c(actionMetaData.componentName,_vm._b({tag:"component",attrs:{"onFormSubmit":() => _vm.handleActionClick(actionMetaData)}},'component',actionMetaData.props,false))],1)}),0):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }