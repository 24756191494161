<template>
  <v-card class="f-datatable" :ref="myRefName" flat :outlined="outlined">
    <v-navigation-drawer
      :style="`z-index: 999`"
      :height="'100%'"
      right
      fixed
      location="bottom"
      :value="!!selectedActionFFormMetaData?.componentName"
      :width="600"
    >
      <v-toolbar height="70" class="pb-4" dense flat>
        <div class="my-3">
          <v-toolbar-title>
            {{ selectedAction?.label }}
          </v-toolbar-title>
        </div>
        <v-spacer />
        <v-btn small icon @click="closeActionFormDrawer"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-toolbar>

      <component
        :ref="selectedActionFFormMetaData.myRefName"
        :is="selectedActionFFormMetaData.componentName"
        :value="selectModel(selectedApiInput, undefined)"
        @input="
          (newValue) => updateModel(selectedApiInput, newValue, undefined)
        "
        v-bind="selectedActionFFormMetaData.props"
        :onSubmit="(item) => handleFormSumbmit()"
      ></component>
    </v-navigation-drawer>
    <v-card flat class="my-5" v-if="enableInfo && selectedRowIndex !== -1">
      <component
        :is="infoFFormMetaData.componentName"
        :value="
          selectModel(
            tableData()[selectedRowIndex],
            infoFFormMetaData.dataSelectorKey
          )
        "
        v-bind="infoFFormMetaData.props"
      ></component>

      <!-- <div
        v-if="!disabled"
        class="d-flex flex-row align-start flex-wrap justify-space-around pa-2"
      >
      <v-btn @click="showInfoForm=false" text color="primary">Close</v-btn>
        <div
          :class="actionMetaData.boundaryClass"
          v-for="(actionMetaData, indx) in infoActionMetaDataList"
          :key="indx"
        >
          <component
            :is="actionMetaData.componentName"
            v-bind="actionMetaData.props"
          ></component>
        </div>
      </div> -->
    </v-card>
    <f-alert
      message="Are you sure want to proceed?"
      @confirmClick="fireActionClick"
      @cancelClick="showConfirmation = false"
      v-if="showConfirmation == true"
    />

    <f-alert
      message="Are you sure want to delete?"
      @confirmClick="fireDeleteActionClick"
      @cancelClick="showDeleteConfirmation = false"
      v-if="showDeleteConfirmation == true"
      color="red"
    />
    <v-data-table
      :value="selectedItemList"
      @input="handleSelectChange"
      :headers="filteredHeaders"
      :items="tableData()"
      class="f-data-table-custom elevation-0"
      :show-select="showCheckbox"
      :single-select="!multiSelect"
      v-bind="$props"
      :disabled="disabled"
      :itemKey="itemKey"
      :search="search"
      checkbox-color="primary"
      :expanded.sync="expanded"
      :show-expand="!!expansionComponent"
      :hide-default-footer="hideDefaultFooter"
      :disable-pagination="!enablePagination"
      :loading="loading || apiLoading"
      :group-by="groupBy.value"
      @toggle-select-all="selectAllToggle"
      :footer-props="{ 'items-per-page-options': [15, 50, 100, -1] }"
      :items-per-page="itemsPerPage"
      :group-desc="[false]"
      ref="dataTableRef"
      :hide-default-header="showCardView"
    >
      <template
        v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
      >
        <div>
          <v-simple-checkbox
            :value="isSelected"
            :readonly="!isCheckboxValid(item)"
            :disabled="!isCheckboxValid(item)"
            @input="select($event)"
            color="primary"
          ></v-simple-checkbox>
        </div>
      </template>
      <template
        v-if="
          title ||
          actions.length > 0 ||
          enableSearch ||
          headerInfoMetaDataList.length > 0
        "
        v-slot:top
      >
        <v-toolbar class="mx-1 py-0 ma-0" flat>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-divider v-if="title" vertical class="mx-3" inset />

          <!-- Header filter -->
          <div
            class="mr-1"
            v-for="(filter, index) of columnFilterListWithValues"
            :key="'header-filter' + index"
          >
            <div
              v-if="filter.enableHeaderFilter && filter.filterItems.length > 0"
            >
              <!-- <div class="caption mx-1">{{filter.label}}</div>
            <v-divider class="mb-2"/> -->
              <div class="d-flex align-center">
                <span class="thText">Filter By: </span>
                <div
                  v-for="filterItem of filter.filterItems"
                  :key="filterItem.id"
                >
                  <v-chip
                    small
                    v-if="getHeaderFilterCount(filter, filterItem) > 0"
                    :color="getHeaderFilterColor(filter, filterItem)"
                    :outlined="
                      isHeaderFilterSelected(filter, filterItem) ? false : true
                    "
                    @click="handleHeaderFilterClick(index, filter, filterItem)"
                    :class="`pl-1 mx-1 filter-chip ${
                      isHeaderFilterSelected(filter, filterItem)
                        ? 'font-weight-bold '
                        : ''
                    }
            `"
                  >
                    <v-chip
                      :color="
                        isHeaderFilterSelected(filter, filterItem)
                          ? 'white'
                          : 'secondary'
                      "
                      x-small
                      :class="`${
                        isHeaderFilterSelected(filter, filterItem)
                          ? 'secondary--text'
                          : ''
                      } font-weight-bold filter-chip-count mr-1`"
                      >{{ getHeaderFilterCount(filter, filterItem) }}</v-chip
                    >
                    {{ filterItem.name }}
                  </v-chip>
                </div>
              </div>
            </div>
          </div>

          <!-- Header filter -->

          <slot name="top-action"></slot>

          <div
            v-for="(info, index) in headerInfoMetaDataList"
            :key="info.label + index"
          >
            <component
              :is="info.infoMetaData.componentName"
              :value="info.value"
              :label="info.label"
              v-bind="info.infoMetaData.props"
              class="mx-1"
            ></component>
          </div>
          <v-spacer />
          <f-tooltip
            class="px-2"
            v-if="enableCardViewToggle"
            tooltipText="Card View"
          >
            <v-switch v-model="showCardView" dense hide-details />
          </f-tooltip>

          <div v-if="filteredActions.length > 0">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  dense
                  small
                  outlined
                >
                  Actions
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(action, index) in filteredActions"
                  :key="index"
                  link
                  :disabled="isActionDisabled(action)"
                  @click="() => handleOtherActionClick(action)"
                  dense
                >
                  <div class="text-caption">{{ action.label }}</div>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div v-else class="d-flex">
            <v-btn
              v-for="(action, index) in filteredActions"
              :key="'action' + index"
              outlined
              @click="() => handleOtherActionClick(action)"
              small
              class="mx-2"
              color="primary"
              :disabled="
                selectedItemList.length == 0 || disabled || action.disabled
              "
            >
              {{ action.label }}
            </v-btn>
          </div>

          <v-btn
            icon
            v-if="refreshBtnData"
            @click="refreshBtnData.onClick"
            :disabled="disabled || refreshBtnData.disabled"
            class="ml-2"
          >
            <v-icon color="primary">mdi-refresh</v-icon>
          </v-btn>
          <f-add-btn
            class="ml-2"
            v-if="addBtnData"
            outlined
            :label="addBtnData.label"
            @click="addBtnData.onClick"
            :disabled="disabled || addBtnData.disabled"
          />

          <v-slide-x-reverse-transition>
            <v-text-field
              v-if="showSearchField"
              v-model="search"
              label="Search Item"
              single-line
              hide-details
              outlined
              dense
              class="shrink ml-3"
              style="width: 180px"
              small
              rounded
              ref="searchTextField"
            ></v-text-field>
          </v-slide-x-reverse-transition>
          <v-btn icon v-if="enableSearch" @click="handleSearchIconPress">
            <v-icon>{{ showSearchField ? "mdi-close" : "mdi-magnify" }}</v-icon>
          </v-btn>

          <v-menu
            v-model="showMoreActionsMenu"
            :close-on-content-click="false"
            location="bottom"
            :offset-y="true"
            left
            top
          >
            <template v-slot:activator="{ on, props }">
              <v-btn
                class="ml-1"
                color="primary"
                v-bind="props"
                icon
                small
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-card min-width="180">
              <!-- <v-toolbar> -->
              <v-list-item
                v-if="enableExport"
                @click="exportAsCsv"
                class="text-caption"
              >
                <v-icon size="20">mdi-file-excel-outline</v-icon>
                Download excel
              </v-list-item>

              <!-- Show and Hide Headers -->
              <div v-if="enableShowHideColumns">
                <v-menu
                  z-index="99999"
                  :offset-x="true"
                  bottom
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on" class="text-caption">
                      <v-icon size="20"> mdi-flip-horizontal </v-icon>
                      Show and hide
                    </v-list-item>
                  </template>
                  <v-card max-height="400px">
                    <v-list dense>
                      <v-list-item
                        v-for="(item, index) in columnList"
                        :key="index"
                      >
                        <v-checkbox
                          v-model="selectedColumnListToView"
                          :value="item"
                          :label="item.text"
                          :hide-details="true"
                          dense
                        >
                          <template v-slot:label>
                            <span class="text-caption">
                              {{ item.text }}
                            </span>
                          </template>
                        </v-checkbox>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </div>
              <!-- Show and Hide Headers -->

              <!-- Group By Headers -->
              <div v-if="groupByList.length > 0">
                <v-menu z-index="99999" offset-x left>
                  <template v-slot:activator="{ on }">
                    <v-list-item class="text-caption" small v-on="on" text>
                      <v-icon size="20"> mdi-format-list-group </v-icon>
                      Group by
                    </v-list-item>
                  </template>
                  <v-card max-height="400px">
                    <v-list dense>
                      <v-list-item
                        v-for="(item, index) in groupByList"
                        :key="index"
                        @click="handleGroupBySelect(item)"
                        class="text-caption"
                      >
                        {{ item.text }}
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </div>
              <!-- Group By Headers -->

              <v-list-item
                v-if="columnFilterList.length > 0"
                text
                class="text-caption"
                @click="filterButtonPressed()"
              >
                <v-icon size="20">
                  {{ showFilterForm ? "mdi-filter" : "mdi-filter-outline" }}
                </v-icon>
                Filter
              </v-list-item>
              <!-- </v-toolbar> -->
            </v-card>
          </v-menu>
        </v-toolbar>
        <v-expand-transition>
          <v-toolbar
            v-if="selectedItemList.length > 0 && multiSelect"
            class="mx-1 py-0 ma-0 caption"
            flat
            color="#f8f8f8"
            dense
            fluid
          >
            <strong class="mr-1">{{ selectedItemList.length }} </strong>
            selected out of
            <strong class="ml-1">{{ tableData().length }}</strong>
          </v-toolbar>
        </v-expand-transition>

        <!-- Filters -->
        <v-toolbar flat class="align-right" v-if="showFilterForm">
          <v-chip small outlined label color="primary"
            >Total: {{ tableData().length }} /
            {{ selectModel(modelValue, dataSelectorKey).length }}</v-chip
          >
          <v-spacer />
          <div
            v-for="(filter, index) in columnFilterList"
            :key="'filter' + filter.dataSelectorKey"
          >
            <v-row v-if="!filter.booleanFilter" class="mx-1">
              <v-select
                v-if="columnFilterListWithValues.length > 0"
                outlined
                dense
                small-chips
                hide-details
                multiple
                :items="filter.filterItems"
                class="mx-2"
                :label="filter.label"
                v-model="columnFilterListWithValues[index].value"
                :itemValue="filter.itemKey"
                :item-text="filter.itemText"
                @input="applyTableFilter()"
              >
              </v-select>
            </v-row>
            <div v-if="filter.booleanFilter" class="mx-1">
              <v-switch
                :label="filter.label"
                v-model="columnFilterListWithValues[index].value"
                @change="applyTableFilter()"
              >
              </v-switch>
            </div>
          </div>

          <v-btn class="ml-3" small icon @click="filterButtonPressed()">
            <v-icon small> mdi-close-circle </v-icon>
          </v-btn>

          <!-- <v-btn class="mx-2" outlined @click="clearTableFilter()"
            >Clear Filter</v-btn
          > -->
        </v-toolbar>

        <!-- Filters -->
      </template>
      <template
        v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }"
      >
        <td v-if="groupBy !== null" :colspan="headers.length">
          <div class="d-flex align-center">
            <v-btn
              :class="`groupByToggle`"
              small
              @click="toggle"
              x-small
              icon
              :ref="group"
            >
              <v-icon small v-if="isOpen">mdi-minus</v-icon>
              <v-icon small v-else>mdi-plus</v-icon>
            </v-btn>
            <span class="mx-5 text-caption flex-fill"
              >{{ groupBy.label }} ( {{ items.length }} ) : {{ group }}</span
            >

            <span class="mx-3 text-caption" v-if="groupBySummaryFunction"
              ><strong>{{ groupBySummaryFunction(items) }}</strong></span
            >
            <v-btn small icon @click="resetGroupBy"
              ><v-icon small>mdi-close</v-icon></v-btn
            >
          </div>
        </td>
      </template>
      <template
        v-for="header in columnList"
        v-slot:[`item.${header.value}`]="{ item }"
      >
        <slot
          v-if="!showCardView"
          :name="[`item.${header.value}`]"
          :item="item"
        >
          <component
            :ref="header.columnCellMetaData.myRefName"
            :is="header.columnCellMetaData.componentName"
            :value="item"
            v-bind="header.columnCellMetaData.props"
            :dataSelectorKey="header.value"
          ></component>
          <f-copy
            v-if="header.enableCopy && !!selectModel(item, header.value)"
            :value="
              selectModel(
                item,
                header.dataSelectorKeyToCopy
                  ? header.dataSelectorKeyToCopy
                  : header.value
              )
            "
            :tooltipText="header.copyTooltipText"
          >
          </f-copy>
        </slot>
      </template>
      <!-- Card View -->
      <template v-if="showCardView" v-slot:[`item`]="{ item, index }">
        <div>
          <v-card class="px-2 d-flex align-center">
            <div class="d-flex flex-fill flex-wrap">
              <div
                class="flex-fill col-md-3"
                v-for="header of viewableColumnListInCard"
                :key="header.value"
              >
                <div class="mx-3 caption">
                  <div>
                    {{ header.text }}
                  </div>

                  <component
                    :ref="header.columnCellMetaData.myRefName"
                    :is="header.columnCellMetaData.componentName"
                    :value="item"
                    v-bind="header.columnCellMetaData.props"
                    :dataSelectorKey="header.value"
                  ></component>
                </div>
              </div>
            </div>
            <v-btn icon @click="handleExpandCardView(index)">
              <v-icon size="20" color="secondary">
                mdi-arrow-down-drop-circle
              </v-icon>
            </v-btn>
          </v-card>
          <v-expand-transition>
            <v-card class="px-2" v-if="expandedCardViewList.includes(index)">
              <div class="d-flex flex-fill flex-wrap">
                <div
                  class="flex-fill col-md-3"
                  v-for="header of hiddenColumnListInCard"
                  :key="header.value"
                >
                  <div class="mx-3 caption">
                    <div>
                      {{ header.text }}
                    </div>

                    <component
                      :ref="header.columnCellMetaData.myRefName"
                      :is="header.columnCellMetaData.componentName"
                      :value="item"
                      v-bind="header.columnCellMetaData.props"
                      :dataSelectorKey="header.value"
                    ></component>
                  </div>
                </div>
              </div>
            </v-card>
          </v-expand-transition>
        </div>
      </template>

      <!-- Card View -->

      <template v-slot:[`item.slNo`]="{ index }">
        {{ index + 1 }}
      </template>

      <template v-slot:[`item.actions`]="{ item, index }">
        <div class="d-flex">
          <v-btn
            :disabled="disabled"
            icon
            @click="handleInfoClick(item, index)"
            v-if="infoBtnData"
          >
            <v-icon small> mdi-information</v-icon>
          </v-btn>

          <v-icon
            v-if="enableInfo"
            @click="handleDetailsClick(item, index)"
            small
          >
            mdi-information</v-icon
          >
          <v-btn
            :disabled="disabled"
            icon
            @click="handleEditClick(item, index)"
            v-if="editBtnData"
          >
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            icon
            :disabled="disabled"
            @click="handleDeleteClick(item, index)"
            v-if="deleteBtnData"
          >
            <v-icon small> mdi-delete </v-icon>
          </v-btn>
        </div>
      </template>
      <!-- Expansion component -->
      <template
        v-if="expansionComponent"
        v-slot:expanded-item="{ headers, item }"
      >
        <td :colspan="headers.length" class="pa-0">
          <component
            class="my-5"
            :is="expansionComponent.componentName"
            :value="item"
            v-bind="expansionComponent.props"
          ></component>
        </td>
      </template>
      <!-- Expansion component -->

      <!-- Footer -->

      <template v-slot:[`body.append`]>
        <tr v-if="!showCardView && showTotalFooter">
          <td v-if="showCheckbox"></td>
          <td
            align="right"
            v-for="header of filteredHeaders"
            :key="header.value"
          >
            <span
              class="primary--text font-weight-bold"
              v-if="header.enableTotal"
            >
              <component
                :ref="header.columnCellMetaData.myRefName"
                :is="header.columnCellMetaData.componentName"
                :value="getTotal(header)"
              ></component>
            </span>
          </td>
        </tr>
      </template>

      <template v-slot:[`footer.prepend`]>
        <div
          v-for="(info, index) in footerInfoMetaDataList"
          :key="info.label + index"
        >
          <component
            :is="info.infoMetaData.componentName"
            :value="info.value"
            :label="info.label"
            v-bind="info.infoMetaData.props"
            class="mx-1"
          ></component>
        </div>
      </template>

      <!-- Footer -->
    </v-data-table>
  </v-card>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { VBtn, VDataTable } from "vuetify/lib/components";
import FAddBtn from "../FAddBtn.vue";
import * as Snackbar from "node-snackbar";
import FCellLink from "./cell/FCellLink.vue";
import FCellBtn from "./cell/FCellBtn.vue";
import FCellCurrency from "./cell/FCellCurrency.vue";
import FCellText from "./cell/FCellText.vue";
import FCellStatus from "./cell/FCellStatus.vue";
import FCellDate from "./cell/FCellDate.vue";
import ModelVue from "../ModelVue";
import { FTableActionField } from "./FDataTableMDP";
import FBtn from "../FBtn.vue";
import FInfoText from "./info/FInfoText.vue";
import FInfoINR from "./info/FInfoINR.vue";
import FAlert from "../FAlert.vue";
import FCellPhone from "./cell/FCellPhone.vue";
import FCellEmail from "./cell/FCellEmail.vue";
import FCellTask from "./cell/FCellTask.vue";
import FCellDateTime from "./cell/FCellDateTime.vue";
import FCellTaskStatus from "./cell/FCellTaskStatus.vue";
import FCellName from "./cell/FCellName.vue";
import FCellUppercase from "./cell/FCellUppercase.vue";
import FCellDayPastDue from "./cell/FCellDayPastDue.vue";
import FCellSLA from "./cell/FCellSLA.vue";
import FCellCurrencyBtn from "./cell/FCellCurrencyBtn.vue";
import * as Json2csv from "json2csv";
import FCellTextEllipsis from "./cell/FCellTextEllipsis.vue";
import FCellDateTimeEllipsis from "./cell/FCellDateTimeEllipsis.vue";
import FExpansionText from "./expansion/FExpansionText.vue";
import FTableExpansionMDP from "./FTableExpansionMDP";
import FCellBoolean from "./cell/FCellBoolean.vue";
import FCellOutOfTotal from "./cell/FCellOutOfTotal.vue";
import FCellBlank from "./cell/FCellBlank.vue";
import FCellBooleanList from "./cell/FCellBooleanList.vue";
import FCellList from "./cell/FCellList.vue";
import FCellCopy from "./cell/FCellCopy.vue";
import FCellStandardDateTime from "./cell/FCellStandardDateTime.vue";
import FCellAmountPaymentList from "./cell/FCellAmountPaymentList.vue";
import FHoverCopy from "../FHoverCopy.vue";
import FForm from "../form/FForm.vue";
import FExpansionFDataTable from "./expansion/FExpansionFDataTable.vue";
import FCopy from "../FCopyBtn.vue";
import FCellRouterLink from "./cell/FCellRouterLink.vue";
import FCellBtnPreview from "./cell/FCellBtnPreview.vue";
import FCellUrlLink from "./cell/FCellUrlLink.vue";
import FCellAccountNumber from "./cell/FCellAccountNumber.vue";
import FBtnMDP from "../FBtnMDP";
import FCellDocumentLink from "./cell/FCellDocumentLink.vue";
import FCellColor from "./cell/FCellColor.vue";
import FCellTime from "./cell/FCellTime.vue";
import { InfoPosition } from "./FTableInfoMDP";
import FInfoINRCompare from "./info/FInfoINRCompare.vue";
import { filter } from "vue/types/umd";
import FFormDrawer from "../form/FFormDrawer.vue";
import FFormDrawerMDP from "@/components/form/FFormDrawerMDP";
import FCellNumber from "./cell/FCellNumber.vue";
import axios from "axios";
import FFormMDP from "../form/FFormMDP";
import FColumnMDP from "./FColumnMDP";
import FTooltip from "../FTooltip.vue";
import FCellAbbrevation from "./cell/FCellAbbrevation.vue";
import FCellCreditorName from "./cell/FCellCreditorName.vue";
import FCellBooleanCheck from "./cell/FCellBooleanCheck.vue";
import FCellAmountPayment from "./cell/FCellAmountPayment.vue";

@Component({
  components: {
    VBtn,
    FAddBtn,
    FCellLink,
    FCellBtn,
    FCellCurrency,
    FCellText,
    FCellStatus,
    FCellDate,
    FBtn,
    FInfoText,
    FInfoINR,
    FAlert,
    FCellPhone,
    FCellEmail,
    FCellTask,
    FCellDateTime,
    FCellTaskStatus,
    FCellName,
    FCellUppercase,
    FCellDayPastDue,
    FCellSLA,
    FCellCurrencyBtn,
    FCellTextEllipsis,
    FCellDateTimeEllipsis,
    FExpansionText,
    FCellBoolean,
    FCellOutOfTotal,
    FCellBlank,
    FCellBooleanList,
    FCellList,
    FCellCopy,
    FCellStandardDateTime,
    FCellAmountPaymentList,
    FHoverCopy,
    FForm,
    FExpansionFDataTable,
    FCopy,
    FCellRouterLink,
    FCellBtnPreview,
    FCellUrlLink,
    FCellAccountNumber,
    FCellDocumentLink,
    FCellColor,
    FCellTime,
    FInfoINRCompare,
    FFormDrawer,
    FCellNumber,
    VDataTable,
    FTooltip,
    FCellAbbrevation,
    FCellCreditorName,
    FCellBooleanCheck,
    FCellAmountPayment,
  },
})
export default class FDataTable extends ModelVue {
  @Prop({
    default: () => [],
  })
  columnList: any[];

  selectedColumnListToView: any[] = [];

  @Prop()
  myRefName: string;

  @Prop()
  dataSelectorKey: string;

  @Prop()
  enableSearch: boolean;

  @Prop()
  multiSelect: boolean;

  @Prop()
  outlined: boolean;

  @Prop({
    default: () => [],
  })
  actions: FTableActionField[];

  @Prop({
    default: () => [],
  })
  infoMetaDataList: any[];

  @Prop()
  groupBySummaryFunction: (itemList: any) => any;

  @Prop()
  itemSelectedEventFunction: (item: any) => any;

  @Prop({
    default: false,
  })
  disabled: boolean;

  @Prop({
    default: false,
  })
  enableExport: boolean;

  @Prop({
    default: false,
  })
  enableShowHideColumns: boolean;

  @Prop({
    default: false,
  })
  hideDefaultFooter: boolean;

  @Prop({
    default: false,
  })
  loading: boolean;

  @Prop({
    default: null,
  })
  title: string;

  @Prop({
    default: null,
  })
  itemKey: string;

  @Prop({
    default: () => [],
  })
  columnFilterList: any[];

  @Prop()
  expansionComponent: any;

  @Prop()
  enableSerialNumber: any;

  @Prop()
  enablePagination: boolean;

  @Prop()
  enableInfo: boolean;

  @Prop()
  enableFooter: boolean;

  @Prop({
    default: () => [],
  })
  infoActionMetaDataList: any[];

  @Prop()
  infoFFormMDP: FFormDrawerMDP;
  @Prop()
  interceptorUrl: string;
  @Prop({
    default: false,
  })
  enableCardViewToggle: boolean;

  infoFFormMDPLocal: FFormDrawerMDP;

  itemsPerPage: number = 15;
  apiLoading: boolean = false;
  showCardView: boolean = false;
  expandedCardViewList: number[] = [];
  @Prop()
  checkboxEnableCondition?: (item: any) => boolean;

  expanded: any = [];
  groupBy: any = {
    label: null,
    value: null,
  };

  search = "";
  showConfirmation: boolean = false;
  showMoreActionsMenu: boolean = false;
  showDeleteConfirmation: boolean = false;
  selectedItemList: any[] = [];
  selectedItemForDelete: any;
  selectedAction: any = {};
  selectedRowIndex: number | undefined = -1;
  columnFilterListWithValues: any[] = [];
  filterApplied: boolean = false;
  showFilterForm: boolean = false;
  filteredTableData: any = [];
  showInfoForm: boolean = false;
  showSearchField: boolean = false;
  selectedActionFFormMDP: FFormMDP | undefined;
  selectedActionFFormMetaData: any = {};

  selectedApiInput: any = {};

  getValue(item: any, path: any) {
    return path.split(".").reduce((res: any, prop: any) => res[prop], item);
  }

  handleSelectChange(newVal: any) {
    this.showConfirmation = false;
    this.selectedItemList = newVal;
  }

  handleOtherActionClick(action: FTableActionField) {
    this.selectedAction = action;
    if (action.confirmation === true) {
      this.showConfirmation = true;
    } else {
      this.fireActionClick();
    }
  }

  fireActionClick() {
    this.showConfirmation = false;
    const selectedItem =
      this.multiSelect &&
      !this.selectedAction?.singleSelect &&
      this.selectedAction?.type === ActionType.OTHERS
        ? this.selectedItemList
        : this.selectedItemList[0];
    //New Code
    this.handleFormClick(selectedItem);
    //New Code

    this.selectedAction?.onClick(selectedItem).then((res: any) => {
      this.clearSelectedItems();
    });
    // if (
    //   this.multiSelect &&
    //   !this.selectedAction?.singleSelect &&
    //   this.selectedAction?.type === ActionType.OTHERS
    // ) {
    //   this.selectedAction.onClick(this.selectedItemList).then((res: any) => {
    //     this.clearSelectedItems();
    //   });
    // } else {
    //   this.selectedAction
    //     ?.onClick(this.selectedItemList[0])
    //     .then((res: any) => {
    //       this.clearSelectedItems();
    //     });
    // }
  }

  closeActionFormDrawer() {
    this.selectedActionFFormMetaData = {};
    this.selectedAction = undefined;
  }

  handleFormSumbmit() {
    this.closeActionFormDrawer();
    this.clearSelectedItems();
  }

  handleFormClick(selectedItem: any) {
    if (this.selectedAction?.apiActionConfig) {
      const formMDP =
        this.selectedAction?.apiActionConfig?.formMDP?.getNewClass();
      this.selectedApiInput = this.selectedAction?.apiActionConfig
        .buildFormInput
        ? this.selectedAction?.apiActionConfig?.buildFormInput(selectedItem)
        : selectedItem;
      this.selectedActionFFormMetaData = formMDP?.getMetaData() || {};
    }
  }

  fireDeleteActionClick() {
    this.showDeleteConfirmation = false;
    this.deleteBtnData
      ?.onClick(this.selectedItemForDelete, this.selectedRowIndex)
      .then((res) => {
        this.clearSelectedItems();
      });
  }

  handleDeleteClick(item: any, index?: number) {
    this.selectedAction = this.deleteBtnData;
    this.selectedItemForDelete = item;
    this.selectedRowIndex = index;
    if (this.selectedAction?.confirmation === true) {
      this.showDeleteConfirmation = true;
    } else {
      this.fireDeleteActionClick();
    }
    // this.deleteBtnData.onClick(item);
  }

  isActionDisabled(action: FTableActionField) {
    return (
      (this.selectedItemList.length == 0 ||
        this.disabled ||
        action.disabled ||
        (this.selectedItemList.length > 1 && action.singleSelect)) &&
      !(action.noSelect && !action.disabled)
    );
  }

  handleEditClick(item: any, index: number) {
    this.selectedRowIndex = index;
    this.editBtnData?.onClick(item, index);
  }

  handleInfoClick(item: any, index: number) {
    this.selectedRowIndex = index;
    this.infoBtnData?.onClick(item, index);
  }

  handleDetailsClick(item: any, index: number) {
    this.selectedRowIndex = index;
    this.showInfoForm = true;
    this.infoFFormMDPLocal = this.infoFFormMDP.getNewClass();
    this.infoFFormMDPLocal.width = "700";
    this.selectedItemList = [item];
    if (this.itemSelectedEventFunction) {
      this.itemSelectedEventFunction(item);
    }
    // this.infoBtnData.onClick(item, index);
  }

  get deleteBtnData() {
    return this.actions.find((item) => item.type === ActionType.DELETE);
  }

  get infoBtnData() {
    return this.actions.find((item) => item.type === ActionType.INFO);
  }

  get editBtnData() {
    return this.actions.find((item) => item.type === ActionType.EDIT);
  }

  get addBtnData() {
    return this.actions.find(
      (item) =>
        item.type === ActionType.ADD &&
        (item.condition == undefined || item.condition == true)
    );
  }

  get refreshBtnData() {
    return this.actions.find((item) => item.type === ActionType.REFRESH);
  }

  get filteredHeaders() {
    let headers = [...this.columnList];
    headers = headers.filter((item) => {
      return this.selectedColumnListToView.find((viewItem) => {
        return item.value === viewItem.value;
      });
    });
    if (
      this.deleteBtnData ||
      this.editBtnData ||
      this.infoBtnData ||
      this.enableInfo
    ) {
      headers.push({ text: "", value: "actions", align: "right" });
    }
    if (this.expansionComponent) {
      headers.push({ text: "", value: "data-table-expand", width: "3%" });
    }

    if (this.enableSerialNumber) {
      headers.unshift({ text: "Sl No", value: "slNo", align: "left" });
    }

    return headers;
  }

  get groupByList() {
    let headerList = [...this.columnList];
    return headerList.filter((header) => header.enableGroupBy);
  }

  get filteredActions() {
    return this.actions.filter(
      (item) =>
        item.type === ActionType.OTHERS &&
        (item.condition == true || item.condition == undefined)
    );
  }

  get showCheckbox() {
    const otherActions = this.actions.filter(
      (item) => item.type === ActionType.OTHERS
    );
    return (
      otherActions.filter((item) => !item.disabled && !item.noSelect).length >
        0 && !this.disabled
    );
  }

  get headerInfoMetaDataList() {
    return this.infoMetaDataList.filter(
      (infoMetaData) =>
        infoMetaData.position === InfoPosition.Header &&
        infoMetaData.condition === true
    );
  }

  get footerInfoMetaDataList() {
    return this.infoMetaDataList.filter(
      (infoMetaData) =>
        infoMetaData.position === InfoPosition.Footer &&
        infoMetaData.condition === true
    );
  }

  get modelValue(): any {
    return this.value || [];
    // return this.filteredTableData || [];
  }

  set modelValue(newModelValue: any) {
    this.$emit("input", newModelValue);
  }

  clearSelectedItems() {
    this.selectedItemList = [];
  }

  private exportAsCsv() {
    const filteredValue = this.filterApplied
      ? this.filteredTableData
      : this.modelValue;
    const fields = this.filteredHeaders.map((obj) => {
      return { value: obj.value, label: obj.text };
    });
    const json2csvParser = new Json2csv.Parser({ fields });
    const csv = json2csvParser.parse(filteredValue);

    const filename = "Export_" + new Date().getTime() + ".csv";
    const charset = "utf-8";
    const blob = new Blob([csv], {
      type: "text/csv;charset=" + charset + ";",
    });
    if ((window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      link.setAttribute("href", window.URL.createObjectURL(blob));
      link.setAttribute("download", `${filename}`);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link); // Required for FF
      link.click();
    }
  }

  filterButtonPressed() {
    this.showMoreActionsMenu = false;
    if (this.showFilterForm === true) {
      this.clearTableFilter();
    } else {
      this.filterApplied = true;
      this.showFilterForm = true;
      this.filteredTableData = this.selectModel(
        this.modelValue,
        this.dataSelectorKey
      );
    }
  }

  applyTableFilter() {
    let filteredDataList = [...this.value];
    console.log(this.columnFilterListWithValues);
    for (let columnFilter of this.columnFilterListWithValues) {
      // this.columnFilterListWithValues.forEach((columnFilter) => {
      filteredDataList = filteredDataList.filter((filteredData: any) => {
        if (columnFilter.booleanFilter === true) {
          return (
            columnFilter.value == undefined ||
            columnFilter.value ===
              this.selectModel(filteredData, columnFilter.dataSelectorKey)
          );
        } else if (columnFilter.value.length > 0) {
          return (
            columnFilter.value == undefined ||
            columnFilter.value.includes(
              this.selectModel(filteredData, columnFilter.dataSelectorKey)
            )
          );
        } else {
          return true;
        }
      });
    }
    // });
    this.filteredTableData = this.selectModel(
      filteredDataList,
      this.dataSelectorKey
    );
  }

  get infoFFormMetaData() {
    this.infoFFormMDPLocal.isVisible = this.showInfoForm;
    this.infoFFormMDPLocal.onClose = () => (this.showInfoForm = false);

    this.infoFFormMDPLocal.actionList = [...this.infoFFormMDP.actionList];

    this.actions
      .filter((item) => {
        console.log(item, "Item action");
        return (
          item.type === ActionType.OTHERS &&
          !item.noSelect &&
          (item.condition == true || item.condition == undefined)
        );
      })
      .map((action) => {
        this.infoFFormMDPLocal.addAction(
          new FBtnMDP({
            label: action.label,
            onClick: () => this.handleOtherActionClick(action),
            disabled: action.disabled || this.disabled,
          })
        );
      });

    return this.infoFFormMDPLocal.getMetaData();
  }
  handleExpandCardView(index: number) {
    if (this.expandedCardViewList.indexOf(index) === -1) {
      this.expandedCardViewList.push(index);
    } else {
      this.expandedCardViewList.splice(index, 1);
    }
  }
  clearTableFilter() {
    this.filterApplied = false;
    this.showFilterForm = false;
    this.filteredTableData = this.selectModel(
      this.modelValue,
      this.dataSelectorKey
    );
    this.resetColumnFilterWithValues();
  }

  @Watch("columnList") columnListUpdated(newValue: any[], oldValue: any[]) {
    if (oldValue.length !== newValue.length) {
      this.selectedColumnListToView = this.columnList.filter((column) => {
        return column.hidden === false;
      });
    }
  }

  get viewableColumnListInCard() {
    return this.columnList.filter((column: FColumnMDP) => {
      return column.hiddenInCardView === false && column.hidden == false;
    });
  }

  get hiddenColumnListInCard() {
    return this.columnList.filter((column: FColumnMDP) => {
      return column.hiddenInCardView === true || column.hidden === true;
    });
  }

  mounted() {
    this.selectedColumnListToView = this.columnList.filter((column) => {
      return column.hidden === false;
    });
    this.resetColumnFilterWithValues();
    this.interceptRequest();
  }

  resetColumnFilterWithValues() {
    this.columnFilterListWithValues = this.columnFilterList.map((filter) => {
      return filter.booleanFilter ? { ...filter } : { ...filter, value: [] };
    });
  }

  tableData(): any {
    return this.filterApplied
      ? this.filteredTableData
      : this.selectModel(this.modelValue, this.dataSelectorKey);
  }

  handleGroupBySelect(item: any) {
    this.groupBy.value = item.value;
    this.groupBy.label = item.text;
    this.itemsPerPage = -1;
    // setTimeout(() => {
    // (document.querySelector(".groupByToggle") as any)?.click();
    // }, 1000);
  }
  resetGroupBy(item: any) {
    this.groupBy = {
      label: null,
      value: null,
    };
  }
  getTotal(header: any) {
    const total = this.tableData().reduce((acc: any, currItem: any) => {
      const item = this.selectModel(currItem, header.value);
      acc = acc + (item ? Number(item) : 0);
      return acc;
    }, 0);
    return total;
  }

  handleSearchIconPress() {
    this.showSearchField = !this.showSearchField;
    this.search = "";
    setTimeout(() => {
      (this.$refs.searchTextField as any)?.focus();
    }, 100);
  }

  get showTotalFooter() {
    return (
      this.enableFooter &&
      this.selectModel(this.modelValue, this.dataSelectorKey).length > 0
    );
  }

  isCheckboxValid(item: any) {
    if (this.checkboxEnableCondition) {
      return this.checkboxEnableCondition(item);
    } else {
      return true;
    }
  }

  selectAllToggle(props: any) {
    const totalItemLength = props.items.filter((item: any) => {
      if (!!this.checkboxEnableCondition) {
        return this.checkboxEnableCondition(item);
      } else {
        return true;
      }
    }).length;
    console.log(totalItemLength, "Total item Length");
    if (this.selectedItemList.length != totalItemLength) {
      this.selectedItemList = [];
      props.items.forEach((item: any) => {
        if (this.checkboxEnableCondition) {
          if (this.checkboxEnableCondition(item)) {
            this.selectedItemList.push(item);
          }
        } else {
          this.selectedItemList.push(item);
        }
      });
    } else {
      this.selectedItemList = [];
    }
  }

  handleHeaderFilterClick(index: number, filter: any, filterItem: any) {
    const filterItemVal = filter.itemKey
      ? filterItem[filter.itemKey]
      : filterItem;
    const filterItemValIndex =
      this.columnFilterListWithValues[index].value.indexOf(filterItemVal);
    if (filterItemValIndex !== -1) {
      this.columnFilterListWithValues[index].value.splice(
        filterItemValIndex,
        1
      );
    } else {
      // this.columnFilterListWithValues[index].value.push(filterItemVal);
      this.columnFilterListWithValues[index].value = [
        filter.itemKey ? filterItem[filter.itemKey] : filterItem,
      ];
    }

    this.filterApplied = true;
    this.applyTableFilter();
  }

  getHeaderFilterCount(columnFilter: any, filterItem: any) {
    return this.value.filter((filteredData: any) => {
      return (
        this.selectModel(filteredData, columnFilter.dataSelectorKey) ===
        (columnFilter.itemKey ? filterItem[columnFilter.itemKey] : filterItem)
      );
    }).length;
  }

  getHeaderFilterColor(columnFilter: any, filterItem: any) {
    return this.isHeaderFilterSelected(columnFilter, filterItem)
      ? "secondary"
      : "default";
  }

  isHeaderFilterSelected(columnFilter: any, filterItem: any) {
    const filterItemVal = columnFilter.itemKey
      ? filterItem[columnFilter.itemKey]
      : filterItem;
    return columnFilter.value?.includes(filterItemVal);
  }

  interceptRequest() {
    axios.interceptors.request.use(
      (config) => {
        // Do something before sending the request
        if (config.url === this.interceptorUrl) {
          // Intercept only requests to a specific URL (replace '/specific-url' with your specific URL)
          this.apiLoading = true;
          console.log("Intercepted request to /specific-url:", config);
          // You can modify the config or perform other actions here
        }
        return config;
      },
      (error) => {
        // Do something with request error
        this.apiLoading = false;
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        // Set the status to 'success' and store the response data
        this.apiLoading = false;
        return response;
      },
      (error) => {
        this.apiLoading = false;
        return Promise.reject(error);
      }
    );
  }
}

export enum ActionType {
  ADD = "ADD",
  DELETE = "DELETE",
  EDIT = "EDIT",
  OTHERS = "OTHERS",
  INFO = "INFO",
  REFRESH = "REFRESH",
}
</script>

<style scoped>
/* .f-data-table-custom /deep/ td{
  height: 40px !important;
}

.f-data-table-custom /deep/ th{
  height: 40px !important;
} */

:deep(td) {
  padding: 0 8px !important;
  height: 38px !important;
}
:deep(th) {
  padding: 0 8px !important;
  height: 38px !important;
}

:deep(.v-list-item) {
  min-height: 35px;
}
.thText {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
}
.filter-chip {
  border-color: #f36f21 !important;
}
.filter-chip-count {
  padding: 5px;
  margin-left: 0px;
}
</style>
