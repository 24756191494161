<template>
  <div>
    <header class="header">
      <div class="leftContent">
        <div class="menu d-sm-none d-xs-block" @click.stop="onMenuClick">
          <v-icon color="#1d4974" size="20">mdi-menu</v-icon>
        </div>
        <img
          :src="require('@/assets/images/freed-logo.svg')"
          @click="gotoHome"
        />
      </div>
      <v-menu
        offset-y
        left
        nudge-bottom="5"
        min-width="230"
        content-class="user-profile-menu-content"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="profile-content" v-bind="attrs" v-on="on">
            <span>{{ PartnerInfo?.fullname || "" }}</span>
            <div class="initial">{{ initial }}</div>
            <!-- <v-icon color="#4e4e4e" size="20">mdi-chevron-down</v-icon> -->
          </div>
        </template>
        <v-list>
          <!-- Logout -->
          <v-list-item @click="logout" dense>
            <v-list-item-icon class="me-2">
              <v-icon size="22"> mdi-logout-variant </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </header>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      class="d-xs-block d-sm-none navigation-drawer"
    >
      <v-list class="list-item" nav dense>
        <v-list-item
          link
          v-for="item in items"
          :key="item.title"
          @click="takeAction(item.routerName)"
          v-bind:class="{
            'list-item-active': currentRouterName === item.routerName,
          }"
        >
          <v-icon
            class="mr-2"
            :color="item.routerName == currentRouterName ? 'secondary' : ''"
            >{{ item.icon }}</v-icon
          >
          <v-list-item-title class="activeColor">
            {{ item.title }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
import SideNav from "./side-nav.vue";

// import * as ServerData from '@/../src-gen/server-data';
// import * as Action from '@/../src-gen/action';

@Component({
  components: { SideNav },
})
export default class HeaderInstitution extends Vue {
  @Store.Getter.Partner.ApplicantDetails.PartnerInfo
  PartnerInfo: Data.Partner.PartnerSummary;
  // @Prop({ default: false })
  drawer: boolean = false;

  onMenuClick() {
    // this.$emit('closeSideNav', true);
    this.drawer = !this.drawer;
  }

  get items() {
    const item = [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        routerName: "InstitutionalPartner.InstitutionDashboard",
      },
      {
        title: "Client File Search",
        icon: "mdi-file-search",
        routerName: "InstitutionalPartner.ClientFileSearch",
      },
      {
        title: "Monthly Sales",
        icon: "mdi-finance",
        routerName: "InstitutionalPartner.MonthlySales",
      },
      {
        title: "Monthly Collection",
        icon: "mdi-hand-coin",
        routerName: "InstitutionalPartner.MonthlyCollection",
      },
      {
        title: "Unregistered Customers",
        icon: "mdi-account-alert",
        routerName: "InstitutionalPartner.UnregisteredCustomers",
      },
    ];
    return item;
  }

  get currentRouterName() {
    return this.$route.name || "";
  }

  get initial() {
    return this.PartnerInfo?.fullname?.[0] || "A";
  }

  takeAction(routerName: string) {
    this.$router.push({ name: routerName });
  }

  gotoHome() {
    this.$router.push({ name: "InstitutionalPartner.InstitutionDashboard" });
  }
  logout() {
    window.localStorage.removeItem("auth_token");
    window.localStorage.removeItem("partnerId");
    this.$router.push({ name: "Login.Login" });
  }
}
</script>
<style lang="scss" scoped>
.header {
  width: 100vw;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background: #ffffff;
  top: 0;
  padding: 5px 15px;
  box-shadow: 0 1px 2px rgba(235, 234, 234, 0.25);
  z-index: 888;
  img {
    width: 100px;
  }
}
.navigation-drawer {
  // position: fixed !important;
  top: 50px !important;
}
.initial {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}
.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: #dee5f8;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}
.profile-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // border: 1px solid #eeeeee;
  padding: 2px 5px;
  border-radius: 4px;
  span {
    text-transform: capitalize;
    font-size: 14px;
  }
}
.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.leftContent {
  display: flex;
  align-items: center;
}
.list-item-active .activeColor {
  color: #f56f07;
}
</style>
