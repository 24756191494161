<template>
  <v-dialog v-model="open" max-width="500" persistent>
    <div class="terms-container">
      <v-btn @click="closeAction" text icon color="lighten-2" class="closeBtn">
        <v-icon size="20">mdi-close</v-icon>
      </v-btn>
      <h3>Terms & Condition</h3>
      <p>
        The <b>FREED</b> Partner Program Code of Conduct has been established -
        to protect the rights and safety of all associated with the program.
        However, each Partner is and remains responsible for their own actions
        and
        <b>FREED</b>
        assumes no responsibility or liability to that respect.
      </p>
      <p>
        As a <b>FREED</b> Authorised Partner, you are expected to comply with
        the following code of conduct:
      </p>
      <ul>
        <li>
          You may not use the FREED Partner Program to engage in unlawful
          activity, including but not limited to fraud or other illegal
          activities.
        </li>
        <li>
          You must not discriminate against anyone on the basis of race, colour,
          ethnicity, national origin, age, religion, marital status, disability
          status, sex or gender identity or expression (or lack thereof), sexual
          orientation or any other legally protected characteristic as
          determined by applicable law.
        </li>
        <li>
          You will ensure that you do not take any payments or payouts directly
          from the client.
        </li>
        <li>
          You must not use any language that is abusive or harassing towards
          another user of the FREED Partner Program or its Partners.
        </li>
        <li>
          You must not post inappropriate content on any page/post within the
          FREED Partner Program as well as display such content publicly on your
          own social media accounts without prior consent from us first via
          email at [<a href="mailto:fpp@freed.care">fpp@freed.care</a>].
        </li>
        <li>
          FREED expects you to be respectful and courteous towards other
          Partners, prospective clients, and FREED’s representatives and
          affiliates.
        </li>
        <li>
          Please maintain confidentiality with respect to any information shared
          between Partners.
        </li>
        <li>
          Please do not engage in any activity that violates laws or regulations
          governing the conduct of business.
        </li>
        <li>
          Confidentiality must be a top priority for Partners and they must
          maintain client confidentiality at all times.
        </li>
        <li>
          No Partner must advertise in a way that conceals or misrepresents
          their identity.
        </li>
        <li>
          Please empathize with the hardship of the client. We know that our
          clients are in financial distress, and we want them to feel supported
          and protected at all times when they sign up with FREED.
        </li>
        <li>
          FREED does not charge its customers any upfront fees and the Partner
          should comply with the same. FREED’s Relationship Manager of your
          referral will inform the customer of the same.
        </li>
        <li>
          Each Partner acknowledges and agrees that a violation of this Code may
          result in termination from the Program, forfeiture of any present and
          future commissions and the requirement to return any commissions
          obtained by means that are in breach of this Code.
        </li>
      </ul>
      <p>
        For any other information, you can write to us at
        <a href="mailto:fpp@freed.care">fpp@freed.care</a>
      </p>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
import * as Action from "@/../src-gen/action";
import Snackbar from "node-snackbar";

@Component({
  components: {},
})
export default class TermsAndCondition extends Vue {
  @Prop({ default: false })
  open: boolean;
  closeAction() {
    this.$emit("close");
  }
}
</script>
<style lang="scss" scoped>
.terms-container {
  background: #ffffff;
  max-width: 500px;
  padding: 20px;
  z-index: 9999;
  position: relative;
  h3 {
    text-align: center;
    margin-bottom: 15px;
  }

  ul {
    list-style: decimal;
  }
}
.closeBtn {
  position: absolute;
  right: 0;
  top: 5px;
  cursor: pointer;
}
</style>