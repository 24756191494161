import FDataTableMDP from '@/components/table/FDataTableMDP';
import FCellBtnMDP from '@/components/table/cell/FCellBtnMDP';
import FCellDateMDP from '@/components/table/cell/FCellDateMDP';
import FCellPhoneMDP from '@/components/table/cell/FCellPhoneMDP';
import FCellRouterLinkMDP from '@/components/table/cell/FCellRouterLinkMDP';

export default class UnregisteredCustomersFDataTableMDP extends FDataTableMDP {
  parent: any;
  constructor({ parent }: { parent: any }) {
    super({
      title: 'Unregistered Customers',
      myRefName: 'unregisteredCustomersFDataTableRef',
      enableSerialNumber: true,
      enablePagination: false,
      hideDefaultFooter: true,
      enableFooter: true
    });
    this.parent = parent;

    this.addColumn({
      label: "Lead Entry Date",
      dataSelectorKey: 'registeredOn',
      columnCellMDP: new FCellDateMDP(),
    })
      .addColumn({
        label: "Lead Remote Id",
        dataSelectorKey: 'fppClientRemoteId',
        enableCopy: true,
        columnCellMDP: new FCellBtnMDP({
          color: 'secondary',
          onClick: (item) => {
            // this.parent.gotoClientFileInfo(item);
          },
        }),
      })
      .addColumn({
        label: "Lead Name",
        dataSelectorKey: "name",
      })

      .addColumn({
        label: "Mobile",
        dataSelectorKey: 'mobile',
        columnCellMDP: new FCellPhoneMDP(),
      })
      .addColumn({
        label: "Email ID",
        dataSelectorKey: 'emailId',
      })


  }
}


