<template>
  <div class="PartnerProfile">
    <iframe class="certificate-iframe" frameborder="0" :src="url"></iframe>
    <div class="btn-container">
      <a download="Partner Certificate" :href="url" class="download-btn"
        >Download</a
      >
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
// import store, * as Store from '@/../src-gen/store';
import * as Data from "@/../src-gen/data";
// import * as ServerData from '@/../src-gen/server-data';
import * as Action from "@/../src-gen/action";

@Component({
  components: {},
})
export default class PartnerCertificate extends Vue {
  uploadInput: Data.Partner.UploadCertificateInput =
    new Data.Partner.UploadCertificateInput();
  url: string = "";
  mounted() {
    this.getPartnerCertificate();
  }
  get partnerId() {
    return localStorage.getItem("partnerId") || "";
  }
  getPartnerCertificate() {
    Action.Partner.GetPartnerCertificate.execute1(this.partnerId, (output) => {
      if (output.documentPath) this.getPresignedUrl(output.documentPath);
    });
  }
  getPresignedUrl(fileName: string) {
    Action.Partner.GetUploadedUrl.execute1(fileName, (output) => {
      this.url = output.uploadedUrl;
    });
  }
  download() {
    const anchorTag = document.createElement("a");
    anchorTag.href = this.url as any;
    anchorTag.download = `Partner Certificate`;
    anchorTag.click();
    anchorTag.remove();
  }
}
</script>

<style lang="scss" scoped>
.certificate-iframe {
  width: calc(100vw - 60px);
  height: 70vh;
  border: 1px solid #16507a;
  border-radius: 15px;
}
.download-btn {
  background: #dee5f8;
  border: 1px solid #16507a;
  color: #01416f;
  padding: 5px 15px;
  font-weight: 500;
  border-radius: 10px;
  text-decoration: none;
}
.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.PartnerProfile {
  padding: 30px;
}
</style>
