<template>
  <header class="header">
    <div class="menu" @click="onMenuClick">
      <v-icon color="#1d4974">mdi-menu</v-icon>
    </div>
    <img :src="require('@/assets/images/freed-logo.svg')" @click="gotoHome" />
    <v-menu
      offset-y
      left
      nudge-bottom="5"
      min-width="230"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="profile-content" v-bind="attrs" v-on="on">
          <div class="initial">{{ initial }}</div>
          <span>{{ PartnerInfo?.fullname || '' }}</span>
        </div>
      </template>
      <v-list>
        <!-- Logout -->
        <v-list-item @click="logout" dense>
          <v-list-item-icon class="me-2">
            <v-icon size="22"> mdi-logout-variant </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </header>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import store, * as Store from '@/../src-gen/store';
import * as Data from '@/../src-gen/data';

// import * as ServerData from '@/../src-gen/server-data';
// import * as Action from '@/../src-gen/action';

@Component({
  components: {},
})
export default class Header extends Vue {
  @Store.Getter.Partner.ApplicantDetails.PartnerInfo
  PartnerInfo: Data.Partner.PartnerSummary;
  @Prop({ default: false })
  drawer: boolean;
  onMenuClick() {
    this.$emit('closeSideNav', true);
  }
  get initial() {
    return this.PartnerInfo?.fullname?.[0] || 'A';
  }

  gotoHome() {
    this.$router.push({ name: 'Partner.Dashboard' });
  }
  logout() {
    window.localStorage.removeItem('auth_token');
    window.localStorage.removeItem('partnerId');
    this.$router.push({ name: 'Login.Login' });
  }
}
</script>
<style lang="scss" scoped>
.header {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  background: #ffffff;
  top: 0;
  padding: 5px 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  z-index: 888;
  img {
    width: 100px;
  }
}
.initial {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu {
  background: #dee5f8;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}
.profile-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    text-transform: capitalize;
  }
}
.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
</style>
