
import Vuex, { GetterTree, MutationTree } from 'vuex';
import {namespace} from 'vuex-class';
import * as Data from './../data';
// tslint:disable


import {LoginDetailsInit} from './../../src/section/login/store/login/LoginDetailsGettersCode';
import {LoginDetailsGettersCode} from './../../src/section/login/store/login/LoginDetailsGettersCode';
import {LoginDetailsMutationsCode} from './../../src/section/login/store/login/LoginDetailsMutationsCode';
import {LoginDetailsGetters} from './login-store-support';
import {LoginDetailsMutations} from './login-store-support';


import store from './../store';


const loginDetailsGetters: LoginDetailsGetters = new LoginDetailsGettersCode();
const LoginDetailsGetterTree: GetterTree<Data.Login.LoginDetails, any> = {
  userName : (state: Data.Login.LoginDetails) => loginDetailsGetters.userName(state),
roleList : (state: Data.Login.LoginDetails) => loginDetailsGetters.roleList(state),
loggedInUser : (state: Data.Login.LoginDetails) => loginDetailsGetters.loggedInUser(state)
};

const loginDetailsMutations: LoginDetailsMutations = new LoginDetailsMutationsCode();
const LoginDetailsMutationTree: MutationTree<Data.Login.LoginDetails> = {
  setUserName : (state: Data.Login.LoginDetails, loginForm: Data.Login.LoginForm ) => loginDetailsMutations.setUserName(state , loginForm  ) ,
mutateRoleList : (state: Data.Login.LoginDetails, loginDetails: Data.Login.LoginDetails ) => loginDetailsMutations.mutateRoleList(state , loginDetails  ) ,
clear : (state: Data.Login.LoginDetails ) => loginDetailsMutations.clear(state   ) ,
mutateLoggedInUser : (state: Data.Login.LoginDetails, loggedInUser: Data.Login.LoggedInUser ) => loginDetailsMutations.mutateLoggedInUser(state , loggedInUser  ) 
};

const storeLoginDetails = {
  namespaced: true,
  state: LoginDetailsInit.initState(),
  mutations : LoginDetailsMutationTree,
  getters: LoginDetailsGetterTree
};

const LoginDetailsSpace = namespace('Login/LoginDetails');
const LoginDetailsGetter = {
    userName : LoginDetailsSpace.Getter('userName') ,
roleList : LoginDetailsSpace.Getter('roleList') ,
loggedInUser : LoginDetailsSpace.Getter('loggedInUser') 
};

class LoginDetailsMutationAPI {

  public static SET_USER_NAME(loginForm: Data.Login.LoginForm ): void {
    store.commit('Login/LoginDetails/setUserName' , loginForm );
  }


  public static MUTATE_ROLE_LIST(loginDetails: Data.Login.LoginDetails ): void {
    store.commit('Login/LoginDetails/mutateRoleList' , loginDetails );
  }


  public static CLEAR( ): void {
    store.commit('Login/LoginDetails/clear'  );
  }


  public static MUTATE_LOGGED_IN_USER(loggedInUser: Data.Login.LoggedInUser ): void {
    store.commit('Login/LoginDetails/mutateLoggedInUser' , loggedInUser );
  }

}




export const LoginStoreInfo = {
     namespaced: true,
     modules: {
      LoginDetails: storeLoginDetails
    }
};


export const LoginGetter = {
LoginDetails : LoginDetailsGetter
};

export const LoginMutation = {
LoginDetails : LoginDetailsMutationAPI
};

