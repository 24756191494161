
import Vuex, { GetterTree, MutationTree } from 'vuex';
import {namespace} from 'vuex-class';
import * as Data from './../data';
// tslint:disable


import {ClientSearchStoreInit} from './../../src/section/affiliateapp/store/client/ClientSearchStoreGettersCode';
import {ClientSearchStoreGettersCode} from './../../src/section/affiliateapp/store/client/ClientSearchStoreGettersCode';
import {ClientSearchStoreMutationsCode} from './../../src/section/affiliateapp/store/client/ClientSearchStoreMutationsCode';
import {ClientSearchStoreGetters} from './client-store-support';
import {ClientSearchStoreMutations} from './client-store-support';


import store from './../store';


const clientSearchStoreGetters: ClientSearchStoreGetters = new ClientSearchStoreGettersCode();
const ClientSearchStoreGetterTree: GetterTree<Data.Client.ClientSearchStore, any> = {
  searchCriteria : (state: Data.Client.ClientSearchStore) => clientSearchStoreGetters.searchCriteria(state),
searchResultList : (state: Data.Client.ClientSearchStore) => clientSearchStoreGetters.searchResultList(state)
};

const clientSearchStoreMutations: ClientSearchStoreMutations = new ClientSearchStoreMutationsCode();
const ClientSearchStoreMutationTree: MutationTree<Data.Client.ClientSearchStore> = {
  updateSearchCriteria : (state: Data.Client.ClientSearchStore, searchCriteria: Data.Client.SearchClientInput ) => clientSearchStoreMutations.updateSearchCriteria(state , searchCriteria  ) ,
updateSearchResultList : (state: Data.Client.ClientSearchStore, searchResultList: Data.Client.SearchClientOutput[] ) => clientSearchStoreMutations.updateSearchResultList(state , searchResultList  ) 
};

const storeClientSearchStore = {
  namespaced: true,
  state: ClientSearchStoreInit.initState(),
  mutations : ClientSearchStoreMutationTree,
  getters: ClientSearchStoreGetterTree
};

const ClientSearchStoreSpace = namespace('Client/ClientSearchStore');
const ClientSearchStoreGetter = {
    searchCriteria : ClientSearchStoreSpace.Getter('searchCriteria') ,
searchResultList : ClientSearchStoreSpace.Getter('searchResultList') 
};

class ClientSearchStoreMutationAPI {

  public static UPDATE_SEARCH_CRITERIA(searchCriteria: Data.Client.SearchClientInput ): void {
    store.commit('Client/ClientSearchStore/updateSearchCriteria' , searchCriteria );
  }


  public static UPDATE_SEARCH_RESULT_LIST(searchResultList: Data.Client.SearchClientOutput[] ): void {
    store.commit('Client/ClientSearchStore/updateSearchResultList' , searchResultList );
  }

}




export const ClientStoreInfo = {
     namespaced: true,
     modules: {
      ClientSearchStore: storeClientSearchStore
    }
};


export const ClientGetter = {
ClientSearchStore : ClientSearchStoreGetter
};

export const ClientMutation = {
ClientSearchStore : ClientSearchStoreMutationAPI
};

