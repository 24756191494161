
import Vuex, { GetterTree, MutationTree } from 'vuex';
import {namespace} from 'vuex-class';
import * as Data from './../data';
// tslint:disable


import {InsightStoreInit} from './../../src/section/partnerapp/store/insight/InsightStoreGettersCode';
import {InsightStoreGettersCode} from './../../src/section/partnerapp/store/insight/InsightStoreGettersCode';
import {InsightStoreMutationsCode} from './../../src/section/partnerapp/store/insight/InsightStoreMutationsCode';
import {InsightStoreGetters} from './insight-store-support';
import {InsightStoreMutations} from './insight-store-support';


import store from './../store';


const insightStoreGetters: InsightStoreGetters = new InsightStoreGettersCode();
const InsightStoreGetterTree: GetterTree<Data.Insight.InsightStore, any> = {
  demographyOutput : (state: Data.Insight.InsightStore) => insightStoreGetters.demographyOutput(state),
partnerClientConversionFunnelOutput : (state: Data.Insight.InsightStore) => insightStoreGetters.partnerClientConversionFunnelOutput(state),
dailyFunnelOutput : (state: Data.Insight.InsightStore) => insightStoreGetters.dailyFunnelOutput(state),
monthlyFunnelOutput : (state: Data.Insight.InsightStore) => insightStoreGetters.monthlyFunnelOutput(state),
conversionSummaryOutput : (state: Data.Insight.InsightStore) => insightStoreGetters.conversionSummaryOutput(state),
searchCriteria : (state: Data.Insight.InsightStore) => insightStoreGetters.searchCriteria(state),
searchResultList : (state: Data.Insight.InsightStore) => insightStoreGetters.searchResultList(state)
};

const insightStoreMutations: InsightStoreMutations = new InsightStoreMutationsCode();
const InsightStoreMutationTree: MutationTree<Data.Insight.InsightStore> = {
  updateDemographyOutput : (state: Data.Insight.InsightStore, demographyOutput: Data.Insight.DemographyOutput[] ) => insightStoreMutations.updateDemographyOutput(state , demographyOutput  ) ,
updatePartnerClientConversionFunnelOutput : (state: Data.Insight.InsightStore, partnerClientConversionFunnelOutput: Data.Insight.PartnerClientConversionFunnelOutput ) => insightStoreMutations.updatePartnerClientConversionFunnelOutput(state , partnerClientConversionFunnelOutput  ) ,
updateDailyFunnelOutput : (state: Data.Insight.InsightStore, dailyFunnelOutput: Data.Insight.DailyFunnelOutput[] ) => insightStoreMutations.updateDailyFunnelOutput(state , dailyFunnelOutput  ) ,
updateMonthlyFunnelOutput : (state: Data.Insight.InsightStore, monthlyFunnelOutput: Data.Insight.MonthlyFunnelOutput[] ) => insightStoreMutations.updateMonthlyFunnelOutput(state , monthlyFunnelOutput  ) ,
updateConversionSummaryOutput : (state: Data.Insight.InsightStore, conversionSummaryOutput: Data.Insight.ConversionSummaryOutput ) => insightStoreMutations.updateConversionSummaryOutput(state , conversionSummaryOutput  ) ,
updateSearchCriteria : (state: Data.Insight.InsightStore, searchCriteria: Data.Insight.SearchClientFileInput ) => insightStoreMutations.updateSearchCriteria(state , searchCriteria  ) ,
updateSearchResultList : (state: Data.Insight.InsightStore, searchResultList: Data.Insight.SearchClientFileOutput ) => insightStoreMutations.updateSearchResultList(state , searchResultList  ) 
};

const storeInsightStore = {
  namespaced: true,
  state: InsightStoreInit.initState(),
  mutations : InsightStoreMutationTree,
  getters: InsightStoreGetterTree
};

const InsightStoreSpace = namespace('Insight/InsightStore');
const InsightStoreGetter = {
    demographyOutput : InsightStoreSpace.Getter('demographyOutput') ,
partnerClientConversionFunnelOutput : InsightStoreSpace.Getter('partnerClientConversionFunnelOutput') ,
dailyFunnelOutput : InsightStoreSpace.Getter('dailyFunnelOutput') ,
monthlyFunnelOutput : InsightStoreSpace.Getter('monthlyFunnelOutput') ,
conversionSummaryOutput : InsightStoreSpace.Getter('conversionSummaryOutput') ,
searchCriteria : InsightStoreSpace.Getter('searchCriteria') ,
searchResultList : InsightStoreSpace.Getter('searchResultList') 
};

class InsightStoreMutationAPI {

  public static UPDATE_DEMOGRAPHY_OUTPUT(demographyOutput: Data.Insight.DemographyOutput[] ): void {
    store.commit('Insight/InsightStore/updateDemographyOutput' , demographyOutput );
  }


  public static UPDATE_PARTNER_CLIENT_CONVERSION_FUNNEL_OUTPUT(partnerClientConversionFunnelOutput: Data.Insight.PartnerClientConversionFunnelOutput ): void {
    store.commit('Insight/InsightStore/updatePartnerClientConversionFunnelOutput' , partnerClientConversionFunnelOutput );
  }


  public static UPDATE_DAILY_FUNNEL_OUTPUT(dailyFunnelOutput: Data.Insight.DailyFunnelOutput[] ): void {
    store.commit('Insight/InsightStore/updateDailyFunnelOutput' , dailyFunnelOutput );
  }


  public static UPDATE_MONTHLY_FUNNEL_OUTPUT(monthlyFunnelOutput: Data.Insight.MonthlyFunnelOutput[] ): void {
    store.commit('Insight/InsightStore/updateMonthlyFunnelOutput' , monthlyFunnelOutput );
  }


  public static UPDATE_CONVERSION_SUMMARY_OUTPUT(conversionSummaryOutput: Data.Insight.ConversionSummaryOutput ): void {
    store.commit('Insight/InsightStore/updateConversionSummaryOutput' , conversionSummaryOutput );
  }


  public static UPDATE_SEARCH_CRITERIA(searchCriteria: Data.Insight.SearchClientFileInput ): void {
    store.commit('Insight/InsightStore/updateSearchCriteria' , searchCriteria );
  }


  public static UPDATE_SEARCH_RESULT_LIST(searchResultList: Data.Insight.SearchClientFileOutput ): void {
    store.commit('Insight/InsightStore/updateSearchResultList' , searchResultList );
  }

}




export const InsightStoreInfo = {
     namespaced: true,
     modules: {
      InsightStore: storeInsightStore
    }
};


export const InsightGetter = {
InsightStore : InsightStoreGetter
};

export const InsightMutation = {
InsightStore : InsightStoreMutationAPI
};

