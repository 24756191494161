<template>
  <v-navigation-drawer v-model="drawer" absolute temporary style="z-index: 999">
    <v-list dense>
      <div class="list-item">
        <div
          v-for="item in items"
          :key="item.title"
          @click="takeAction(item.routerName)"
          v-bind:class="{
            'list-item-active': currentRouterName === item.routerName,
          }"
        >
          {{ item.title }}
        </div>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import store, * as Store from '@/../src-gen/store';
import * as Data from '@/../src-gen/data';
// import * as ServerData from '@/../src-gen/server-data';
// import * as Action from '@/../src-gen/action';

@Component({
  components: {},
})
export default class SideNav extends Vue {
  @Store.Getter.Partner.ApplicantDetails.PartnerInfo
  PartnerInfo: Data.Partner.PartnerSummary;
  @Prop({ default: false })
  drawer: boolean;
  // items: any = [
  //   { title: "Dashboard", routerName: "Partner.Dashboard" },
  //   { title: "Profile", routerName: "Partner.PartnerProfile" },
  //   {
  //     title: "Partner Certificate",
  //     routerName: "Partner.PartnerCertificate",
  //   },
  // ];
  get items() {
    const item = [
      { title: 'Dashboard', routerName: 'Partner.Dashboard' },
      { title: 'Profile', routerName: 'Partner.PartnerProfile' },
    ];
    const hasCertificate = this.PartnerInfo?.documentList.some(
      (el) => el.documentType.id === 'CERTIFICATE'
    );
    if (hasCertificate) {
      item.push({
        title: 'Partner Certificate',
        routerName: 'Partner.PartnerCertificate',
      });
    }
    return item;
  }
  @Watch('drawer')
  onDrawerChange() {
    this.$emit('closeSideNav', this.drawer);
  }
  get currentRouterName() {
    return this.$route.name || '';
  }

  takeAction(routerName: string) {
    this.$router.push({ name: routerName });
  }
}
</script>

<style lang="scss" scoped>
.list-item {
  margin: 0 25px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  > div {
    width: 100%;
    padding: 15px 20px;
    background: #eff0f3;
    border-radius: 0 50px 50px 0;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
  }
  .list-item-active {
    background: #dee5f8;
  }
}
</style>
