import FColumnCellMDP from '../FColumnCellMDP';
export default class FCellBooleanCheckMDP implements FColumnCellMDP {
  constructor(props: {}) {}
  componentName: string = 'FCellBooleanCheck';
  getMetaData(): object {
    return {
      componentName: this.componentName,
      props: {},
    };
  }
}
