
import Vuex, { GetterTree, MutationTree } from 'vuex';
import {namespace} from 'vuex-class';
import * as Data from './../data';
// tslint:disable


import {GenericStoreInit} from './../../src/section/affiliateapp/store/spine/GenericStoreGettersCode';
import {GenericStoreGettersCode} from './../../src/section/affiliateapp/store/spine/GenericStoreGettersCode';
import {GenericStoreMutationsCode} from './../../src/section/affiliateapp/store/spine/GenericStoreMutationsCode';
import {GenericStoreGetters} from './spine-store-support';
import {GenericStoreMutations} from './spine-store-support';


import {RouterStoreInit} from './../../src/section/affiliateapp/store/spine/RouterStoreGettersCode';
import {RouterStoreGettersCode} from './../../src/section/affiliateapp/store/spine/RouterStoreGettersCode';
import {RouterStoreMutationsCode} from './../../src/section/affiliateapp/store/spine/RouterStoreMutationsCode';
import {RouterStoreGetters} from './spine-store-support';
import {RouterStoreMutations} from './spine-store-support';


import store from './../store';


const genericStoreGetters: GenericStoreGetters = new GenericStoreGettersCode();
const GenericStoreGetterTree: GetterTree<Data.Spine.GenericStore, any> = {
  confirmationAlertInfo : (state: Data.Spine.GenericStore) => genericStoreGetters.confirmationAlertInfo(state)
};

const genericStoreMutations: GenericStoreMutations = new GenericStoreMutationsCode();
const GenericStoreMutationTree: MutationTree<Data.Spine.GenericStore> = {
  showConfirmation : (state: Data.Spine.GenericStore, confirmationAlertInfo: Data.Spine.ConfirmationAlertInfo ) => genericStoreMutations.showConfirmation(state , confirmationAlertInfo  ) ,
hideConfirmation : (state: Data.Spine.GenericStore ) => genericStoreMutations.hideConfirmation(state   ) 
};

const storeGenericStore = {
  namespaced: true,
  state: GenericStoreInit.initState(),
  mutations : GenericStoreMutationTree,
  getters: GenericStoreGetterTree
};

const GenericStoreSpace = namespace('Spine/GenericStore');
const GenericStoreGetter = {
    confirmationAlertInfo : GenericStoreSpace.Getter('confirmationAlertInfo') 
};

class GenericStoreMutationAPI {

  public static SHOW_CONFIRMATION(confirmationAlertInfo: Data.Spine.ConfirmationAlertInfo ): void {
    store.commit('Spine/GenericStore/showConfirmation' , confirmationAlertInfo );
  }


  public static HIDE_CONFIRMATION( ): void {
    store.commit('Spine/GenericStore/hideConfirmation'  );
  }

}



const routerStoreGetters: RouterStoreGetters = new RouterStoreGettersCode();
const RouterStoreGetterTree: GetterTree<Data.Spine.RouterStore, any> = {
  flattenedRouterList : (state: Data.Spine.RouterStore) => routerStoreGetters.flattenedRouterList(state)
};

const routerStoreMutations: RouterStoreMutations = new RouterStoreMutationsCode();
const RouterStoreMutationTree: MutationTree<Data.Spine.RouterStore> = {
  updateFlattenedRouterList : (state: Data.Spine.RouterStore ) => routerStoreMutations.updateFlattenedRouterList(state   ) 
};

const storeRouterStore = {
  namespaced: true,
  state: RouterStoreInit.initState(),
  mutations : RouterStoreMutationTree,
  getters: RouterStoreGetterTree
};

const RouterStoreSpace = namespace('Spine/RouterStore');
const RouterStoreGetter = {
    flattenedRouterList : RouterStoreSpace.Getter('flattenedRouterList') 
};

class RouterStoreMutationAPI {

  public static UPDATE_FLATTENED_ROUTER_LIST( ): void {
    store.commit('Spine/RouterStore/updateFlattenedRouterList'  );
  }

}




export const SpineStoreInfo = {
     namespaced: true,
     modules: {
      GenericStore: storeGenericStore,
RouterStore: storeRouterStore
    }
};


export const SpineGetter = {
GenericStore : GenericStoreGetter, RouterStore : RouterStoreGetter
};

export const SpineMutation = {
GenericStore : GenericStoreMutationAPI, RouterStore : RouterStoreMutationAPI
};

