
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class PartnerReaderService {

   public static findPartnerDetails(
           axiosInstance: AxiosInstance, input: ServerData.PartnerWebReader.FindPartnerDetails$Input,
           f: (output: ServerData.PartnerData$PartnerSummary|undefined) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'partnerprogramapi/partner/find-partner-details',
            {params : input.toJson()}
        ).then((response) => (response.data != null) ? ServerData.PartnerData$PartnerSummary.fromJson(response.data) : undefined)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getReferralOrgCode(
           axiosInstance: AxiosInstance, input: ServerData.PartnerWebReader.GetReferralOrgCode$Input,
           f: (output: ServerData.PartnerWebReader.GetReferralOrgCode$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'partnerprogramapi/partner/get-referral-org-code',
            {params : input.toJson()}
        ).then((response) => ServerData.PartnerWebReader.GetReferralOrgCode$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getOnboardedFileList(
           axiosInstance: AxiosInstance, input: ServerData.PartnerWebReader.GetOnboardedFileList$Input,
           f: (output: ServerData.PartnerData$ClientFileSummary[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'partnerprogramapi/partner/get-onboarded-file-list',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.PartnerData$ClientFileSummary.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getUnverifiedFileList(
           axiosInstance: AxiosInstance, input: ServerData.PartnerWebReader.GetUnverifiedFileList$Input,
           f: (output: ServerData.PartnerData$PartnerClientDetails[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'partnerprogramapi/partner/get-unverified-file-list',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.PartnerData$PartnerClientDetails.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getPartnerCertificate(
           axiosInstance: AxiosInstance, input: ServerData.PartnerWebReader.GetPartnerCertificate$Input,
           f: (output: ServerData.PartnerData$PartnerDocument) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'partnerprogramapi/partner/get-partner-certificate',
            {params : input.toJson()}
        ).then((response) => ServerData.PartnerData$PartnerDocument.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getOnboardedClientDetails(
           axiosInstance: AxiosInstance, input: ServerData.PartnerWebReader.GetOnboardedClientDetails$Input,
           f: (output: ServerData.PartnerData$OnboardedClientFileDetails) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'partnerprogramapi/partner/get-onboarded-client-details',
            {params : input.toJson()}
        ).then((response) => ServerData.PartnerData$OnboardedClientFileDetails.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getNotEnrolledPartnerClientList(
           axiosInstance: AxiosInstance, input: ServerData.PartnerWebReader.GetNotEnrolledPartnerClientList$Input,
           f: (output: ServerData.PartnerWebReader.GetNotEnrolledPartnerClientList$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'partnerprogramapi/partner/get-not-enrolled-partner-client-list',
            {params : input.toJson()}
        ).then((response) => ServerData.PartnerWebReader.GetNotEnrolledPartnerClientList$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


}

