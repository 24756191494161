<template>
  <div>
    {{ number }}
    <!-- <div>{{ numberFormat(animatedNumber) }}</div> -->
  </div>
</template>

<script lang="ts">
import Helper from "@/util/Helper";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class AnimatedNumber extends Vue {
  @Prop({ default: 0 }) number!: number;

  animatedNumber: number = 0;
  animationFrame: number | null = null;

  // @Watch("number")
  // onNumberChanged() {
  //   if (this.animationFrame === null) {
  //     this.animateNumber();
  //   }
  // }

  // animateNumber() {
  //   if (this.number === this.animatedNumber) {
  //     this.animationFrame = null;
  //     return;
  //   }

  //   const change = (this.number - this.animatedNumber) / 10;
  //   this.animatedNumber =
  //     this.animatedNumber +
  //     (change >= 0 ? Math.ceil(change) : Math.floor(change));

  //   if (Math.abs(this.number - this.animatedNumber) <= 1) {
  //     this.animatedNumber = this.number;
  //   } else {
  //     this.animationFrame = requestAnimationFrame(this.animateNumber);
  //   }
  // }

  mounted() {
    // this.animateNumber();
  }

  beforeDestroy() {
    if (this.animationFrame !== null) {
      cancelAnimationFrame(this.animationFrame);
    }
  }

  numberFormat(value: number) {
    return Helper.numberIND(value);
  }
}
</script>
