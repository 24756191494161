
import FBtnMDP, { BtnType } from '@/components/FBtnMDP';
import FFormMDP, { FFormChildMDP } from '@/components/form/FFormMDP';
import FSelectDateFieldMDP from '@/components/form/field/FDateSelectFieldMDP';
import FDateSelectRangeFieldMDP from '@/components/form/field/FDateSelectRangeFieldMDP';
import FPhoneFieldMDP from '@/components/form/field/FPhoneFieldMDP';

export default class CSIDateRangeSearchFFormMDP extends FFormMDP {
    childMDP = new FFormChildMDP();
    taskRoot: any;
    parent: any;
    constructor({ taskRoot, parent }: { taskRoot: any; parent: any }) {
        super({
            myRefName: 'csiDateRangeSearchFFormRef',
        });
        this.parent = parent;
        this.taskRoot = taskRoot;

        this.addField(
            new FDateSelectRangeFieldMDP({
                parentMDP: this.childMDP,
                dataSelectorKey: "dateRange",
                label: "Creation Date Range",
                boundaryClass: "col-6",
                mandatory: true,
                // onSelect: () => this.parent.handleSearchOPRList(),
                futureDaysDisabled: true,
                rules: "date_difference:29"
            })
        )
            .addAction(
                new FBtnMDP({
                    label: 'Reset to MTD',
                    btnType: BtnType.TEXT,
                    onClick: () => {
                        this.resetToMTD();
                    },
                })
            )
            .addAction(
                new FBtnMDP({
                    label: 'Submit',
                    onClick: () => this.taskRoot.getPartnerClientCount(),
                })
            );
    }

    getMyRef() {
        return this.taskRoot.$refs[this.myRefName];
    }


    resetToMTD() {
        this.taskRoot.handleResetToMTDButtonClick();
    }
}
