<template>
  <div class="PartnerProfile">
    <div class="card-container">
      <div v-bind:class="{ card: true, flexColumn: edit }">
        <section>
          <div class="initial">
            {{ initialName }}
            <!-- <img
              src="https://randomuser.me/api/portraits/men/78.jpg"
              class="image-card"
            />
            <div class="edit-btn" v-if="edit">Edit</div> -->
          </div>
          <div v-if="!edit">
            <p class="name">{{ PartnerInfo.fullname }}</p>
            <p class="partner">FREED Authorised Partner</p>
            <p class="partner-lives">Debt Free Lives Created</p>
            <span>{{ totalSale }}</span>
          </div>
          <div class="form" v-else>
            <v-form ref="form">
              <v-text-field
                label="Name *"
                outlined
                dense
                v-model="changeDetailsInput.firstName"
                :rules="firstNameRules"
              ></v-text-field>
              <v-text-field
                label="Email Id*"
                outlined
                dense
                v-model="changeDetailsInput.emailId"
                :rules="emailRules"
              ></v-text-field>
              <v-text-field
                label="Phone Number*"
                outlined
                dense
                :value="changeDetailsInput.mobile"
                :rules="mobileRules"
              ></v-text-field>
              <!-- <v-text-field label="Title*" outlined dense></v-text-field> -->
              <!-- <v-text-field label="Password*" outlined dense></v-text-field>
              <v-text-field
                label="Confirm Password*"
                outlined
                dense
              ></v-text-field> -->
              * The Field Can be changed by contacting support
            </v-form>
          </div>
        </section>
        <div class="border-design">
          <div></div>
          <section></section>
        </div>
      </div>
      <div class="btn-container" v-if="!edit">
        <v-btn color="primary" small @click="edit = true">Edit</v-btn>
        <!-- <v-btn color="secondary" small> Download</v-btn> -->
      </div>
      <div class="btn-container-submit" v-else>
        <v-btn color="primary" small @click="onSubmit()">save</v-btn>
        <v-btn color="secondary" small @click="edit = false"> cancel</v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import store, * as Store from '@/../src-gen/store';
import * as Data from '@/../src-gen/data';
// import * as ServerData from '@/../src-gen/server-data';
import * as Action from '@/../src-gen/action';
import Helper from '@/util/Helper';

@Component({
  components: {},
})
export default class PartnerProfile extends Vue {
  onboardedFileInput: Data.Partner.OnboardedFileInput =
    new Data.Partner.OnboardedFileInput();

  totalSale: number = 0;
  emailRules = [
    (v: any) => !!v.trim() || 'Email is required',
    (v: any) => v.indexOf(' ') === -1 || 'Provide a valid Email',
    (v: any) => /.+@.+\..+/.test(v) || 'Provide a valid Email',
  ];
  firstNameRules = [(v: any) => !!v.trim() || 'Name is required'];
  mobileRules = [
    (v: any) => !!v || 'Mobile Number is required',
    (v: any) => {
      const value = v?.replace(' ', '');
      return (!!value && value.length === 10) || 'Please Enter valid  Number';
    },
  ];
  passwordRules = [(v: any) => !!v || !!v.trim() || 'Password  is required'];
  confirmPasswordRules = [
    (v: any) => !!v || !!v.trim() || 'Confirm Password  is required',
    // (v: any) =>
    //   v === this.changeDetailsInput.password || "Confirm Password  doesn't match",
  ];
  @Store.Getter.Partner.ApplicantDetails.PartnerInfo
  PartnerInfo: Data.Partner.PartnerSummary;
  edit: boolean = false;
  changeDetailsInput: Data.Partner.ChangeDetailsInput =
    new Data.Partner.ChangeDetailsInput();

  mounted() {
    this.getFileList();
  }

  get initialName() {
    return this.PartnerInfo?.fullname?.[0] || 'A';
  }
  @Watch('edit')
  onChangeOfDetails() {
    this.changeDetailsInput.firstName = this.PartnerInfo?.fullname || '';
    this.changeDetailsInput.mobile = this.PartnerInfo?.mobile || '';
    this.changeDetailsInput.emailId = this.PartnerInfo?.emailId || '';
  }
  get partnerId() {
    return localStorage.getItem('partnerId') || '';
  }
  getFileList() {
    this.onboardedFileInput.partnerId = this.partnerId;
    this.onboardedFileInput.fromDate = Helper.dateMTDWithCurrent().startOfYear;
    this.onboardedFileInput.toDate = Helper.dateMTDWithCurrent().currentDate;
    Action.Partner.GetOnboardedFileList.execute(
      this.onboardedFileInput,
      (output) => {
        this.totalSale = output.length;
      }
    );
  }
  onSubmit() {
    const validate = (this.$refs.form as any).validate();
    if (validate) {
      this.changeDetailsInput.mobile = this.PartnerInfo?.mobile;
      Action.Partner.ChangeDetails.execute(
        this.changeDetailsInput,
        (output) => {
          this.getPartnerDetails();
        }
      );
    }
  }
  getPartnerDetails() {
    Action.Partner.FindPartnerDetails.execute1(this.partnerId, (output) => {
      this.edit = false;
    });
  }
}
</script>

<style lang="scss" scoped>
.PartnerProfile {
  padding: 30px;
}
.image-card {
  width: 100%;
  height: 100%;
}
.flexColumn {
  section {
    flex-direction: column;
  }
}
.initial {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  .edit-btn {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.card-container {
  max-width: 450px;
}
.card {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  position: relative;

  color: #243872;
  border: 1px solid #979fad;
  border-radius: 15px;
  overflow: hidden;
  padding-bottom: 35px;
  > section {
    padding: 10px 20px;
    display: flex;
    align-items: flex-start;
    gap: 15px;
  }
  p {
    margin: 0;
    padding: 0;
  }
  .name {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  .partner {
    &,
    &-lives {
      font-size: 12px;
      margin-bottom: 5px;
    }
    & {
      margin-bottom: 25px;
    }
  }
  span {
    display: block;
    width: 100%;
    text-align: center;
    color: #f69562;
    font-weight: 600;
  }
}
.border-design {
  position: absolute;
  bottom: -10px;
  left: -10px;
  width: 100%;
  section {
    width: 150%;
    height: 20px;
    background: #01416f;

    border-radius: 15px;
  }
  div {
    width: 50%;
    height: 10px;
    background: #f36f22;
    border-radius: 0 15px 0 0;
  }
}
.btn-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.btn-container-submit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 15px;
}
</style>
>
