import FDataTableMDP, { ActionType } from '@/components/table/FDataTableMDP';
import FCellAmountPaymentMDP from '@/components/table/cell/FCellAmountPaymentMDP';
import FCellBooleanCheckMDP from '@/components/table/cell/FCellBooleanCheckMDP';
import FCellBooleanListMDP from '@/components/table/cell/FCellBooleanListMDP';
import FCellBooleanMDP from '@/components/table/cell/FCellBooleanMDP';
import FCellBtnMDP from '@/components/table/cell/FCellBtnMDP';
import FCellDateMDP from '@/components/table/cell/FCellDateMDP';

import FCellDateTimeMDP from '@/components/table/cell/FCellDateTimeMDP';
import FCellEmailMDP from '@/components/table/cell/FCellEmailMDP';
import FCellPhoneMDP from '@/components/table/cell/FCellPhoneMDP';
import Helper from '@/util/Helper';

export default class CFSFDataTableMDP extends FDataTableMDP {
    parent: any;
    constructor(props: { parent: any }) {
        super({
            myRefName: 'cfsFDataTableMDPRef',
            enableSearch: true,
            title: 'Conversion File',
            enablePagination: false,
            hideDefaultFooter: true,
        });
        this.parent = props.parent;
        this.addColumn({
            label: 'Created On',
            dataSelectorKey: 'createdOn',
            columnCellMDP: new FCellDateMDP(),
        })
            .addColumn({
                label: 'File ID',
                dataSelectorKey: 'clientFileNumber',
                enableCopy: true,
                columnCellMDP: new FCellBtnMDP({
                    color: 'secondary',
                    onClick: (item) => {
                        this.parent.gotoClientFileInfo(item);
                    },
                }),
            })
            .addColumn({
                label: 'Name',
                dataSelectorKey: 'fullName',
                hidden: true,
            })
            .addColumn({
                label: 'Mobile',
                dataSelectorKey: 'mobile',
                columnCellMDP: new FCellPhoneMDP(),
            })
            .addColumn({
                label: 'E-mail',
                dataSelectorKey: 'email',
                columnCellMDP: new FCellEmailMDP(),
                hidden: true,
            })
            .addColumn({
                label: 'City',
                dataSelectorKey: 'city',
                hidden: true,
            })
            .addColumn({
                label: 'Credit Score',
                dataSelectorKey: 'creditScore',
            })
            .addColumn({
                label: 'WAD',
                dataSelectorKey: 'wad',
            })
            .addCurrencyColumn({
                label: 'Debt',
                dataSelectorKey: 'totalCurrentOutstanding',
            })
            .addColumn({
                label: 'Tenor',
                dataSelectorKey: 'tenor',
                align: 'center',
                hidden: true
            })
            .addColumn({
                dataSelectorKey: 'isAgreementDone',
                label: 'Agreement',
                columnCellMDP: new FCellBooleanCheckMDP({}),
                align: 'center',
                hidden: true
            })
            .addColumn({
                label: 'Onboarded On',
                dataSelectorKey: 'onBoardingDate',
                columnCellMDP: new FCellDateMDP(),
                align: "center"
            })
            .addColumn({
                dataSelectorKey: 'onBoardingFeeAmount',
                label: 'On BoardingFee',
                columnCellMDP: new FCellAmountPaymentMDP({
                    dataSelectorKeyStatus: 'onBoardingFeePaid',
                    paymentDataSelectorKey: 'onBoardingFeeAmount',
                }),
                align: 'center',
            })
            .addColumn({
                dataSelectorKey: 'isEmandateDone',
                label: 'Emandate',
                columnCellMDP: new FCellBooleanCheckMDP({}),
                align: 'center',
            })
            .addColumn({
                dataSelectorKey: 'msfDraftAmount',
                label: 'MSF',
                columnCellMDP: new FCellAmountPaymentMDP({
                    dataSelectorKeyStatus: 'firstMSFPaid',
                    paymentDataSelectorKey: 'msfDraftAmount',
                }),
                align: 'center',
            })
            .addClientFileStatusColumn({
                dataSelectorKey: 'clientFileStatus',
            })
            .addAction({
                label: 'Download Excel',
                onClick: () => this.handleDownloadExcel(),
                type: ActionType.OTHERS,
                noSelect: true,
            });
    }

    handleDownloadExcel() {
        return new Promise((res) => {
            this.parent.showDownloadFrom();
            res(true);
        });
    }
}
