<template>
  <span >
    <v-avatar v-if="selectedColor" :color="selectedColor" size="20" class="mr-4"> </v-avatar>
    <!-- <v-chip v-if="selectedColor" color="selectedColor"></v-chip> -->
    <span v-else>--</span>
  </span>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { GapColor } from "../../form/field/FSelectGapColorFieldMDP";
import ModelVue from "../../ModelVue";

@Component({
  components: {
  },
})
export default class FCellColor extends ModelVue {
 @Prop()
 dataSelectorKey: string;
gapColorList: GapColor[] = [{
    colorCode: "red",
    colorIndex: 40,
    colorName: "Red"
},
{
    colorCode: "yellow",
    colorIndex: 30,
    colorName: "Yellow"
},
{
    colorCode: "#7F00FF",
    colorIndex: 20,
    colorName: "Violet"
},
{
    colorCode: "green",
    colorIndex: 10,
    colorName: "Green"
}
]

get selectedColor() {
    const colorIndex = this.gapColorList.findIndex(item => item.colorIndex === this.selectModel(this.modelValue,this.dataSelectorKey))
    if(colorIndex !== -1) {
        return this.gapColorList[colorIndex].colorCode
    } else {
        return null
    } 
}
}
</script>
