import FBtnMDP, { BtnType } from '@/components/FBtnMDP';
import FFormSubmitBtnMDP from '@/components/FFormSubmitBtnMDP';
import FFormMDP, { FFormChildMDP } from '@/components/form/FFormMDP';
import FSelectDateFieldMDP from '@/components/form/field/FDateSelectFieldMDP';

export default class ExportDownloadDateSearchFFormMDP extends FFormMDP {
  childMDP = new FFormChildMDP();
  taskRoot: any;
  constructor({ taskRoot }: { taskRoot: any }) {
    super({
      myRefName: 'exportDownloadDateSearchRef',
    });
    this.taskRoot = taskRoot;

    this.addField(
      new FSelectDateFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: 'fromDate',
        label: 'From Date',
        futureDaysDisabled: false,
        mandatory: true,
      })
    )
      .addField(
        new FSelectDateFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: 'toDate',
          label: 'To Date',
          futureDaysDisabled: false,
          mandatory: true,
        })
      )
      .addAction(
        new FBtnMDP({
          label: 'Cancel',
          btnType: BtnType.TEXT,
          onClick: () => this.taskRoot.closeCancelFlow(),
        })
      )
      .addAction(
        new FFormSubmitBtnMDP({
          label: 'Download ',
          onClick: this.taskRoot.exportDownloadClick(),
        })
      );
  }
}
