<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-badge
        top
        color="secondary"
        overlap
        offset-x="14"
        offset-y="14"
        class="ms-4"
        dot
      > -->
      <v-avatar size="40px" v-bind="attrs" v-on="on">
        <!-- <v-img :src="require('@/assets/images/avatars/1.png')"></v-img> -->
        <v-icon>mdi-account-circle-outline</v-icon>
      </v-avatar>
      <!-- </v-badge> -->
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar size="40px">
            <!-- <v-img :src="require('@/assets/images/avatars/1.png')"></v-img> -->
            <v-icon>mdi-account-circle</v-icon>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align: middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ loggedInUser.userName }}
            <!-- John Doe -->
          </span>
          <small class="text--disabled text-capitalize">
            {{ loggedInUser.fullName }}</small
          >
        </div>
      </div>

      <!-- <v-divider></v-divider> -->

      <v-list-item @click="goto('PartnerAffiliate.User.ChangePassword')" link>
        <v-list-item-icon class="me-2">
          <v-icon small> mdi-key-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Change Password</v-list-item-title>
        </v-list-item-content>
      </v-list-item>



      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click="logout" dense>
        <v-list-item-icon class="me-2">
          <v-icon size="22"> mdi-logout-variant </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
import * as Action from "@/../src-gen/action";
@Component
export default class AppBarUserMenu extends Vue {
  @Store.Getter.Login.LoginDetails.loggedInUser
  loggedInUser: Data.Login.LoginDetails;
  
  @Store.Getter.Login.LoginDetails.roleList
  roleList: string[];
  buildInfoDialog: boolean = false;

  buildInfoOptionList = [
    {
      label: "Repository:",
      key: "repository_name",
      icon: "mdi-source-repository"
    },
    {
      label: "Branch:",
      key: "branch_name",
      icon: "mdi-source-branch"
    },
    {
      label: "Built by:",
      key: "deployed_by",
      icon: "mdi-account-circle-outline"
    },
    {
      label: "Built on:",
      key: "deployed_on",
      icon: "mdi-clipboard-text-clock-outline"
    }
  ]

  mounted() {
  }

  logout() {
     try {
     } catch {
      //  console.error('Something went wrong')
    }

    setTimeout(() => {
        window.localStorage.removeItem("userName");
        window.localStorage.removeItem("auth_token");
        this.$router.push({ name: "Login.AffiliateLogin" });
      }, 2000); 
     
     
  }

  goto(routerName: string) {
    this.$router.push({ name: routerName });
  }

  private logoutByAmeyo() {
    // AmeyoService.logout();
  }
}
</script>
