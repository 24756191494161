import FBtnMDP from "@/components/FBtnMDP";
import FEmailFieldMDP from "@/components/form/field/FEmailFieldMDP";
import FFormMDP, { FFormChildMDP } from "@/components/form/FFormMDP";
import FTextFieldMDP from "@/components/form/field/FTextFieldMDP";
import FPhoneFieldMDP from "@/components/form/field/FPhoneFieldMDP";
import FFormSubmitBtnMDP from "@/components/FFormSubmitBtnMDP";

export default class RegisterMyCFFFormMDP extends FFormMDP {
  childMDP = new FFormChildMDP();
  root: any;
  // parent: any;
  constructor({ root,hideCancel=false }: { root: any;hideCancel?: boolean; }) {
    super({
      myRefName: "createMYCFFFormRef",
    });
    this.root = root;
    // this.parent = parent;

    this.addField(
      new FTextFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: "firstName",
        label: "First Name",
        boundaryClass: "col-4",
        mandatory: true,
      })
    )
      .addField(
        new FTextFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "middleName",
          label: "Middle Name",
          boundaryClass: "col-4",
        })
      )
      .addField(
        new FTextFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "lastName",
          label: "Last Name",
          boundaryClass: "col-4",
        })
      )
      .addField(
        new FEmailFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "emailId",
          label: "Email",
          boundaryClass: "col-4",
          mandatory: true,
        })
      )
      .addField(
        new FPhoneFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "mobile",
          label: "Mobile",
          boundaryClass: "col-4",
          mandatory: true,
        })
      )
      // .addField(
      //   new FPhoneFieldMDP({
      //     parentMDP: this.childMDP,
      //     dataSelectorKey: "leadSource",
      //     label: "Lead Source",
      //     boundaryClass: "col-4",
      //     disabled: true,
      //   })
      // )
      .addAction(
        new FBtnMDP({
          label: "Cancel",
          onClick: () => this.root.handleRegisterFormCancelClick(),
          condition: !hideCancel
        })
      )
      .addAction(
        new FFormSubmitBtnMDP({
          label: "Add My Client File",
          onClick: () => this.root.registerClient(),
        })
      );
  }

  
  getMyRef(): any {
    return this.root.$refs[this.myRefName];
  }
}
