import * as Data from "@/../src-gen/data";
import * as Action from "@/../src-gen/action";
import FDataTableMDP, {
  ActionType,
} from "@/components/table/FDataTableMDP";
import FSnackbar from "@/fsnackbar";
import CFPaymentListFDataTableMDP from "../payment/CFPaymentListFDataTableMDP";

export default class FCashfreeListFDataTableMDP extends CFPaymentListFDataTableMDP {
  parent: any;
  constructor(props: { parent: any; taskRoot: any }) {
    super({
      
      parent: props.parent,
    });
    this.parent = props.parent;
    this.addAction({
        label: "Generate Cashfree",
        onClick: () => this.handleGenerateLink(),
        type: ActionType.ADD,
      })
      .addAction({
        label: `Generate Cashfree with discount(${this.parent.firstMSFDiscountPercentage}%)`,
        onClick: () => this.handleGenerateWithDiscount(),
        type: ActionType.OTHERS,
        noSelect: true,
        disabled: !this.parent.isAdmin(),
        condition: this.parent.firstMSFDiscountPercentage>0
      })
      .addAction({
        label: "Refresh",
        onClick: () => this.handleRefresh(),
        type: ActionType.REFRESH,
        noSelect: true,
      });
  }

  handleGenerateLink() {
      return new Promise((res) => {
        const draftFirstMSFPaymentInput: Data.ClientFile.DraftFirstMSFThroughCashfreeInput =
          new Data.ClientFile.DraftFirstMSFThroughCashfreeInput();
        draftFirstMSFPaymentInput.clientFileId =
          this.parent.clientFileId;
        // receiveFirstMSFPaymentInput.taskId = this.parent.taskId;
        Action.ClientFile.DraftAndPresentFirstMSFThroughCashfree.execute(
          draftFirstMSFPaymentInput,
          (output) => {
            FSnackbar.success("Successfully generated the Cashfree");
          }
        );
        res(true);
      });
  }

  handleGenerateWithDiscount() {
      return new Promise((res) => {
        const {firstMSFDiscountPercentage=0} = this.parent;
        const { msfDraftAmount = 0 } = this.parent.fiPaymentPlanInfoStore.ppCalculator;
        const discount = msfDraftAmount * firstMSFDiscountPercentage / 100;
        const msfDraftAmountAfterDiscount = Math.ceil(msfDraftAmount - discount);
        // FSnackbar.confirm({
        //   message: `Msf amount will be ${msfDraftAmountAfterDiscount} after applying ${firstMSFDiscountPercentage}% discount.Are you sure want to continue?`, onConfirm: () => {
            // const presentFirstMSFPaymentDicInput: Data.ClientFile.PresentFirstMSFWithDiscInput =
              // new Data.ClientFile.PresentFirstMSFWithDiscInput();
              // presentFirstMSFPaymentDicInput.clientFileId =
              // this.parent.clientFileId;
            // draftFirstMSFPaymentInput.msfAmount = msfDraftAmountAfterDiscount;
            // Action.ClientFile.DraftAndPresentFirstMSFThroughCashfreeWithDisc.execute(
            //   presentFirstMSFPaymentDicInput,
            //   (output) => {
            //     FSnackbar.success("Successfully generated the Cashfree");
            //   }
            // );
            res(true);
        //   }
        // })
      });
  }


  handleDetailsClick() {
    return (item: any) => {
      return new Promise(res => {
        this.parent.handleInfoClick(item);
      });
    }
  }

  handleRefresh() {
      return new Promise((res) => {
        this.getFiPaymentList()
        this.parent.getClientFileBasicInfo();
        this.parent.getOffers();
        res(true);
      });
  }

  getFiPaymentList() {
    Action.ClientFile.GetFiPaymentList.execute1(this.parent.clientFileId, (output) => {
      
    });
  }

  openAgreementLink() {
    return (item: any) => {
      console.log(item);
      if (item.agreementUrl) {
        window.open(item.agreementUrl);
      }
    };

  }

  // handleResendClick() {
  //   return (item: any) => {
  //     return new Promise((res) => {
  //       const sendAgreementInput = new Data.ClientFile.SendAgreementInput();
  //       sendAgreementInput.byEmail = true;
  //       sendAgreementInput.bySMS = false;
  //       sendAgreementInput.byWhatsapp = false;
  //       sendAgreementInput.token = item.ssaToken;
  //       Action.ClientFile.SendAgreement.execute(
  //         sendAgreementInput,
  //         (output) => {
  //           FSnackbar.success("Succesfully resend the agreement");
  //           res(true);
  //         }
  //       );
  //     });
  //   };
  // }
}
