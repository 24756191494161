<template>
  <v-card flat>
    <!-- <v-card-actions class="pa-0 ma-0">
      <f-btn
        class="mx-2"
        label="START"
        small
        outlined
        color="primary"
        :onClick="() => pullAndStartTask()"
        v-if="
          taskDetails.taskState === 'ALLOCATED' ||
          taskDetails.taskState === 'TO_BE_PULLED'
        "
      ></f-btn>
    </v-card-actions> -->
    <v-card-text class="pa-0">
      <component v-if="!loading" :is="selectedComponent"></component>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
import * as Action from "@/../src-gen/action";


import EnrollClientFileTask from "@/section/partnerapp/components/task/enrollment/enrollClientFile/EnrollClientFileTask.vue";
import Task from "@/util/Task";
import Helper from "@/util/Helper";

@Component({
  components: {
    
    EnrollClientFileTask,
    
  },
})
export default class CFTaskDetails extends Vue {
  @Store.Getter.Login.LoginDetails.userName userName: string;

  @Store.Getter.TaskList.Summary.executiveTaskDetails
  taskDetails: Data.TaskList.ExecutiveTaskDetails;

  @Store.Getter.ClientFile.ClientFileSummary.clientFileBasicInfo
  clientFileBasicInfo: Data.ClientFile.ClientFileBasicInfo;
  loading = true;

  get items() {
    return [
      {
        text: "Tasks",
        disabled: false,
        routerName: "Root.ClientFile.Workarea",
      },
      {
        text: this.taskDetails.taskName,
        disabled: false,
      },
    ];
  }


  taskId = this.$route.params.taskId;

  get fqTaskName() {
    return `${this.taskDetails.flowName}::${this.taskDetails.taskName}`;
  }

  get selectedComponent() {
    let selectedComponentTemp = Task.getTaskComponentMap().get(this.fqTaskName);
    return !selectedComponentTemp ? "GenericTask" : selectedComponentTemp;
  }

  get taskFormDisabled() {
    return !(
      this.taskDetails.taskState === "STARTED" ||
      this.taskDetails.taskState === "PARTIALLY_COMPLETED"
    );
  }

  public getExecutiveTaskDetailsHandler = (output: any) => {
    setTimeout(this.getExecutiveTaskDetails, 1000);
  };

  public getTaskDetailsAndFileSummaryWithDelayHandler = (output: any) => {
    setTimeout(() => {
      this.getExecutiveTaskDetails();
      this.findClientFileSummary();
      this.$router.push({ name: "Root.CFile.CFTask.CFActiveTasks" });
    }, 1000);
  };

  getFindClientFileSummaryWithDelay() {
    setTimeout(this.findClientFileSummary, 1000);
  }


  public handleSaveAndComplete = () => {
    //this.gotoFile();
  };

  public mounted() {
    Action.TaskList.PullTask.interested(this.getExecutiveTaskDetailsHandler);
    Action.TaskList.PullStartAndMerge.interested(
      this.getExecutiveTaskDetailsHandler
    );
    Action.TaskList.Save.interested(this.getExecutiveTaskDetailsHandler);
    Action.TaskList.Complete.interested(this.getExecutiveTaskDetailsHandler);
    Action.TaskList.SaveAndComplete.interested(
      this.getTaskDetailsAndFileSummaryWithDelayHandler
    );
    Action.TaskList.Suspend.interested(this.getExecutiveTaskDetailsHandler);
    Action.TaskList.Resume.interested(this.getExecutiveTaskDetailsHandler);
    Action.TaskList.Rescue.interested(this.getExecutiveTaskDetailsHandler);
    Action.Spine.Reassign.interested(this.getExecutiveTaskDetailsHandler);
    Action.Spine.Assign.interested(this.getExecutiveTaskDetailsHandler);
    //Commands

    Action.Spine.ReceiveFirstMSFPayment.interested(
      this.getExecutiveTaskDetailsHandler
    );

    Action.Spine.UpdateFirstMSFPaymentStatus.interested(
      this.getExecutiveTaskDetailsHandler
    );

    Action.Spine.UpdateMsfPaymentStatus.interested(
      this.getExecutiveTaskDetailsHandler
    );

    Action.Spine.ReceiveMSFPayment.interested(
      this.getExecutiveTaskDetailsHandler
    );

    Action.TaskList.Suspend.interested(this.getExecutiveTaskDetailsHandler);
    Action.TaskList.Resume.interested(this.getExecutiveTaskDetailsHandler);
    Action.TaskList.Retry.interested(this.getExecutiveTaskDetailsHandler);

    Action.TaskList.CancelFlow.interested(this.getExecutiveTaskDetailsHandler);
    Action.TaskList.CancelTask.interested(this.getExecutiveTaskDetailsHandler);

    // Action.ClientFile.Cancel.interested(
    //   this.getTaskDetailsAndFileSummaryWithDelayHandler
    // );

    this.getExecutiveTaskDetailsWithDelay();
  }

  public destroyed() {
    Action.TaskList.PullTask.notInterested(this.getExecutiveTaskDetailsHandler);
    Action.TaskList.PullStartAndMerge.notInterested(
      this.getExecutiveTaskDetailsHandler
    );
    Action.TaskList.Save.notInterested(this.getExecutiveTaskDetailsHandler);
    Action.TaskList.Complete.notInterested(this.getExecutiveTaskDetailsHandler);
    Action.TaskList.SaveAndComplete.notInterested(
      this.getTaskDetailsAndFileSummaryWithDelayHandler
    );
    Action.TaskList.Suspend.notInterested(this.getExecutiveTaskDetailsHandler);
    Action.TaskList.Resume.notInterested(this.getExecutiveTaskDetailsHandler);
    Action.TaskList.Retry.notInterested(this.getExecutiveTaskDetailsHandler);

    //Commands
    Action.Spine.ReceiveFirstMSFPayment.notInterested(
      this.getExecutiveTaskDetailsHandler
    );
    Action.Spine.UpdateFirstMSFPaymentStatus.notInterested(
      this.getExecutiveTaskDetailsHandler
    );

    Action.Spine.ReceiveMSFPayment.notInterested(
      this.getExecutiveTaskDetailsHandler
    );

    Action.Spine.UpdateMsfPaymentStatus.notInterested(
      this.getExecutiveTaskDetailsHandler
    );

    Action.TaskList.Suspend.notInterested(this.getExecutiveTaskDetailsHandler);
    Action.TaskList.Resume.notInterested(this.getExecutiveTaskDetailsHandler);

    Action.TaskList.CancelFlow.notInterested(
      this.getExecutiveTaskDetailsHandler
    );
    Action.TaskList.CancelTask.notInterested(
      this.getExecutiveTaskDetailsHandler
    );

    // Ohter Action list
    
    Action.TaskList.Rescue.notInterested(this.getExecutiveTaskDetailsHandler);
    // Action.ClientFile.Cancel.notInterested(
    //   this.getTaskDetailsAndFileSummaryWithDelayHandler
    // );
    Action.Spine.Reassign.notInterested(this.getExecutiveTaskDetailsHandler);
    Action.Spine.Assign.notInterested(this.getExecutiveTaskDetailsHandler);
  }

  getExecutiveTaskDetailsWithDelay() {
    setTimeout(this.getExecutiveTaskDetails, 1000);
  }

  // getTaskDetailsAndFileSummaryWithDelay() {
  //   setTimeout(() => {
  //     this.getExecutiveTaskDetails();
  //     this.findClientFileSummary();
  //     this.$router.push({ name: "Root.CFile.CFTask.CFActiveTasks" });
  //   }, 1000);
  // }

 
  getExecutiveTaskDetails() {
    Action.TaskList.GetExecutiveTaskDetails.execute1(
      this.$route.params.taskId,
      (output) => {
        this.loading = false;
      }
    );
  }

  pullAndStartTask() {
    Action.TaskList.PullStartAndMerge.execute1(
      this.$route.params.taskId,
      (output) => {
      }
    );
  }

  pullTask() {
    Action.TaskList.PullTask.execute2(
      this.$route.params.taskId,
      this.userName,
      (output) => {
        // this.gotoTask(item);
      }
    );
  }

  gotoFile() {
    Helper.Router.gotoFile({
      router: this.$router,
      clientFileNumber: this.$route.params.clientFileNumber,
    });
  }

  goto(routerName: string) {
    this.$router.push({
      name: routerName,
      query: {
        ...this.$route.query,
      },
    });
  }

  gotoTask(item: any) {
    const params = { ...this.$route.params, taskId: item.taskId };
    this.$router.push({
      name: "Root.ClientFile.FileTask.FileTaskDetails",
      params: params,
      query: {
        ...this.$route.query,
      },
    });
  }

  findClientFileSummary() {
    Action.ClientFile.GetClientFileSummary.execute1(
      this.clientFileBasicInfo.clientFileId,
      (output) => {}
    );
  }

}
</script>

<style></style>
