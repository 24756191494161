<template>
<v-app>
    <v-main> 
    <div class="d-flex">
        
          <div v-show="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl || $vuetify.breakpoint.md" class="image-container col-6">
          <div class="text-center">
          <img :src="require('@/assets/images/freedLogo.svg')" width="274px"/>
          <h2 class="white--text">India's 1st Debt Relief Platform</h2>
          </div>
        </div>
        <v-col>
          <v-container fill-height>
            <v-col cols="7">
                <h2 class="mb-5 text-center primary--text">FREED Partner Program</h2>
                    <component
                      v-if="!!loginFormMetaData"
                      :ref="loginFormMetaData.myRefName"
                      :is="loginFormMetaData.componentName"
                      v-model="loginForm"
                      v-bind="loginFormMetaData.props"
                    ></component>
              
             
            </v-col>
          </v-container>
        </v-col>
      </div>
    </v-main>
  </v-app>
 
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
// import store, * as Store from '@/../src-gen/store';
import * as Data from "@/../src-gen/data";
// import * as ServerData from '@/../src-gen/server-data';
import * as Action from "@/../src-gen/action";
import AffiliateLoginFFormMDP from "./AffiliateLoginFFormMDP";
import FForm from "@/components/form/FForm.vue";
import PartnerButton from "@/components/partnerButton.vue";
import ErrorResponse from "@/error-response";

@Component({
  components: {
    FForm,
    PartnerButton
  },
})
export default class Login extends Vue {
  selectedLoginScreen: number = 0;
  loginForm: Data.Login.LoginForm = new Data.Login.LoginForm();
  emailRules = [
    (v: any) => !!v.trim() || "Mobile Number / User Name is required",
    (v: any) => v.indexOf(" ") === -1 || "Provide a valid Mobile Number",
  ];

  userNameRules = [
    (v: any) => !!v || "Mobile Number / User Name is required",
  ];
  mobileNumber: string = "";
  password: string = "";
  type: string = "password";
  mounted() {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("partnerId");
  }
  onEyeClick() {
    this.type = this.type === "password" ? "text" : "password";
  }
  goToHome() {
    this.$router.push({ name: "Root.Home" });
  }

  goForgotPassword() {
    this.$router.push({ name: "Root.ForgotPassword" });
  }

  onSignIn() {
    Action.Login.Login.execute2(
      this.mobileNumber,
      this.password,
      (output: any) => {
        localStorage.setItem("auth_token", output.Authorization);
        localStorage.setItem("partnerId", output.partnerId);
        // this.$router.push({ name: "Partner.Dashboard" });
        this.getPartnerDetailsAndRoute(output.partnerId)
      }
    );
  }

  getPartnerDetailsAndRoute(partnerId:string) {
    Action.Partner.FindPartnerDetails.execute1(partnerId, (output) => {
      if(output?.partnerType.id === Data.Partner.PARTNER_TYPE.INSTITUTIONAL.id) {
        this.$router.push({ name: "InstitutionalPartner.InstitutionDashboard" });
      } else {
        this.$router.push({ name: "Partner.Dashboard" });
      }
        
    });
  }

  public affiliateLogin() {
    // const vm = this;

    Action.Login.SpineLogin.execute(this.loginForm, (output: any) => {
      localStorage.setItem("auth_token", output.Authorization);
      this.getRoleListForUser(this.loginForm.userName);
      console.log("output Login");
    },
    error => {
      ErrorResponse.handle(error)
    });
  }

  getLoggedInUser(userName: string) {
    Action.Login.GetUserDetails.execute1(userName, (output) => {
      console.log("output Loggedin user");
    });
  }

  getRoleListForUser(userName: string) {
    // const vm = this;
    try {
      Action.Login.GetRoleListForUser.execute(
        new Data.Login.MyAppId(),
        (output) => {
          console.log(output);
          if (output.roleList.indexOf("Affiliate") >= 0) {
            window.localStorage.setItem("userName", this.loginForm.userName);
            console.log("output role List");
            this.getLoggedInUser(this.loginForm.userName);
            if (this.$route.query.redirect) {
              console.log("output Redirect");
              const redirect: any = this.$route.query.redirect;
              this.$router.push({ path: redirect });
            } else {
              this.$router.push({ name: "PartnerAffiliate.AffiliateHome" });
            }
          } else {
            window.localStorage.removeItem("userName");
            // Snackbar.show({
            //   text: 'Not Authorized.',
            //   pos: 'bottom-center'
            // });
          }
        }
      );
    } catch (e) {
      console.log(e, "Error is this");
    }
  }

  get loginFormMetaData(): any {
    return new AffiliateLoginFFormMDP({
      taskRoot: this,
      parent: this,
    }).getMetaData();
  }
}
</script>

<style lang="scss" scoped>
.info-text {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  color: #676767;
  > span {
    color: #f36f21;
    font-weight: bold;
    cursor: pointer;
  }
}
.forgotPassword {
  text-align: right;
  color: #00447a;
  cursor: pointer;
  margin-bottom: 15px;
}
@media screen and (max-width: 960px) {
  .loginMobileView {
    display: block;
  }
}
.loginImg {
  height: 100vh;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 25% 100%);
  position: relative;
}
.image-container {
  background-image: url("@/assets/images/LoginBanner.png");
  height: 100vh;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 40%;
}
</style>
