
import FEMandateListMDP from "@/components/generic/file/eMandateList/FEMandateListMDP";



export default class ECFTEMandateListStepMDP extends FEMandateListMDP {
    
    constructor({ taskRoot, parent }:{ taskRoot: any, parent: any }) {
        super({taskRoot, parent})
    }

}

