<template>
<span>
  <a class="mr-1" @click="handleOnClick">
    <v-chip v-if="documentType" class="mx-1" x-small label>{{documentType}}</v-chip> {{selectModel(modelValue, dataSelectorKey)||"Document Url"}} <v-icon class="mx-2" small color="primary">mdi-open-in-new</v-icon>
    
  </a>
</span>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ModelVue from "../../ModelVue";
import * as Action from "@/../src-gen/action";

@Component({
  components: {
  },
})
export default class FCellDocumentLink extends ModelVue {
 @Prop({
  default: "Url"
 })
 placeholder: string;
  
  @Prop()
  documentKeySelector: string;


  @Prop()
  dataSelectorKey: string;

  handleOnClick() {
    Action.Spine.GetFileUrl.execute1(this.documentKey, (output) => {
      window.open(output.url);
    });
  }

  get documentKey(): string {
    const docKey = this.documentKeySelector?this.documentKeySelector: this.dataSelectorKey;
    return this.selectModel(this.modelValue, docKey) || ""
  }

  get documentType() {
    return this.documentKey.split('.').pop();
  }
}
</script>
