import FBtnMDP, { BtnType } from '@/components/FBtnMDP';
import FFormMDP, { FFormChildMDP } from '@/components/form/FFormMDP';
import FSelectDateFieldMDP from '@/components/form/field/FDateSelectFieldMDP';

export default class MonthlySalesAOSearchFFormMDP extends FFormMDP {
  childMDP = new FFormChildMDP();
  taskRoot: any;
  parent: any;
  constructor({ taskRoot, parent }: { taskRoot: any; parent: any }) {
    super({
      myRefName: 'monthlySalesAORef',
    });
    this.parent = parent;
    this.taskRoot = taskRoot;

    this.addField(
      new FSelectDateFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: 'fromDate',
        label: 'From Date',
        futureDaysDisabled: true,
        boundaryClass: "col-6",
      })
    )
      .addField(
        new FSelectDateFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: 'toDate',
          label: 'To Date',
          futureDaysDisabled: true,
          boundaryClass: "col-6",
        })
      )
      .addAction(
        new FBtnMDP({
          label: 'Reset to MTD',
          btnType: BtnType.TEXT,
          onClick: () => {
            this.taskRoot.handleAOShowMTDButtonClick();
          },
        })
      )
      .addAction(
        new FBtnMDP({
          label: 'Submit',
          onClick: () => this.taskRoot.getSaleCountDayWiseList(),
        })
      );
  }

  getMyRef() {
    return this.taskRoot.$refs[this.myRefName];
  }


  closeAddForm() {
    this.taskRoot.clearAOSearchForm();
  }
}
