import FBtnMDP, { BtnType } from "@/components/FBtnMDP";
import FFormSubmitBtnMDP from "@/components/FFormSubmitBtnMDP";
import FFormMDP, { FFormChildMDP } from "@/components/form/FFormMDP";
import FTextFieldMDP from "@/components/form/field/FTextFieldMDP";


export default class FCancelFlowFFormMDP extends FFormMDP {
  childMDP = new FFormChildMDP();
  taskRoot: any;
  constructor({ taskRoot }: { taskRoot: any }) {
    super({
      myRefName: "cancelFlowFFormFormRef",
      disabled: taskRoot.taskDisabled,
    });
    this.taskRoot = taskRoot;

    this.addField(
      new FTextFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: "reason",
        label: "Reason",
        mandatory: true,
      })
    )
      .addAction(
        new FBtnMDP({
          label: "Cancel",
          btnType: BtnType.TEXT,
          onClick: () => this.taskRoot.closeCancelFlow(),
        })
      )
      .addAction(
        new FFormSubmitBtnMDP({
          label: "Submit",
          onClick: () => this.taskRoot.handleCancelFlowClick(),
        })
      );
  }

  getMyRef(): any {
    return this.taskRoot.$refs[this.myRefName][0];
  }

}
