
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class PaymentWriterService {

   public static checkAndUpdatePaymentStatus(
           axiosInstance: AxiosInstance, input: ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Input,
           f: (output: ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/payment/check-and-update-payment-status',
            input.toJson()
        ).then((response) => ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static requestFundSplit(
           axiosInstance: AxiosInstance, input: ServerData.PaymentWebWriter.RequestFundSplit$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/payment/request-fund-split',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static updateFundSplitStatus(
           axiosInstance: AxiosInstance, input: ServerData.PaymentWebWriter.UpdateFundSplitStatus$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/payment/update-fund-split-status',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static cancelPayment(
           axiosInstance: AxiosInstance, input: ServerData.PaymentWebWriter.CancelPayment$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/payment/cancel-payment',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


}

