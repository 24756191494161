<template>
  <div class="monthlyBilling">
    <div class="pa-3 fadeIn">
      <component
        :ref="monthlyMSFCollectionFFormMetaData.myRefName"
        :is="monthlyMSFCollectionFFormMetaData.componentName"
        :value="selectModel(monthlyCollectionData, undefined)"
        @input="
          (newValue) => updateModel(monthlyCollectionData, newValue, undefined)
        "
        v-bind="monthlyMSFCollectionFFormMetaData.props"
      ></component>
      <FLoader v-if="loader" />
      <component
        v-if="!loader"
        :ref="monthlyCollectionFDataTableMetaData.myRefName"
        :is="monthlyCollectionFDataTableMetaData.componentName"
        :value="collectionReportInfo.collectionDataList"
        v-bind="monthlyCollectionFDataTableMetaData.props"
      ></component>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
// import * as ServerData from '@/../src-gen/server-data';
import * as Action from "@/../src-gen/action";
import ModelVue from "@/components/ModelVue";
import MonthlyCollectionFDataTableMDP from "./MonthlyCollection/MonthlyCollectionFDataTableMDP";
import MonthlyCollectionFFormMDP from "./MonthlyCollection/MonthlyCollectionFFormMDP";
import FDataTable from "@/components/table/FDataTable.vue";
import FForm from "@/components/form/FForm.vue";
import moment from "moment";
import FLoader from "@/components/FLoader.vue";

@Component({ components: { FDataTable, FForm, FLoader } })
export default class MonthlyCollection extends ModelVue {
  monthlyCollectionData: any = {
    month: moment().get("month"),
    year: moment().get("year"),
  };

  loader: boolean = false;

  collectionReportInput: Data.Insight.GetCollectionReportInput =
    new Data.Insight.GetCollectionReportInput();

  collectionReportInfo: Data.Insight.CollectionReportInfo =
    new Data.Insight.CollectionReportInfo();

  get monthlyCollectionFDataTableMetaData() {
    return new MonthlyCollectionFDataTableMDP({
      parent: this,
    }).getMetaData();
  }

  get monthlyMSFCollectionFFormMetaData() {
    return new MonthlyCollectionFFormMDP({
      parent: this,
    }).getMetaData();
  }

  getCollectionReport() {
    this.loader = true;
    const selectedDate = moment();
    selectedDate.set("month", this.monthlyCollectionData.month);
    selectedDate.set("year", this.monthlyCollectionData.year);
    const fromDate = selectedDate.startOf("month").toISOString();
    const toDate = selectedDate.endOf("month").toISOString();
    Action.Insight.GetCollectionReport.execute2(
      fromDate,
      toDate,
      (output) => {
        this.loader = false;
        this.collectionReportInfo = output;
      },
      (error) => {
        this.loader = false;
        console.log("error", error);
      }
    );
  }

  mounted() {
    this.getCollectionReport();
  }

  // gotoClientFileInfo(item: any) {
  //   console.log(item);
  //   this.$router.push({
  //     name: "InstitutionalPartner.InstitutionClientFileInfo",
  //     params: {
  //       clientFileId: item.clientFileId,
  //     },
  //   });
  // }
  // @Client File id is not available
}
</script>

<style>
</style>
