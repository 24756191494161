import FColumnCellMDP from '../FColumnCellMDP';
export default class FCellBooleanMDP implements FColumnCellMDP {
    type?: BooleanCellType = BooleanCellType.Normal;
    constructor(props: {
        type?: BooleanCellType;
    }) {
        this.type = props.type;
    }
    componentName: string = "FCellBoolean";
    getMetaData(): object {
        return {
            componentName: this.componentName,
            props: {
                type: this.type
            }
        }
    }

}

export enum BooleanCellType {
    Normal="Normal",
    Dotted="Dotted"
}