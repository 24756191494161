
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class TaskReaderService {

   public static getToBePulledTaskList(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebReader.GetToBePulledTaskList$Input,
           f: (output: ServerData.SpineData$TaskListByCid[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/task/get-to-be-pulled-task-list',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.SpineData$TaskListByCid.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getTaskListByCid(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebReader.GetTaskListByCid$Input,
           f: (output: ServerData.SpineData$TaskListByCid[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/task/get-task-list-by-cid',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.SpineData$TaskListByCid.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getActiveTaskListAllocated(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebReader.GetActiveTaskListAllocated$Input,
           f: (output: ServerData.SpineData$ActiveTaskAllocated[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/task/get-active-task-list-allocated',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.SpineData$ActiveTaskAllocated.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getSuspendedTaskListAllocated(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebReader.GetSuspendedTaskListAllocated$Input,
           f: (output: ServerData.SpineData$SuspendedTaskAllocated[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/task/get-suspended-task-list-allocated',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.SpineData$SuspendedTaskAllocated.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getExecutiveTaskDetails(
           axiosInstance: AxiosInstance, input: ServerData.TaskWebReader.GetExecutiveTaskDetails$Input,
           f: (output: ServerData.SpineData$ExecutiveTaskDetails) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/task/get-executive-task-details',
            {params : input.toJson()}
        ).then((response) => ServerData.SpineData$ExecutiveTaskDetails.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


}

