<template>
  <div class="Home">
    <v-container class="pa-0">
      <div class="logo">
        <img
          src="@/assets/images/freed-logo.svg"
          class="img-fluid logo-size"
          alt="..."
        />
      </div>
    </v-container>
    <div style="position: relative; overflow: hidden">
      <div class="overlyText-carousel">Jiyo Happy Zindagi...</div>
      <SignupForm :carousel="carousel" />
      <v-carousel
        v-model="carousel"
        cycle
        :show-arrows="false"
        :height="carouselHeight"
        :interval="interval"
        hide-delimiter-background
        style="position: relative"
      >
        <v-carousel-item :src="carouselImages[1]" cover>
          <v-container class="containerCarousel pa-0"> </v-container>
        </v-carousel-item>
        <v-carousel-item :src="carouselImages[2]" cover>
          <v-container class="containerCarousel pa-0"> </v-container>
        </v-carousel-item>
        <v-carousel-item :src="carouselImages[3]" cover>
          <v-container class="containerCarousel pa-0"> </v-container>
        </v-carousel-item>
      </v-carousel>
    </div>
    <div class="setpSectionBG">
      <v-container>
        <div class="incomeSection">
          <div class="incomeHeading">
            Create a new Income Stream in 3 easy steps
          </div>
          <div class="incomeSubHeading">
            Help Others. Make Money. It’s a win-win!
          </div>
          <div class="incomeCardContaner">
            <div class="row">
              <div class="col-md-4">
                <div class="incomeCard">
                  <div class="incomeStep">STEP 1</div>
                  <div class="incomeCardInner">
                    <div class="bodyContainer">
                      <div class="bdDiscretion">
                        <div class="bdHeading">SIGN UP</div>
                        with<br />
                        Zero Investment
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="incomeCard primaryBorder">
                  <div class="incomeStep">STEP 2</div>
                  <div class="incomeCardInner primaryBorder">
                    <div class="bodyContainer">
                      <div class="bdDiscretion">
                        <div class="bdHeading">BECOME A PARTNER</div>
                        and get your<br />
                        Certificate
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="incomeCard">
                  <div class="incomeStep">STEP 3</div>
                  <div class="incomeCardInner">
                    <div class="bodyContainer">
                      <div class="bdDiscretion">
                        <div class="bdHeading">GET PAID</div>
                        to help people get<br />
                        Debt Free
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </div>

    <div class="greyIncomeSection videoSectionBG">
      <div class="container">
        <div class="incomeHeading">
          Learn more about the FREED Partner Program
        </div>
        <div class="videoSection">
          <v-slide-group
            class="pa-1 video-view-desktop"
            selected-class="bg-success"
            show-arrows
          >
            <v-slide-group-item key="1">
              <div class="col-md-4 col-xs-12 videoCard">
                <iframe
                  height="215"
                  src="https://www.youtube.com/embed/vYxXxjoFZvs?rel=0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <div class="videoTitle">More Freedom</div>
              </div>
            </v-slide-group-item>
            <v-slide-group-item key="2">
              <div class="col-md-4 col-xs-12 videoCard">
                <iframe
                  height="215"
                  src="https://www.youtube.com/embed/9TwnJ0ld8vg?rel=0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <div class="videoTitle">More Income</div>
              </div>
            </v-slide-group-item>
            <v-slide-group-item key="3">
              <div class="col-md-4 col-xs-12 videoCard">
                <iframe
                  width="300"
                  height="215"
                  src="https://www.youtube.com/embed/_dKRtRrUUz4?rel=0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <div class="videoTitle">More Smiles</div>
              </div>
            </v-slide-group-item>
          </v-slide-group>
          <div class="mobile-view-video-section">
            <div class="col-md-4 col-xs-12 videoCard">
              <iframe
                loading="lazy"
                height="215"
                src="https://www.youtube.com/embed/vYxXxjoFZvs?rel=0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <div class="videoTitle">More Freedom</div>
            </div>
            <div class="col-md-4 col-xs-12 videoCard">
              <iframe
                loading="lazy"
                height="215"
                src="https://www.youtube.com/embed/QWSYUsLGODM?rel=0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <div class="videoTitle">More Income</div>
            </div>
            <div class="col-md-4 col-xs-12 videoCard">
              <iframe
                loading="lazy"
                width="300"
                height="215"
                src="https://www.youtube.com/embed/tx2vkAitaZA?rel=0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <div class="videoTitle">More Smiles</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="greyIncomeSection" style="padding-bottom: 20px">
      <v-container>
        <div class="incomeHeading">We are committed to your success</div>
        <div class="incomeSubHeading">
          We’ll help you make people happy while earning money
        </div>
        <div class="greyIncomeSectionSub" style="padding-bottom: 0px">
          <div class="row">
            <div class="col-md-6">
              <div class="leftIncomeList">
                <ul>
                  <li>
                    <div class="incomeContentCardSm">
                      <p class="INCHeading">
                        <v-icon color="#000000"> mdi-send </v-icon>Dedicated
                        Support Team
                      </p>
                      <p class="incSUbcontent">
                        We will make sure you have a smooth journey as our
                        support team answers all your questions.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="incomeContentCardSm">
                      <p class="INCHeading">
                        <v-icon color="#000000"> mdi-send </v-icon>Sales
                        Assistance
                      </p>
                      <p class="incSUbcontent">
                        FREED Debt expert will help you crack your first few
                        sales. You learn from the experts.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="incomeContentCardSm">
                      <p class="INCHeading">
                        <v-icon color="#000000"> mdi-send </v-icon>Your Own
                        Dashboard
                      </p>
                      <p class="incSUbcontent">
                        Everything you need in one place. You can access your
                        total earnings, success rate, number of debt-free lives
                        created - all at your fingertips.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="incomeContentCardSm">
                      <p class="INCHeading">
                        <v-icon color="#000000"> mdi-send </v-icon>Exclusive
                        Community Access
                      </p>
                      <p class="incSUbcontent">
                        Learn from FREED Partners and share experiences of
                        helping people Get FREED.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div class="PPcontentImg">
                <img
                  src="@/assets/images/we_are_commited.png"
                  class=""
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </div>

    <div class="normalIncomeSection">
      <div class="container">
        <div class="incomeHeading">Why join the FREED Partner Program?</div>
        <div class="greyIncomeSectionSub">
          <div class="row" style="position: relative">
            <div class="col-md-6">
              <div class="leftIncomeList mg0">
                <ul>
                  <li>
                    <div class="incomeContentCard">
                      <img src="@/assets/images/icon1.png" alt="..." />
                      <p>
                        An opportunity to make a difference & earn recognition
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="incomeContentCard">
                      <img src="@/assets/images/icon2.png" alt="..." />
                      <p>₹ 500 Welcome Bonus paid on your 1st sale</p>
                    </div>
                  </li>
                  <li>
                    <div class="incomeContentCard">
                      <img src="@/assets/images/icon3.png" alt="..." />
                      <p>Get Paid every time your Client Pays</p>
                    </div>
                  </li>
                  <li>
                    <div class="incomeContentCard">
                      <img src="@/assets/images/icon4.png" alt="..." />
                      <p>Transparent and Quick payouts weekly</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="borderRight"></div>
            <div class="col-md-6">
              <div class="leftIncomeList mg0">
                <ul>
                  <li>
                    <div class="incomeContentCard">
                      <img src="@/assets/images/icon5.png" alt="..." />
                      <p>Help India become Debt Free & Change Lives</p>
                    </div>
                  </li>
                  <li>
                    <div class="incomeContentCard">
                      <img src="@/assets/images/icon6.png" alt="..." />
                      <p>An Alternate Income Stream with Zero Investment</p>
                    </div>
                  </li>
                  <li>
                    <div class="incomeContentCard">
                      <img src="@/assets/images/icon7.png" alt="..." />
                      <p>Earning opportunity up to ₹ 50,000* a month or more</p>
                    </div>
                  </li>
                  <li>
                    <div class="incomeContentCard">
                      <img src="@/assets/images/icon8.png" alt="..." />
                      <p>More Income, More Smiles & More Flexibility</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="joinNowSection">
          <button
            type="button"
            class="btn btn-secondary calculator-btn"
            @click="scrollToTop"
          >
            Join Now
            <div class="icon-container">
              <img
                src="@/assets/images/paper-plane-white.svg"
                class="img-fluid w-100e"
                alt="..."
              />
            </div>
          </button>
        </div>
      </div>
    </div>

    <div class="PPAboutSection">
      <div class="incomeHeading">About FREED</div>
      <div class="PPSubHeading ppBoldSub">
        India's 1<sup>st</sup> Debt Relief Platform. FREED is a revolution to
        help those unable to pay off their debts become debt free.
      </div>
      <v-container>
        <v-row>
          <v-col>
            <div class="iosCard">
              <img src="@/assets/images/badge1.png" alt="..." />
            </div>
          </v-col>
          <v-col>
            <div class="iosCard">
              <img src="@/assets/images/badge2.png" alt="..." />
            </div>
          </v-col>
          <v-col>
            <div class="iosCard">
              <img src="@/assets/images/badge3.png" alt="..." />
            </div>
          </v-col>
          <v-col>
            <div class="iosCard">
              <img src="@/assets/images/badge4.png" alt="..." />
            </div>
          </v-col>
          <v-col>
            <div class="iosCard">
              <img src="@/assets/images/badge5.png" alt="..." />
            </div>
          </v-col>
        </v-row>
        <div class="inPressSection">
          <div class="incomeHeading">As Seen In Press</div>
          <v-slide-group class="pa-4" selected-class="bg-success" show-arrows>
            <v-slide-group-item v-for="n in 38" :key="n">
              <div class="iosCard" :class="['ma-4']">
                <img
                  :src="
                    require('@/assets/images/media-mentions/img' + n + '.png')
                  "
                  alt="..."
                  class="mediaImg"
                />
              </div>
            </v-slide-group-item>
          </v-slide-group>
        </div>
      </v-container>
    </div>

    <div class="becomeFreedPP">
      <div class="overlayImg-1">
        <img src="@/assets/images/freed-small-logo.svg" />
      </div>
      <div class="overlayImg-2">
        <img src="@/assets/images/freed-small-logo.svg" />
      </div>
      <div class="incomeHeading">
        Join FREED Partner Program! Help People & Make Money
      </div>
      <div class="PPSubHeading">
        Become a FREED Authorised Partner today<br />
        Help More, Earn More.
      </div>
      <button
        type="button"
        class="btn btn-secondary calculator-btn"
        @click="scrollToTop"
      >
        Become a FREED Partner
      </button>
    </div>

    <div class="greyIncomeSection faqSection">
      <template>
        <div class="incomeHeading">Frequently Asked Questions</div>
        <v-container>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header
                ><div class="expansion-heading">
                  1. How does the FREED Partner Program work?
                </div>
                <template v-slot:actions>
                  <v-icon color="teal"> mdi-minus-circle-outline </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                You identify people who are burdened with personal loan and
                credit card debt and are looking to get debt free. Once you have
                spoken with them about FREED’s program and ascertained their
                intent to sign up, you share the unique referral link with them.
                FREED’s Debt Relief Platform does the rest.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                ><div class="expansion-heading">
                  2. How do I qualify for this program?
                </div>
                <template v-slot:actions>
                  <v-icon color="teal"> mdi-minus-circle-outline </v-icon>
                </template></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                If you are 18 years or older and want to bring relief and smiles
                to debt-burdened consumers of India, you are eligible to become
                a FREED Authorised Partner. There are no pre-qualifying criteria
                or a waiting period.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                ><div class="expansion-heading">
                  3. How do I earn in this program?
                </div>
                <template v-slot:actions>
                  <v-icon color="teal"> mdi-minus-circle-outline </v-icon>
                </template></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                As a FREED Authorised Partner, you get a unique referral link to
                share with people who need help with resolving their credit card
                and personal loan debt. When they sign up and make their first
                payment, you start earning money. You get paid every time your
                referred client pays.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                ><div class="expansion-heading">
                  4. How do I sign up for the program?
                </div>
                <template v-slot:actions>
                  <v-icon color="teal"> mdi-minus-circle-outline </v-icon>
                </template></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                It's very simple. Just
                <a @click="scrollToTop">click here</a> and signup. Once logged
                in, request your onboarding call to become a FREED Authorised
                Partner.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                ><div class="expansion-heading">
                  5. How and when do I receive my payout?
                </div>
                <template v-slot:actions>
                  <v-icon color="teal"> mdi-minus-circle-outline </v-icon>
                </template></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                You will receive your payouts every week. You can also access
                your total earnings & performance metrics from the Partner
                Dashboard.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                ><div class="expansion-heading">
                  6. How much do I have to pay to join the program?
                </div>
                <template v-slot:actions>
                  <v-icon color="teal"> mdi-minus-circle-outline </v-icon>
                </template></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                ZERO! You don't pay any fees to become a FREED Authorised
                Partner.
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </template>
      <VideoModal :dialogInput="dialogInput" @close="closeAction(false)" />
    </div>
    <div :class="['learning-btn']">
      <img
        :src="require('@/assets/learn-btn-desktop.png')"
        width="150"
        class="learn-desktop"
        @click="closeAction(true)"
      />
      <img
        :src="require('@/assets/learn-btn-mobile.png')"
        width="50"
        class="learn-mobile"
        @click="closeAction(true)"
      />
    </div>
    <div :class="['becomeFreedBtn', addClass ? 'showBecomeFreedBtn' : '']">
      <v-btn color="primary" class="pa-7 partnerBtn" @click="scrollToTop"
        >Become a <br />FREED Partner
        <img src="@/assets/images/paper-plane-white.svg" alt="..." />
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
// import store, * as Store from '@/../src-gen/store';
import * as Data from '@/../src-gen/data';
// import * as ServerData from '@/../src-gen/server-data';
import * as Action from '@/../src-gen/action';
import SignupForm from '../components/sign-up-form.vue';
import VideoModal from '../components/video-modal.vue';
import { VSlideGroup, VSlideItem } from 'vuetify/lib';

@Component({
  components: {
    'v-slide-group': VSlideGroup,
    'v-slide-group-item': VSlideItem,
    SignupForm,
    VideoModal,
  },
})
export default class Home extends Vue {
  carousel: number = 0;
  interval: number = 3000;
  confirmPassword: string = '';
  addClass = false;
  offsetHeight = 400;
  signUpInput: Data.Partner.SignUpInput = new Data.Partner.SignUpInput();
  emailRules = [
    (v: any) => !!v.trim() || 'Email is required',
    (v: any) => v.indexOf(' ') === -1 || 'Provide a valid Email',
    (v: any) => /.+@.+\..+/.test(v) || 'Provide a valid Email',
  ];
  firstNameRules = [(v: any) => !!v.trim() || 'Name is required'];
  mobileRules = [
    (v: any) => !!v || 'Mobile Number is required',
    (v: any) => {
      const value = v?.replace(' ', '');
      return (!!value && value.length === 10) || 'Please Enter valid  Number';
    },
  ];
  passwordRules = [(v: any) => !!v || !!v.trim() || 'Password  is required'];
  confirmPasswordRules = [
    (v: any) => !!v || !!v.trim() || 'Confirm Password  is required',
    (v: any) =>
      v === this.signUpInput.password || "Confirm Password  doesn't match",
  ];
  dialogInput: boolean = false;

  closeAction(close: boolean = false) {
    this.dialogInput = close;
  }

  onSignUp() {
    const valid = (this.$refs.signUpRef as any).validate();
    if (valid) {
      const [firstName = '', lastName = ''] =
        this.signUpInput.firstName.split(' ');
      this.signUpInput.firstName = firstName;
      this.signUpInput.lastName = lastName;
      Action.Partner.SignUp.execute(this.signUpInput, (output) => {
        this.$router.push({ name: 'Login.Login' });
      });
    }
  }
  get carouselHeight() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return '1300';
      case 'sm':
        return '1300';
      case 'md':
        return '900 ';
      case 'lg':
        return '900';
      case 'xl':
        return '900';
    }
  }
  scrollToTop() {
    window.scrollTo(0, 0);
  }
  imageCaoursel = '@/assets/images/banner_image1.png';
  get carouselImages() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return {
          1: require('@/assets/images/mobile/a.png'),
          2: require('@/assets/images/mobile/b.png'),
          3: require('@/assets/images/mobile/c.png'),
        };
      case 'sm':
        return {
          1: require('@/assets/images/mobile/a.png'),
          2: require('@/assets/images/mobile/b.png'),
          3: require('@/assets/images/mobile/c.png'),
        };
      case 'md':
        return {
          1: require('@/assets/images/banner_image1.png'),
          2: require('@/assets/images/banner_image2.png'),
          3: require('@/assets/images/banner_image3.png'),
        };
      case 'lg':
        return {
          1: require('@/assets/images/banner_image1.png'),
          2: require('@/assets/images/banner_image2.png'),
          3: require('@/assets/images/banner_image3.png'),
        };
      case 'xl':
        return {
          1: require('@/assets/images/banner_image1.png'),
          2: require('@/assets/images/banner_image2.png'),
          3: require('@/assets/images/banner_image3.png'),
        };
    }
  }

  created() {
    document.addEventListener('scroll', this.scroll);
  }

  mounted() {
    scroll();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  scroll() {
    if (window.scrollY >= this.offsetHeight) {
      this.addClass = true;
    } else {
      this.addClass = false;
    }
  }
}
</script>

<style lang="scss">
.formFooter {
  padding-top: 15px;
  text-align: center;
}
.formFooter a {
  color: #f16c23 !important;
  font-weight: bold;
}
.container {
  padding: 15px;
}
.btn.btn-secondary {
  background-color: #f16c23;
  border-color: #f16c23;
  font-size: 15px;
  font-weight: normal;
  box-shadow: none !important;
  min-width: 150px;
  padding: 8px 15px;
  display: flex;
  margin: 0 auto;
  color: #fff;
  border-radius: 4px;
  font-weight: bold;
  min-height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.calculator-btn img {
  width: 24px !important;
  height: 24px !important;
}
.calculator-btn:disabled {
  opacity: 0.5;
}
.containerCarousel {
  position: absolute;
  top: 0;
  z-index: 999;
  left: 0;
  right: 0;
}
.logo {
  position: absolute;
  z-index: 9;
  padding: 15px 10px;
}
.logo img {
  height: 60px;
}
.partnerForm {
  position: relative;
  top: 100px;
  overflow-x: hidden;
}
.bannerTitle {
  font-size: 36px;
  color: #fff;
  margin-bottom: 5px;
  font-weight: bold;
}
.bannerSubTitle {
  font-size: 24px;
  color: #fff;
  margin-bottom: 10px;
}
.partnerInputForm {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  width: 450px;
}
.partnerInputForm {
  text-align: center;
}
.formTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}
.icon-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  animation: iconMove 3s infinite;
}
.incomeSection {
  padding-top: 60px;
}
.incomeHeading {
  font-size: 36px;
  color: #131313;
  text-align: center;
  padding-bottom: 10px;
  font-weight: bold;
}

.incomeSubHeading {
  font-size: 24px;
  color: #131313;
  text-align: center;
  padding-bottom: 10px;
}
.incomeCardContaner {
  padding: 45px 0;
}
.incomeCard {
  position: relative;
  border: 1px solid #f16c23;
  border-radius: 12px;
  padding: 10px;
}

.incomeCardInner {
  border: 4px solid #f16c23;
  border-radius: 12px;
  padding: 15px;
}

.incomeStep {
  background-color: #f16c23;
  color: #fff;
  font-size: 16px;
  border-radius: 20px;
  text-align: center;
  padding: 10px;
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
  width: 200px;
  margin: 0 auto;
}
.incomeCard.primaryBorder,
.incomeCardInner.primaryBorder {
  border-color: #004479 !important;
}
.incomeCard.primaryBorder .incomeStep {
  background-color: #004479;
}
.bodyContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 170px;
  text-align: center;
  font-size: 22px;
}
.bdHeading {
  font-size: 28px;
  font-weight: bold;
  color: #131313;
}
.incomeCardContaner {
  padding: 60px 0;
}

.incomeSection {
  padding-top: 60px;
}

.greyIncomeSection {
  background-color: #f3f9fb;
  padding: 40px 0;
}

.leftIncomeList ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.INCHeading {
  font-size: 24px;
  color: var(--text-primary-color);
  margin-bottom: 5px;
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: bold;
}
.greyIncomeSectionSub,
.normalIncomeSection {
  position: relative;
  padding: 40px 0;
}
.PPcontentImg img {
  width: 100%;
}
.joinNowSection {
  text-align: center;
  padding-top: 15px;
}
.joinNowSection .btn {
  min-width: 125px;
}

.PPAboutSection {
  background-color: #fef4ef;
  padding: 60px 0;
}
.setpSectionBG {
  background-color: #fef4ef;
}
.videoSectionBG {
  background-color: #fff;
}

.PPSubHeading {
  font-size: 18px;
  text-align: center;
  margin-bottom: 30px;
}

.becomeFreedPP {
  overflow: hidden;
  position: relative;
  padding: 60px 0;
  text-align: center;
}
.overlayImg-1 {
  width: 200px;
  position: absolute;
  left: -50px;
  top: -31px;
  opacity: 0.1;
}
.overlayImg-2 {
  width: 200px;
  position: absolute;
  right: -50px;
  bottom: -31px;
  opacity: 0.1;
}
.incomeContentCard {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-bottom: 30px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
}
.incomeContentCard p {
  font-size: 20px;
  margin: 0;
}
.incomeContentCard img {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}
.borderRight {
  position: absolute;
  border: 0.5px solid #ccc;
  height: 100%;
  right: 50%;
}
.expansion-heading {
  font-size: 16px;
  font-weight: bold;
}

.leftIncomeList ul > li:last-child .incomeContentCard {
  border-bottom: 0;
}
.becomeFreedBtn {
  position: fixed;
  bottom: 80px;
  right: 0;
  z-index: 9;
  display: none;
}
.learning-btn {
  position: fixed;
  top: 50px;
  right: 0;
  z-index: 189;
}
.learning-btn img {
  cursor: pointer;
}
.showBecomeFreedBtn {
  display: block;
}
.partnerBtn {
  border-radius: 12px !important;
  font-weight: bold;
}
.partnerBtn img {
  height: 24px;
  margin-left: 15px;
}
.PPSubHeading.ppBoldSub {
  font-weight: bold;
}

.iosCard {
  background: #fff;
  border-radius: 8px;
  padding: 15px;
  height: 135px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.inPressSection {
  padding-top: 60px;
}
.videoCard {
  display: table;
  padding: 0 30px;
}
.videoCard iframe {
  width: 300px;
}

.videoCard img {
  width: 285px;
  height: 200px;
  border-radius: 8px;
}
.videoSection {
  text-align: center;
  margin-top: 25px;
}
.mediaImg {
  width: 100%;
  height: 110px;
}
.v-application .teal--text {
  color: #004479 !important;
}
.partnerInputForm-mobile {
  width: 450px;
}
.partnerInputForm .v-text-field--outlined fieldset,
.partnerInputForm-mobile .v-text-field--outlined fieldset {
  border-radius: 40px;
  background: #eef2fb;
  border: 0;
}
.partnerInputForm .v-text-field--outlined.v-input--dense .v-label,
.partnerInputForm-mobile .v-text-field--outlined.v-input--dense .v-label,
.partnerInputForm-mobile .v-text-field input,
.partnerInputForm .v-text-field input {
  padding-left: 15px;
}
.incSUbcontent {
  padding-left: 30px;
  font-size: 20px;
}

.videoTitle {
  font-size: 18px;
  font-weight: bold;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background: inherit;
}
.mobile-view-video-section {
  display: none;
}

.overlyText-carousel {
  position: absolute;
  bottom: 120px;
  right: 60px;
  font-size: 70px;
  font-weight: bold;
  color: #fff;
  // font-family: "Dancing Font";
  transform: rotate(-15deg);
  letter-spacing: 5px;
  z-index: 199;
  font-family: 'Alex Brush', cursive;
}
.video-view-desktop .v-slide-group__content {
  justify-content: center;
}
.v-expansion-panel::before {
  box-shadow: none;
}
.v-expansion-panel-header {
  font-weight: bold;
}
@media screen and (max-width: 960px) {
  .overlyText-carousel {
    position: absolute;
    bottom: 100px;
    right: 0;
    left: 20px;
    font-size: 27px;
    font-weight: bold;
    color: #fff;
    transform: rotate(-10deg);
    letter-spacing: 5px;
    z-index: 199;
    width: 100%;
    text-align: center;
  }
  .video-view-desktop {
    display: none;
  }
  .partnerForm {
    top: 215px;
    left: 30px;
    width: 90%;
  }
  .partnerInputForm {
    display: none;
    width: 0;
  }

  .partnerInputForm-mobile {
    display: block;
    // width: calc(100% - 30px);
    width: 100%;
  }
  .bannerSubTitle {
    font-size: 16px;
    color: #fff;
    margin-bottom: 5px;
  }
  .bannerTitle {
    font-size: 24px;
    color: #fff;
    margin-bottom: 5px;
    font-weight: bold;
    line-height: 1.2;
  }
  .videoCard {
    display: table;
    padding: 0 15px;
  }
  .incomeSection,
  .incomeCardContaner,
  .greyIncomeSection {
    padding: 30px 0;
  }
  .incomeCardContaner {
    padding-bottom: 0;
  }
  .incomeHeading {
    font-size: 26px;
    color: #131313;
    text-align: center;
    padding-bottom: 5px;
    font-weight: bold;
  }
  .overlayImg-1 {
    width: 200px;
    position: absolute;
    left: -50px;
    top: -31px;
    opacity: 0.1;
  }
  .overlayImg-2 {
    width: 200px;
    position: absolute;
    right: -50px;
    bottom: -31px;
    opacity: 0.1;
  }
  .becomeFreedPP {
    overflow: hidden;
    position: relative;
    padding: 30px 15px;
    text-align: center;
  }
  .PPSubHeading {
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
  }
  .PPcontentImg {
    position: absolute;
    top: 18px;
    left: 0;
    right: 0;
    z-index: 9;
  }
  .leftIncomeList {
    margin-top: 60%;
  }
  .INCHeading {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 5px !important;
  }
  .greyIncomeSectionSub {
    padding-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
  .greyIncomeSection {
    padding-bottom: 0;
  }
  .normalIncomeSection {
    padding: 30px 0;
  }
  .leftIncomeList.mg0 {
    margin: 0;
  }
  .greyIncomeSectionSub .col-md-6 {
    padding-bottom: 0;
    padding-top: 0;
  }
  .leftIncomeList ul > li:last-child .incomeContentCard {
    border-bottom: 1px solid #ccc;
  }
  .leftIncomeList.borderRight {
    border: 0;
  }
  .PPAboutSection {
    padding: 30px 0;
  }
  .PPAboutSection .PPSubHeading.ppBoldSub {
    padding: 0 30px;
  }
  .inPressSection .pa-4 {
    padding: 0 !important;
  }
  // .becomeFreedBtn {
  //   position: inherit;
  // }
  .becomeFreedBtn {
    width: 100%;
    bottom: 0;
  }
  .becomeFreedBtn .partnerBtn {
    width: 100%;
    border-radius: 0 !important;
  }
  .overlayImg-1,
  .overlayImg-2 {
    width: 135px;
  }
  .borderRight {
    display: none;
  }
  .mobile-view-video-section {
    display: block;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
  }
  .videoCard iframe {
    width: 100%;
    border-radius: 10px;
  }
  .greyIncomeSection.faqSection {
    padding-bottom: 60px;
  }
}
.learn-mobile {
  display: none;
}
.learn-desktop {
  display: block;
}
@media screen and (max-width: 600px) {
  .learn-mobile {
    display: block;
  }
  .learn-desktop {
    display: none;
  }
}
::v-deep .v-dialog__content .v-dialog {
  box-shadow: none !important;
}
</style>
