<template>
  <div class="UnregisteredCustomers pa-3 fadeIn">
    <component
      :ref="unregisteredCustomersFFormMetaData.myRefName"
      :is="unregisteredCustomersFFormMetaData.componentName"
      :value="selectModel(notEnrolledPartnerClientListInput, undefined)"
      @input="
        (newValue) =>
          updateModel(notEnrolledPartnerClientListInput, newValue, undefined)
      "
      v-bind="unregisteredCustomersFFormMetaData.props"
    >
    </component>
    <component
      v-if="!loader"
      :ref="unregisteredCustomersFDataTableMetaData.myRefName"
      :is="unregisteredCustomersFDataTableMetaData.componentName"
      :value="notEnrolledPartnerClientListOutput.notEnrolledPartnerClientList"
      v-bind="unregisteredCustomersFDataTableMetaData.props"
    ></component>
    <FLoader v-else />
    <f-pagination
      v-if="notEnrolledPartnerClientListInput.count > 0"
      :total="notEnrolledPartnerClientListOutput.totalCount"
      :count="notEnrolledPartnerClientListInput.count"
      :value="notEnrolledPartnerClientListInput.offset"
      @input="handlePaginationChange"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
import * as Action from "@/../src-gen/action";
import Helper from "@/util/Helper";
import ModelVue from "@/components/ModelVue";
import FDataTable from "@/components/table/FDataTable.vue";
import FForm from "@/components/form/FForm.vue";
import UnregisteredCustomersFDataTableMDP from "./UnregisteredCustomers/UnregisteredCustomersFDataTableMDP";
import UnregisteredCustomersFFormMDP from "./UnregisteredCustomers/UnregisteredCustomersFFormMDP";
import FPagination from "@/components/FPagination.vue";
import FLoader from "@/components/FLoader.vue";

@Component({
  components: { FDataTable, FForm, "f-pagination": FPagination, FLoader },
})
export default class UnregisteredCustomers extends ModelVue {
  loader: boolean = false;

  notEnrolledPartnerClientListInput: Data.Partner.NotEnrolledPartnerClientListInput =
    new Data.Partner.NotEnrolledPartnerClientListInput();

  notEnrolledPartnerClientListOutput: Data.Partner.NotEnrolledPartnerClientListOutput =
    new Data.Partner.NotEnrolledPartnerClientListOutput();

  get unregisteredCustomersFDataTableMetaData() {
    return new UnregisteredCustomersFDataTableMDP({
      parent: this,
    }).getMetaData();
  }

  get unregisteredCustomersFFormMetaData() {
    return new UnregisteredCustomersFFormMDP({
      parent: this,
      taskRoot: this,
    }).getMetaData();
  }

  getNotEnrolledPartnerClientList() {
    this.loader = true;
    Action.Partner.GetNotEnrolledPartnerClientList.execute(
      this.notEnrolledPartnerClientListInput,
      (output) => {
        this.loader = false;
        this.notEnrolledPartnerClientListOutput = output;
      },
      (error) => {
        this.loader = false;
        console.log("error", error);
      }
    );
  }

  handlePaginationChange(newVal: any) {
    console.log("newVal", newVal);
    this.notEnrolledPartnerClientListInput.offset = newVal.offset;
    this.notEnrolledPartnerClientListInput.count = newVal.count;
    this.getNotEnrolledPartnerClientList();
  }

  mounted() {
    this.notEnrolledPartnerClientListInput.fromDate =
      Helper.dateMTDWithCurrent().startOfMonth;
    this.notEnrolledPartnerClientListInput.toDate =
      Helper.dateMTDWithCurrent().currentDate;
    this.notEnrolledPartnerClientListInput.count = 20;
    this.notEnrolledPartnerClientListInput.offset = 0;
    this.getNotEnrolledPartnerClientList();
  }
}
</script>


<style lang="css" scoped>
p {
  margin: 0;
}
</style>

