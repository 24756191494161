<template>
  <div>
    <v-tooltip v-bind="positionProps" max-width="300">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" small icon class="pa-0">
          <slot></slot>
        </v-btn>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ModelVue from "./ModelVue";


@Component({
  components: {},
})
export default class FTooltip extends ModelVue {
  @Prop({
    default: "Click here to copy",
  })
  tooltipText: string;

  @Prop({
    default: 'bottom'
  })
  position: string;

  get positionProps() {
    const positionProp: any = {};
    positionProp[this.position] = true;
    return positionProp
  }

}
</script>
