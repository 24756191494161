
import * as Data from "@/../src-gen/data";
import FBtnMDP, { BtnType } from "@/components/FBtnMDP";
import FFormSubmitBtnMDP from "@/components/FFormSubmitBtnMDP";
import FFormMDP, { FFormChildMDP } from "@/components/form/FFormMDP";
import FFileFieldMDP from "@/components/form/field/FFileFieldMDP";
import FSelectFieldMDP from "@/components/form/field/FSelectFieldMDP";
import FTextareaMDP from "@/components/form/field/FTextareaMDP";

export default class FUploadDocumentFFormMDP extends FFormMDP {
  childMDP = new FFormChildMDP();
  taskRoot: any;
  parent: any;
  constructor({ taskRoot, parent }: { taskRoot: any; parent: any }) {
    super({
      myRefName: "uploadDocumentFormRef",
      disabled: taskRoot.taskDisabled,
    });
    this.taskRoot = taskRoot;
    this.parent = parent;

    this.addField(
      new FSelectFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: "docType",
        label: "Type of Document",
        mandatory: true,
        boundaryClass: "col-4",
        options:Data.Spine.DocumentType.list(),
        optionLabel:"name",
        optionValue:"name"
        // options:["PAN","Credit Report", "SIF Document","Photo", "Call Recordings","CHPP",
        // "Income Proof - Bank Statement",
        // "Income Proof - Salary Slip",
        // "Income Proof - ITR",
        // "Income Proof - Form 16",
        // "Income Proof - Rent Receipts",
        // "Income Proof - Balance Sheet for Deposit",
        // "Others"]
      })).addField(
      new FFileFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: "fileDoc",
        label: "Document",
        mandatory: true,
        boundaryClass: "col-8"
      })
    ).addField(
      new FTextareaMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: "documentDetails",
        label: "Details",
        mandatory: false,
        boundaryClass: "col-12"
      })
    ).addAction(new FBtnMDP({
        label: "Cancel",
        btnType: BtnType.TEXT,
        onClick: () => { this.closeAddForm() }
      })).addAction(new FFormSubmitBtnMDP({
        label: "Upload Document",
        onClick: () => this.uploadDocument()
      }));
  }

  getMyRef() {
    return this.parent.getMyRef().$refs[this.myRefName]
  }

  uploadDocument() {
        this.parent.getMyRef().getPresignedURLAndUpload();
  }

  closeAddForm() {
    this.parent.getMyRef().closeAndClearAllForms();
  }

}
