import FColumnCellMDP from '../FColumnCellMDP';
export default class FCellUrlLinkMDP implements FColumnCellMDP {
    dataSelectorKey?: string;
    placeholder?: string;
    openInNewTab?: boolean;
    urlLinkActionType?: UrlLinkActionType;
    constructor({
        dataSelectorKey,
        placeholder,
        openInNewTab=false,
        urlLinkActionType=UrlLinkActionType.Url
    }: {  dataSelectorKey?: string;placeholder?: string;openInNewTab?: boolean;urlLinkActionType?: UrlLinkActionType }) {
        this.dataSelectorKey = dataSelectorKey;
        this.placeholder = placeholder;
        this.openInNewTab = openInNewTab;
        this.urlLinkActionType = urlLinkActionType;
    }
    // dataSelectorKey: string;
    componentName: string = "FCellUrlLink";
    getMetaData(): object {
        return {
            componentName: this.componentName,
            props: {
                dataSelectorKey: this.dataSelectorKey,
                placeholder: this.placeholder,
                openInNewTab: this.openInNewTab,
                urlLinkActionType: this.urlLinkActionType
            }
        }
    }
}

export enum UrlLinkActionType {
    Url="Url",
    Btn="Btn"
}