<template>
  <v-dialog
    v-model="dialogInput"
    persistent
    max-width="600"
    class="onboardingModal"
  >
    <div class="onboardingContent">
      <v-btn @click="closeAction" text icon color="lighten-2" class="closeBtn">
        <v-icon size="20">mdi-close</v-icon>
      </v-btn>
      <div class="headerContent">
        <img src="@/assets/images/paper-plane-orange.svg" class="planeImg" />
        <div class="videoTitleMd">Watch the video to learn more about the</div>
        <div class="videoHeading">FREED PARTNER PROGRAM</div>
      </div>
      <div class="iframe-container">
        <iframe
          v-if="dialogInput"
          loading="lazy"
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/Aq8vnk-H04M?rel=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </v-dialog>
</template>
<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
// import * as ServerData from '@/../src-gen/server-data';
import * as Action from "@/../src-gen/action";

@Component({
  components: {},
})
export default class VideoModal extends Vue {
  @Prop({ default: false })
  dialogInput: boolean;

  closeAction() {
    this.$emit("close");
  }
}
</script>

<style lang="scss" scoped>
.closeBtn {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 999;
}

.onboardingContent {
  background: #fff;
  width: 600px;
  border-radius: 30px;
  text-align: center;
  position: relative;
}
.iframe-container {
  padding: 0 55px;
}

.headerContent {
  position: relative;
  overflow: hidden;
  padding-top: 40px;
}

.planeImg {
  opacity: 0.2;
  position: absolute;
  left: 0;
  right: 0;
  top: -40px;
  width: 330px;
  margin: 0 auto;
}

.onboardingHeading {
  font-size: 28px;
  font-weight: bold;
  color: #000;
}

.description {
  font-size: 16px;
  color: #000;
  padding: 30px 0;
}

.requestBtn {
  background: #00447a;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 8px 0;
  cursor: pointer;
}
.videoTitleMd {
  font-size: 30px;
  text-align: center;
  margin-bottom: 15px;
  line-height: 1;
}
.videoHeading {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}
iframe {
  border-radius: 30px;
}
@media screen and (max-width: 650px) {
  .onboardingContent {
    width: calc(100%);
    padding: 15px;
    min-height: 90%;
  }
  .iframe-container {
    padding: 0 0px;
  }
  .videoTitleMd {
    font-size: 22px;
    text-align: center;
    margin-bottom: 10px;
    line-height: 1;
  }
  .videoHeading {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
  }
}
</style>
