
import axios, { AxiosError, AxiosInstance } from 'axios';
import * as RemoteApiPoint from './../../src/remote-api-point';
import {EventBus} from './../../src/eventbus';
import * as Store from './../../src-gen/store';
import * as Data from './../data';
import * as ServerData from './../server-data';
import FiNoteReaderService from './../service/spineapi/FiNoteReaderService';
import FiNoteWriterService from './../service/spineapi/FiNoteWriterService';

// tslint:disable



export class GetFiNoteList {

    
    public static execute1(clientFileId: string, f: (output: Data.FiNote.FiNote[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetFiNoteList.execute(new ServerData.FiNoteWebReader.GetFiNoteList$Input(clientFileId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.FiNoteWebReader.GetFiNoteList$Input, f: (output: Data.FiNote.FiNote[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        FiNoteReaderService.getFiNoteList(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$FiNote[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.FiNote.FiNoteStore.UPDATE_FI_NOTE_LIST(mappedOutput.map((x) => Data.FiNote.FiNote.fromJson(x.toJson())));
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.FiNoteWebReader.GetFiNoteList$Input): ServerData.FiNoteWebReader.GetFiNoteList$Input {
        return new GetFiNoteList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$FiNote[]): Data.FiNote.FiNote[] {
        return new GetFiNoteList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetFiNoteList$Mapper {

    public mapInput(input: ServerData.FiNoteWebReader.GetFiNoteList$Input): ServerData.FiNoteWebReader.GetFiNoteList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$FiNote[]): Data.FiNote.FiNote[] {
        return  output.map((x) => Data.FiNote.FiNote.fromJson(x.toJson()));
    }

}

class GetFiNoteList$MapperCode extends GetFiNoteList$Mapper {

}

       


export class AddNote {

    

    

    public static execute(input: Data.FiNote.AddNoteInput, f: (output: ServerData.FiNoteWebWriter.AddNote$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        FiNoteWriterService.addNote(axoisInstance, this.mapInput(input),
            ((output: ServerData.FiNoteWebWriter.AddNote$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.FiNote.AddNoteInput): ServerData.FiNoteWebWriter.AddNote$Input {
        return new AddNote$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.FiNoteWebWriter.AddNote$Output): ServerData.FiNoteWebWriter.AddNote$Output {
        return new AddNote$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class AddNote$Mapper {

    public mapInput(input: Data.FiNote.AddNoteInput): ServerData.FiNoteWebWriter.AddNote$Input {
        return ServerData.FiNoteWebWriter.AddNote$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.FiNoteWebWriter.AddNote$Output): ServerData.FiNoteWebWriter.AddNote$Output {
        return output
    }

}

class AddNote$MapperCode extends AddNote$Mapper {

}

       


export class TagNote {

    

    

    public static execute(input: Data.FiNote.TagNoteInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        FiNoteWriterService.tagNote(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.FiNote.TagNoteInput): ServerData.FiNoteWebWriter.TagNote$Input {
        return new TagNote$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new TagNote$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class TagNote$Mapper {

    public mapInput(input: Data.FiNote.TagNoteInput): ServerData.FiNoteWebWriter.TagNote$Input {
        return ServerData.FiNoteWebWriter.TagNote$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class TagNote$MapperCode extends TagNote$Mapper {

}

       


export class UnTagNote {

    

    

    public static execute(input: Data.FiNote.UnTagNoteInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        FiNoteWriterService.unTagNote(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.FiNote.UnTagNoteInput): ServerData.FiNoteWebWriter.UnTagNote$Input {
        return new UnTagNote$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new UnTagNote$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class UnTagNote$Mapper {

    public mapInput(input: Data.FiNote.UnTagNoteInput): ServerData.FiNoteWebWriter.UnTagNote$Input {
        return ServerData.FiNoteWebWriter.UnTagNote$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class UnTagNote$MapperCode extends UnTagNote$Mapper {

}

       


export class RemoveNote {

    
    public static execute1(noteId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      RemoveNote.execute(new ServerData.FiNoteWebWriter.RemoveNote$Input(noteId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.FiNoteWebWriter.RemoveNote$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        FiNoteWriterService.removeNote(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.FiNoteWebWriter.RemoveNote$Input): ServerData.FiNoteWebWriter.RemoveNote$Input {
        return new RemoveNote$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new RemoveNote$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class RemoveNote$Mapper {

    public mapInput(input: ServerData.FiNoteWebWriter.RemoveNote$Input): ServerData.FiNoteWebWriter.RemoveNote$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class RemoveNote$MapperCode extends RemoveNote$Mapper {

}

       

     