
import axios, { AxiosError, AxiosInstance } from 'axios';
import * as RemoteApiPoint from './../../src/remote-api-point';
import {EventBus} from './../../src/eventbus';
import * as Store from './../../src-gen/store';
import * as Data from './../data';
import * as ServerData from './../server-data';
import GompaReaderService from './../service/spineapi/GompaReaderService';
import GompaWriterService from './../service/partnerprogramapi/GompaWriterService';

// tslint:disable



export class GetUserDetails {

    
    public static execute1(userName: string, f: (output: Data.Login.LoggedInUser) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetUserDetails.execute(new ServerData.GompaWebReader.GetUserDetails$Input(userName), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.GompaWebReader.GetUserDetails$Input, f: (output: Data.Login.LoggedInUser) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        GompaReaderService.getUserDetails(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$GompaUserDetails) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.Login.LoginDetails.MUTATE_LOGGED_IN_USER(mappedOutput);
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.GompaWebReader.GetUserDetails$Input): ServerData.GompaWebReader.GetUserDetails$Input {
        return new GetUserDetails$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$GompaUserDetails): Data.Login.LoggedInUser {
        return new GetUserDetails$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetUserDetails$Mapper {

    public mapInput(input: ServerData.GompaWebReader.GetUserDetails$Input): ServerData.GompaWebReader.GetUserDetails$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$GompaUserDetails): Data.Login.LoggedInUser {
        return Data.Login.LoggedInUser.fromJson(output.toJson());
    }

}

class GetUserDetails$MapperCode extends GetUserDetails$Mapper {

}

       


export class Login {

    

    
    public static execute2(userName: string, password: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {
      Login.execute(new ServerData.GompaWebWriter.Login$Input(userName, password), f, eh, axoisInstance);
    } 

    public static execute(input: ServerData.GompaWebWriter.Login$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        GompaWriterService.login(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.GompaWebWriter.Login$Input): ServerData.GompaWebWriter.Login$Input {
        return new Login$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new Login$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class Login$Mapper {

    public mapInput(input: ServerData.GompaWebWriter.Login$Input): ServerData.GompaWebWriter.Login$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class Login$MapperCode extends Login$Mapper {

}

       


export class GetRoleListForUser {

    
    public static execute1(appId: string, f: (output: Data.Login.LoginDetails) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetRoleListForUser.execute(new Data.Login.MyAppId(appId), f, eh, axoisInstance);
    } 

    

    public static execute(input: Data.Login.MyAppId, f: (output: Data.Login.LoginDetails) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        GompaWriterService.getRolesForAppId(axoisInstance, this.mapInput(input),
            ((output: ServerData.GompaWebWriter.GetRolesForAppId$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.Login.LoginDetails.MUTATE_ROLE_LIST(mappedOutput);
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Login.MyAppId): ServerData.GompaWebWriter.GetRolesForAppId$Input {
        return new GetRoleListForUser$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.GompaWebWriter.GetRolesForAppId$Output): Data.Login.LoginDetails {
        return new GetRoleListForUser$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetRoleListForUser$Mapper {

    public mapInput(input: Data.Login.MyAppId): ServerData.GompaWebWriter.GetRolesForAppId$Input {
        return ServerData.GompaWebWriter.GetRolesForAppId$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.GompaWebWriter.GetRolesForAppId$Output): Data.Login.LoginDetails {
        return Data.Login.LoginDetails.fromJson(output.toJson());
    }

}

class GetRoleListForUser$MapperCode extends GetRoleListForUser$Mapper {

}

       


export class SpineLogin {

    

    

    public static execute(input: Data.Login.LoginForm, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        GompaWriterService.loginToSpine(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Login.LoginForm): ServerData.GompaWebWriter.LoginToSpine$Input {
        return new SpineLogin$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new SpineLogin$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class SpineLogin$Mapper {

    public mapInput(input: Data.Login.LoginForm): ServerData.GompaWebWriter.LoginToSpine$Input {
        return ServerData.GompaWebWriter.LoginToSpine$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class SpineLogin$MapperCode extends SpineLogin$Mapper {

}

       


export class SendOTP {

    
    public static execute1(mobile: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {
      SendOTP.execute(new ServerData.GompaWebWriter.SendOTP$Input(mobile), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.GompaWebWriter.SendOTP$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        GompaWriterService.sendOTP(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.GompaWebWriter.SendOTP$Input): ServerData.GompaWebWriter.SendOTP$Input {
        return new SendOTP$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new SendOTP$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class SendOTP$Mapper {

    public mapInput(input: ServerData.GompaWebWriter.SendOTP$Input): ServerData.GompaWebWriter.SendOTP$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class SendOTP$MapperCode extends SendOTP$Mapper {

}

       


export class ValidateOTP {

    

    
    public static execute2(mobile: string, otp: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {
      ValidateOTP.execute(new ServerData.GompaWebWriter.ValidateOTP$Input(mobile, otp), f, eh, axoisInstance);
    } 

    public static execute(input: ServerData.GompaWebWriter.ValidateOTP$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        GompaWriterService.validateOTP(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.GompaWebWriter.ValidateOTP$Input): ServerData.GompaWebWriter.ValidateOTP$Input {
        return new ValidateOTP$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new ValidateOTP$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class ValidateOTP$Mapper {

    public mapInput(input: ServerData.GompaWebWriter.ValidateOTP$Input): ServerData.GompaWebWriter.ValidateOTP$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class ValidateOTP$MapperCode extends ValidateOTP$Mapper {

}

       


export class ResetPassword {

    

    
    public static execute2(mobile: string, newPassword: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {
      ResetPassword.execute(new ServerData.GompaWebWriter.ResetPassword$Input(mobile, newPassword), f, eh, axoisInstance);
    } 

    public static execute(input: ServerData.GompaWebWriter.ResetPassword$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        GompaWriterService.resetPassword(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.GompaWebWriter.ResetPassword$Input): ServerData.GompaWebWriter.ResetPassword$Input {
        return new ResetPassword$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new ResetPassword$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class ResetPassword$Mapper {

    public mapInput(input: ServerData.GompaWebWriter.ResetPassword$Input): ServerData.GompaWebWriter.ResetPassword$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class ResetPassword$MapperCode extends ResetPassword$Mapper {

}

       

     