<template>
  <div>
    <v-card class="my-3" flat>
      <div class="d-flex">
        <v-avatar size="40" class="mr-3" color="grey">
          <div class="caption white--text">{{createdBy|userAvatarChar}}</div>
        </v-avatar>
        <div>
          <div class="d-flex align-center subtitle-1 mt-1">
            <span class="font-weight-bold mr-3">{{ createdBy }}</span>
            <span class="subtitle-2 grey--text text--darken-2">
              {{ createdOn | datetime }} ({{ createdOn | fromNow }})</span
            >
            <div class="ml-3" v-if="onNoteHighlight">
              <v-icon :color="isHighlighted?'secondary':'grey'" @click="handleHighlightNote()"> mdi-star-outline </v-icon>
            </div>
          </div>
          <div class="subtitle-1">{{ noteMessage }}</div>
        </div>
      </div>

    </v-card>
  </div>
</template>
<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { VBtn } from "vuetify/lib/components";
import ModelVue from "./ModelVue";

@Component({
  components: {},
})
export default class FComment extends ModelVue {
  @Prop({ default: false })
  isHighlighted: boolean;
  @Prop({ default: "" })
  noteMessage: string;

  @Prop({ default: "" })
  createdBy: string;

  @Prop({ default: "" })
  createdOn: string;
  @Prop()
  onNoteHighlight: () => void;

  handleHighlightNote() {
    this.onNoteHighlight();
  }
}
</script>
