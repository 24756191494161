
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class PartnerAnalyticsReaderService {

   public static getPartnerClientList(
           axiosInstance: AxiosInstance, input: ServerData.PartnerAnalyticsWebReader.GetPartnerClientList$Input,
           f: (output: ServerData.PartnerAnalyticsWebReader.GetPartnerClientList$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'insightapi/partneranalytics/get-partner-client-list',
            {params : input.toJson()}
        ).then((response) => ServerData.PartnerAnalyticsWebReader.GetPartnerClientList$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getCollectionReport(
           axiosInstance: AxiosInstance, input: ServerData.PartnerAnalyticsWebReader.GetCollectionReport$Input,
           f: (output: ServerData.PartnerAnalyticsWebReader.GetCollectionReport$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'insightapi/partneranalytics/get-collection-report',
            {params : input.toJson()}
        ).then((response) => ServerData.PartnerAnalyticsWebReader.GetCollectionReport$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getPartnerClientListForRange(
           axiosInstance: AxiosInstance, input: ServerData.PartnerAnalyticsWebReader.GetPartnerClientListForRange$Input,
           f: (output: ServerData.PartnerAnalyticsWebReader.GetPartnerClientListForRange$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'insightapi/partneranalytics/get-partner-client-list-for-range',
            {params : input.toJson()}
        ).then((response) => ServerData.PartnerAnalyticsWebReader.GetPartnerClientListForRange$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getPartnerClientCount(
           axiosInstance: AxiosInstance, input: ServerData.PartnerAnalyticsWebReader.GetPartnerClientCount$Input,
           f: (output: ServerData.PartnerAnalyticsWebReader.GetPartnerClientCount$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'insightapi/partneranalytics/get-partner-client-count',
            {params : input.toJson()}
        ).then((response) => ServerData.PartnerAnalyticsWebReader.GetPartnerClientCount$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static findParticularPartnerClient(
           axiosInstance: AxiosInstance, input: ServerData.PartnerAnalyticsWebReader.FindParticularPartnerClient$Input,
           f: (output: ServerData.InsightData$FindParticularPartnerClientOutput) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'insightapi/partneranalytics/find-particular-partner-client',
            {params : input.toJson()}
        ).then((response) => ServerData.InsightData$FindParticularPartnerClientOutput.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getPartnerClientFunnelForFpp(
           axiosInstance: AxiosInstance, input: ServerData.PartnerAnalyticsWebReader.GetPartnerClientFunnelForFpp$Input,
           f: (output: ServerData.InsightData$PartnerClientConversionFunnelForFpp) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'insightapi/partneranalytics/get-partner-client-funnel-for-fpp',
            {params : input.toJson()}
        ).then((response) => ServerData.InsightData$PartnerClientConversionFunnelForFpp.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getPartnerClientDemography(
           axiosInstance: AxiosInstance, input: ServerData.PartnerAnalyticsWebReader.GetPartnerClientDemography$Input,
           f: (output: ServerData.InsightData$Demography[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'insightapi/partneranalytics/get-partner-client-demography',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.InsightData$Demography.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getPartnerClientFunnel(
           axiosInstance: AxiosInstance, input: ServerData.PartnerAnalyticsWebReader.GetPartnerClientFunnel$Input,
           f: (output: ServerData.InsightData$PartnerClientConversionFunnel) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'insightapi/partneranalytics/get-partner-client-funnel',
            {params : input.toJson()}
        ).then((response) => ServerData.InsightData$PartnerClientConversionFunnel.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getDailyFunnel(
           axiosInstance: AxiosInstance, input: ServerData.PartnerAnalyticsWebReader.GetDailyFunnel$Input,
           f: (output: ServerData.InsightData$DailyFunnel[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'insightapi/partneranalytics/get-daily-funnel',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.InsightData$DailyFunnel.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getMonthlyFunnel(
           axiosInstance: AxiosInstance, input: ServerData.PartnerAnalyticsWebReader.GetMonthlyFunnel$Input,
           f: (output: ServerData.InsightData$MonthlyFunnel[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'insightapi/partneranalytics/get-monthly-funnel',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.InsightData$MonthlyFunnel.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getPartnerConversionSummary(
           axiosInstance: AxiosInstance, input: ServerData.PartnerAnalyticsWebReader.GetPartnerConversionSummary$Input,
           f: (output: ServerData.InsightData$ConversionSummary) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'insightapi/partneranalytics/get-partner-conversion-summary',
            {params : input.toJson()}
        ).then((response) => ServerData.InsightData$ConversionSummary.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


}

