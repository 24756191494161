import { render, staticRenderFns } from "./LoginLayout.vue?vue&type=template&id=2efa8f7b&scoped=true&"
import script from "./LoginLayout.vue?vue&type=script&lang=ts&"
export * from "./LoginLayout.vue?vue&type=script&lang=ts&"
import style0 from "./LoginLayout.vue?vue&type=style&index=0&id=2efa8f7b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2efa8f7b",
  null
  
)

export default component.exports