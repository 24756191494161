import FColumnCellMDP from '../FColumnCellMDP';
export default class FCellCurrencyMDP implements FColumnCellMDP {
    rounded: boolean | undefined;
    enableDecimal: boolean | undefined;
    constructor({rounded=false,enableDecimal=false}: {rounded?: boolean;enableDecimal?: boolean}) {
        this.rounded = rounded;
        this.enableDecimal = enableDecimal
    }
    componentName: string = "FCellCurrency";
    getMetaData(): object {
        return {
            componentName: this.componentName,
            props: {
                rounded: this.rounded,
                enableDecimal: this.enableDecimal
            }
        }
    }

}