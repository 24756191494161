
import * as Data from '@/../src-gen/data';
import {ApplicantDetailsMutations} from '@/../src-gen/store/partner-store-support';

export class ApplicantDetailsMutationsCode implements ApplicantDetailsMutations   {


   public updatePartnerInfo(state: Data.Partner.ApplicantDetails, PartnerInfo: Data.Partner.PartnerSummary|undefined  ): void  {
 state.PartnerInfo=PartnerInfo
   }

   public updateReferralOrgCode(state: Data.Partner.ApplicantDetails, GetReferralOrgCodeInfo: Data.Partner.GetReferralOrgCodeOutput): void {
    state.GetReferralOrgCodeInfo = GetReferralOrgCodeInfo
  } 

}

