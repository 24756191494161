<template>
  <a :class="`${color}--text ${fontType=='bold'?'font-weight-bold':''}`">
    <router-link
      v-if="label||selectModel(modelValue, dataSelectorKey)"
      style="text-decoration: none; color: inherit"
      :to="routerObject"
    >
    <v-icon :color="color" v-if="icon" dense small class="mr-1">
      {{ icon }}
    </v-icon>
      {{ label||selectModel(modelValue, dataSelectorKey) }}
    </router-link>
    <div v-else>--</div>
  </a>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ModelVue from "../../ModelVue";
import { Param, Query } from "./FCellRouterLinkMDP";

@Component({
  components: {},
})
export default class FCellRouterLink extends ModelVue {
  @Prop()
  dataSelectorKey: string;

  @Prop()
  routerName: string;

  @Prop()
  paramName: string;

  @Prop()
  paramKey: string;

  @Prop({ default: () => [] })
  paramsList: Param[];
  
  @Prop({ default: () => [] })
  queryList: Query[];

  @Prop({
    default: "default",
  })
  color: string;

  @Prop()
  icon: string;

  @Prop()
  fontType: string;

  @Prop()
  label: string;

  

  get routerObject() {
    const params: any = {};
    const queries: any = {};
    if (this.paramName) {
      params[this.paramName] = this.selectModel(
        this.modelValue,
        this.paramKey ? this.paramKey : this.dataSelectorKey
      );
    } // NEED TO REMOVE param name and param key and use paramsList

    this.paramsList.forEach((item) => {
      params[item.paramName] = this.selectModel(
        this.modelValue,
        item.paramKey ? item.paramKey : this.dataSelectorKey
      );
    });

     this.queryList.forEach((item) => {
      queries[item.queryName] = this.selectModel(
        this.modelValue,
        item.queryKey ? item.queryKey : this.dataSelectorKey
      );
    });

    return { name: this.routerName, params: params,query: queries };
  }
}
</script>
