<template>
  <router-view />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class LoginLayout extends Vue {

}
</script>

<style lang="scss" scoped>

.loginImg {
  height: 100vh;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 25% 100%);
  position: relative;
}
.image-container {
  background-image: url("@/assets/images/loginCover.png");
  height: 100vh;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
  // width: 40%;
}
.curve {
  width: 200px;
  height: 100vh;
  background: #fff;
  position: absolute;
  height: 100%;
  border-top-left-radius: 60%;
  border-bottom-left-radius: 60%;
  right: 0;
  top: 0;
  // border-top: 45vh solid transparent;
  // border-right: 150px solid #fff;
  // border-bottom: 45vh solid transparent;
  // position: absolute;
  // height: 100%;
  // border-top-left-radius: 50%;
  // border-bottom-left-radius: 50%;
  // right: 0;
}
</style>