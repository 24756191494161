<template>
  <div>
    <HeaderInstitution :drawer="drawer" @closeSideNav="closeSideNav" />
    <SideNav @closeSideNav="closeSideNav" />
    <div class="mainContainer">
      <div class="row no-gutters" :class="$vuetify.breakpoint.mdAndUp ? 'institutionalPartnerContent' : ''">
        <div class="col-12" v-if="PartnerInfo">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
import * as Action from "@/../src-gen/action";
import SideNav from "../components/institution/side-nav.vue";

import OnboardingCard from "../components/OnboardingCard.vue";
import OnboardingSuccessCard from "../components/onBoardingSuccess.vue";
import Helper from "@/util/Helper";
import HeaderInstitution from "../components/institution/HeaderInstitution.vue";

@Component({
  components: {
    SideNav,
    HeaderInstitution,
    OnboardingCard,
    OnboardingSuccessCard,
  },
})
export default class InstitutionalPartnerLayout extends Vue {
  @Store.Getter.Partner.ApplicantDetails.PartnerInfo
  PartnerInfo: Data.Partner.PartnerSummary;
  dailyFunnelInput: Data.Insight.GetDailyFunnelInput =
    new Data.Insight.GetDailyFunnelInput();
  partnerStatusEnum: any = Data.Partner.PARTNER_STATUS.SIGNED_UP;
  get dialog() {
    return (
      this.PartnerInfo?.status?.id == Data.Partner.PARTNER_STATUS.SIGNED_UP.id
    );
  }
  drawer: boolean = false;
  closeSideNav(drawer: boolean) {
    this.drawer = drawer;
  }
  get isAuthenticated() {
    return localStorage.getItem("auth_token") || "";
  }
  get partnerId() {
    return localStorage.getItem("partnerId") || "";
  }
  get dailyFunnelInputLocal() {
    this.dailyFunnelInput.fromDate =
      Helper.dateMTDWithCurrent().last7DaysToDate;
    this.dailyFunnelInput.toDate = Helper.dateMTDWithCurrent().currentDate;
    return this.dailyFunnelInput;
  }
  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push({ name: "Login.Login" });
    } else {
      this.getPartner();
      this.getPartnerClientDemography();
      this.getDailyFunnel();
    }
  }
  getPartner() {
    Action.Partner.FindPartnerDetails.execute1(this.partnerId, (output) => {});
  }
  getDailyFunnel() {
    Action.Insight.GetDailyFunnel.execute(
      this.dailyFunnelInputLocal,
      (output) => {}
    );
  }

  getPartnerClientDemography() {
    Action.Insight.GetPartnerClientDemography.execute((output) => {});
  }
}
</script>
<style scoped>
.institutionalPartnerContent {
  margin-left: 60px !important;
}
.hero-container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #eeeeee;
  position: relative;
}
.container-wrapper {
  /* background-color: #ffffff; */
  background-color: #f2f3f7;
  width: 100vw;
}
.mainContainer {
  display: flex;
  flex-direction: row;
  background: #f7f8fb;
  min-height: calc(100vh - 80px);
  margin-top: 50px;
}
@media all and (min-device-width: 360px) and (max-device-width: 540px) {
}
@media only screen and (max-width: 450px) {
  .container-wrapper {
    width: 100%;
  }
}

@media all and (min-device-width: 360px) and (max-device-width: 540px) {
  .mainContainer {
    display: inherit;
  }
}
</style>
