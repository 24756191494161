import { render, staticRenderFns } from "./ClientFileInfo.vue?vue&type=template&id=96646f0c&scoped=true&"
import script from "./ClientFileInfo.vue?vue&type=script&lang=ts&"
export * from "./ClientFileInfo.vue?vue&type=script&lang=ts&"
import style0 from "./ClientFileInfo.vue?vue&type=style&index=0&id=96646f0c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96646f0c",
  null
  
)

export default component.exports