
import MDP from "../MDP";
import FTickMDP from "./FTickMDP";

export default class FXAxisMDP implements MDP {
  type?: string | undefined;
  labelText?: string | undefined;
  labelPosition?: string | undefined;
  xAxisLabelKey: string;
  tick?: FTickMDP | undefined;
  showXAxis?: boolean;

  constructor(props: {
    type?: string;
    labelText?: string;
    labelPosition?: string;
    xAxisLabelKey: string;
    tick?: FTickMDP;
    showXAxis?: boolean;
  }) {
    this.type = props.type;
    this.labelText = props.labelText;
    this.labelPosition = props.labelPosition;
    this.xAxisLabelKey = props.xAxisLabelKey;
    this.tick = props.tick;
    this.showXAxis = props.showXAxis;
  }

  getMetaData(): object {
    return {
      type: this.type,
      labelText: this.labelText,
      labelPosition: this.labelPosition,
      xAxisLabelKey: this.xAxisLabelKey,
      tick: this.tick,
      show: this.showXAxis,
    };
  }
}
