<template>
  <div>
    <v-list-item-title
      class="primary--text text-body-2 font-weight-bold text-center py-2"
      >{{ chartTitle }}
    </v-list-item-title>
    <div class="chart-container" v-if="!!modelValue">
      <div ref="chart"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";

import c3 from "c3";
import * as d3 from "d3";
import FXAxisMDP from "./FXAxisMDP";
import FYAxisMDP from "./FYAxisMDP";
import FPointLabelsMDP from "./FPointLabelsMDP";
import ModelVue from "../ModelVue";
import Helper from "@/util/Helper";

@Component({
  components: {},
})
export default class FC3Chart extends ModelVue {
  @Prop({
    default: () => [],
  })
  dataSelectorKeyMetaDataList: any[];

  @Prop()
  chartTitle: string;

  @Prop()
  type: string;

  @Prop()
  xAxis: FXAxisMDP;

  @Prop()
  yAxis: FYAxisMDP;

  @Prop({ default: true })
  linePointShow: boolean;

  @Prop()
  pointLabels: FPointLabelsMDP;

  @Prop({ default: true })
  legendShow: boolean;

  @Prop({
    default: () => [],
  })
  colorPattern?: string[];

  mounted() {
    setTimeout(() => {
      this.createChart(this.chartData);
    }, 500);
  }

  @Watch("chartData") watchModelName(oldValue: any, newValue: any) {
    this.createChart(this.chartData);
  }

  get calculatePercentage() {
    let a = 800,
      b = 800;
    return ((b / a) * 100).toFixed(); // Fixed to 2 decimal places
  }

  get modelValue() {
    return this.value;
  }

  set modelValue(value) {
    this.modelValue = value;
  }

  get columns() {
    return this.dataSelectorKeyMetaDataList.map((keyMetaData) => {
      let arr = [keyMetaData.legend];
      this.modelValue.forEach((obj: any) => {
        if (
          Object.keys(obj).filter(
            (key) => obj[key] === keyMetaData.dataSelectorKey
          )
        ) {
          arr.push(obj[keyMetaData.dataSelectorKey]);
        }
      });
      return arr;
    });
  }

  set columns(value) {
    this.columns = value;
  }

  get types() {
    const types: { [key: string]: string } = {};
    this.dataSelectorKeyMetaDataList.forEach((chartMetaData) => {
      if (chartMetaData.type) {
        types[chartMetaData.legend] = chartMetaData.type;
      }
    });
    return types;
  }

  get pointlabelsLocal() {
    let labels: any;
    if (this.pointLabels.currencySymbols === true) {
      labels = {
        format: function (v: any) {
          return "₹" + d3.format(",")(v);
        }, // Format all labels as currency with Indian Rupee symbol
      };
    } else {
      labels = {
        format: function (v: any) {
          return d3.format(",")(v);
        }, // Format all labels as currency with Indian Rupee symbol
      };
    }
    return labels;
  }

  get percentageValueLocal() {
    let labels: any;
    if (this.pointLabels.percentageValue) {
      labels = {
        format: function (v: any) {
          return Helper.calculatePositivePercentage(v);
        },
      };
    }
    return labels;
  }

  get categoriesList() {
    return this.modelValue.map((value: any) => value[this.xAxis.xAxisLabelKey]);
  }

  get chartData() {
    return {
      columns: this.columns,
      types: this.types,
      labels: this.pointLabels.percentageValue
        ? this.percentageValueLocal
        : this.pointlabelsLocal,
    };
  }

  createChart(data: any) {
    const chartOptions: any = {
      bindto: this.$refs.chart as HTMLElement,
      data: data,
      // zoom: {
      //   enabled: true,
      // },
      axis: {
        x: {
          type: "category",
          categories: this.categoriesList,
          tick: {
            rotate: -25,
            multiline: false,
            // culling: {
            //   max: 10, // the number of tick texts will be adjusted to less than this value
            // },
          },
          label: {
            text: this.xAxis?.labelText,
            position: this.xAxis?.labelPosition,
          },
          show: this.xAxis?.showXAxis,
        },
        y: {
          label: {
            text: this.yAxis?.labelText,
            position: this.yAxis?.labelPosition,
          },
          show: this.yAxis?.showYAxis,
          max: this.yAxis?.max,
          min: this.yAxis?.min,
          ticks: 50,
          tick: this.pointlabelsLocal,
        },
      },
      point: {
        show: this.linePointShow,
      },
      // donut: {
      //   label: {
      //     show: this.donutLable,
      //   },
      // },
      legend: {
        show: this.legendShow,
      },
      color: {
        pattern: this.colorPattern || ["#00447a"],
      },
    };

    c3.generate(chartOptions);
  }
}
</script>

<style lang="css">
.c3 .c3-axis-x path,
.c3 .c3-axis-x line {
  stroke: #e0e0e0;
}
.c3 .c3-axis-y path,
.c3 .c3-axis-y line {
  stroke: #e0e0e0;
}
.c3-legend-item {
  fill: #6e6e6e;
}
</style>
