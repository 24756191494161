
import Vue from 'vue';
import Vuex from 'vuex';


import {LoginGetter, LoginMutation, LoginStoreInfo} from './store/login-store'; 
import {PartnerGetter, PartnerMutation, PartnerStoreInfo} from './store/partner-store'; 
import {InsightGetter, InsightMutation, InsightStoreInfo} from './store/insight-store'; 
import {SpineGetter, SpineMutation, SpineStoreInfo} from './store/spine-store'; 
import {ClientFileGetter, ClientFileMutation, ClientFileStoreInfo} from './store/clientfile-store'; 
import {ColorGetter, ColorMutation, ColorStoreInfo} from './store/color-store'; 
import {TaskListGetter, TaskListMutation, TaskListStoreInfo} from './store/tasklist-store'; 
import {ClientGetter, ClientMutation, ClientStoreInfo} from './store/client-store'; 
import {FiNoteGetter, FiNoteMutation, FiNoteStoreInfo} from './store/finote-store'; 
// tslint:disable

Vue.use(Vuex);

const storeInfo = {
     modules: {
      Login: LoginStoreInfo ,
Partner: PartnerStoreInfo ,
Insight: InsightStoreInfo ,
Spine: SpineStoreInfo ,
ClientFile: ClientFileStoreInfo ,
Color: ColorStoreInfo ,
TaskList: TaskListStoreInfo ,
Client: ClientStoreInfo ,
FiNote: FiNoteStoreInfo 
    }
};

const store = new Vuex.Store(storeInfo);


export const Getter = {
  Login : LoginGetter, Partner : PartnerGetter, Insight : InsightGetter, Spine : SpineGetter, ClientFile : ClientFileGetter, Color : ColorGetter, TaskList : TaskListGetter, Client : ClientGetter, FiNote : FiNoteGetter
};


export const Mutation = {
  Login : LoginMutation, Partner : PartnerMutation, Insight : InsightMutation, Spine : SpineMutation, ClientFile : ClientFileMutation, Color : ColorMutation, TaskList : TaskListMutation, Client : ClientMutation, FiNote : FiNoteMutation
};


export default store;

