
import * as Data from '@/../src-gen/data';
import {InsightStoreGetters} from '@/../src-gen/store/insight-store-support';

export class InsightStoreInit {
    public static initState(): Data.Insight.InsightStore  { return new Data.Insight.InsightStore(); }
}

export class InsightStoreGettersCode implements InsightStoreGetters {

   public demographyOutput(state: Data.Insight.InsightStore): Data.Insight.DemographyOutput[] {
     return state.demographyOutput
   }


   public partnerClientConversionFunnelOutput(state: Data.Insight.InsightStore): Data.Insight.PartnerClientConversionFunnelOutput {
    return state.partnerClientConversionFunnelOutput
   }


   public dailyFunnelOutput(state: Data.Insight.InsightStore): Data.Insight.DailyFunnelOutput[] {
     return state.dailyFunnelOutput
   }


   public monthlyFunnelOutput(state: Data.Insight.InsightStore): Data.Insight.MonthlyFunnelOutput[] {
     return state.monthlyFunnelOutput
   }


   public conversionSummaryOutput(state: Data.Insight.InsightStore): Data.Insight.ConversionSummaryOutput {
     return state.conversionSummaryOutput
   }

  public searchCriteria(state: Data.Insight.InsightStore): Data.Insight.SearchClientFileInput {
    return state.searchCriteria
  }
  public searchResultList(state: Data.Insight.InsightStore): Data.Insight.SearchClientFileOutput {
    return state.searchResultList
  }



}
