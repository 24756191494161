
import * as Data from "@/../src-gen/data";
import * as Action from "@/../src-gen/action";
import * as Snackbar from "node-snackbar";
import FFormMDP, { FFormChildMDP } from "@/components/form/FFormMDP";
import FCurrencyFieldMDP from "@/components/form/field/FCurrencyFieldMDP";
import FBtnMDP from "@/components/FBtnMDP";
import FFormSubmitBtnMDP from "@/components/FFormSubmitBtnMDP";


export default class AddPsEntryFFormMDP extends FFormMDP {
  childMDP = new FFormChildMDP();
  parent: any;
  constructor({
    parent,
  }: {
    parent: any;
  }) {
    super({
      myRefName: "addPsEntryFFormRef",
    });
    this.parent = parent;

    this.addField(new FCurrencyFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: "spaAmount",
        label: "SPA Amount",
        boundaryClass: "col-6",
        mandatory: true
    })).addField(new FCurrencyFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: "feeAmount",
        boundaryClass: "col-6",
        label: "Fee Amount"
    })).addAction(
      new FBtnMDP({
        label: "Cancel",
        onClick: () => this.parent.resetFormsTableAndData(),
      })
    ).addAction(
      new FFormSubmitBtnMDP({
        label: "Add Entry",
        onClick: () => this.modifyEntry(),
      })
    );
  }

  getMyRef() {
    return this.parent.$refs[this.myRefName];
  }

  modifyEntry() {
    Action.ClientFile.ModifyAmountWithFixedTenure.execute(this.parent.modifyAmountPSEListInput, output => {
      this.parent.resetFormsTableAndData();
      Snackbar.show({
        text: "Succesfully added an entry",
        pos: "bottom-center",
      });
    });
  }
}
