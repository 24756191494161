<template>
<v-card flat>
<v-container>
  <v-card outlined flat class="DRPPaymentCalculator">
    <component
      :ref="calculateAndRegisterClientFileMetaData.myRefName"
      :is="calculateAndRegisterClientFileMetaData.componentName"
      v-bind="calculateAndRegisterClientFileMetaData.props"
    ></component>
  </v-card>
</v-container>
</v-card>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
import * as ServerData from "@/../src-gen/server-data";
import * as Action from "@/../src-gen/action";
import TMOSSimulatorEditable from "@/components/generic/tmoStimulator/TMOSSimulatorEditable.vue";

import FForm from "@/components/form/FForm.vue";
import ModelVue from "@/components/ModelVue";

import CalculateAndRegisterClientFile from "@/components/generic/file/calculateAndRegister/CalculateAndRegisterClientFile.vue";
import CalculateAndRegisterClientFileMDP from "@/components/generic/file/calculateAndRegister/CalculateAndRegisterClientFileMDP";


@Component({
  components: {
    TMOSSimulatorEditable,
    FForm,
    CalculateAndRegisterClientFile
  },
})
export default class DRPPaymentCalculator extends ModelVue {
  
  get calculateAndRegisterClientFileMetaData() {
    return new CalculateAndRegisterClientFileMDP({
      root: this
    }).getMetaData();
  }

  
}
</script>

<style>
</style>
