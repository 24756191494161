
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class PaymentScheduleEntryWriterService {

   public static addPSEntry(
           axiosInstance: AxiosInstance, input: ServerData.PaymentScheduleEntryWebWriter.AddPSEntry$Input,
           f: (output: ServerData.PaymentScheduleEntryWebWriter.AddPSEntry$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/paymentscheduleentry/add-p-s-entry',
            input.toJson()
        ).then((response) => ServerData.PaymentScheduleEntryWebWriter.AddPSEntry$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static presentPSEntry(
           axiosInstance: AxiosInstance, input: ServerData.PaymentScheduleEntryWebWriter.PresentPSEntry$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/paymentscheduleentry/present-p-s-entry',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static removePSEntryList(
           axiosInstance: AxiosInstance, input: ServerData.PaymentScheduleEntryWebWriter.RemovePSEntryList$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/paymentscheduleentry/remove-p-s-entry-list',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static modifyAmountWithFixedTenure(
           axiosInstance: AxiosInstance, input: ServerData.PaymentScheduleEntryWebWriter.ModifyAmountWithFixedTenure$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/paymentscheduleentry/modify-amount-with-fixed-tenure',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static skip(
           axiosInstance: AxiosInstance, input: ServerData.PaymentScheduleEntryWebWriter.Skip$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'spineapi/paymentscheduleentry/skip',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


}

