import { render, staticRenderFns } from "./UnregisteredCustomers.vue?vue&type=template&id=4d31191b&scoped=true&"
import script from "./UnregisteredCustomers.vue?vue&type=script&lang=ts&"
export * from "./UnregisteredCustomers.vue?vue&type=script&lang=ts&"
import style0 from "./UnregisteredCustomers.vue?vue&type=style&index=0&id=4d31191b&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d31191b",
  null
  
)

export default component.exports