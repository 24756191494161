
import Vuex, { GetterTree, MutationTree } from 'vuex';
import {namespace} from 'vuex-class';
import * as Data from './../data';
// tslint:disable


import {MyClientFileStoreInit} from './../../src/section/affiliateapp/store/clientfile/MyClientFileStoreGettersCode';
import {MyClientFileStoreGettersCode} from './../../src/section/affiliateapp/store/clientfile/MyClientFileStoreGettersCode';
import {MyClientFileStoreMutationsCode} from './../../src/section/affiliateapp/store/clientfile/MyClientFileStoreMutationsCode';
import {MyClientFileStoreGetters} from './clientfile-store-support';
import {MyClientFileStoreMutations} from './clientfile-store-support';


import {ClientFileSummaryInit} from './../../src/section/affiliateapp/store/clientfile/ClientFileSummaryGettersCode';
import {ClientFileSummaryGettersCode} from './../../src/section/affiliateapp/store/clientfile/ClientFileSummaryGettersCode';
import {ClientFileSummaryMutationsCode} from './../../src/section/affiliateapp/store/clientfile/ClientFileSummaryMutationsCode';
import {ClientFileSummaryGetters} from './clientfile-store-support';
import {ClientFileSummaryMutations} from './clientfile-store-support';


import store from './../store';


const myClientFileStoreGetters: MyClientFileStoreGetters = new MyClientFileStoreGettersCode();
const MyClientFileStoreGetterTree: GetterTree<Data.ClientFile.MyClientFileStore, any> = {
  myClientFileList : (state: Data.ClientFile.MyClientFileStore) => myClientFileStoreGetters.myClientFileList(state),
myEMandatePendingCFList : (state: Data.ClientFile.MyClientFileStore) => myClientFileStoreGetters.myEMandatePendingCFList(state),
myEMandateActiveCFList : (state: Data.ClientFile.MyClientFileStore) => myClientFileStoreGetters.myEMandateActiveCFList(state),
myMSFPendingCFList : (state: Data.ClientFile.MyClientFileStore) => myClientFileStoreGetters.myMSFPendingCFList(state),
myMSFPaidCFList : (state: Data.ClientFile.MyClientFileStore) => myClientFileStoreGetters.myMSFPaidCFList(state),
myActiveCFList : (state: Data.ClientFile.MyClientFileStore) => myClientFileStoreGetters.myActiveCFList(state),
myAgreementCFList : (state: Data.ClientFile.MyClientFileStore) => myClientFileStoreGetters.myAgreementCFList(state),
myLeadCFList : (state: Data.ClientFile.MyClientFileStore) => myClientFileStoreGetters.myLeadCFList(state),
myHoldCFList : (state: Data.ClientFile.MyClientFileStore) => myClientFileStoreGetters.myHoldCFList(state),
msfEMandateEligibleCFList : (state: Data.ClientFile.MyClientFileStore) => myClientFileStoreGetters.msfEMandateEligibleCFList(state),
clientFileDashboardSummary : (state: Data.ClientFile.MyClientFileStore) => myClientFileStoreGetters.clientFileDashboardSummary(state)
};

const myClientFileStoreMutations: MyClientFileStoreMutations = new MyClientFileStoreMutationsCode();
const MyClientFileStoreMutationTree: MutationTree<Data.ClientFile.MyClientFileStore> = {
  updateMyClientFileList : (state: Data.ClientFile.MyClientFileStore, myClientFileList: Data.ClientFile.MyClientFile[] ) => myClientFileStoreMutations.updateMyClientFileList(state , myClientFileList  ) 
};

const storeMyClientFileStore = {
  namespaced: true,
  state: MyClientFileStoreInit.initState(),
  mutations : MyClientFileStoreMutationTree,
  getters: MyClientFileStoreGetterTree
};

const MyClientFileStoreSpace = namespace('ClientFile/MyClientFileStore');
const MyClientFileStoreGetter = {
    myClientFileList : MyClientFileStoreSpace.Getter('myClientFileList') ,
myEMandatePendingCFList : MyClientFileStoreSpace.Getter('myEMandatePendingCFList') ,
myEMandateActiveCFList : MyClientFileStoreSpace.Getter('myEMandateActiveCFList') ,
myMSFPendingCFList : MyClientFileStoreSpace.Getter('myMSFPendingCFList') ,
myMSFPaidCFList : MyClientFileStoreSpace.Getter('myMSFPaidCFList') ,
myActiveCFList : MyClientFileStoreSpace.Getter('myActiveCFList') ,
myAgreementCFList : MyClientFileStoreSpace.Getter('myAgreementCFList') ,
myLeadCFList : MyClientFileStoreSpace.Getter('myLeadCFList') ,
myHoldCFList : MyClientFileStoreSpace.Getter('myHoldCFList') ,
msfEMandateEligibleCFList : MyClientFileStoreSpace.Getter('msfEMandateEligibleCFList') ,
clientFileDashboardSummary : MyClientFileStoreSpace.Getter('clientFileDashboardSummary') 
};

class MyClientFileStoreMutationAPI {

  public static UPDATE_MY_CLIENT_FILE_LIST(myClientFileList: Data.ClientFile.MyClientFile[] ): void {
    store.commit('ClientFile/MyClientFileStore/updateMyClientFileList' , myClientFileList );
  }

}



const clientFileSummaryGetters: ClientFileSummaryGetters = new ClientFileSummaryGettersCode();
const ClientFileSummaryGetterTree: GetterTree<Data.ClientFile.ClientFileSummary, any> = {
  fiEMandateList : (state: Data.ClientFile.ClientFileSummary) => clientFileSummaryGetters.fiEMandateList(state),
clientFileBasicInfo : (state: Data.ClientFile.ClientFileSummary) => clientFileSummaryGetters.clientFileBasicInfo(state),
personalInfo : (state: Data.ClientFile.ClientFileSummary) => clientFileSummaryGetters.personalInfo(state),
fiBankInfo : (state: Data.ClientFile.ClientFileSummary) => clientFileSummaryGetters.fiBankInfo(state),
fiCreditorInfo : (state: Data.ClientFile.ClientFileSummary) => clientFileSummaryGetters.fiCreditorInfo(state),
fiDocumentList : (state: Data.ClientFile.ClientFileSummary) => clientFileSummaryGetters.fiDocumentList(state),
fiPaymentPlanInfo : (state: Data.ClientFile.ClientFileSummary) => clientFileSummaryGetters.fiPaymentPlanInfo(state),
budgetInfo : (state: Data.ClientFile.ClientFileSummary) => clientFileSummaryGetters.budgetInfo(state),
clCreditorList : (state: Data.ClientFile.ClientFileSummary) => clientFileSummaryGetters.clCreditorList(state),
fiEMandateSummaryList : (state: Data.ClientFile.ClientFileSummary) => clientFileSummaryGetters.fiEMandateSummaryList(state),
fiSignAgreementList : (state: Data.ClientFile.ClientFileSummary) => clientFileSummaryGetters.fiSignAgreementList(state),
fiPaymentList : (state: Data.ClientFile.ClientFileSummary) => clientFileSummaryGetters.fiPaymentList(state),
fiPaymentTransactionList : (state: Data.ClientFile.ClientFileSummary) => clientFileSummaryGetters.fiPaymentTransactionList(state),
fileSummary : (state: Data.ClientFile.ClientFileSummary) => clientFileSummaryGetters.fileSummary(state),
fiClientFileEnrollmentSummary : (state: Data.ClientFile.ClientFileSummary) => clientFileSummaryGetters.fiClientFileEnrollmentSummary(state),
msfCashfreePaymentList : (state: Data.ClientFile.ClientFileSummary) => clientFileSummaryGetters.msfCashfreePaymentList(state)
};

const clientFileSummaryMutations: ClientFileSummaryMutations = new ClientFileSummaryMutationsCode();
const ClientFileSummaryMutationTree: MutationTree<Data.ClientFile.ClientFileSummary> = {
  updateFiEMandateList : (state: Data.ClientFile.ClientFileSummary, fiEMandateList: Data.ClientFile.FiEMandateList[] ) => clientFileSummaryMutations.updateFiEMandateList(state , fiEMandateList  ) ,
updateClientFileBasicInfo : (state: Data.ClientFile.ClientFileSummary, clientFileBasicInfo: Data.ClientFile.ClientFileBasicInfo ) => clientFileSummaryMutations.updateClientFileBasicInfo(state , clientFileBasicInfo  ) ,
updateFiBankInfo : (state: Data.ClientFile.ClientFileSummary, fiBankInfo: Data.ClientFile.FiBankInfo|undefined ) => clientFileSummaryMutations.updateFiBankInfo(state , fiBankInfo  ) ,
updateFiCreditorInfo : (state: Data.ClientFile.ClientFileSummary, fiCreditorInfo: Data.ClientFile.FiCreditorInfo|undefined ) => clientFileSummaryMutations.updateFiCreditorInfo(state , fiCreditorInfo  ) ,
updateFiDocumentList : (state: Data.ClientFile.ClientFileSummary, fiDocumentList: Data.ClientFile.FiDocument[] ) => clientFileSummaryMutations.updateFiDocumentList(state , fiDocumentList  ) ,
updateFiPaymentPlan : (state: Data.ClientFile.ClientFileSummary, fiPaymentPlanInfo: Data.ClientFile.FiPaymentPlanInfo|undefined ) => clientFileSummaryMutations.updateFiPaymentPlan(state , fiPaymentPlanInfo  ) ,
updateBudgetInfo : (state: Data.ClientFile.ClientFileSummary, budgetInfo: Data.ClientFile.BudgetInfo|undefined ) => clientFileSummaryMutations.updateBudgetInfo(state , budgetInfo  ) ,
updatePersonalInfo : (state: Data.ClientFile.ClientFileSummary, personalInfo: Data.ClientFile.ClPersonalInfo|undefined ) => clientFileSummaryMutations.updatePersonalInfo(state , personalInfo  ) ,
updateClCreditorList : (state: Data.ClientFile.ClientFileSummary, clCreditorList: Data.ClientFile.ClCreditor[] ) => clientFileSummaryMutations.updateClCreditorList(state , clCreditorList  ) ,
updateFiEMandateSummaryList : (state: Data.ClientFile.ClientFileSummary, fiEMandateSummaryList: Data.ClientFile.FiEMandateSummary[] ) => clientFileSummaryMutations.updateFiEMandateSummaryList(state , fiEMandateSummaryList  ) ,
updateFiSignAgreementList : (state: Data.ClientFile.ClientFileSummary, fiSignAgreementList: Data.ClientFile.FiSSASummary[] ) => clientFileSummaryMutations.updateFiSignAgreementList(state , fiSignAgreementList  ) ,
updateFiPaymentList : (state: Data.ClientFile.ClientFileSummary, fiPaymentList: Data.ClientFile.FiPayment[] ) => clientFileSummaryMutations.updateFiPaymentList(state , fiPaymentList  ) ,
updateFiPaymentTransactionList : (state: Data.ClientFile.ClientFileSummary, fiPaymentTransactionList: Data.ClientFile.FiPaymentTransaction[] ) => clientFileSummaryMutations.updateFiPaymentTransactionList(state , fiPaymentTransactionList  ) ,
updateFileSummary : (state: Data.ClientFile.ClientFileSummary, fileSummary: Data.ClientFile.FileSummary ) => clientFileSummaryMutations.updateFileSummary(state , fileSummary  ) ,
updateClientFileEnrollmentSummary : (state: Data.ClientFile.ClientFileSummary, fiClientFileEnrollmentSummary: Data.ClientFile.ClientFileEnrollmentSummary ) => clientFileSummaryMutations.updateClientFileEnrollmentSummary(state , fiClientFileEnrollmentSummary  ) ,
resetCFSummary : (state: Data.ClientFile.ClientFileSummary ) => clientFileSummaryMutations.resetCFSummary(state   ) 
};

const storeClientFileSummary = {
  namespaced: true,
  state: ClientFileSummaryInit.initState(),
  mutations : ClientFileSummaryMutationTree,
  getters: ClientFileSummaryGetterTree
};

const ClientFileSummarySpace = namespace('ClientFile/ClientFileSummary');
const ClientFileSummaryGetter = {
    fiEMandateList : ClientFileSummarySpace.Getter('fiEMandateList') ,
clientFileBasicInfo : ClientFileSummarySpace.Getter('clientFileBasicInfo') ,
personalInfo : ClientFileSummarySpace.Getter('personalInfo') ,
fiBankInfo : ClientFileSummarySpace.Getter('fiBankInfo') ,
fiCreditorInfo : ClientFileSummarySpace.Getter('fiCreditorInfo') ,
fiDocumentList : ClientFileSummarySpace.Getter('fiDocumentList') ,
fiPaymentPlanInfo : ClientFileSummarySpace.Getter('fiPaymentPlanInfo') ,
budgetInfo : ClientFileSummarySpace.Getter('budgetInfo') ,
clCreditorList : ClientFileSummarySpace.Getter('clCreditorList') ,
fiEMandateSummaryList : ClientFileSummarySpace.Getter('fiEMandateSummaryList') ,
fiSignAgreementList : ClientFileSummarySpace.Getter('fiSignAgreementList') ,
fiPaymentList : ClientFileSummarySpace.Getter('fiPaymentList') ,
fiPaymentTransactionList : ClientFileSummarySpace.Getter('fiPaymentTransactionList') ,
fileSummary : ClientFileSummarySpace.Getter('fileSummary') ,
fiClientFileEnrollmentSummary : ClientFileSummarySpace.Getter('fiClientFileEnrollmentSummary') ,
msfCashfreePaymentList : ClientFileSummarySpace.Getter('msfCashfreePaymentList') 
};

class ClientFileSummaryMutationAPI {

  public static UPDATE_FI_E_MANDATE_LIST(fiEMandateList: Data.ClientFile.FiEMandateList[] ): void {
    store.commit('ClientFile/ClientFileSummary/updateFiEMandateList' , fiEMandateList );
  }


  public static UPDATE_CLIENT_FILE_BASIC_INFO(clientFileBasicInfo: Data.ClientFile.ClientFileBasicInfo ): void {
    store.commit('ClientFile/ClientFileSummary/updateClientFileBasicInfo' , clientFileBasicInfo );
  }


  public static UPDATE_FI_BANK_INFO(fiBankInfo: Data.ClientFile.FiBankInfo|undefined ): void {
    store.commit('ClientFile/ClientFileSummary/updateFiBankInfo' , fiBankInfo );
  }


  public static UPDATE_FI_CREDITOR_INFO(fiCreditorInfo: Data.ClientFile.FiCreditorInfo|undefined ): void {
    store.commit('ClientFile/ClientFileSummary/updateFiCreditorInfo' , fiCreditorInfo );
  }


  public static UPDATE_FI_DOCUMENT_LIST(fiDocumentList: Data.ClientFile.FiDocument[] ): void {
    store.commit('ClientFile/ClientFileSummary/updateFiDocumentList' , fiDocumentList );
  }


  public static UPDATE_FI_PAYMENT_PLAN(fiPaymentPlanInfo: Data.ClientFile.FiPaymentPlanInfo|undefined ): void {
    store.commit('ClientFile/ClientFileSummary/updateFiPaymentPlan' , fiPaymentPlanInfo );
  }


  public static UPDATE_BUDGET_INFO(budgetInfo: Data.ClientFile.BudgetInfo|undefined ): void {
    store.commit('ClientFile/ClientFileSummary/updateBudgetInfo' , budgetInfo );
  }


  public static UPDATE_PERSONAL_INFO(personalInfo: Data.ClientFile.ClPersonalInfo|undefined ): void {
    store.commit('ClientFile/ClientFileSummary/updatePersonalInfo' , personalInfo );
  }


  public static UPDATE_CL_CREDITOR_LIST(clCreditorList: Data.ClientFile.ClCreditor[] ): void {
    store.commit('ClientFile/ClientFileSummary/updateClCreditorList' , clCreditorList );
  }


  public static UPDATE_FI_E_MANDATE_SUMMARY_LIST(fiEMandateSummaryList: Data.ClientFile.FiEMandateSummary[] ): void {
    store.commit('ClientFile/ClientFileSummary/updateFiEMandateSummaryList' , fiEMandateSummaryList );
  }


  public static UPDATE_FI_SIGN_AGREEMENT_LIST(fiSignAgreementList: Data.ClientFile.FiSSASummary[] ): void {
    store.commit('ClientFile/ClientFileSummary/updateFiSignAgreementList' , fiSignAgreementList );
  }


  public static UPDATE_FI_PAYMENT_LIST(fiPaymentList: Data.ClientFile.FiPayment[] ): void {
    store.commit('ClientFile/ClientFileSummary/updateFiPaymentList' , fiPaymentList );
  }


  public static UPDATE_FI_PAYMENT_TRANSACTION_LIST(fiPaymentTransactionList: Data.ClientFile.FiPaymentTransaction[] ): void {
    store.commit('ClientFile/ClientFileSummary/updateFiPaymentTransactionList' , fiPaymentTransactionList );
  }


  public static UPDATE_FILE_SUMMARY(fileSummary: Data.ClientFile.FileSummary ): void {
    store.commit('ClientFile/ClientFileSummary/updateFileSummary' , fileSummary );
  }


  public static UPDATE_CLIENT_FILE_ENROLLMENT_SUMMARY(fiClientFileEnrollmentSummary: Data.ClientFile.ClientFileEnrollmentSummary ): void {
    store.commit('ClientFile/ClientFileSummary/updateClientFileEnrollmentSummary' , fiClientFileEnrollmentSummary );
  }


  public static RESET_C_F_SUMMARY( ): void {
    store.commit('ClientFile/ClientFileSummary/resetCFSummary'  );
  }

}




export const ClientFileStoreInfo = {
     namespaced: true,
     modules: {
      MyClientFileStore: storeMyClientFileStore,
ClientFileSummary: storeClientFileSummary
    }
};


export const ClientFileGetter = {
MyClientFileStore : MyClientFileStoreGetter, ClientFileSummary : ClientFileSummaryGetter
};

export const ClientFileMutation = {
MyClientFileStore : MyClientFileStoreMutationAPI, ClientFileSummary : ClientFileSummaryMutationAPI
};

