
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class PartnerWriterService {

   public static requestOnboarding(
           axiosInstance: AxiosInstance, input: ServerData.PartnerWebWriter.RequestOnboarding$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'partnerprogramapi/partner/request-onboarding',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static uploadCertificate(
           axiosInstance: AxiosInstance, input: ServerData.PartnerWebWriter.UploadCertificate$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'partnerprogramapi/partner/upload-certificate',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static addPartnerClient(
           axiosInstance: AxiosInstance, input: ServerData.PartnerWebWriter.AddPartnerClient$Input,
           f: (output: ServerData.PartnerWebWriter.AddPartnerClient$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'partnerprogramapi/partner/add-partner-client',
            input.toJson()
        ).then((response) => ServerData.PartnerWebWriter.AddPartnerClient$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static sendOTP(
           axiosInstance: AxiosInstance, input: ServerData.PartnerWebWriter.SendOTP$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'partnerprogramapi/partner/send-o-t-p',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static validateAndRegisterPartnerClientFile(
           axiosInstance: AxiosInstance, input: ServerData.PartnerWebWriter.ValidateAndRegisterPartnerClientFile$Input,
           f: (output: string) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            data : input.toJson()

         };
        return axiosInstance.post(
            'partnerprogramapi/partner/validate-and-register-partner-client-file',
            input.toJson()
        ).then((response) => response.data as string)
       .then((output) => f(output))
       .catch(theEh);
   }


}

