import FBtnMDP, { BtnType } from '@/components/FBtnMDP';
import FFormSubmitBtnMDP from '@/components/FFormSubmitBtnMDP';
import FFormMDP, { FFormChildMDP } from '@/components/form/FFormMDP';
import FCurrencyFieldMDP from '@/components/form/field/FCurrencyFieldMDP';
import FEmailFieldMDP from '@/components/form/field/FEmailFieldMDP';
import FPhoneFieldMDP from '@/components/form/field/FPhoneFieldMDP';
import FSelectFieldMDP from '@/components/form/field/FSelectFieldMDP';
import FTextFieldMDP from '@/components/form/field/FTextFieldMDP';

export default class AddPartnerFFormMDP extends FFormMDP {
  childMDP = new FFormChildMDP();
  root: any;
  // parent: any;
  constructor({ root }: { root: any }) {
    super({
      myRefName: 'addPartnerFFormRef',
    });
    this.root = root;
    // this.parent = parent;

    this.addField(
      new FTextFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: 'firstName',
        label: 'First Name',
        mandatory: true,
        boundaryClass: 'col-6',
      })
    )
      .addField(
        new FTextFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: 'lastName',
          label: 'Last Name',
          mandatory: true,
          boundaryClass: 'col-6',
        })
      )
      .addField(
        new FEmailFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: 'emailId',
          label: 'Email',
          mandatory: true,
          boundaryClass: 'col-12',
        })
      )
      .addField(
        new FPhoneFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: 'mobile',
          label: 'Mobile',
          mandatory: true,
          boundaryClass: 'col-12',
        })
      )
      .addField(
        new FCurrencyFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: 'totalCreditCardDebt',
          label: 'Total Credit Card Debt',
          mandatory: true,
          boundaryClass: 'col-6',
        })
      )
      .addField(
        new FCurrencyFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: 'totalPersonalLoanDebt',
          label: 'Total Personal Loan Debt',
          mandatory: true,
          boundaryClass: 'col-6',
        })
      )
      .addField(
        new FSelectFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: 'missedEmiInLast3Months',
          label: 'Missed any EMI in the last 3 months?',
          mandatory: true,
          options: [
            { id: true, name: 'Yes' },
            { id: false, name: 'No' },
          ],
          optionLabel: 'name',
          optionValue: 'id',
          boundaryClass: 'col-12',
        })
      )
      .addAction(
        new FBtnMDP({
          label: 'cancel',
          btnType: BtnType.TEXT,
          onClick: () => this.root.resetRegisterClientform(),
        })
      )

      .addAction(
        new FFormSubmitBtnMDP({
          label: 'Register',
          onClick: () => this.root.addPartnerClient(),
        })
      );
  }

  getMyRef(): any {
    return this.root.$refs[this.myRefName];
  }
}
