
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class FiNoteReaderService {

   public static getFiNoteList(
           axiosInstance: AxiosInstance, input: ServerData.FiNoteWebReader.GetFiNoteList$Input,
           f: (output: ServerData.SpineData$FiNote[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/finote/get-fi-note-list',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.SpineData$FiNote.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


}

