
import axios, { AxiosError, AxiosInstance } from 'axios';
import * as RemoteApiPoint from './../../src/remote-api-point';
import {EventBus} from './../../src/eventbus';

import * as Data from './../data';
import * as ServerData from './../server-data';
import ClientFileReaderService from './../service/spineapi/ClientFileReaderService';
import DocumentUploadReaderService from './../service/spineapi/DocumentUploadReaderService';
import GompaReaderService from './../service/spineapi/GompaReaderService';
import DocumentUploadWriterService from './../service/spineapi/DocumentUploadWriterService';
import ClientFileWriterService from './../service/spineapi/ClientFileWriterService';
import GompaWriterService from './../service/partnerprogramapi/GompaWriterService';
import UserWriterService from './../service/gompaapi/UserWriterService';
import TaskWriterService from './../service/valeyapi/TaskWriterService';
import DspTaskWriterService from './../service/dspapi/DspTaskWriterService';
import ClientWriterService from './../service/spineapi/ClientWriterService';

// tslint:disable



export class GetFiPresignedURLForCreditReportTempUpload {

    
    public static execute1(fileName: string, f: (output: ServerData.ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetFiPresignedURLForCreditReportTempUpload.execute(new ServerData.ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Input(fileName), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Input, f: (output: ServerData.ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileReaderService.getFiPresignedURLForCreditReportTempUpload(axoisInstance, this.mapInput(input),
            ((output: ServerData.ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Input): ServerData.ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Input {
        return new GetFiPresignedURLForCreditReportTempUpload$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Output): ServerData.ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Output {
        return new GetFiPresignedURLForCreditReportTempUpload$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetFiPresignedURLForCreditReportTempUpload$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Input): ServerData.ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Input {
        return input; 
    }

    public mapOutput(output: ServerData.ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Output): ServerData.ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Output {
        return output
    }

}

class GetFiPresignedURLForCreditReportTempUpload$MapperCode extends GetFiPresignedURLForCreditReportTempUpload$Mapper {

}

       


export class GetPresignedURLForUpload {

    

    

    public static execute(input: ServerData.DocumentUploadWebReader.GetPresignedURLForUpload$Input, f: (output: ServerData.DocumentUploadWebReader.GetPresignedURLForUpload$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        DocumentUploadReaderService.getPresignedURLForUpload(axoisInstance, this.mapInput(input),
            ((output: ServerData.DocumentUploadWebReader.GetPresignedURLForUpload$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.DocumentUploadWebReader.GetPresignedURLForUpload$Input): ServerData.DocumentUploadWebReader.GetPresignedURLForUpload$Input {
        return new GetPresignedURLForUpload$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.DocumentUploadWebReader.GetPresignedURLForUpload$Output): ServerData.DocumentUploadWebReader.GetPresignedURLForUpload$Output {
        return new GetPresignedURLForUpload$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetPresignedURLForUpload$Mapper {

    public mapInput(input: ServerData.DocumentUploadWebReader.GetPresignedURLForUpload$Input): ServerData.DocumentUploadWebReader.GetPresignedURLForUpload$Input {
        return input; 
    }

    public mapOutput(output: ServerData.DocumentUploadWebReader.GetPresignedURLForUpload$Output): ServerData.DocumentUploadWebReader.GetPresignedURLForUpload$Output {
        return output
    }

}

class GetPresignedURLForUpload$MapperCode extends GetPresignedURLForUpload$Mapper {

}

       


export class GetUploadedUrl {

    
    public static execute1(key: string, f: (output: ServerData.DocumentUploadWebReader.GetUploadedUrl$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetUploadedUrl.execute(new ServerData.DocumentUploadWebReader.GetUploadedUrl$Input(key), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.DocumentUploadWebReader.GetUploadedUrl$Input, f: (output: ServerData.DocumentUploadWebReader.GetUploadedUrl$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        DocumentUploadReaderService.getUploadedUrl(axoisInstance, this.mapInput(input),
            ((output: ServerData.DocumentUploadWebReader.GetUploadedUrl$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.DocumentUploadWebReader.GetUploadedUrl$Input): ServerData.DocumentUploadWebReader.GetUploadedUrl$Input {
        return new GetUploadedUrl$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.DocumentUploadWebReader.GetUploadedUrl$Output): ServerData.DocumentUploadWebReader.GetUploadedUrl$Output {
        return new GetUploadedUrl$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetUploadedUrl$Mapper {

    public mapInput(input: ServerData.DocumentUploadWebReader.GetUploadedUrl$Input): ServerData.DocumentUploadWebReader.GetUploadedUrl$Input {
        return input; 
    }

    public mapOutput(output: ServerData.DocumentUploadWebReader.GetUploadedUrl$Output): ServerData.DocumentUploadWebReader.GetUploadedUrl$Output {
        return output
    }

}

class GetUploadedUrl$MapperCode extends GetUploadedUrl$Mapper {

}

       


export class GetDocumentList {

    
    public static execute1(documentRefId: string, f: (output: ServerData.SpineData$DocumentDetails[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetDocumentList.execute(new ServerData.DocumentUploadWebReader.GetDocumentList$Input(documentRefId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.DocumentUploadWebReader.GetDocumentList$Input, f: (output: ServerData.SpineData$DocumentDetails[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        DocumentUploadReaderService.getDocumentList(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$DocumentDetails[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.DocumentUploadWebReader.GetDocumentList$Input): ServerData.DocumentUploadWebReader.GetDocumentList$Input {
        return new GetDocumentList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$DocumentDetails[]): ServerData.SpineData$DocumentDetails[] {
        return new GetDocumentList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetDocumentList$Mapper {

    public mapInput(input: ServerData.DocumentUploadWebReader.GetDocumentList$Input): ServerData.DocumentUploadWebReader.GetDocumentList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$DocumentDetails[]): ServerData.SpineData$DocumentDetails[] {
        return output
    }

}

class GetDocumentList$MapperCode extends GetDocumentList$Mapper {

}

       


export class GetFiPresignedURLForUpload {

    

    
    public static execute2(clientFileNumber: string, fileName: string, f: (output: ServerData.ClientFileWebReader.GetFiPresignedURLForUpload$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetFiPresignedURLForUpload.execute(new ServerData.ClientFileWebReader.GetFiPresignedURLForUpload$Input(clientFileNumber, fileName), f, eh, axoisInstance);
    } 

    public static execute(input: ServerData.ClientFileWebReader.GetFiPresignedURLForUpload$Input, f: (output: ServerData.ClientFileWebReader.GetFiPresignedURLForUpload$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileReaderService.getFiPresignedURLForUpload(axoisInstance, this.mapInput(input),
            ((output: ServerData.ClientFileWebReader.GetFiPresignedURLForUpload$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.GetFiPresignedURLForUpload$Input): ServerData.ClientFileWebReader.GetFiPresignedURLForUpload$Input {
        return new GetFiPresignedURLForUpload$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.ClientFileWebReader.GetFiPresignedURLForUpload$Output): ServerData.ClientFileWebReader.GetFiPresignedURLForUpload$Output {
        return new GetFiPresignedURLForUpload$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetFiPresignedURLForUpload$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.GetFiPresignedURLForUpload$Input): ServerData.ClientFileWebReader.GetFiPresignedURLForUpload$Input {
        return input; 
    }

    public mapOutput(output: ServerData.ClientFileWebReader.GetFiPresignedURLForUpload$Output): ServerData.ClientFileWebReader.GetFiPresignedURLForUpload$Output {
        return output
    }

}

class GetFiPresignedURLForUpload$MapperCode extends GetFiPresignedURLForUpload$Mapper {

}

       


export class GetFileUrl {

    
    public static execute1(key: string, f: (output: ServerData.ClientFileWebReader.GetFileUrl$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetFileUrl.execute(new ServerData.ClientFileWebReader.GetFileUrl$Input(key), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebReader.GetFileUrl$Input, f: (output: ServerData.ClientFileWebReader.GetFileUrl$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileReaderService.getFileUrl(axoisInstance, this.mapInput(input),
            ((output: ServerData.ClientFileWebReader.GetFileUrl$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.GetFileUrl$Input): ServerData.ClientFileWebReader.GetFileUrl$Input {
        return new GetFileUrl$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.ClientFileWebReader.GetFileUrl$Output): ServerData.ClientFileWebReader.GetFileUrl$Output {
        return new GetFileUrl$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetFileUrl$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.GetFileUrl$Input): ServerData.ClientFileWebReader.GetFileUrl$Input {
        return input; 
    }

    public mapOutput(output: ServerData.ClientFileWebReader.GetFileUrl$Output): ServerData.ClientFileWebReader.GetFileUrl$Output {
        return output
    }

}

class GetFileUrl$MapperCode extends GetFileUrl$Mapper {

}

       


export class GetUserOrgCode {

    
    public static execute1(userName: string, f: (output: ServerData.GompaWebReader.GetUserOrgCode$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetUserOrgCode.execute(new ServerData.GompaWebReader.GetUserOrgCode$Input(userName), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.GompaWebReader.GetUserOrgCode$Input, f: (output: ServerData.GompaWebReader.GetUserOrgCode$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        GompaReaderService.getUserOrgCode(axoisInstance, this.mapInput(input),
            ((output: ServerData.GompaWebReader.GetUserOrgCode$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.GompaWebReader.GetUserOrgCode$Input): ServerData.GompaWebReader.GetUserOrgCode$Input {
        return new GetUserOrgCode$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.GompaWebReader.GetUserOrgCode$Output): ServerData.GompaWebReader.GetUserOrgCode$Output {
        return new GetUserOrgCode$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetUserOrgCode$Mapper {

    public mapInput(input: ServerData.GompaWebReader.GetUserOrgCode$Input): ServerData.GompaWebReader.GetUserOrgCode$Input {
        return input; 
    }

    public mapOutput(output: ServerData.GompaWebReader.GetUserOrgCode$Output): ServerData.GompaWebReader.GetUserOrgCode$Output {
        return output
    }

}

class GetUserOrgCode$MapperCode extends GetUserOrgCode$Mapper {

}

       


export class AttachUploadedDocument {

    

    

    public static execute(input: Data.Spine.AttachUploadedDocumentInput, f: (output: ServerData.DocumentUploadWebWriter.AttachDocument$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        DocumentUploadWriterService.attachDocument(axoisInstance, this.mapInput(input),
            ((output: ServerData.DocumentUploadWebWriter.AttachDocument$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Spine.AttachUploadedDocumentInput): ServerData.DocumentUploadWebWriter.AttachDocument$Input {
        return new AttachUploadedDocument$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.DocumentUploadWebWriter.AttachDocument$Output): ServerData.DocumentUploadWebWriter.AttachDocument$Output {
        return new AttachUploadedDocument$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class AttachUploadedDocument$Mapper {

    public mapInput(input: Data.Spine.AttachUploadedDocumentInput): ServerData.DocumentUploadWebWriter.AttachDocument$Input {
        return ServerData.DocumentUploadWebWriter.AttachDocument$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.DocumentUploadWebWriter.AttachDocument$Output): ServerData.DocumentUploadWebWriter.AttachDocument$Output {
        return output
    }

}

class AttachUploadedDocument$MapperCode extends AttachUploadedDocument$Mapper {

}

       


export class AttachDocument {

    

    

    public static execute(input: Data.Spine.AttachDocumentInput, f: (output: ServerData.ClientFileWebWriter.AttachDocument$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.attachDocument(axoisInstance, this.mapInput(input),
            ((output: ServerData.ClientFileWebWriter.AttachDocument$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('Spine::AttachDocument', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: ServerData.ClientFileWebWriter.AttachDocument$Output) => void): (output: ServerData.ClientFileWebWriter.AttachDocument$Output) => void {
        EventBus.$on('Spine::AttachDocument', f);
        return f;
    }

    public static notInterested(f: (output: ServerData.ClientFileWebWriter.AttachDocument$Output) => void): void {
        EventBus.$off('Spine::AttachDocument', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('Spine::AttachDocument');
    }
    

    private static mapInput(input: Data.Spine.AttachDocumentInput): ServerData.ClientFileWebWriter.AttachDocument$Input {
        return new AttachDocument$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.ClientFileWebWriter.AttachDocument$Output): ServerData.ClientFileWebWriter.AttachDocument$Output {
        return new AttachDocument$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class AttachDocument$Mapper {

    public mapInput(input: Data.Spine.AttachDocumentInput): ServerData.ClientFileWebWriter.AttachDocument$Input {
        return ServerData.ClientFileWebWriter.AttachDocument$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.ClientFileWebWriter.AttachDocument$Output): ServerData.ClientFileWebWriter.AttachDocument$Output {
        return output
    }

}

class AttachDocument$MapperCode extends AttachDocument$Mapper {

}

       


export class DetachDocument {

    
    public static execute1(fiDocumentId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      DetachDocument.execute(new ServerData.ClientFileWebWriter.DetachDocument$Input(fiDocumentId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebWriter.DetachDocument$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.detachDocument(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('Spine::DetachDocument', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('Spine::DetachDocument', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('Spine::DetachDocument', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('Spine::DetachDocument');
    }
    

    private static mapInput(input: ServerData.ClientFileWebWriter.DetachDocument$Input): ServerData.ClientFileWebWriter.DetachDocument$Input {
        return new DetachDocument$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new DetachDocument$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class DetachDocument$Mapper {

    public mapInput(input: ServerData.ClientFileWebWriter.DetachDocument$Input): ServerData.ClientFileWebWriter.DetachDocument$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class DetachDocument$MapperCode extends DetachDocument$Mapper {

}

       


export class DetachUploadedDocument {

    
    public static execute1(documentId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      DetachUploadedDocument.execute(new ServerData.DocumentUploadWebWriter.DetachDocument$Input(documentId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.DocumentUploadWebWriter.DetachDocument$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        DocumentUploadWriterService.detachDocument(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.DocumentUploadWebWriter.DetachDocument$Input): ServerData.DocumentUploadWebWriter.DetachDocument$Input {
        return new DetachUploadedDocument$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new DetachUploadedDocument$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class DetachUploadedDocument$Mapper {

    public mapInput(input: ServerData.DocumentUploadWebWriter.DetachDocument$Input): ServerData.DocumentUploadWebWriter.DetachDocument$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class DetachUploadedDocument$MapperCode extends DetachUploadedDocument$Mapper {

}

       


export class GetLeadSquareId {

    
    public static execute1(emailId: string, f: (output: ServerData.GompaWebWriter.GetLeadSquareId$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetLeadSquareId.execute(new ServerData.GompaWebWriter.GetLeadSquareId$Input(emailId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.GompaWebWriter.GetLeadSquareId$Input, f: (output: ServerData.GompaWebWriter.GetLeadSquareId$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        GompaWriterService.getLeadSquareId(axoisInstance, this.mapInput(input),
            ((output: ServerData.GompaWebWriter.GetLeadSquareId$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.GompaWebWriter.GetLeadSquareId$Input): ServerData.GompaWebWriter.GetLeadSquareId$Input {
        return new GetLeadSquareId$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.GompaWebWriter.GetLeadSquareId$Output): ServerData.GompaWebWriter.GetLeadSquareId$Output {
        return new GetLeadSquareId$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetLeadSquareId$Mapper {

    public mapInput(input: ServerData.GompaWebWriter.GetLeadSquareId$Input): ServerData.GompaWebWriter.GetLeadSquareId$Input {
        return input; 
    }

    public mapOutput(output: ServerData.GompaWebWriter.GetLeadSquareId$Output): ServerData.GompaWebWriter.GetLeadSquareId$Output {
        return output
    }

}

class GetLeadSquareId$MapperCode extends GetLeadSquareId$Mapper {

}

       


export class ChangePassword {

    

    

    public static execute(input: ServerData.UserWebWriter.ChangePassword$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.GompaApi): void {

    

    

        UserWriterService.changePassword(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.UserWebWriter.ChangePassword$Input): ServerData.UserWebWriter.ChangePassword$Input {
        return new ChangePassword$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new ChangePassword$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class ChangePassword$Mapper {

    public mapInput(input: ServerData.UserWebWriter.ChangePassword$Input): ServerData.UserWebWriter.ChangePassword$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class ChangePassword$MapperCode extends ChangePassword$Mapper {

}

       


export class Assign {

    

    

    public static execute(input: Data.Spine.AssignInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.ValeyApi): void {

    

    

        TaskWriterService.assign(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('Spine::Assign', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('Spine::Assign', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('Spine::Assign', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('Spine::Assign');
    }
    

    private static mapInput(input: Data.Spine.AssignInput): ServerData.TaskWebWriter.Assign$Input {
        return new Assign$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new Assign$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class Assign$Mapper {

    public mapInput(input: Data.Spine.AssignInput): ServerData.TaskWebWriter.Assign$Input {
        return ServerData.TaskWebWriter.Assign$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class Assign$MapperCode extends Assign$Mapper {

}

       


export class Reassign {

    

    

    public static execute(input: Data.Spine.ReassignInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.ValeyApi): void {

    

    

        TaskWriterService.reassign(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('Spine::Reassign', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('Spine::Reassign', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('Spine::Reassign', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('Spine::Reassign');
    }
    

    private static mapInput(input: Data.Spine.ReassignInput): ServerData.TaskWebWriter.Reassign$Input {
        return new Reassign$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new Reassign$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class Reassign$Mapper {

    public mapInput(input: Data.Spine.ReassignInput): ServerData.TaskWebWriter.Reassign$Input {
        return ServerData.TaskWebWriter.Reassign$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class Reassign$MapperCode extends Reassign$Mapper {

}

       


export class ReceiveFirstMSFPayment {

    

    
    public static execute2(taskId: string, clientFileId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.DspApi): void {
      ReceiveFirstMSFPayment.execute(new Data.Spine.ReceiveFirstMSFPaymentInput(taskId, clientFileId), f, eh, axoisInstance);
    } 

    public static execute(input: Data.Spine.ReceiveFirstMSFPaymentInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.DspApi): void {

    

    

        DspTaskWriterService.receiveFirstMSFPayment(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('Spine::ReceiveFirstMSFPayment', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('Spine::ReceiveFirstMSFPayment', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('Spine::ReceiveFirstMSFPayment', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('Spine::ReceiveFirstMSFPayment');
    }
    

    private static mapInput(input: Data.Spine.ReceiveFirstMSFPaymentInput): ServerData.DspTaskWebWriter.ReceiveFirstMSFPayment$Input {
        return new ReceiveFirstMSFPayment$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new ReceiveFirstMSFPayment$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class ReceiveFirstMSFPayment$Mapper {

    public mapInput(input: Data.Spine.ReceiveFirstMSFPaymentInput): ServerData.DspTaskWebWriter.ReceiveFirstMSFPayment$Input {
        return ServerData.DspTaskWebWriter.ReceiveFirstMSFPayment$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class ReceiveFirstMSFPayment$MapperCode extends ReceiveFirstMSFPayment$Mapper {

}

       


export class UpdateFirstMSFPaymentStatus {

    

    

    public static execute(input: Data.Spine.UpdateFirstMSFPaymentStatusInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.DspApi): void {

    

    

        DspTaskWriterService.updateFirstMSFPaymentStatus(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('Spine::UpdateFirstMSFPaymentStatus', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('Spine::UpdateFirstMSFPaymentStatus', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('Spine::UpdateFirstMSFPaymentStatus', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('Spine::UpdateFirstMSFPaymentStatus');
    }
    

    private static mapInput(input: Data.Spine.UpdateFirstMSFPaymentStatusInput): ServerData.DspTaskWebWriter.UpdateFirstMSFPaymentStatus$Input {
        return new UpdateFirstMSFPaymentStatus$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new UpdateFirstMSFPaymentStatus$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class UpdateFirstMSFPaymentStatus$Mapper {

    public mapInput(input: Data.Spine.UpdateFirstMSFPaymentStatusInput): ServerData.DspTaskWebWriter.UpdateFirstMSFPaymentStatus$Input {
        return ServerData.DspTaskWebWriter.UpdateFirstMSFPaymentStatus$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class UpdateFirstMSFPaymentStatus$MapperCode extends UpdateFirstMSFPaymentStatus$Mapper {

}

       


export class UpdateMsfPaymentStatus {

    

    

    public static execute(input: Data.Spine.UpdateMsfPaymentStatusInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.DspApi): void {

    

    

        DspTaskWriterService.updateMsfPaymentStatus(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('Spine::UpdateMsfPaymentStatus', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('Spine::UpdateMsfPaymentStatus', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('Spine::UpdateMsfPaymentStatus', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('Spine::UpdateMsfPaymentStatus');
    }
    

    private static mapInput(input: Data.Spine.UpdateMsfPaymentStatusInput): ServerData.DspTaskWebWriter.UpdateMsfPaymentStatus$Input {
        return new UpdateMsfPaymentStatus$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new UpdateMsfPaymentStatus$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class UpdateMsfPaymentStatus$Mapper {

    public mapInput(input: Data.Spine.UpdateMsfPaymentStatusInput): ServerData.DspTaskWebWriter.UpdateMsfPaymentStatus$Input {
        return ServerData.DspTaskWebWriter.UpdateMsfPaymentStatus$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class UpdateMsfPaymentStatus$MapperCode extends UpdateMsfPaymentStatus$Mapper {

}

       


export class ReceiveMSFPayment {

    

    

    public static execute(input: Data.Spine.ReceiveMSFPaymentInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.DspApi): void {

    

    

        DspTaskWriterService.receiveMSFPayment(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('Spine::ReceiveMSFPayment', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('Spine::ReceiveMSFPayment', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('Spine::ReceiveMSFPayment', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('Spine::ReceiveMSFPayment');
    }
    

    private static mapInput(input: Data.Spine.ReceiveMSFPaymentInput): ServerData.DspTaskWebWriter.ReceiveMSFPayment$Input {
        return new ReceiveMSFPayment$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new ReceiveMSFPayment$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class ReceiveMSFPayment$Mapper {

    public mapInput(input: Data.Spine.ReceiveMSFPaymentInput): ServerData.DspTaskWebWriter.ReceiveMSFPayment$Input {
        return ServerData.DspTaskWebWriter.ReceiveMSFPayment$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class ReceiveMSFPayment$MapperCode extends ReceiveMSFPayment$Mapper {

}

       


export class UpdateClPersonalInfo {

    

    

    public static execute(input: Data.Spine.UpdateClPersonalInfoInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientWriterService.updateClPersonalInfo(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('Spine::UpdateClPersonalInfo', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('Spine::UpdateClPersonalInfo', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('Spine::UpdateClPersonalInfo', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('Spine::UpdateClPersonalInfo');
    }
    

    private static mapInput(input: Data.Spine.UpdateClPersonalInfoInput): ServerData.ClientWebWriter.UpdateClPersonalInfo$Input {
        return new UpdateClPersonalInfo$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new UpdateClPersonalInfo$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class UpdateClPersonalInfo$Mapper {

    public mapInput(input: Data.Spine.UpdateClPersonalInfoInput): ServerData.ClientWebWriter.UpdateClPersonalInfo$Input {
        return ServerData.ClientWebWriter.UpdateClPersonalInfo$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class UpdateClPersonalInfo$MapperCode extends UpdateClPersonalInfo$Mapper {

}

       


export class ParseCreditReport {

    

    

    public static execute(input: Data.Spine.ParseCreditReportInput, f: (output: Data.Spine.ParseCreditReportOutput) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.parseCreditReport(axoisInstance, this.mapInput(input),
            ((output: ServerData.ClientFileWebWriter.ParseCreditReport$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('Spine::ParseCreditReport', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: Data.Spine.ParseCreditReportOutput) => void): (output: Data.Spine.ParseCreditReportOutput) => void {
        EventBus.$on('Spine::ParseCreditReport', f);
        return f;
    }

    public static notInterested(f: (output: Data.Spine.ParseCreditReportOutput) => void): void {
        EventBus.$off('Spine::ParseCreditReport', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('Spine::ParseCreditReport');
    }
    

    private static mapInput(input: Data.Spine.ParseCreditReportInput): ServerData.ClientFileWebWriter.ParseCreditReport$Input {
        return new ParseCreditReport$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.ClientFileWebWriter.ParseCreditReport$Output): Data.Spine.ParseCreditReportOutput {
        return new ParseCreditReport$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class ParseCreditReport$Mapper {

    public mapInput(input: Data.Spine.ParseCreditReportInput): ServerData.ClientFileWebWriter.ParseCreditReport$Input {
        return ServerData.ClientFileWebWriter.ParseCreditReport$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.ClientFileWebWriter.ParseCreditReport$Output): Data.Spine.ParseCreditReportOutput {
        return Data.Spine.ParseCreditReportOutput.fromJson(output.toJson());
    }

}

class ParseCreditReport$MapperCode extends ParseCreditReport$Mapper {

}

       

     