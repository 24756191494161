
import Vuex, { GetterTree, MutationTree } from 'vuex';
import {namespace} from 'vuex-class';
import * as Data from './../data';
// tslint:disable


import {BenchTaskSummaryInit} from './../../src/section/affiliateapp/store/tasklist/BenchTaskSummaryGettersCode';
import {BenchTaskSummaryGettersCode} from './../../src/section/affiliateapp/store/tasklist/BenchTaskSummaryGettersCode';
import {BenchTaskSummaryMutationsCode} from './../../src/section/affiliateapp/store/tasklist/BenchTaskSummaryMutationsCode';
import {BenchTaskSummaryGetters} from './tasklist-store-support';
import {BenchTaskSummaryMutations} from './tasklist-store-support';


import {MyTaskStoreInit} from './../../src/section/affiliateapp/store/tasklist/MyTaskStoreGettersCode';
import {MyTaskStoreGettersCode} from './../../src/section/affiliateapp/store/tasklist/MyTaskStoreGettersCode';
import {MyTaskStoreMutationsCode} from './../../src/section/affiliateapp/store/tasklist/MyTaskStoreMutationsCode';
import {MyTaskStoreGetters} from './tasklist-store-support';
import {MyTaskStoreMutations} from './tasklist-store-support';


import {SummaryInit} from './../../src/section/affiliateapp/store/tasklist/SummaryGettersCode';
import {SummaryGettersCode} from './../../src/section/affiliateapp/store/tasklist/SummaryGettersCode';
import {SummaryMutationsCode} from './../../src/section/affiliateapp/store/tasklist/SummaryMutationsCode';
import {SummaryGetters} from './tasklist-store-support';
import {SummaryMutations} from './tasklist-store-support';


import store from './../store';


const benchTaskSummaryGetters: BenchTaskSummaryGetters = new BenchTaskSummaryGettersCode();
const BenchTaskSummaryGetterTree: GetterTree<Data.TaskList.BenchTaskSummary, any> = {
  countSummary : (state: Data.TaskList.BenchTaskSummary) => benchTaskSummaryGetters.countSummary(state),
cfTaskList : (state: Data.TaskList.BenchTaskSummary) => benchTaskSummaryGetters.cfTaskList(state),
cfActiveTaskList : (state: Data.TaskList.BenchTaskSummary) => benchTaskSummaryGetters.cfActiveTaskList(state)
};

const benchTaskSummaryMutations: BenchTaskSummaryMutations = new BenchTaskSummaryMutationsCode();
const BenchTaskSummaryMutationTree: MutationTree<Data.TaskList.BenchTaskSummary> = {
  setCountSummary : (state: Data.TaskList.BenchTaskSummary, countSummary: Data.TaskList.CountSummary ) => benchTaskSummaryMutations.setCountSummary(state , countSummary  ) ,
setCfTaskList : (state: Data.TaskList.BenchTaskSummary, cfTaskList: Data.TaskList.GetTaskListByCidGrid[] ) => benchTaskSummaryMutations.setCfTaskList(state , cfTaskList  ) 
};

const storeBenchTaskSummary = {
  namespaced: true,
  state: BenchTaskSummaryInit.initState(),
  mutations : BenchTaskSummaryMutationTree,
  getters: BenchTaskSummaryGetterTree
};

const BenchTaskSummarySpace = namespace('TaskList/BenchTaskSummary');
const BenchTaskSummaryGetter = {
    countSummary : BenchTaskSummarySpace.Getter('countSummary') ,
cfTaskList : BenchTaskSummarySpace.Getter('cfTaskList') ,
cfActiveTaskList : BenchTaskSummarySpace.Getter('cfActiveTaskList') 
};

class BenchTaskSummaryMutationAPI {

  public static SET_COUNT_SUMMARY(countSummary: Data.TaskList.CountSummary ): void {
    store.commit('TaskList/BenchTaskSummary/setCountSummary' , countSummary );
  }


  public static SET_CF_TASK_LIST(cfTaskList: Data.TaskList.GetTaskListByCidGrid[] ): void {
    store.commit('TaskList/BenchTaskSummary/setCfTaskList' , cfTaskList );
  }

}



const myTaskStoreGetters: MyTaskStoreGetters = new MyTaskStoreGettersCode();
const MyTaskStoreGetterTree: GetterTree<Data.TaskList.MyTaskStore, any> = {
  myActiveTaskList : (state: Data.TaskList.MyTaskStore) => myTaskStoreGetters.myActiveTaskList(state),
myCompletedTaskList : (state: Data.TaskList.MyTaskStore) => myTaskStoreGetters.myCompletedTaskList(state),
mySuspendedTaskList : (state: Data.TaskList.MyTaskStore) => myTaskStoreGetters.mySuspendedTaskList(state),
myTaskDashboardSummary : (state: Data.TaskList.MyTaskStore) => myTaskStoreGetters.myTaskDashboardSummary(state),
taskCommentList : (state: Data.TaskList.MyTaskStore) => myTaskStoreGetters.taskCommentList(state)
};

const myTaskStoreMutations: MyTaskStoreMutations = new MyTaskStoreMutationsCode();
const MyTaskStoreMutationTree: MutationTree<Data.TaskList.MyTaskStore> = {
  updateMyActiveTaskList : (state: Data.TaskList.MyTaskStore, activeTaskList: Data.TaskList.GetActiveTaskListAllocatedGrid[] ) => myTaskStoreMutations.updateMyActiveTaskList(state , activeTaskList  ) ,
updateCompletedTaskList : (state: Data.TaskList.MyTaskStore, completedTaskList: Data.TaskList.CompletedTaskGrid[] ) => myTaskStoreMutations.updateCompletedTaskList(state , completedTaskList  ) ,
updateMySuspendedTaskList : (state: Data.TaskList.MyTaskStore, suspendedTaskList: Data.TaskList.SuspendedTaskAllocated[] ) => myTaskStoreMutations.updateMySuspendedTaskList(state , suspendedTaskList  ) ,
updateTaskCommentList : (state: Data.TaskList.MyTaskStore, taskCommentList: Data.TaskList.Comment[] ) => myTaskStoreMutations.updateTaskCommentList(state , taskCommentList  ) 
};

const storeMyTaskStore = {
  namespaced: true,
  state: MyTaskStoreInit.initState(),
  mutations : MyTaskStoreMutationTree,
  getters: MyTaskStoreGetterTree
};

const MyTaskStoreSpace = namespace('TaskList/MyTaskStore');
const MyTaskStoreGetter = {
    myActiveTaskList : MyTaskStoreSpace.Getter('myActiveTaskList') ,
myCompletedTaskList : MyTaskStoreSpace.Getter('myCompletedTaskList') ,
mySuspendedTaskList : MyTaskStoreSpace.Getter('mySuspendedTaskList') ,
myTaskDashboardSummary : MyTaskStoreSpace.Getter('myTaskDashboardSummary') ,
taskCommentList : MyTaskStoreSpace.Getter('taskCommentList') 
};

class MyTaskStoreMutationAPI {

  public static UPDATE_MY_ACTIVE_TASK_LIST(activeTaskList: Data.TaskList.GetActiveTaskListAllocatedGrid[] ): void {
    store.commit('TaskList/MyTaskStore/updateMyActiveTaskList' , activeTaskList );
  }


  public static UPDATE_COMPLETED_TASK_LIST(completedTaskList: Data.TaskList.CompletedTaskGrid[] ): void {
    store.commit('TaskList/MyTaskStore/updateCompletedTaskList' , completedTaskList );
  }


  public static UPDATE_MY_SUSPENDED_TASK_LIST(suspendedTaskList: Data.TaskList.SuspendedTaskAllocated[] ): void {
    store.commit('TaskList/MyTaskStore/updateMySuspendedTaskList' , suspendedTaskList );
  }


  public static UPDATE_TASK_COMMENT_LIST(taskCommentList: Data.TaskList.Comment[] ): void {
    store.commit('TaskList/MyTaskStore/updateTaskCommentList' , taskCommentList );
  }

}



const summaryGetters: SummaryGetters = new SummaryGettersCode();
const SummaryGetterTree: GetterTree<Data.TaskList.Summary, any> = {
  executiveTaskDetails : (state: Data.TaskList.Summary) => summaryGetters.executiveTaskDetails(state),
isTaskOutputSchemaEmpty : (state: Data.TaskList.Summary) => summaryGetters.isTaskOutputSchemaEmpty(state),
isTaskInputSchemaEmpty : (state: Data.TaskList.Summary) => summaryGetters.isTaskInputSchemaEmpty(state)
};

const summaryMutations: SummaryMutations = new SummaryMutationsCode();
const SummaryMutationTree: MutationTree<Data.TaskList.Summary> = {
  setExecutiveTaskDetails : (state: Data.TaskList.Summary, taskDetails: Data.TaskList.ExecutiveTaskDetails ) => summaryMutations.setExecutiveTaskDetails(state , taskDetails  ) 
};

const storeSummary = {
  namespaced: true,
  state: SummaryInit.initState(),
  mutations : SummaryMutationTree,
  getters: SummaryGetterTree
};

const SummarySpace = namespace('TaskList/Summary');
const SummaryGetter = {
    executiveTaskDetails : SummarySpace.Getter('executiveTaskDetails') ,
isTaskOutputSchemaEmpty : SummarySpace.Getter('isTaskOutputSchemaEmpty') ,
isTaskInputSchemaEmpty : SummarySpace.Getter('isTaskInputSchemaEmpty') 
};

class SummaryMutationAPI {

  public static SET_EXECUTIVE_TASK_DETAILS(taskDetails: Data.TaskList.ExecutiveTaskDetails ): void {
    store.commit('TaskList/Summary/setExecutiveTaskDetails' , taskDetails );
  }

}




export const TaskListStoreInfo = {
     namespaced: true,
     modules: {
      BenchTaskSummary: storeBenchTaskSummary,
MyTaskStore: storeMyTaskStore,
Summary: storeSummary
    }
};


export const TaskListGetter = {
BenchTaskSummary : BenchTaskSummaryGetter, MyTaskStore : MyTaskStoreGetter, Summary : SummaryGetter
};

export const TaskListMutation = {
BenchTaskSummary : BenchTaskSummaryMutationAPI, MyTaskStore : MyTaskStoreMutationAPI, Summary : SummaryMutationAPI
};

