<template>
  <span >
    {{(selectModel(modelValue,dataSelectorKey) ||'--') | initial}}
  </span>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ModelVue from "../../ModelVue";

@Component({
  components: {
  },
})
export default class FCellAbbrevation extends ModelVue {
 
}
</script>
