
import Vuex, { GetterTree, MutationTree } from 'vuex';
import {namespace} from 'vuex-class';
import * as Data from './../data';
// tslint:disable



import store from './../store';




export const ColorStoreInfo = {
     namespaced: true,
     modules: {
      
    }
};


export const ColorGetter = {

};

export const ColorMutation = {

};

