import FCreditorMDP from "@/components/generic/file/creditor/FCreditorMDP";
import FSnackbar from "@/fsnackbar";

export default class ECFTCreditorStepFCreditorMDP extends FCreditorMDP {
  constructor({ taskRoot, parent }: { taskRoot: any; parent: any }) {
    super({
      taskRoot: taskRoot,
      parent: parent,
      myRefName: "creditorRef",
      dataSelectorKey: "taskOutput.creditorInfo",
      disabled: taskRoot.taskDisabled,
    });
  }

  getMyRef() {
    return this.parent.getMyRef().$refs[this.myRefName];
  }

  validateAndSumbit() {
    return (nextCallback?: () => void) => {
      if (
        !!this.taskRoot.clientFileBasicInfo.creditScore
      ) {
        if(nextCallback)
        nextCallback();
      } else {
        FSnackbar.error("Please update the credit score to proceed")
      }
    };
  }
}
