<template>
  <div class="d-flex align-center">
    <div class="dot mx-2" :style="`background:${textColor};opacity:0.7`"></div>
    <div class="flex-fill">
      <router-link
      class="primary--text"
        style="text-decoration: none; color: inherit"
        :to="routerObject"
      >
        {{ (selectModel(modelValue, dataSelectorKey) || "--") | truncate(24) }}
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ModelVue from "../../ModelVue";

@Component({
  components: {},
})
export default class FCellCreditorName extends ModelVue {
  get textColor(): string {
    return this.selectModel(
      this.modelValue,
      "creditorMasterInfo.isServicedByFreed"
    )
      ? "green"
      : "#F5885B";
  }

  get routerObject() {
    return {
      name: "Root.CFile.CFCreditorDetails.CFCreditorDetailsRedirect",
      params: {
        fiCreditorId: this.selectModel(this.modelValue, "fiCreditorId"),
      },
    };
  }
}
</script>

<style scoped>
.dot {
  position: relative;
  width: fit-content;
  border-radius: 40px;
  width: 8px !important;
  height: 8px;
}
</style>
