<template>
  <div class="successModal" v-if="dialog">
    <div class="onboardingContentSuccess">
      <div class="freedLogoBg">
        <div class="freedLogo">
          <img src="@/assets/images/paper-plane-orange.svg" />
        </div>
      </div>
      <div class="descriptionSuccessContent">
        <div class="successHeading">Success</div>
        <div class="descriptionSuccess">
          Thank you for requesting your on-boarding call.
        </div>
        <div class="descriptionSuccessSub">
          We'll get in touch with you within the next 24-48 hours.
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
import * as Action from "@/../src-gen/action";

@Component({
  components: {},
})
export default class OnboardingSuccessCard extends Vue {
  @Store.Getter.Partner.ApplicantDetails.PartnerInfo
  PartnerInfo: Data.Partner.PartnerSummary;
  get dialog() {
    return (
      this.PartnerInfo?.status?.id ==
      Data.Partner.PARTNER_STATUS.ONBOARDING_REQUESTED.id
    );
  }
}
</script>

<style lang="scss" scoped>
.successModal {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboardingContentSuccess {
  background: #fff;
  width: 350px;
  margin: 0 auto;
  padding: 0px;
  border-radius: 30px;
  text-align: center;
  padding-bottom: 40px;
  position: relative;
}

.freedLogoBg {
  background: #fee4d6;
  padding: 30px 0;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
}

.freedLogo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: #d7dff5;
}

.freedLogo img {
  width: 55px;
}

.descriptionSuccessContent {
  padding: 30px 15px;
}

.successHeading {
  font-size: 24px;
  font-weight: bold;
  color: #f36f21;
}

.descriptionSuccess {
  font-size: 18px;
  font-weight: bold;
  padding: 30px;
}
</style>