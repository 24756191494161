
import * as Data from "@/../src-gen/data";
import FBtnMDP, { BtnType } from "@/components/FBtnMDP";
import FFormSubmitBtnMDP from "@/components/FFormSubmitBtnMDP";
import FFormMDP, { FFormChildMDP } from "@/components/form/FFormMDP";
import FAccountFieldMDP from "@/components/form/field/FAccountFieldMDP";
import FCurrencyFieldMDP from "@/components/form/field/FCurrencyFieldMDP";
import FIFSCCodeFieldMDP from "@/components/form/field/FIFSCCodeFieldMDP";
import FNupayBankSelectFieldMDP from "@/components/form/field/FNupayBankSelectFieldMDP";
import FSelectFieldMDP from "@/components/form/field/FSelectFieldMDP";
import FTextFieldMDP from "@/components/form/field/FTextFieldMDP";

export default class InitiateEMandateFFormMDP extends FFormMDP {
  childMDP = new FFormChildMDP();
  parent: any;
  constructor({ parent }: { parent: any }) {
    super({
      myRefName: "eMandateFormRef",
    });
    this.parent = parent;

    this.addField(
      new FTextFieldMDP({
        parentMDP: this.childMDP,
        dataSelectorKey: "clientBankInfo.accountHolderName",
        label: "Account Holder Name",
        mandatory: true,
        boundaryClass: "col-4",
      })
    )
      .addField(
        new FAccountFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "clientBankInfo.accountNumber",
          label: "Account Number",
          mandatory: true,
          boundaryClass: "col-4",
        })
      )
      .addField(
        new FSelectFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "clientBankInfo.accountType",
          label: "Account Type",
          boundaryClass: "col-4",
          mandatory: true,
          options: Data.ClientFile.ACCOUNT_TYPE.list(),
          optionLabel: "name",
          optionValue: "id",
          returnObject: true
        })
      )
      .addField(
        new FCurrencyFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "collectionAmount",
          label: "Collection Amount",
          boundaryClass: "col-4",
          mandatory: true,
        })
      )
      .addField(
        new FNupayBankSelectFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "clientBankInfo.nupayBankId",
          label: "Bank Name",
          boundaryClass: "col-4",
          mandatory: true,
          optionValue: "nupayBankId"
        })
      )
      .addField(
        new FIFSCCodeFieldMDP({
          parentMDP: this.childMDP,
          dataSelectorKey: "clientBankInfo.ifscCode",
          label: "IFSC Code",
          mandatory: true,
          boundaryClass: "col-4",
          rules: "validate_ifsc"
          
        })
      )
      .addAction(
        new FBtnMDP({
          label: "Cancel",
          onClick: () => this.handleCancelClick(),
          btnType: BtnType.TEXT
        })
      )
      .addAction(
        new FFormSubmitBtnMDP({
          label: "Initiate EMandate",
          onClick: () => this.parent.initiateEMandate(),
          disabled: this.parent.isAgreementNotSigned()
        })
      );
      
  }
  

  getMyRef(): any {
    return this.parent.$refs[this.myRefName];
  }

  handleCancelClick() {
      this.parent.showInitiateForm = false;
  }

  populateBankDetails(details: any) {
    this.parent.populateBankDetails(details);
  }
}
