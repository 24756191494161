import MDP from '@/components/MDP';

export default class FFormSubmitBtnMDP implements MDP {
  componentName = 'FFormSubmitBtn';
  label: string;
  onClick: () => void;
  color: BtnColor;
  elevation = 0;
  btnType: BtnType;
  condition: boolean;
  disabled: boolean;
  boundaryClass?: string;
  constructor({
    label,
    onClick,
    color = BtnColor.PRIMARY,
    btnType = BtnType.OUTLINED,
    condition = true,
    disabled = false,
    boundaryClass
  }: {
    label: string;
    onClick: () => void;
    color?: BtnColor;
    btnType?: BtnType;
    condition?: boolean;
    disabled?: boolean;
    boundaryClass?: string;
  }) {
    this.label = label;
    this.onClick = onClick;
    this.color = color;
    this.btnType = btnType;
    this.condition = condition;
    this.disabled = disabled;
    this.boundaryClass = boundaryClass;
  }
  getMetaData(): object {
    return {
      componentName: this.componentName,
      condition: this.condition,
      boundaryClass: this.boundaryClass,
      props: {
        label: this.label,
        onFormSubmit: this.onClick,
        outlined: this.btnType === BtnType.OUTLINED,
        text: this.btnType === BtnType.TEXT,
        elevation: this.elevation,
        color: this.color,
        disabled: this.disabled,
      },
    };
  }
}

export enum BtnType {
  TEXT = 'TEXT',
  OUTLINED = 'OUTLINED',
  FILLED = 'FILLED',
}

export enum BtnColor {
  PRIMARY = 'primary',
  SECONADRY = 'secondary',
}
