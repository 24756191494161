
import axios, { AxiosError, AxiosInstance } from 'axios';
import * as RemoteApiPoint from './../../src/remote-api-point';
import {EventBus} from './../../src/eventbus';
import * as Store from './../../src-gen/store';
import * as Data from './../data';
import * as ServerData from './../server-data';
import PartnerMasterReaderService from './../service/partnerprogramapi/PartnerMasterReaderService';
import PartnerReaderService from './../service/partnerprogramapi/PartnerReaderService';
import DocumentUploadP2PReaderService from './../service/partnerprogramapi/DocumentUploadP2PReaderService';
import GompaWriterService from './../service/partnerprogramapi/GompaWriterService';
import PartnerWriterService from './../service/partnerprogramapi/PartnerWriterService';

// tslint:disable



export class GetStateList {

    

    

    public static execute( f: (output: ServerData.PartnerMasterWebReader.GetStateList$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    const input = new ServerData.PartnerMasterWebReader.GetStateList$Input();

    

        PartnerMasterReaderService.getStateList(axoisInstance, this.mapInput(input),
            ((output: ServerData.PartnerMasterWebReader.GetStateList$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.PartnerMasterWebReader.GetStateList$Input): ServerData.PartnerMasterWebReader.GetStateList$Input {
        return new GetStateList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PartnerMasterWebReader.GetStateList$Output): ServerData.PartnerMasterWebReader.GetStateList$Output {
        return new GetStateList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetStateList$Mapper {

    public mapInput(input: ServerData.PartnerMasterWebReader.GetStateList$Input): ServerData.PartnerMasterWebReader.GetStateList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.PartnerMasterWebReader.GetStateList$Output): ServerData.PartnerMasterWebReader.GetStateList$Output {
        return output
    }

}

class GetStateList$MapperCode extends GetStateList$Mapper {

}

       


export class FindPartnerDetails {

    
    public static execute1(partnerId: string, f: (output: Data.Partner.PartnerSummary|undefined) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {
      FindPartnerDetails.execute(new ServerData.PartnerWebReader.FindPartnerDetails$Input(partnerId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.PartnerWebReader.FindPartnerDetails$Input, f: (output: Data.Partner.PartnerSummary|undefined) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        PartnerReaderService.findPartnerDetails(axoisInstance, this.mapInput(input),
            ((output: ServerData.PartnerData$PartnerSummary|undefined) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.Partner.ApplicantDetails.UPDATE_PARTNER_INFO(mappedOutput);
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.PartnerWebReader.FindPartnerDetails$Input): ServerData.PartnerWebReader.FindPartnerDetails$Input {
        return new FindPartnerDetails$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PartnerData$PartnerSummary|undefined): Data.Partner.PartnerSummary|undefined {
        return new FindPartnerDetails$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class FindPartnerDetails$Mapper {

    public mapInput(input: ServerData.PartnerWebReader.FindPartnerDetails$Input): ServerData.PartnerWebReader.FindPartnerDetails$Input {
        return input; 
    }

    public mapOutput(output: ServerData.PartnerData$PartnerSummary|undefined): Data.Partner.PartnerSummary|undefined {
        return (output) ? Data.Partner.PartnerSummary.fromJson(output.toJson()) : undefined;
    }

}

class FindPartnerDetails$MapperCode extends FindPartnerDetails$Mapper {

}

       


export class GetReferralOrgCode {

    
    public static execute1(uniqueReferralCode: string, f: (output: Data.Partner.GetReferralOrgCodeOutput) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {
      GetReferralOrgCode.execute(new Data.Partner.GetReferralOrgCodeInput(uniqueReferralCode), f, eh, axoisInstance);
    } 

    

    public static execute(input: Data.Partner.GetReferralOrgCodeInput, f: (output: Data.Partner.GetReferralOrgCodeOutput) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        PartnerReaderService.getReferralOrgCode(axoisInstance, this.mapInput(input),
            ((output: ServerData.PartnerWebReader.GetReferralOrgCode$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.Partner.ApplicantDetails.UPDATE_REFERRAL_ORG_CODE(mappedOutput);
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Partner.GetReferralOrgCodeInput): ServerData.PartnerWebReader.GetReferralOrgCode$Input {
        return new GetReferralOrgCode$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PartnerWebReader.GetReferralOrgCode$Output): Data.Partner.GetReferralOrgCodeOutput {
        return new GetReferralOrgCode$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetReferralOrgCode$Mapper {

    public mapInput(input: Data.Partner.GetReferralOrgCodeInput): ServerData.PartnerWebReader.GetReferralOrgCode$Input {
        return ServerData.PartnerWebReader.GetReferralOrgCode$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.PartnerWebReader.GetReferralOrgCode$Output): Data.Partner.GetReferralOrgCodeOutput {
        return Data.Partner.GetReferralOrgCodeOutput.fromJson(output.toJson());
    }

}

class GetReferralOrgCode$MapperCode extends GetReferralOrgCode$Mapper {

}

       


export class GetOnboardedFileList {

    

    

    public static execute(input: ServerData.PartnerWebReader.GetOnboardedFileList$Input, f: (output: ServerData.PartnerData$ClientFileSummary[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        PartnerReaderService.getOnboardedFileList(axoisInstance, this.mapInput(input),
            ((output: ServerData.PartnerData$ClientFileSummary[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.PartnerWebReader.GetOnboardedFileList$Input): ServerData.PartnerWebReader.GetOnboardedFileList$Input {
        return new GetOnboardedFileList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PartnerData$ClientFileSummary[]): ServerData.PartnerData$ClientFileSummary[] {
        return new GetOnboardedFileList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetOnboardedFileList$Mapper {

    public mapInput(input: ServerData.PartnerWebReader.GetOnboardedFileList$Input): ServerData.PartnerWebReader.GetOnboardedFileList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.PartnerData$ClientFileSummary[]): ServerData.PartnerData$ClientFileSummary[] {
        return output
    }

}

class GetOnboardedFileList$MapperCode extends GetOnboardedFileList$Mapper {

}

       


export class GetUnverifiedFileList {

    
    public static execute1(partnerId: string, f: (output: ServerData.PartnerData$PartnerClientDetails[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {
      GetUnverifiedFileList.execute(new ServerData.PartnerWebReader.GetUnverifiedFileList$Input(partnerId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.PartnerWebReader.GetUnverifiedFileList$Input, f: (output: ServerData.PartnerData$PartnerClientDetails[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        PartnerReaderService.getUnverifiedFileList(axoisInstance, this.mapInput(input),
            ((output: ServerData.PartnerData$PartnerClientDetails[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.PartnerWebReader.GetUnverifiedFileList$Input): ServerData.PartnerWebReader.GetUnverifiedFileList$Input {
        return new GetUnverifiedFileList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PartnerData$PartnerClientDetails[]): ServerData.PartnerData$PartnerClientDetails[] {
        return new GetUnverifiedFileList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetUnverifiedFileList$Mapper {

    public mapInput(input: ServerData.PartnerWebReader.GetUnverifiedFileList$Input): ServerData.PartnerWebReader.GetUnverifiedFileList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.PartnerData$PartnerClientDetails[]): ServerData.PartnerData$PartnerClientDetails[] {
        return output
    }

}

class GetUnverifiedFileList$MapperCode extends GetUnverifiedFileList$Mapper {

}

       


export class GetPartnerCertificate {

    
    public static execute1(partnerId: string, f: (output: ServerData.PartnerData$PartnerDocument) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {
      GetPartnerCertificate.execute(new ServerData.PartnerWebReader.GetPartnerCertificate$Input(partnerId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.PartnerWebReader.GetPartnerCertificate$Input, f: (output: ServerData.PartnerData$PartnerDocument) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        PartnerReaderService.getPartnerCertificate(axoisInstance, this.mapInput(input),
            ((output: ServerData.PartnerData$PartnerDocument) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.PartnerWebReader.GetPartnerCertificate$Input): ServerData.PartnerWebReader.GetPartnerCertificate$Input {
        return new GetPartnerCertificate$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PartnerData$PartnerDocument): ServerData.PartnerData$PartnerDocument {
        return new GetPartnerCertificate$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetPartnerCertificate$Mapper {

    public mapInput(input: ServerData.PartnerWebReader.GetPartnerCertificate$Input): ServerData.PartnerWebReader.GetPartnerCertificate$Input {
        return input; 
    }

    public mapOutput(output: ServerData.PartnerData$PartnerDocument): ServerData.PartnerData$PartnerDocument {
        return output
    }

}

class GetPartnerCertificate$MapperCode extends GetPartnerCertificate$Mapper {

}

       


export class GetPresignedURLForUpload {

    

    
    public static execute2(partnerId: string, fileName: string, f: (output: ServerData.DocumentUploadP2PWebReader.GetPresignedURLForUpload$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {
      GetPresignedURLForUpload.execute(new ServerData.DocumentUploadP2PWebReader.GetPresignedURLForUpload$Input(partnerId, fileName), f, eh, axoisInstance);
    } 

    public static execute(input: ServerData.DocumentUploadP2PWebReader.GetPresignedURLForUpload$Input, f: (output: ServerData.DocumentUploadP2PWebReader.GetPresignedURLForUpload$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        DocumentUploadP2PReaderService.getPresignedURLForUpload(axoisInstance, this.mapInput(input),
            ((output: ServerData.DocumentUploadP2PWebReader.GetPresignedURLForUpload$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.DocumentUploadP2PWebReader.GetPresignedURLForUpload$Input): ServerData.DocumentUploadP2PWebReader.GetPresignedURLForUpload$Input {
        return new GetPresignedURLForUpload$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.DocumentUploadP2PWebReader.GetPresignedURLForUpload$Output): ServerData.DocumentUploadP2PWebReader.GetPresignedURLForUpload$Output {
        return new GetPresignedURLForUpload$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetPresignedURLForUpload$Mapper {

    public mapInput(input: ServerData.DocumentUploadP2PWebReader.GetPresignedURLForUpload$Input): ServerData.DocumentUploadP2PWebReader.GetPresignedURLForUpload$Input {
        return input; 
    }

    public mapOutput(output: ServerData.DocumentUploadP2PWebReader.GetPresignedURLForUpload$Output): ServerData.DocumentUploadP2PWebReader.GetPresignedURLForUpload$Output {
        return output
    }

}

class GetPresignedURLForUpload$MapperCode extends GetPresignedURLForUpload$Mapper {

}

       


export class GetUploadedUrl {

    
    public static execute1(key: string, f: (output: ServerData.DocumentUploadP2PWebReader.GetUploadedUrl$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {
      GetUploadedUrl.execute(new ServerData.DocumentUploadP2PWebReader.GetUploadedUrl$Input(key), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.DocumentUploadP2PWebReader.GetUploadedUrl$Input, f: (output: ServerData.DocumentUploadP2PWebReader.GetUploadedUrl$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        DocumentUploadP2PReaderService.getUploadedUrl(axoisInstance, this.mapInput(input),
            ((output: ServerData.DocumentUploadP2PWebReader.GetUploadedUrl$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.DocumentUploadP2PWebReader.GetUploadedUrl$Input): ServerData.DocumentUploadP2PWebReader.GetUploadedUrl$Input {
        return new GetUploadedUrl$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.DocumentUploadP2PWebReader.GetUploadedUrl$Output): ServerData.DocumentUploadP2PWebReader.GetUploadedUrl$Output {
        return new GetUploadedUrl$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetUploadedUrl$Mapper {

    public mapInput(input: ServerData.DocumentUploadP2PWebReader.GetUploadedUrl$Input): ServerData.DocumentUploadP2PWebReader.GetUploadedUrl$Input {
        return input; 
    }

    public mapOutput(output: ServerData.DocumentUploadP2PWebReader.GetUploadedUrl$Output): ServerData.DocumentUploadP2PWebReader.GetUploadedUrl$Output {
        return output
    }

}

class GetUploadedUrl$MapperCode extends GetUploadedUrl$Mapper {

}

       


export class GetOnboardedClientDetails {

    
    public static execute1(onboardedClientFileId: string, f: (output: Data.Partner.OnboardedClientFileDetails) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {
      GetOnboardedClientDetails.execute(new ServerData.PartnerWebReader.GetOnboardedClientDetails$Input(onboardedClientFileId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.PartnerWebReader.GetOnboardedClientDetails$Input, f: (output: Data.Partner.OnboardedClientFileDetails) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        PartnerReaderService.getOnboardedClientDetails(axoisInstance, this.mapInput(input),
            ((output: ServerData.PartnerData$OnboardedClientFileDetails) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.PartnerWebReader.GetOnboardedClientDetails$Input): ServerData.PartnerWebReader.GetOnboardedClientDetails$Input {
        return new GetOnboardedClientDetails$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PartnerData$OnboardedClientFileDetails): Data.Partner.OnboardedClientFileDetails {
        return new GetOnboardedClientDetails$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetOnboardedClientDetails$Mapper {

    public mapInput(input: ServerData.PartnerWebReader.GetOnboardedClientDetails$Input): ServerData.PartnerWebReader.GetOnboardedClientDetails$Input {
        return input; 
    }

    public mapOutput(output: ServerData.PartnerData$OnboardedClientFileDetails): Data.Partner.OnboardedClientFileDetails {
        return Data.Partner.OnboardedClientFileDetails.fromJson(output.toJson());
    }

}

class GetOnboardedClientDetails$MapperCode extends GetOnboardedClientDetails$Mapper {

}

       


export class GetNotEnrolledPartnerClientList {

    

    

    public static execute(input: Data.Partner.NotEnrolledPartnerClientListInput, f: (output: Data.Partner.NotEnrolledPartnerClientListOutput) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        PartnerReaderService.getNotEnrolledPartnerClientList(axoisInstance, this.mapInput(input),
            ((output: ServerData.PartnerWebReader.GetNotEnrolledPartnerClientList$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Partner.NotEnrolledPartnerClientListInput): ServerData.PartnerWebReader.GetNotEnrolledPartnerClientList$Input {
        return new GetNotEnrolledPartnerClientList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PartnerWebReader.GetNotEnrolledPartnerClientList$Output): Data.Partner.NotEnrolledPartnerClientListOutput {
        return new GetNotEnrolledPartnerClientList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetNotEnrolledPartnerClientList$Mapper {

    public mapInput(input: Data.Partner.NotEnrolledPartnerClientListInput): ServerData.PartnerWebReader.GetNotEnrolledPartnerClientList$Input {
        return ServerData.PartnerWebReader.GetNotEnrolledPartnerClientList$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.PartnerWebReader.GetNotEnrolledPartnerClientList$Output): Data.Partner.NotEnrolledPartnerClientListOutput {
        return Data.Partner.NotEnrolledPartnerClientListOutput.fromJson(output.toJson());
    }

}

class GetNotEnrolledPartnerClientList$MapperCode extends GetNotEnrolledPartnerClientList$Mapper {

}

       


export class SignUp {

    

    

    public static execute(input: ServerData.GompaWebWriter.SignUp$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        GompaWriterService.signUp(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.GompaWebWriter.SignUp$Input): ServerData.GompaWebWriter.SignUp$Input {
        return new SignUp$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new SignUp$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class SignUp$Mapper {

    public mapInput(input: ServerData.GompaWebWriter.SignUp$Input): ServerData.GompaWebWriter.SignUp$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class SignUp$MapperCode extends SignUp$Mapper {

}

       


export class RequestOnboarding {

    
    public static execute1(partnerId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {
      RequestOnboarding.execute(new ServerData.PartnerWebWriter.RequestOnboarding$Input(partnerId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.PartnerWebWriter.RequestOnboarding$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        PartnerWriterService.requestOnboarding(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.PartnerWebWriter.RequestOnboarding$Input): ServerData.PartnerWebWriter.RequestOnboarding$Input {
        return new RequestOnboarding$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new RequestOnboarding$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class RequestOnboarding$Mapper {

    public mapInput(input: ServerData.PartnerWebWriter.RequestOnboarding$Input): ServerData.PartnerWebWriter.RequestOnboarding$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class RequestOnboarding$MapperCode extends RequestOnboarding$Mapper {

}

       


export class ChangeDetails {

    

    

    public static execute(input: ServerData.GompaWebWriter.ChangeDetails$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        GompaWriterService.changeDetails(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.GompaWebWriter.ChangeDetails$Input): ServerData.GompaWebWriter.ChangeDetails$Input {
        return new ChangeDetails$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new ChangeDetails$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class ChangeDetails$Mapper {

    public mapInput(input: ServerData.GompaWebWriter.ChangeDetails$Input): ServerData.GompaWebWriter.ChangeDetails$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class ChangeDetails$MapperCode extends ChangeDetails$Mapper {

}

       


export class UploadCertificate {

    

    

    public static execute(input: ServerData.PartnerWebWriter.UploadCertificate$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        PartnerWriterService.uploadCertificate(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.PartnerWebWriter.UploadCertificate$Input): ServerData.PartnerWebWriter.UploadCertificate$Input {
        return new UploadCertificate$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new UploadCertificate$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class UploadCertificate$Mapper {

    public mapInput(input: ServerData.PartnerWebWriter.UploadCertificate$Input): ServerData.PartnerWebWriter.UploadCertificate$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class UploadCertificate$MapperCode extends UploadCertificate$Mapper {

}

       


export class AddPartnerClient {

    

    

    public static execute(input: Data.Partner.AddPartnerInput, f: (output: ServerData.PartnerWebWriter.AddPartnerClient$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        PartnerWriterService.addPartnerClient(axoisInstance, this.mapInput(input),
            ((output: ServerData.PartnerWebWriter.AddPartnerClient$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Partner.AddPartnerInput): ServerData.PartnerWebWriter.AddPartnerClient$Input {
        return new AddPartnerClient$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PartnerWebWriter.AddPartnerClient$Output): ServerData.PartnerWebWriter.AddPartnerClient$Output {
        return new AddPartnerClient$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class AddPartnerClient$Mapper {

    public mapInput(input: Data.Partner.AddPartnerInput): ServerData.PartnerWebWriter.AddPartnerClient$Input {
        return ServerData.PartnerWebWriter.AddPartnerClient$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.PartnerWebWriter.AddPartnerClient$Output): ServerData.PartnerWebWriter.AddPartnerClient$Output {
        return output
    }

}

class AddPartnerClient$MapperCode extends AddPartnerClient$Mapper {

}

       


export class SendOTP {

    

    
    public static execute2(mobile: string, uniqueReferralCode: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {
      SendOTP.execute(new Data.Partner.SendOTPInput(mobile, uniqueReferralCode), f, eh, axoisInstance);
    } 

    public static execute(input: Data.Partner.SendOTPInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        PartnerWriterService.sendOTP(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Partner.SendOTPInput): ServerData.PartnerWebWriter.SendOTP$Input {
        return new SendOTP$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new SendOTP$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class SendOTP$Mapper {

    public mapInput(input: Data.Partner.SendOTPInput): ServerData.PartnerWebWriter.SendOTP$Input {
        return ServerData.PartnerWebWriter.SendOTP$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class SendOTP$MapperCode extends SendOTP$Mapper {

}

       


export class ValidateAndRegisterPartnerClientFile {

    

    
    public static execute2(partnerClientId: string, otp: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {
      ValidateAndRegisterPartnerClientFile.execute(new Data.Partner.ValidateAndRegisterPartnerClientFileInput(partnerClientId, otp), f, eh, axoisInstance);
    } 

    public static execute(input: Data.Partner.ValidateAndRegisterPartnerClientFileInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.PartnerProgramApi): void {

    

    

        PartnerWriterService.validateAndRegisterPartnerClientFile(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.Partner.ValidateAndRegisterPartnerClientFileInput): ServerData.PartnerWebWriter.ValidateAndRegisterPartnerClientFile$Input {
        return new ValidateAndRegisterPartnerClientFile$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new ValidateAndRegisterPartnerClientFile$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class ValidateAndRegisterPartnerClientFile$Mapper {

    public mapInput(input: Data.Partner.ValidateAndRegisterPartnerClientFileInput): ServerData.PartnerWebWriter.ValidateAndRegisterPartnerClientFile$Input {
        return ServerData.PartnerWebWriter.ValidateAndRegisterPartnerClientFile$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class ValidateAndRegisterPartnerClientFile$MapperCode extends ValidateAndRegisterPartnerClientFile$Mapper {

}

       

     