
import FSnackbar from "@/fsnackbar";
import * as Data from "@/../src-gen/data";
import FFormMDP, { FFormChildMDP } from "@/components/form/FFormMDP";
import FBtnMDP from "@/components/FBtnMDP";
import FFormSubmitBtnMDP from "@/components/FFormSubmitBtnMDP";

export default class TMOStimulatorMDP extends FFormMDP {
  childMDP = new FFormChildMDP();
  taskRoot: any;
  constructor({ taskRoot }: { taskRoot: any }) {
    super({
      myRefName: "TMOStimulatorRef",
      disabled: taskRoot.taskDisabled,
    });
    this.taskRoot = taskRoot;

    this.addAction(
      new FBtnMDP({
        label: "Cancel",
        onClick: ()=>this.cancelClicked(),
        condition: this.taskRoot.editMode,
      })
    )
    .addAction(
      new FFormSubmitBtnMDP({
        label: this.taskRoot.isPaymentPlanDataAvailable() ? "Recalculate Payment Plan" : "Calculate Payment Plan",
        onClick: () => this.calculateOrDraftPaymentSchedule(),
        condition: this.taskRoot.editMode,
        disabled: this.taskRoot.isRecalculationNotAllowed()
      })
    )
    .addAction(
      new FBtnMDP({
        label: "Edit",
        onClick: () => this.editClicked(),
        condition: !this.taskRoot.editMode
      })
    )
  }

  getMyRef(): any {
    return this.taskRoot.$refs[this.myRefName];
  }
  
  


  editClicked() {
      this.taskRoot.handleEditClick();
  }
  cancelClicked() {
      this.taskRoot.handleCancelEditClick();
  }
  calculateOrDraftPaymentSchedule() {
    const confirmationMsg = 
    this.taskRoot.clientFileBasicInfo?.clientFileStatus === Data.ClientFile.CLIENT_FILE_STATUS.AGREEMENT_SIGNED.id?
    "Making changes to the details will result in the cancellation of the existing agreement. To continue with the changes, the client needs to resign the agreement.Do you want to continue?"
    :"Are you sure want to continue?"
      FSnackbar.confirm({
        message: confirmationMsg,
        onConfirm: () => {
            this.taskRoot.scheduleorDraftPaymentPlan();
          }})
        // }
      // this.taskRoot.scheduleorDraftPaymentPlan();
  }

  getTMOSSimulatorFormRef() {
    return this.taskRoot.$refs['tmoStimulatorFFormRef'];
  }
}
