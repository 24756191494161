<template>
  <div class="monthlySalesReport">
    <div class="pa-3">
      <v-row>
        <v-col :class="aoZoomIn ? 'col-12' : 'col-6'">
          <div class="chart-container">
            <div class="searchContainer" v-if="!!showAOSearchForm">
              <component
                :ref="monthlySalesAOSearchFFormMetaData.myRefName"
                :is="monthlySalesAOSearchFFormMetaData.componentName"
                :value="
                  selectModel(getSaleCountDayWiseListInputLocal, undefined)
                "
                @input="
                  (newValue) =>
                    updateModel(
                      getSaleCountDayWiseListInputLocal,
                      newValue,
                      undefined
                    )
                "
                v-bind="monthlySalesAOSearchFFormMetaData.props"
              >
              </component>
            </div>

            <div class="filterSection">
              <div class="leftContainer">
                <v-list-item-title
                  class="text-body-2 font-weight-bold py-2 colorText"
                  >Onboarding
                </v-list-item-title>
              </div>
              <div class="rightContainer" v-if="!showAOSearchForm">
                <p class="sunHeading">Showing MTD Data</p>
                <div class="filterIocn" @click="handleAOSearchFormClick()">
                  <v-icon color="#5599ff" small>mdi mdi-filter</v-icon>
                </div>
                <div class="expandIcon" @click="expandGraphClick('onboarding')">
                  <v-icon>{{
                    aoZoomIn ? "mdi-arrow-collapse" : "mdi-arrow-expand"
                  }}</v-icon>
                </div>
              </div>
            </div>
            <component
              v-if="loadGraph"
              :ref="monthlyAOCountC3ChartMetaData.myRefName"
              :is="monthlyAOCountC3ChartMetaData.componentName"
              :value="saleCountDayWiseDataInfo.daywiseCountList"
              v-bind="monthlyAOCountC3ChartMetaData.props"
            ></component>
          </div>
        </v-col>
        <v-col :class="aomZoomIn ? 'col-12' : 'col-6'">
          <div class="chart-container">
            <div class="searchContainer" v-if="!!showAOMSearchForm">
              <component
                :ref="monthlySalesAOMSearchFFormMetaData.myRefName"
                :is="monthlySalesAOMSearchFFormMetaData.componentName"
                :value="
                  selectModel(getMandateCountDayWiseListInputLocal, undefined)
                "
                @input="
                  (newValue) =>
                    updateModel(
                      getMandateCountDayWiseListInputLocal,
                      newValue,
                      undefined
                    )
                "
                v-bind="monthlySalesAOMSearchFFormMetaData.props"
              >
              </component>
            </div>
            <div class="filterSection">
              <div class="leftContainer">
                <v-list-item-title
                  class="text-body-2 font-weight-bold py-2 colorText"
                  >Mandate Done
                </v-list-item-title>
              </div>
              <div class="rightContainer" v-if="!showAOMSearchForm">
                <p class="sunHeading">Showing MTD Data</p>
                <div class="filterIocn" @click="handleAOMSearchFormClick()">
                  <v-icon color="#5599ff" small>mdi mdi-filter</v-icon>
                </div>
                <div class="expandIcon" @click="expandGraphClick('mandate')">
                  <v-icon>{{
                    aomZoomIn ? "mdi-arrow-collapse" : "mdi-arrow-expand"
                  }}</v-icon>
                </div>
              </div>
            </div>
            <component
              v-if="loadGraph"
              :ref="monthlyAOMCountC3ChartMetaData.myRefName"
              :is="monthlyAOMCountC3ChartMetaData.componentName"
              :value="mandateCountDayWiseDataInfo.daywiseCountList"
              v-bind="monthlyAOMCountC3ChartMetaData.props"
            ></component>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Data from "@/../src-gen/data";
// import * as ServerData from '@/../src-gen/server-data';
import * as Action from "@/../src-gen/action";
import Helper from "@/util/Helper";
import ModelVue from "@/components/ModelVue";
import FDataTable from "@/components/table/FDataTable.vue";
import FForm from "@/components/form/FForm.vue";
import FTooltip from "@/components/FTooltip.vue";
import MonthlySalesAOSearchFFormMDP from "./MonthlySalesAOSearchFFormMDP";
import MonthlySalesAOMSearchFFormMDP from "./MonthlySalesAOMSearchFFormMDP";
import FLineFC3ChartMDP from "@/components/C3Chart/FLineFC3ChartMDP";
import FXAxisMDP from "@/components/C3Chart/FXAxisMDP";
import FChartLineMDP from "@/components/C3Chart/FChartLineMDP";
import FC3Chart from "@/components/C3Chart/FC3Chart.vue";

@Component({
  components: { FDataTable, FForm, "f-tooltip": FTooltip, FC3Chart },
})
export default class MonthlySales extends ModelVue {
  showAOSearchForm: boolean = false;
  showAOMSearchForm: boolean = false;
  aoZoomIn: boolean = false;
  aomZoomIn: boolean = false;
  loadGraph = true;

  getSaleCountDayWiseListInput: Data.Insight.GetSaleCountDayWiseListInput =
    new Data.Insight.GetSaleCountDayWiseListInput();

  getMandateCountDayWiseListInput: Data.Insight.GetMandateCountDayWiseListInput =
    new Data.Insight.GetMandateCountDayWiseListInput();

  mandateCountDayWiseDataInfo: Data.Insight.MandateCountDayWiseDataInfo =
    new Data.Insight.MandateCountDayWiseDataInfo();

  saleCountDayWiseDataInfo: Data.Insight.SaleCountDayWiseDataInfo =
    new Data.Insight.SaleCountDayWiseDataInfo();

  @Store.Getter.Partner.ApplicantDetails.PartnerInfo
  PartnerInfo: Data.Partner.PartnerSummary;

  get monthlySalesAOSearchFFormMetaData() {
    return new MonthlySalesAOSearchFFormMDP({
      taskRoot: this,
      parent: this,
    }).getMetaData();
  }

  get monthlySalesAOMSearchFFormMetaData() {
    return new MonthlySalesAOMSearchFFormMDP({
      taskRoot: this,
      parent: this,
    }).getMetaData();
  }

  get monthlyAOCountC3ChartMetaData() {
    return new FLineFC3ChartMDP({
      xAxis: new FXAxisMDP({
        xAxisLabelKey: "ddMM",
      }),
    })
      .addLine(
        new FChartLineMDP({
          dataSelectorKey: "count",
          legend: "Onboarding",
        })
      )
      .getMetaData();
  }

  get monthlyAOMCountC3ChartMetaData() {
    return new FLineFC3ChartMDP({
      xAxis: new FXAxisMDP({
        xAxisLabelKey: "ddMM",
      }),
    })
      .addLine(
        new FChartLineMDP({
          dataSelectorKey: "count",
          legend: "Mandate Done",
        })
      )
      .getMetaData();
  }

  get getSaleCountDayWiseListInputLocal() {
    (this.getSaleCountDayWiseListInput.fromDate =
      Helper.dateMTDWithCurrent().startOfMonth),
      (this.getSaleCountDayWiseListInput.toDate =
        Helper.dateMTDWithCurrent().currentDate);
    return this.getSaleCountDayWiseListInput;
  }

  get getMandateCountDayWiseListInputLocal() {
    (this.getMandateCountDayWiseListInput.fromDate =
      Helper.dateMTDWithCurrent().startOfMonth),
      (this.getMandateCountDayWiseListInput.toDate =
        Helper.dateMTDWithCurrent().currentDate);
    return this.getMandateCountDayWiseListInput;
  }

  getSaleCountDayWiseList() {
    Action.Insight.GetSaleCountDayWiseList.execute(
      this.getSaleCountDayWiseListInput,
      (output) => {
        this.saleCountDayWiseDataInfo = output;
      }
    );
  }

  getMandateCountDayWiseList() {
    Action.Insight.GetMandateCountDayWiseList.execute(
      this.getMandateCountDayWiseListInput,
      (output) => {
        this.mandateCountDayWiseDataInfo = output;
      }
    );
  }

  clearAOSearchForm() {
    this.showAOSearchForm = false;
  }
  clearAOMSearchForm() {
    this.showAOMSearchForm = false;
  }
  handleAOSearchFormClick() {
    this.showAOSearchForm = true;
  }
  handleAOMSearchFormClick() {
    this.showAOMSearchForm = true;
  }
  expandGraphClick(section: string) {
    this.loadGraph = false;
    setTimeout(() => {
      this.loadGraph = true;
    }, 10);
    if (section === "onboarding") {
      this.aoZoomIn = !this.aoZoomIn;
    } else if (section === "mandate") {
      this.aomZoomIn = !this.aomZoomIn;
    }
  }

  handleAOShowMTDButtonClick() {
    (this.getSaleCountDayWiseListInputLocal.fromDate =
      Helper.dateMTDWithCurrent().startOfMonth),
      (this.getSaleCountDayWiseListInputLocal.toDate =
        Helper.dateMTDWithCurrent().currentDate);
    this.getSaleCountDayWiseList();
    this.clearAOSearchForm();
  }

  handleAOMShowMTDButtonClick() {
    (this.getMandateCountDayWiseListInput.fromDate =
      Helper.dateMTDWithCurrent().startOfMonth),
      (this.getMandateCountDayWiseListInput.toDate =
        Helper.dateMTDWithCurrent().currentDate);
    this.getMandateCountDayWiseList();
    this.clearAOMSearchForm();
  }

  public mounted() {
    this.getSaleCountDayWiseListInput.fromDate =
      Helper.dateMTDWithCurrent().startOfMonth;
    this.getSaleCountDayWiseListInput.toDate =
      Helper.dateMTDWithCurrent().currentDate;
    this.getSaleCountDayWiseListInput.orgCode = this.PartnerInfo.orgCode;

    this.getMandateCountDayWiseListInput.fromDate =
      Helper.dateMTDWithCurrent().startOfMonth;
    this.getMandateCountDayWiseListInput.toDate =
      Helper.dateMTDWithCurrent().currentDate;
    this.getMandateCountDayWiseListInput.orgCode = this.PartnerInfo.orgCode;

    this.getSaleCountDayWiseList();
    this.getMandateCountDayWiseList();
  }
}
</script>


<style lang="css" scoped>
p {
  margin: 0;
}
.colorText {
  color: #4e4e4e;
}
.chart-container {
  background: #ffffff;
  padding: 15px;
  border-radius: 12px;
  border: 1px solid #f7f8fb;
  min-height: 410px;
}
.filterSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.filter {
  display: flex;
  flex-direction: row;
  padding: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}
.filterCard {
  width: 40px;
  height: 30px;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  /* margin: 0 5px; */
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, font-weight 0.3s;
}

.filterCard.activefilterCard {
  background: #f7f8fb;
  color: #5599ff;
  font-weight: bold;
}
.divider {
  width: 1px;
  height: 18px;
  background: #e0e0e0;
}
.filterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filterIocn {
  cursor: pointer;
  color: #5599ff;
  background: #f7f8fb;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 10px;
}
.expandIcon {
  cursor: pointer;
  color: #5599ff;
  background: #f7f8fb;
  font-size: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 1px 5px;
  margin-left: 10px;
}

.searchContainer {
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}
.demographyCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #fef1e6;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 5px;
  background: #fef1e6;
}
.demographyState {
  color: #004479;
  font-size: 10px;
  font-weight: bold;
}
.demographyNumber {
  color: #f36f21;
  font-size: 10px;
  font-weight: bold;
}
.animation-wrapper {
  text-align: center;
  height: 340px;
  overflow: auto;
  position: relative;
  color: #4e4e4e;
  box-sizing: border-box;
}

.animation-wrapper::-webkit-scrollbar {
  display: none;
}
.sunHeading {
  font-size: 12px;
  color: #4e4e4e;
  white-space: nowrap;
}
.mobileDeviceOFF {
  display: block;
}
.mobileDeviceNO {
  display: none;
}
.rightContainer,
.leftContainer {
  display: flex;
  flex-direction: row;
  /* width: 30%; */
  align-items: center;
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@media all and (min-device-width: 360px) and (max-device-width: 540px) {
  .mobileDeviceOFF {
    display: none;
  }
  .mobileDeviceNO {
    display: block;
  }
  .filterSection.DFMobile {
    display: inherit;
  }
  .filterCard {
    width: 60px;
  }
}
</style>

