<template>
  <div class="pa-3 fadeIn">
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <div class="card-container">
          <div class="filterSection">
            <div class="leftContainer">
              <v-list-item-title
                class="text-body-2 font-weight-bold py-2 colorText"
                >Daily Conversion
              </v-list-item-title>
              <f-tooltip tooltipText="Showing data as of today.">
                <v-icon color="colorText" small>
                  mdi-information-variant-circle-outline</v-icon
                >
              </f-tooltip>
            </div>
          </div>

          <div class="setions">
            <div
              class="subcontainer bgPink"
              @click="gotoClientFileLookup(dateRangeInputCurrent.dateRange)"
            >
              <div class="cards">
                <div class="titleWrapper">
                  <p class="cardSubheading">FOOTFALL</p>
                  <p class="cardAmount">
                    {{ partnerClientCountOutputForCurrentDate.footfallCount
                    }}<span class="leadCount"
                      > /{{
                        partnerClientCountOutputForCurrentDate.totalLeadCount
                      }}
                      </span
                    >
                  </p>
                </div>
                <div class="cardIcon bgDarkPink">
                  <img src="@/assets/images/TotalFootfalls.svg" />
                </div>
              </div>
              <UpDowncard
                :data="conversionSummaryInfo.percentageDifferenceLeads"
              />
            </div>

            <!-- <div
              class="subcontainer bgPink"
              @click="gotoClientFileLookup(dateRangeInputCurrent.dateRange)"
            >
              <div class="cardIcon bgDrakPink">
                <img src="@/assets/images/TotalFootfalls.svg" />
              </div>
              <p class="cardAmount">
                {{ partnerClientCountOutputForCurrentDate.footfallCount }}<span class="leadCount">/100</span>
                <AnimatedNumber
                  :number="partnerClientCountOutputForCurrentDate.todaysLeadCount"
                ></AnimatedNumber>
                <UpDowncard
                  :data="conversionSummaryInfo.percentageDifferenceLeads"
                />
              </p>
              <p class="cardSubheading">Total Footfalls</p>
            </div> -->

            <div
              class="subcontainer bgBlue"
              @click="
                gotoClientFileLookup(
                  dateRangeInputCurrent.dateRange,
                  'ONBOARDED'
                )
              "
            >
              <div class="cards">
                <div class="titleWrapper">
                  <p class="cardSubheading">ONBOARDED</p>
                  <p class="cardAmount">
                    {{ partnerClientCountOutputForCurrentDate.onBoardedCount }}
                  </p>
                </div>
                <div class="cardIcon bgDarkBlue">
                  <img src="@/assets/images/MTDConversion.svg" />
                </div>
              </div>
              <UpDowncard
                :data="
                  conversionSummaryInfo.percentageDifferenceOnBoardingConversion
                "
              />
            </div>

            <div
              class="subcontainer bgGreen"
              @click="
                gotoClientFileLookup(dateRangeInputCurrent.dateRange, 'MANDATE')
              "
            >
              <div class="cards">
                <div class="titleWrapper">
                  <p class="cardSubheading">MANDATE</p>
                  <p class="cardAmount">
                    {{
                      partnerClientCountOutputForCurrentDate.mandateDoneCount
                    }}
                  </p>
                </div>
                <div class="cardIcon bgDarkGreen">
                  <img src="@/assets/images/TotalSales.svg" />
                </div>
              </div>
              <UpDowncard
                :data="
                  conversionSummaryInfo.percentageDifferenceEmandateConversion
                "
              />
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <div class="card-container">
          <!-- ******************************** -->
          <div class="searchContainer" v-if="!!showSearchForm">
            <component
              :ref="CSIDateRangeSearchFFormMetaData.myRefName"
              :is="CSIDateRangeSearchFFormMetaData.componentName"
              :value="selectModel(dateRangeInput, undefined)"
              @input="
                (newValue) => updateModel(dateRangeInput, newValue, undefined)
              "
              v-bind="CSIDateRangeSearchFFormMetaData.props"
            ></component>
          </div>
          <div class="filterSection">
            <div class="leftContainer">
              <v-list-item-title
                class="text-body-2 font-weight-bold py-2 colorText"
                >MTD Conversion
              </v-list-item-title>
              <f-tooltip tooltipText="Showing data as of today.">
                <v-icon color="colorText" small>
                  mdi-information-variant-circle-outline</v-icon
                >
              </f-tooltip>
            </div>
            <div class="rightContainer" v-if="!showSearchForm">
              <p class="sunHeading">Showing MTD Data</p>
              <div class="filterIcon" @click="handleSearchFormClick()">
                <v-icon color="#5599ff" small>mdi mdi-filter</v-icon>
              </div>
            </div>
          </div>

          <!-- ******************************** -->
          <div class="setions">
            <div
              class="subcontainer bgPink"
              @click="gotoClientFileLookup(dateRangeInput.dateRange)"
            >
              <div class="cards">
                <div class="titleWrapper">
                  <p class="cardSubheading">FOOTFALLS</p>
                  <p class="cardAmount">
                    {{ partnerClientCountOutputLocal.footfallCount }}
                  </p>
                </div>
                <div class="cardIcon bgDarkPink">
                  <img src="@/assets/images/TotalFootfalls.svg" />
                </div>
              </div>
            </div>

            <!-- <div
              class="subcontainer bgPink"
              @click="gotoClientFileLookup(dateRangeInput.dateRange)"
            >
              <div class="cardIcon bgDarkPink">
                <img src="@/assets/images/TotalFootfalls.svg" />
              </div>
              <p class="cardAmount">
                {{ partnerClientCountOutputLocal.footfallCount }}
                <AnimatedNumber
                  :number="partnerClientCountOutputLocal.footfallCount"
                ></AnimatedNumber>
              </p>
              <p class="cardSubheading">Total Footfalls</p>
            </div> -->
            <div
              class="subcontainer bgBlue"
              @click="
                gotoClientFileLookup(dateRangeInput.dateRange, 'ONBOARDED')
              "
            >
              <div class="cards">
                <div class="titleWrapper">
                  <p class="cardSubheading">ONBOARDED</p>
                  <p class="cardAmount">
                    {{ partnerClientCountOutputLocal.onBoardedCount }}
                  </p>
                </div>
                <div class="cardIcon bgDarkBlue">
                  <img src="@/assets/images/MTDConversion.svg" />
                </div>
              </div>
            </div>

            <div
              class="subcontainer bgGreen"
              @click="gotoClientFileLookup(dateRangeInput.dateRange, 'MANDATE')"
            >
              <div class="cards">
                <div class="titleWrapper">
                  <p class="cardSubheading">MANDATE</p>
                  <p class="cardAmount">
                    {{ partnerClientCountOutputLocal.mandateDoneCount }}
                  </p>
                </div>
                <div class="cardIcon bgDarkGreen">
                  <img src="@/assets/images/TotalSales.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import store, * as Store from "@/../src-gen/store";
import * as Action from "@/../src-gen/action";
import * as Data from "@/../src-gen/data";
import AnimatedNumber from "../../components/AnimatedNumber.vue";
import Helper from "@/util/Helper";
import UpDowncard from "./UpDowncard.vue";
import FTooltip from "@/components/FTooltip.vue";
import CSIDateRangeSearchFFormMDP from "./CSIDateRangeSearchFFormMDP";
import FForm from "@/components/form/FForm.vue";
import FDateSelectRangeField from "@/components/form/field/FDateSelectRangeField.vue";
import ModelVue from "@/components/ModelVue";

@Component({
  components: {
    "f-tooltip": FTooltip,
    AnimatedNumber,
    UpDowncard,
    FForm,
    FDateSelectRangeField,
  },
})
export default class ConversionSummaryInfo extends ModelVue {
  @Store.Getter.Insight.InsightStore.conversionSummaryOutput
  conversionSummaryInfo: Data.Insight.ConversionSummaryOutput;
  // you can removed this after test
  dailyFunnelInput: Data.Insight.GetDailyFunnelInput =
    new Data.Insight.GetDailyFunnelInput();

  partnerClientCountInput: Data.Insight.GetPartnerClientCountInput =
    new Data.Insight.GetPartnerClientCountInput();

  partnerClientCountInputForCurrentDate: Data.Insight.GetPartnerClientCountInput =
    new Data.Insight.GetPartnerClientCountInput();

  partnerClientCountOutput: Data.Insight.GetPartnerClientCountOutput =
    new Data.Insight.GetPartnerClientCountOutput();

  partnerClientCountOutputForCurrentDate: Data.Insight.GetPartnerClientCountOutput =
    new Data.Insight.GetPartnerClientCountOutput();

  dateRangeInput: any = { dateRange: [] };
  dateRangeInputCurrent: any = { dateRange: [] };
  showSearchForm: boolean = false;

  clearSearchForm() {
    this.showSearchForm = false;
  }
  handleSearchFormClick() {
    this.showSearchForm = true;
  }

  gotoClientFileLookup(dateRange: string, clientFileStatus?: string) {
    // const status = clientFileStatus == 'ONBOARDED' && Data.Insight.CF_OM_FILTER.ONBOARDED;
    this.$router.push({
      name: "InstitutionalPartner.ClientFileLookup",
      params: {
        dateRange: dateRange,
        clientFileStatus: clientFileStatus || "",
      },
    });
  }

  mounted() {
    this.partnerClientCountInput.fromDate = this.dateRangeInput.dateRange[0];
    this.partnerClientCountInput.toDate = this.dateRangeInput.dateRange[1];
    this.dateRangeInput.dateRange = [this.monthStartDate, this.currentDate];

    this.dateRangeInputCurrent.dateRange = [this.currentDate, this.currentDate];
    this.partnerClientCountInputForCurrentDate.fromDate =
      this.dateRangeInputCurrent.dateRange[0];
    this.partnerClientCountInputForCurrentDate.toDate =
      this.dateRangeInputCurrent.dateRange[1];
    // this.getDailyFunnel();
    this.getPartnerClientCount();
    this.getPartnerClientCountForCurrentDate();
  }

  get currentDate() {
    return Helper.dateMTDWithCurrent().currentDate;
  }
  get monthStartDate() {
    return Helper.dateMTDWithCurrent().startOfMonth;
  }

  get CSIDateRangeSearchFFormMetaData() {
    return new CSIDateRangeSearchFFormMDP({
      taskRoot: this,
      parent: this,
    }).getMetaData();
  }


  getPartnerClientCount() {
    this.partnerClientCountInput.fromDate = this.dateRangeInput.dateRange[0];
    this.partnerClientCountInput.toDate = this.dateRangeInput.dateRange[1];
    Action.Insight.GetPartnerClientCount.execute(
      this.partnerClientCountInput,
      (output) => {
        this.partnerClientCountOutput = output;
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  getPartnerClientCountForCurrentDate() {
    this.partnerClientCountInputForCurrentDate.fromDate =
      this.dateRangeInputCurrent.dateRange[0];
    this.partnerClientCountInputForCurrentDate.toDate =
      this.dateRangeInputCurrent.dateRange[1];
    Action.Insight.GetPartnerClientCount.execute(
      this.partnerClientCountInputForCurrentDate,
      (output) => {
        this.partnerClientCountOutputForCurrentDate = output;
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  get partnerClientCountOutputLocal() {
    return this.partnerClientCountOutput;
  }

  set partnerClientCountOutputLocal(newVal) {
    this.partnerClientCountOutput = newVal;
  }

  handleResetToMTDButtonClick() {
    this.dateRangeInput.dateRange = [this.monthStartDate, this.currentDate];
    this.partnerClientCountInput.fromDate = this.dateRangeInput.dateRange[0];
    this.partnerClientCountInput.toDate = this.dateRangeInput.dateRange[1];
    this.getPartnerClientCount();
    this.clearSearchForm();
  }
}
</script>

<style scoped>
p {
  margin: 0;
}

.bgDarkPink {
  background: #e8657f;
}
.bgDarkBlue {
  background: #699ddc;
}
.bgDarkGreen {
  background: #6fd567;
}
.bgDarkLightBlue {
  background: #6dc8de;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
}

.bgGreen {
  background: #e2fbe8;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
}
.bgLightBlue {
  background: #e0f8fd;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
}
.bgBlue {
  background: #ecf5fc;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
}
.bgPink {
  background: #fae3e6;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
}

.bgLightBlue:hover {
  background: #d4f2f8;
}
.bgGreen:hover {
  background: #d9f8e0;
}
.bgBlue:hover {
  background: #e5f2fd;
}
.bgPink:hover {
  background: #fcdde2;
}

.card-container {
  background: #ffffff;
  padding: 15px;
  border-radius: 12px;
  border: 1px solid #f7f8fb;
  min-height: 200px;
}
.card_heading {
  font-size: 14px;
  font-weight: bold;
  color: #4e4e4e;
}
.setions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin-top: 15px;
}
.subcontainer {
  height: 180px;
  flex: 1;
  padding: 30px 10px;
  border-radius: 8px;
}
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.divider {
  width: 1px;
  height: 45px;
  background: #ccc;
  margin: 10px 0;
}
.heading {
  font-size: 14px;
  font-weight: bold;
  color: #00447a !important;
}
.amount {
  font-size: 20px;
  font-weight: bold;
  color: #f36f21 !important;
}
.cardAmount {
  font-size: 40px;
  color: #2e2e2e;
  font-weight: bold;
  margin: 0;
  /* line-height: 1.2; */
}
.cardSubheading {
  font-size: 14px;
  color: #3f4043;
  /* margin-bottom: 5px; */
  font-weight: 500;
}
.cardDis {
  font-size: 14px;
  color: #5599ff;
}

.cardIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.cardIcon > img {
  width: 18px;
  height: 18px;
}
.headContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.viewReport {
  font-size: 12px;
  color: #5599ff;
  background: #f7f8fb;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.chart-container {
  background: #ffffff;
  padding: 15px;
  border-radius: 12px;
  border: 1px solid #f7f8fb;
  min-height: 410px;
}
.filterSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.searchContainer {
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}
.rightContainer,
.leftContainer {
  display: flex;
  flex-direction: row;
  /* width: 30%; */
  align-items: center;
}
.colorText {
  color: #4e4e4e;
}
.sunHeading {
  font-size: 12px;
  color: #4e4e4e;
  white-space: nowrap;
}
.filterIcon {
  cursor: pointer;
  color: #5599ff;
  background: #f7f8fb;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 10px;
}
.leadCount {
  font-size: 16px;
  color: #454343;
  font-weight: 400;
  margin: 0;
}

.cards {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 18px;
}
.titleWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
}

@media all and (min-device-width: 360px) and (max-device-width: 540px) {
  .setions {
    flex-direction: column;
  }
  .subcontainer {
    width: 100%;
  }
}
</style>
