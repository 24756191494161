
import axios, { AxiosError, AxiosInstance } from 'axios';
import * as RemoteApiPoint from './../../src/remote-api-point';
import {EventBus} from './../../src/eventbus';
import * as Store from './../../src-gen/store';
import * as Data from './../data';
import * as ServerData from './../server-data';
import MasterReaderService from './../service/spineapi/MasterReaderService';
import ClientFileReaderService from './../service/spineapi/ClientFileReaderService';
import FiCreditorReaderService from './../service/spineapi/FiCreditorReaderService';
import ClientReaderService from './../service/spineapi/ClientReaderService';
import PaymentReaderService from './../service/spineapi/PaymentReaderService';
import ClientFileWriterService from './../service/spineapi/ClientFileWriterService';
import ClientWriterService from './../service/spineapi/ClientWriterService';
import AmendmentWriterService from './../service/spineapi/AmendmentWriterService';
import PaymentSchedulePlanWriterService from './../service/spineapi/PaymentSchedulePlanWriterService';
import PaymentScheduleEntryWriterService from './../service/spineapi/PaymentScheduleEntryWriterService';
import PaymentWriterService from './../service/spineapi/PaymentWriterService';
import MSFScheduleEntryWriterService from './../service/spineapi/MSFScheduleEntryWriterService';

// tslint:disable



export class GetStateList {

    

    

    public static execute( f: (output: ServerData.MasterWebReader.GetStateList$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    const input = new ServerData.MasterWebReader.GetStateList$Input();

    

        MasterReaderService.getStateList(axoisInstance, this.mapInput(input),
            ((output: ServerData.MasterWebReader.GetStateList$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.MasterWebReader.GetStateList$Input): ServerData.MasterWebReader.GetStateList$Input {
        return new GetStateList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.MasterWebReader.GetStateList$Output): ServerData.MasterWebReader.GetStateList$Output {
        return new GetStateList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetStateList$Mapper {

    public mapInput(input: ServerData.MasterWebReader.GetStateList$Input): ServerData.MasterWebReader.GetStateList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.MasterWebReader.GetStateList$Output): ServerData.MasterWebReader.GetStateList$Output {
        return output
    }

}

class GetStateList$MapperCode extends GetStateList$Mapper {

}

       


export class GetNupayBankMasterList {

    

    

    public static execute( f: (output: Data.ClientFile.NupayBankMasterListOutput) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    const input = new ServerData.MasterWebReader.GetNupayBankMasterList$Input();

    

        MasterReaderService.getNupayBankMasterList(axoisInstance, this.mapInput(input),
            ((output: ServerData.MasterWebReader.GetNupayBankMasterList$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.MasterWebReader.GetNupayBankMasterList$Input): ServerData.MasterWebReader.GetNupayBankMasterList$Input {
        return new GetNupayBankMasterList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.MasterWebReader.GetNupayBankMasterList$Output): Data.ClientFile.NupayBankMasterListOutput {
        return new GetNupayBankMasterList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetNupayBankMasterList$Mapper {

    public mapInput(input: ServerData.MasterWebReader.GetNupayBankMasterList$Input): ServerData.MasterWebReader.GetNupayBankMasterList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.MasterWebReader.GetNupayBankMasterList$Output): Data.ClientFile.NupayBankMasterListOutput {
        return Data.ClientFile.NupayBankMasterListOutput.fromJson(output.toJson());
    }

}

class GetNupayBankMasterList$MapperCode extends GetNupayBankMasterList$Mapper {

}

       


export class GetClientLanguageList {

    

    

    public static execute( f: (output: string[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    const input = new ServerData.MasterWebReader.GetClientLanguageList$Input();

    

        MasterReaderService.getClientLanguageList(axoisInstance, this.mapInput(input),
            ((output: string[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.MasterWebReader.GetClientLanguageList$Input): ServerData.MasterWebReader.GetClientLanguageList$Input {
        return new GetClientLanguageList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string[]): string[] {
        return new GetClientLanguageList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetClientLanguageList$Mapper {

    public mapInput(input: ServerData.MasterWebReader.GetClientLanguageList$Input): ServerData.MasterWebReader.GetClientLanguageList$Input {
        return input; 
    }

    public mapOutput(output: string[]): string[] {
        return output
    }

}

class GetClientLanguageList$MapperCode extends GetClientLanguageList$Mapper {

}

       


export class GetEMandateList {

    
    public static execute1(clientFileId: string, f: (output: Data.ClientFile.FiEMandateList[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetEMandateList.execute(new ServerData.ClientFileWebReader.GetEMandateList$Input(clientFileId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebReader.GetEMandateList$Input, f: (output: Data.ClientFile.FiEMandateList[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileReaderService.getEMandateList(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$FiEMandate[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.ClientFile.ClientFileSummary.UPDATE_FI_E_MANDATE_LIST(mappedOutput.map((x) => Data.ClientFile.FiEMandateList.fromJson(x.toJson())));
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.GetEMandateList$Input): ServerData.ClientFileWebReader.GetEMandateList$Input {
        return new GetEMandateList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$FiEMandate[]): Data.ClientFile.FiEMandateList[] {
        return new GetEMandateList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetEMandateList$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.GetEMandateList$Input): ServerData.ClientFileWebReader.GetEMandateList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$FiEMandate[]): Data.ClientFile.FiEMandateList[] {
        return  output.map((x) => Data.ClientFile.FiEMandateList.fromJson(x.toJson()));
    }

}

class GetEMandateList$MapperCode extends GetEMandateList$Mapper {

}

       


export class GetClientFileBasicInfo {

    
    public static execute1(clientFileNumber: string, f: (output: Data.ClientFile.ClientFileBasicInfo) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetClientFileBasicInfo.execute(new ServerData.ClientFileWebReader.GetClientFileBasicInfo$Input(clientFileNumber), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebReader.GetClientFileBasicInfo$Input, f: (output: Data.ClientFile.ClientFileBasicInfo) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileReaderService.getClientFileBasicInfo(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$ClientFileBasicInfo) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.ClientFile.ClientFileSummary.UPDATE_CLIENT_FILE_BASIC_INFO(mappedOutput);
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.GetClientFileBasicInfo$Input): ServerData.ClientFileWebReader.GetClientFileBasicInfo$Input {
        return new GetClientFileBasicInfo$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$ClientFileBasicInfo): Data.ClientFile.ClientFileBasicInfo {
        return new GetClientFileBasicInfo$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetClientFileBasicInfo$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.GetClientFileBasicInfo$Input): ServerData.ClientFileWebReader.GetClientFileBasicInfo$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$ClientFileBasicInfo): Data.ClientFile.ClientFileBasicInfo {
        return Data.ClientFile.ClientFileBasicInfo.fromJson(output.toJson());
    }

}

class GetClientFileBasicInfo$MapperCode extends GetClientFileBasicInfo$Mapper {

}

       


export class GetCFBasicInfo {

    
    public static execute1(clientFileId: string, f: (output: Data.ClientFile.ClientFileBasicInfo) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetCFBasicInfo.execute(new ServerData.ClientFileWebReader.GetCFBasicInfo$Input(clientFileId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebReader.GetCFBasicInfo$Input, f: (output: Data.ClientFile.ClientFileBasicInfo) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileReaderService.getCFBasicInfo(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$ClientFileBasicInfo) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.ClientFile.ClientFileSummary.UPDATE_CLIENT_FILE_BASIC_INFO(mappedOutput);
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.GetCFBasicInfo$Input): ServerData.ClientFileWebReader.GetCFBasicInfo$Input {
        return new GetCFBasicInfo$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$ClientFileBasicInfo): Data.ClientFile.ClientFileBasicInfo {
        return new GetCFBasicInfo$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetCFBasicInfo$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.GetCFBasicInfo$Input): ServerData.ClientFileWebReader.GetCFBasicInfo$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$ClientFileBasicInfo): Data.ClientFile.ClientFileBasicInfo {
        return Data.ClientFile.ClientFileBasicInfo.fromJson(output.toJson());
    }

}

class GetCFBasicInfo$MapperCode extends GetCFBasicInfo$Mapper {

}

       


export class GetMyClientFileList {

    

    

    public static execute( f: (output: Data.ClientFile.MyClientFile[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    const input = new ServerData.ClientFileWebReader.GetMyClientFileList$Input();

    

        ClientFileReaderService.getMyClientFileList(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$MyClientFile[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.ClientFile.MyClientFileStore.UPDATE_MY_CLIENT_FILE_LIST(mappedOutput.map((x) => Data.ClientFile.MyClientFile.fromJson(x.toJson())));
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.GetMyClientFileList$Input): ServerData.ClientFileWebReader.GetMyClientFileList$Input {
        return new GetMyClientFileList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$MyClientFile[]): Data.ClientFile.MyClientFile[] {
        return new GetMyClientFileList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetMyClientFileList$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.GetMyClientFileList$Input): ServerData.ClientFileWebReader.GetMyClientFileList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$MyClientFile[]): Data.ClientFile.MyClientFile[] {
        return  output.map((x) => Data.ClientFile.MyClientFile.fromJson(x.toJson()));
    }

}

class GetMyClientFileList$MapperCode extends GetMyClientFileList$Mapper {

}

       


export class GetDocumentList {

    
    public static execute1(clientFileId: string, f: (output: Data.ClientFile.FiDocument[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetDocumentList.execute(new ServerData.ClientFileWebReader.FindDocumentList$Input(clientFileId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebReader.FindDocumentList$Input, f: (output: Data.ClientFile.FiDocument[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileReaderService.findDocumentList(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$FiDocument[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.ClientFile.ClientFileSummary.UPDATE_FI_DOCUMENT_LIST(mappedOutput.map((x) => Data.ClientFile.FiDocument.fromJson(x.toJson())));
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.FindDocumentList$Input): ServerData.ClientFileWebReader.FindDocumentList$Input {
        return new GetDocumentList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$FiDocument[]): Data.ClientFile.FiDocument[] {
        return new GetDocumentList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetDocumentList$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.FindDocumentList$Input): ServerData.ClientFileWebReader.FindDocumentList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$FiDocument[]): Data.ClientFile.FiDocument[] {
        return  output.map((x) => Data.ClientFile.FiDocument.fromJson(x.toJson()));
    }

}

class GetDocumentList$MapperCode extends GetDocumentList$Mapper {

}

       


export class GetClientFileSummary {

    
    public static execute1(clientFileId: string, f: (output: Data.ClientFile.FileSummary) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetClientFileSummary.execute(new ServerData.ClientFileWebReader.GetClientFileSummary$Input(clientFileId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebReader.GetClientFileSummary$Input, f: (output: Data.ClientFile.FileSummary) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileReaderService.getClientFileSummary(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$ClientFileSummary) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.ClientFile.ClientFileSummary.UPDATE_FILE_SUMMARY(mappedOutput);
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.GetClientFileSummary$Input): ServerData.ClientFileWebReader.GetClientFileSummary$Input {
        return new GetClientFileSummary$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$ClientFileSummary): Data.ClientFile.FileSummary {
        return new GetClientFileSummary$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetClientFileSummary$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.GetClientFileSummary$Input): ServerData.ClientFileWebReader.GetClientFileSummary$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$ClientFileSummary): Data.ClientFile.FileSummary {
        return Data.ClientFile.FileSummary.fromJson(output.toJson());
    }

}

class GetClientFileSummary$MapperCode extends GetClientFileSummary$Mapper {

}

       


export class GetClientFileEnrollmentSummary {

    
    public static execute1(clientFileId: string, f: (output: Data.ClientFile.ClientFileEnrollmentSummary) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetClientFileEnrollmentSummary.execute(new ServerData.ClientFileWebReader.GetClientFileEnrollmentSummary$Input(clientFileId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebReader.GetClientFileEnrollmentSummary$Input, f: (output: Data.ClientFile.ClientFileEnrollmentSummary) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileReaderService.getClientFileEnrollmentSummary(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$ClientFileEnrollmentSummary) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.ClientFile.ClientFileSummary.UPDATE_CLIENT_FILE_ENROLLMENT_SUMMARY(mappedOutput);
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.GetClientFileEnrollmentSummary$Input): ServerData.ClientFileWebReader.GetClientFileEnrollmentSummary$Input {
        return new GetClientFileEnrollmentSummary$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$ClientFileEnrollmentSummary): Data.ClientFile.ClientFileEnrollmentSummary {
        return new GetClientFileEnrollmentSummary$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetClientFileEnrollmentSummary$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.GetClientFileEnrollmentSummary$Input): ServerData.ClientFileWebReader.GetClientFileEnrollmentSummary$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$ClientFileEnrollmentSummary): Data.ClientFile.ClientFileEnrollmentSummary {
        return Data.ClientFile.ClientFileEnrollmentSummary.fromJson(output.toJson());
    }

}

class GetClientFileEnrollmentSummary$MapperCode extends GetClientFileEnrollmentSummary$Mapper {

}

       


export class GetCreditorInfo {

    
    public static execute1(clientFileId: string, f: (output: Data.ClientFile.FiCreditorInfo|undefined) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetCreditorInfo.execute(new ServerData.ClientFileWebReader.FindCreditorInfo$Input(clientFileId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebReader.FindCreditorInfo$Input, f: (output: Data.ClientFile.FiCreditorInfo|undefined) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileReaderService.findCreditorInfo(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$FiCreditorInfo|undefined) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.ClientFile.ClientFileSummary.UPDATE_FI_CREDITOR_INFO(mappedOutput);
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.FindCreditorInfo$Input): ServerData.ClientFileWebReader.FindCreditorInfo$Input {
        return new GetCreditorInfo$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$FiCreditorInfo|undefined): Data.ClientFile.FiCreditorInfo|undefined {
        return new GetCreditorInfo$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetCreditorInfo$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.FindCreditorInfo$Input): ServerData.ClientFileWebReader.FindCreditorInfo$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$FiCreditorInfo|undefined): Data.ClientFile.FiCreditorInfo|undefined {
        return (output) ? Data.ClientFile.FiCreditorInfo.fromJson(output.toJson()) : undefined;
    }

}

class GetCreditorInfo$MapperCode extends GetCreditorInfo$Mapper {

}

       


export class GetFiCreditorInfo {

    
    public static execute1(fiCreditorId: string, f: (output: Data.ClientFile.FiCreditor) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetFiCreditorInfo.execute(new ServerData.FiCreditorWebReader.GetFiCreditorInfo$Input(fiCreditorId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.FiCreditorWebReader.GetFiCreditorInfo$Input, f: (output: Data.ClientFile.FiCreditor) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        FiCreditorReaderService.getFiCreditorInfo(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$FiCreditor) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.FiCreditorWebReader.GetFiCreditorInfo$Input): ServerData.FiCreditorWebReader.GetFiCreditorInfo$Input {
        return new GetFiCreditorInfo$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$FiCreditor): Data.ClientFile.FiCreditor {
        return new GetFiCreditorInfo$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetFiCreditorInfo$Mapper {

    public mapInput(input: ServerData.FiCreditorWebReader.GetFiCreditorInfo$Input): ServerData.FiCreditorWebReader.GetFiCreditorInfo$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$FiCreditor): Data.ClientFile.FiCreditor {
        return Data.ClientFile.FiCreditor.fromJson(output.toJson());
    }

}

class GetFiCreditorInfo$MapperCode extends GetFiCreditorInfo$Mapper {

}

       


export class GetFiCreditorSifDocumentList {

    

    
    public static execute2(clientFileId: string, fiCreditorId: string, f: (output: Data.ClientFile.FiDocument[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetFiCreditorSifDocumentList.execute(new ServerData.FiCreditorWebReader.GetFiCreditorSifDocumentList$Input(clientFileId, fiCreditorId), f, eh, axoisInstance);
    } 

    public static execute(input: ServerData.FiCreditorWebReader.GetFiCreditorSifDocumentList$Input, f: (output: Data.ClientFile.FiDocument[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        FiCreditorReaderService.getFiCreditorSifDocumentList(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$FiDocument[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.FiCreditorWebReader.GetFiCreditorSifDocumentList$Input): ServerData.FiCreditorWebReader.GetFiCreditorSifDocumentList$Input {
        return new GetFiCreditorSifDocumentList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$FiDocument[]): Data.ClientFile.FiDocument[] {
        return new GetFiCreditorSifDocumentList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetFiCreditorSifDocumentList$Mapper {

    public mapInput(input: ServerData.FiCreditorWebReader.GetFiCreditorSifDocumentList$Input): ServerData.FiCreditorWebReader.GetFiCreditorSifDocumentList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$FiDocument[]): Data.ClientFile.FiDocument[] {
        return  output.map((x) => Data.ClientFile.FiDocument.fromJson(x.toJson()));
    }

}

class GetFiCreditorSifDocumentList$MapperCode extends GetFiCreditorSifDocumentList$Mapper {

}

       


export class GetClCreditorList {

    
    public static execute1(clientId: string, f: (output: Data.ClientFile.ClCreditor[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetClCreditorList.execute(new ServerData.ClientWebReader.GetClCreditorList$Input(clientId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientWebReader.GetClCreditorList$Input, f: (output: Data.ClientFile.ClCreditor[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientReaderService.getClCreditorList(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$ClCreditor[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.ClientFile.ClientFileSummary.UPDATE_CL_CREDITOR_LIST(mappedOutput.map((x) => Data.ClientFile.ClCreditor.fromJson(x.toJson())));
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientWebReader.GetClCreditorList$Input): ServerData.ClientWebReader.GetClCreditorList$Input {
        return new GetClCreditorList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$ClCreditor[]): Data.ClientFile.ClCreditor[] {
        return new GetClCreditorList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetClCreditorList$Mapper {

    public mapInput(input: ServerData.ClientWebReader.GetClCreditorList$Input): ServerData.ClientWebReader.GetClCreditorList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$ClCreditor[]): Data.ClientFile.ClCreditor[] {
        return  output.map((x) => Data.ClientFile.ClCreditor.fromJson(x.toJson()));
    }

}

class GetClCreditorList$MapperCode extends GetClCreditorList$Mapper {

}

       


export class GetBudgetInfo {

    
    public static execute1(clientFileId: string, f: (output: Data.ClientFile.BudgetInfo|undefined) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetBudgetInfo.execute(new ServerData.ClientFileWebReader.FindBudgetInfo$Input(clientFileId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebReader.FindBudgetInfo$Input, f: (output: Data.ClientFile.BudgetInfo|undefined) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileReaderService.findBudgetInfo(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$FiBudgetInfo|undefined) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.ClientFile.ClientFileSummary.UPDATE_BUDGET_INFO(mappedOutput);
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.FindBudgetInfo$Input): ServerData.ClientFileWebReader.FindBudgetInfo$Input {
        return new GetBudgetInfo$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$FiBudgetInfo|undefined): Data.ClientFile.BudgetInfo|undefined {
        return new GetBudgetInfo$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetBudgetInfo$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.FindBudgetInfo$Input): ServerData.ClientFileWebReader.FindBudgetInfo$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$FiBudgetInfo|undefined): Data.ClientFile.BudgetInfo|undefined {
        return (output) ? Data.ClientFile.BudgetInfo.fromJson(output.toJson()) : undefined;
    }

}

class GetBudgetInfo$MapperCode extends GetBudgetInfo$Mapper {

}

       


export class GetPaymentPlanInfo {

    
    public static execute1(clientFileId: string, f: (output: Data.ClientFile.FiPaymentPlanInfo|undefined) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetPaymentPlanInfo.execute(new ServerData.ClientFileWebReader.FindPaymentPlan$Input(clientFileId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebReader.FindPaymentPlan$Input, f: (output: Data.ClientFile.FiPaymentPlanInfo|undefined) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileReaderService.findPaymentPlan(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$FiPaymentPlan|undefined) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.ClientFile.ClientFileSummary.UPDATE_FI_PAYMENT_PLAN(mappedOutput);
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.FindPaymentPlan$Input): ServerData.ClientFileWebReader.FindPaymentPlan$Input {
        return new GetPaymentPlanInfo$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$FiPaymentPlan|undefined): Data.ClientFile.FiPaymentPlanInfo|undefined {
        return new GetPaymentPlanInfo$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetPaymentPlanInfo$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.FindPaymentPlan$Input): ServerData.ClientFileWebReader.FindPaymentPlan$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$FiPaymentPlan|undefined): Data.ClientFile.FiPaymentPlanInfo|undefined {
        return (output) ? Data.ClientFile.FiPaymentPlanInfo.fromJson(output.toJson()) : undefined;
    }

}

class GetPaymentPlanInfo$MapperCode extends GetPaymentPlanInfo$Mapper {

}

       


export class GetCashfreePaymentStatus {

    
    public static execute1(paymentId: string, f: (output: ServerData.PaymentWebReader.GetCashfreePaymentStatus$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetCashfreePaymentStatus.execute(new ServerData.PaymentWebReader.GetCashfreePaymentStatus$Input(paymentId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.PaymentWebReader.GetCashfreePaymentStatus$Input, f: (output: ServerData.PaymentWebReader.GetCashfreePaymentStatus$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        PaymentReaderService.getCashfreePaymentStatus(axoisInstance, this.mapInput(input),
            ((output: ServerData.PaymentWebReader.GetCashfreePaymentStatus$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.PaymentWebReader.GetCashfreePaymentStatus$Input): ServerData.PaymentWebReader.GetCashfreePaymentStatus$Input {
        return new GetCashfreePaymentStatus$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PaymentWebReader.GetCashfreePaymentStatus$Output): ServerData.PaymentWebReader.GetCashfreePaymentStatus$Output {
        return new GetCashfreePaymentStatus$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetCashfreePaymentStatus$Mapper {

    public mapInput(input: ServerData.PaymentWebReader.GetCashfreePaymentStatus$Input): ServerData.PaymentWebReader.GetCashfreePaymentStatus$Input {
        return input; 
    }

    public mapOutput(output: ServerData.PaymentWebReader.GetCashfreePaymentStatus$Output): ServerData.PaymentWebReader.GetCashfreePaymentStatus$Output {
        return output
    }

}

class GetCashfreePaymentStatus$MapperCode extends GetCashfreePaymentStatus$Mapper {

}

       


export class GetFiPaymentList {

    
    public static execute1(clientFileId: string, f: (output: Data.ClientFile.FiPayment[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetFiPaymentList.execute(new ServerData.PaymentWebReader.GetFiPaymentList$Input(clientFileId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.PaymentWebReader.GetFiPaymentList$Input, f: (output: Data.ClientFile.FiPayment[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        PaymentReaderService.getFiPaymentList(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$FiPayment[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.ClientFile.ClientFileSummary.UPDATE_FI_PAYMENT_LIST(mappedOutput.map((x) => Data.ClientFile.FiPayment.fromJson(x.toJson())));
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.PaymentWebReader.GetFiPaymentList$Input): ServerData.PaymentWebReader.GetFiPaymentList$Input {
        return new GetFiPaymentList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$FiPayment[]): Data.ClientFile.FiPayment[] {
        return new GetFiPaymentList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetFiPaymentList$Mapper {

    public mapInput(input: ServerData.PaymentWebReader.GetFiPaymentList$Input): ServerData.PaymentWebReader.GetFiPaymentList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$FiPayment[]): Data.ClientFile.FiPayment[] {
        return  output.map((x) => Data.ClientFile.FiPayment.fromJson(x.toJson()));
    }

}

class GetFiPaymentList$MapperCode extends GetFiPaymentList$Mapper {

}

       


export class GetQrCodeStatusListForClient {

    
    public static execute1(clientFileId: string, f: (output: Data.ClientFile.GetQrCodeStatusListForClientOutput) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetQrCodeStatusListForClient.execute(new ServerData.PaymentWebReader.GetQrCodeStatusListForClient$Input(clientFileId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.PaymentWebReader.GetQrCodeStatusListForClient$Input, f: (output: Data.ClientFile.GetQrCodeStatusListForClientOutput) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        PaymentReaderService.getQrCodeStatusListForClient(axoisInstance, this.mapInput(input),
            ((output: ServerData.PaymentWebReader.GetQrCodeStatusListForClient$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.PaymentWebReader.GetQrCodeStatusListForClient$Input): ServerData.PaymentWebReader.GetQrCodeStatusListForClient$Input {
        return new GetQrCodeStatusListForClient$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PaymentWebReader.GetQrCodeStatusListForClient$Output): Data.ClientFile.GetQrCodeStatusListForClientOutput {
        return new GetQrCodeStatusListForClient$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetQrCodeStatusListForClient$Mapper {

    public mapInput(input: ServerData.PaymentWebReader.GetQrCodeStatusListForClient$Input): ServerData.PaymentWebReader.GetQrCodeStatusListForClient$Input {
        return input; 
    }

    public mapOutput(output: ServerData.PaymentWebReader.GetQrCodeStatusListForClient$Output): Data.ClientFile.GetQrCodeStatusListForClientOutput {
        return Data.ClientFile.GetQrCodeStatusListForClientOutput.fromJson(output.toJson());
    }

}

class GetQrCodeStatusListForClient$MapperCode extends GetQrCodeStatusListForClient$Mapper {

}

       


export class GetPaymentTransactionList {

    
    public static execute1(paymentId: string, f: (output: Data.ClientFile.PaymentTransaction[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetPaymentTransactionList.execute(new ServerData.PaymentWebReader.GetPaymentTransactionList$Input(paymentId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.PaymentWebReader.GetPaymentTransactionList$Input, f: (output: Data.ClientFile.PaymentTransaction[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        PaymentReaderService.getPaymentTransactionList(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$FiPaymentTransaction[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.PaymentWebReader.GetPaymentTransactionList$Input): ServerData.PaymentWebReader.GetPaymentTransactionList$Input {
        return new GetPaymentTransactionList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$FiPaymentTransaction[]): Data.ClientFile.PaymentTransaction[] {
        return new GetPaymentTransactionList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetPaymentTransactionList$Mapper {

    public mapInput(input: ServerData.PaymentWebReader.GetPaymentTransactionList$Input): ServerData.PaymentWebReader.GetPaymentTransactionList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$FiPaymentTransaction[]): Data.ClientFile.PaymentTransaction[] {
        return  output.map((x) => Data.ClientFile.PaymentTransaction.fromJson(x.toJson()));
    }

}

class GetPaymentTransactionList$MapperCode extends GetPaymentTransactionList$Mapper {

}

       


export class GetFiBankInfo {

    
    public static execute1(clientFileId: string, f: (output: Data.ClientFile.FiBankInfo|undefined) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetFiBankInfo.execute(new ServerData.ClientFileWebReader.FindFiBankInfo$Input(clientFileId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebReader.FindFiBankInfo$Input, f: (output: Data.ClientFile.FiBankInfo|undefined) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileReaderService.findFiBankInfo(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$FiBankInfo|undefined) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.ClientFile.ClientFileSummary.UPDATE_FI_BANK_INFO(mappedOutput);
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.FindFiBankInfo$Input): ServerData.ClientFileWebReader.FindFiBankInfo$Input {
        return new GetFiBankInfo$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$FiBankInfo|undefined): Data.ClientFile.FiBankInfo|undefined {
        return new GetFiBankInfo$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetFiBankInfo$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.FindFiBankInfo$Input): ServerData.ClientFileWebReader.FindFiBankInfo$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$FiBankInfo|undefined): Data.ClientFile.FiBankInfo|undefined {
        return (output) ? Data.ClientFile.FiBankInfo.fromJson(output.toJson()) : undefined;
    }

}

class GetFiBankInfo$MapperCode extends GetFiBankInfo$Mapper {

}

       


export class GetAllSignAgreementList {

    
    public static execute1(clientFileId: string, f: (output: Data.ClientFile.FiSSASummary[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetAllSignAgreementList.execute(new ServerData.ClientFileWebReader.GetAllSignAgreementList$Input(clientFileId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebReader.GetAllSignAgreementList$Input, f: (output: Data.ClientFile.FiSSASummary[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileReaderService.getAllSignAgreementList(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$FiSSASummary[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.ClientFile.ClientFileSummary.UPDATE_FI_SIGN_AGREEMENT_LIST(mappedOutput.map((x) => Data.ClientFile.FiSSASummary.fromJson(x.toJson())));
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.GetAllSignAgreementList$Input): ServerData.ClientFileWebReader.GetAllSignAgreementList$Input {
        return new GetAllSignAgreementList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$FiSSASummary[]): Data.ClientFile.FiSSASummary[] {
        return new GetAllSignAgreementList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetAllSignAgreementList$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.GetAllSignAgreementList$Input): ServerData.ClientFileWebReader.GetAllSignAgreementList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$FiSSASummary[]): Data.ClientFile.FiSSASummary[] {
        return  output.map((x) => Data.ClientFile.FiSSASummary.fromJson(x.toJson()));
    }

}

class GetAllSignAgreementList$MapperCode extends GetAllSignAgreementList$Mapper {

}

       


export class GetEMandateDetails {

    
    public static execute1(fiMandateId: string, f: (output: ServerData.SpineData$FiEMandateSummary) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetEMandateDetails.execute(new ServerData.ClientFileWebReader.GetEMandateDetails$Input(fiMandateId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebReader.GetEMandateDetails$Input, f: (output: ServerData.SpineData$FiEMandateSummary) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileReaderService.getEMandateDetails(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$FiEMandateSummary) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.GetEMandateDetails$Input): ServerData.ClientFileWebReader.GetEMandateDetails$Input {
        return new GetEMandateDetails$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$FiEMandateSummary): ServerData.SpineData$FiEMandateSummary {
        return new GetEMandateDetails$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetEMandateDetails$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.GetEMandateDetails$Input): ServerData.ClientFileWebReader.GetEMandateDetails$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$FiEMandateSummary): ServerData.SpineData$FiEMandateSummary {
        return output
    }

}

class GetEMandateDetails$MapperCode extends GetEMandateDetails$Mapper {

}

       


export class GetAllEMandateList {

    
    public static execute1(clientFileId: string, f: (output: Data.ClientFile.FiEMandateSummary[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetAllEMandateList.execute(new ServerData.ClientFileWebReader.GetAllEMandateList$Input(clientFileId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebReader.GetAllEMandateList$Input, f: (output: Data.ClientFile.FiEMandateSummary[]) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileReaderService.getAllEMandateList(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$FiEMandateSummary[]) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.ClientFile.ClientFileSummary.UPDATE_FI_E_MANDATE_SUMMARY_LIST(mappedOutput.map((x) => Data.ClientFile.FiEMandateSummary.fromJson(x.toJson())));
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.GetAllEMandateList$Input): ServerData.ClientFileWebReader.GetAllEMandateList$Input {
        return new GetAllEMandateList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$FiEMandateSummary[]): Data.ClientFile.FiEMandateSummary[] {
        return new GetAllEMandateList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetAllEMandateList$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.GetAllEMandateList$Input): ServerData.ClientFileWebReader.GetAllEMandateList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$FiEMandateSummary[]): Data.ClientFile.FiEMandateSummary[] {
        return  output.map((x) => Data.ClientFile.FiEMandateSummary.fromJson(x.toJson()));
    }

}

class GetAllEMandateList$MapperCode extends GetAllEMandateList$Mapper {

}

       


export class GetExceptionTakenList {

    
    public static execute1(clientFileId: string, f: (output: Data.ClientFile.GetExceptionTakenListOutput) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GetExceptionTakenList.execute(new ServerData.ClientFileWebReader.GetExceptionTakenList$Input(clientFileId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebReader.GetExceptionTakenList$Input, f: (output: Data.ClientFile.GetExceptionTakenListOutput) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileReaderService.getExceptionTakenList(axoisInstance, this.mapInput(input),
            ((output: ServerData.ClientFileWebReader.GetExceptionTakenList$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.GetExceptionTakenList$Input): ServerData.ClientFileWebReader.GetExceptionTakenList$Input {
        return new GetExceptionTakenList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.ClientFileWebReader.GetExceptionTakenList$Output): Data.ClientFile.GetExceptionTakenListOutput {
        return new GetExceptionTakenList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GetExceptionTakenList$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.GetExceptionTakenList$Input): ServerData.ClientFileWebReader.GetExceptionTakenList$Input {
        return input; 
    }

    public mapOutput(output: ServerData.ClientFileWebReader.GetExceptionTakenList$Output): Data.ClientFile.GetExceptionTakenListOutput {
        return Data.ClientFile.GetExceptionTakenListOutput.fromJson(output.toJson());
    }

}

class GetExceptionTakenList$MapperCode extends GetExceptionTakenList$Mapper {

}

       


export class FindClPersonalInfo {

    
    public static execute1(clientFileId: string, f: (output: Data.ClientFile.ClPersonalInfo|undefined) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      FindClPersonalInfo.execute(new ServerData.ClientFileWebReader.FindClPersonalInfo$Input(clientFileId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebReader.FindClPersonalInfo$Input, f: (output: Data.ClientFile.ClPersonalInfo|undefined) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileReaderService.findClPersonalInfo(axoisInstance, this.mapInput(input),
            ((output: ServerData.SpineData$ClPersonalInfo|undefined) => {
                      const mappedOutput = this.mapOutput(output);

           

           Store.Mutation.ClientFile.ClientFileSummary.UPDATE_PERSONAL_INFO(mappedOutput);
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebReader.FindClPersonalInfo$Input): ServerData.ClientFileWebReader.FindClPersonalInfo$Input {
        return new FindClPersonalInfo$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.SpineData$ClPersonalInfo|undefined): Data.ClientFile.ClPersonalInfo|undefined {
        return new FindClPersonalInfo$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class FindClPersonalInfo$Mapper {

    public mapInput(input: ServerData.ClientFileWebReader.FindClPersonalInfo$Input): ServerData.ClientFileWebReader.FindClPersonalInfo$Input {
        return input; 
    }

    public mapOutput(output: ServerData.SpineData$ClPersonalInfo|undefined): Data.ClientFile.ClPersonalInfo|undefined {
        return (output) ? Data.ClientFile.ClPersonalInfo.fromJson(output.toJson()) : undefined;
    }

}

class FindClPersonalInfo$MapperCode extends FindClPersonalInfo$Mapper {

}

       


export class AttachDocument {

    

    

    public static execute(input: Data.ClientFile.AttachDocumentInput, f: (output: ServerData.ClientFileWebWriter.AttachDocument$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.attachDocument(axoisInstance, this.mapInput(input),
            ((output: ServerData.ClientFileWebWriter.AttachDocument$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::AttachDocument', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: ServerData.ClientFileWebWriter.AttachDocument$Output) => void): (output: ServerData.ClientFileWebWriter.AttachDocument$Output) => void {
        EventBus.$on('ClientFile::AttachDocument', f);
        return f;
    }

    public static notInterested(f: (output: ServerData.ClientFileWebWriter.AttachDocument$Output) => void): void {
        EventBus.$off('ClientFile::AttachDocument', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::AttachDocument');
    }
    

    private static mapInput(input: Data.ClientFile.AttachDocumentInput): ServerData.ClientFileWebWriter.AttachDocument$Input {
        return new AttachDocument$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.ClientFileWebWriter.AttachDocument$Output): ServerData.ClientFileWebWriter.AttachDocument$Output {
        return new AttachDocument$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class AttachDocument$Mapper {

    public mapInput(input: Data.ClientFile.AttachDocumentInput): ServerData.ClientFileWebWriter.AttachDocument$Input {
        return ServerData.ClientFileWebWriter.AttachDocument$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.ClientFileWebWriter.AttachDocument$Output): ServerData.ClientFileWebWriter.AttachDocument$Output {
        return output
    }

}

class AttachDocument$MapperCode extends AttachDocument$Mapper {

}

       


export class DetachDocument {

    
    public static execute1(fiDocumentId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      DetachDocument.execute(new ServerData.ClientFileWebWriter.DetachDocument$Input(fiDocumentId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebWriter.DetachDocument$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.detachDocument(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::DetachDocument', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::DetachDocument', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::DetachDocument', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::DetachDocument');
    }
    

    private static mapInput(input: ServerData.ClientFileWebWriter.DetachDocument$Input): ServerData.ClientFileWebWriter.DetachDocument$Input {
        return new DetachDocument$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new DetachDocument$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class DetachDocument$Mapper {

    public mapInput(input: ServerData.ClientFileWebWriter.DetachDocument$Input): ServerData.ClientFileWebWriter.DetachDocument$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class DetachDocument$MapperCode extends DetachDocument$Mapper {

}

       


export class UploadDocument {

    

    

    public static execute(input: Data.ClientFile.UploadDocumentForm, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.uploadDocument(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.ClientFile.UploadDocumentForm): ServerData.ClientFileWebWriter.UploadDocument$Input {
        return new UploadDocument$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new UploadDocument$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class UploadDocument$Mapper {

    public mapInput(input: Data.ClientFile.UploadDocumentForm): ServerData.ClientFileWebWriter.UploadDocument$Input {
        return ServerData.ClientFileWebWriter.UploadDocument$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class UploadDocument$MapperCode extends UploadDocument$Mapper {

}

       


export class AddFiCreditor {

    

    

    public static execute(input: Data.ClientFile.AddFiCreditorInput, f: (output: ServerData.ClientFileWebWriter.AddFiCreditor$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.addFiCreditor(axoisInstance, this.mapInput(input),
            ((output: ServerData.ClientFileWebWriter.AddFiCreditor$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::AddFiCreditor', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: ServerData.ClientFileWebWriter.AddFiCreditor$Output) => void): (output: ServerData.ClientFileWebWriter.AddFiCreditor$Output) => void {
        EventBus.$on('ClientFile::AddFiCreditor', f);
        return f;
    }

    public static notInterested(f: (output: ServerData.ClientFileWebWriter.AddFiCreditor$Output) => void): void {
        EventBus.$off('ClientFile::AddFiCreditor', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::AddFiCreditor');
    }
    

    private static mapInput(input: Data.ClientFile.AddFiCreditorInput): ServerData.ClientFileWebWriter.AddFiCreditor$Input {
        return new AddFiCreditor$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.ClientFileWebWriter.AddFiCreditor$Output): ServerData.ClientFileWebWriter.AddFiCreditor$Output {
        return new AddFiCreditor$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class AddFiCreditor$Mapper {

    public mapInput(input: Data.ClientFile.AddFiCreditorInput): ServerData.ClientFileWebWriter.AddFiCreditor$Input {
        return ServerData.ClientFileWebWriter.AddFiCreditor$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.ClientFileWebWriter.AddFiCreditor$Output): ServerData.ClientFileWebWriter.AddFiCreditor$Output {
        return output
    }

}

class AddFiCreditor$MapperCode extends AddFiCreditor$Mapper {

}

       


export class IncludeFiCreditorToProgram {

    

    
    public static execute2(clientFileId: string, clCreditorId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      IncludeFiCreditorToProgram.execute(new ServerData.ClientFileWebWriter.IncludeInProgram$Input(clientFileId, clCreditorId), f, eh, axoisInstance);
    } 

    public static execute(input: ServerData.ClientFileWebWriter.IncludeInProgram$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.includeInProgram(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::IncludeFiCreditorToProgram', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::IncludeFiCreditorToProgram', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::IncludeFiCreditorToProgram', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::IncludeFiCreditorToProgram');
    }
    

    private static mapInput(input: ServerData.ClientFileWebWriter.IncludeInProgram$Input): ServerData.ClientFileWebWriter.IncludeInProgram$Input {
        return new IncludeFiCreditorToProgram$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new IncludeFiCreditorToProgram$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class IncludeFiCreditorToProgram$Mapper {

    public mapInput(input: ServerData.ClientFileWebWriter.IncludeInProgram$Input): ServerData.ClientFileWebWriter.IncludeInProgram$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class IncludeFiCreditorToProgram$MapperCode extends IncludeFiCreditorToProgram$Mapper {

}

       


export class ExcludeFiCreditorFromProgram {

    

    
    public static execute2(clientFileId: string, fiCreditorId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      ExcludeFiCreditorFromProgram.execute(new ServerData.ClientFileWebWriter.ExcludeFromProgram$Input(clientFileId, fiCreditorId), f, eh, axoisInstance);
    } 

    public static execute(input: ServerData.ClientFileWebWriter.ExcludeFromProgram$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.excludeFromProgram(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::ExcludeFiCreditorFromProgram', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::ExcludeFiCreditorFromProgram', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::ExcludeFiCreditorFromProgram', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::ExcludeFiCreditorFromProgram');
    }
    

    private static mapInput(input: ServerData.ClientFileWebWriter.ExcludeFromProgram$Input): ServerData.ClientFileWebWriter.ExcludeFromProgram$Input {
        return new ExcludeFiCreditorFromProgram$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new ExcludeFiCreditorFromProgram$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class ExcludeFiCreditorFromProgram$Mapper {

    public mapInput(input: ServerData.ClientFileWebWriter.ExcludeFromProgram$Input): ServerData.ClientFileWebWriter.ExcludeFromProgram$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class ExcludeFiCreditorFromProgram$MapperCode extends ExcludeFiCreditorFromProgram$Mapper {

}

       


export class AddIncludeFiCreditor {

    

    

    public static execute(input: Data.ClientFile.AddIncludeFiCreditorInput, f: (output: ServerData.ClientFileWebWriter.AddFiCreditor$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.addFiCreditor(axoisInstance, this.mapInput(input),
            ((output: ServerData.ClientFileWebWriter.AddFiCreditor$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::AddIncludeFiCreditor', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: ServerData.ClientFileWebWriter.AddFiCreditor$Output) => void): (output: ServerData.ClientFileWebWriter.AddFiCreditor$Output) => void {
        EventBus.$on('ClientFile::AddIncludeFiCreditor', f);
        return f;
    }

    public static notInterested(f: (output: ServerData.ClientFileWebWriter.AddFiCreditor$Output) => void): void {
        EventBus.$off('ClientFile::AddIncludeFiCreditor', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::AddIncludeFiCreditor');
    }
    

    private static mapInput(input: Data.ClientFile.AddIncludeFiCreditorInput): ServerData.ClientFileWebWriter.AddFiCreditor$Input {
        return new AddIncludeFiCreditor$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.ClientFileWebWriter.AddFiCreditor$Output): ServerData.ClientFileWebWriter.AddFiCreditor$Output {
        return new AddIncludeFiCreditor$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class AddIncludeFiCreditor$Mapper {

    public mapInput(input: Data.ClientFile.AddIncludeFiCreditorInput): ServerData.ClientFileWebWriter.AddFiCreditor$Input {
        return ServerData.ClientFileWebWriter.AddFiCreditor$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.ClientFileWebWriter.AddFiCreditor$Output): ServerData.ClientFileWebWriter.AddFiCreditor$Output {
        return output
    }

}

class AddIncludeFiCreditor$MapperCode extends AddIncludeFiCreditor$Mapper {

}

       


export class UpdateFiCreditor {

    

    

    public static execute(input: Data.ClientFile.UpdateFiCreditorInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.updateFiCreditor(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::UpdateFiCreditor', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::UpdateFiCreditor', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::UpdateFiCreditor', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::UpdateFiCreditor');
    }
    

    private static mapInput(input: Data.ClientFile.UpdateFiCreditorInput): ServerData.ClientFileWebWriter.UpdateFiCreditor$Input {
        return new UpdateFiCreditor$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new UpdateFiCreditor$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class UpdateFiCreditor$Mapper {

    public mapInput(input: Data.ClientFile.UpdateFiCreditorInput): ServerData.ClientFileWebWriter.UpdateFiCreditor$Input {
        return ServerData.ClientFileWebWriter.UpdateFiCreditor$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class UpdateFiCreditor$MapperCode extends UpdateFiCreditor$Mapper {

}

       


export class RemoveClCreditor {

    

    
    public static execute2(clCreditorId: string, reason: string|undefined, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      RemoveClCreditor.execute(new Data.ClientFile.RemoveClCreditorInput(clCreditorId, reason), f, eh, axoisInstance);
    } 

    public static execute(input: Data.ClientFile.RemoveClCreditorInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientWriterService.removeClCreditor(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::RemoveClCreditor', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::RemoveClCreditor', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::RemoveClCreditor', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::RemoveClCreditor');
    }
    

    private static mapInput(input: Data.ClientFile.RemoveClCreditorInput): ServerData.ClientWebWriter.RemoveClCreditor$Input {
        return new RemoveClCreditor$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new RemoveClCreditor$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class RemoveClCreditor$Mapper {

    public mapInput(input: Data.ClientFile.RemoveClCreditorInput): ServerData.ClientWebWriter.RemoveClCreditor$Input {
        return ServerData.ClientWebWriter.RemoveClCreditor$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class RemoveClCreditor$MapperCode extends RemoveClCreditor$Mapper {

}

       


export class UpdateClCreditor {

    

    

    public static execute(input: Data.ClientFile.RemoveClCreditorInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientWriterService.updateClCreditor(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::UpdateClCreditor', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::UpdateClCreditor', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::UpdateClCreditor', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::UpdateClCreditor');
    }
    

    private static mapInput(input: Data.ClientFile.RemoveClCreditorInput): ServerData.ClientWebWriter.UpdateClCreditor$Input {
        return new UpdateClCreditor$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new UpdateClCreditor$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class UpdateClCreditor$Mapper {

    public mapInput(input: Data.ClientFile.RemoveClCreditorInput): ServerData.ClientWebWriter.UpdateClCreditor$Input {
        return ServerData.ClientWebWriter.UpdateClCreditor$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class UpdateClCreditor$MapperCode extends UpdateClCreditor$Mapper {

}

       


export class UpdateIncludeClCreditor {

    

    

    public static execute(input: Data.ClientFile.UpdateIncludeClCreditorInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.updateIncludeClCreditor(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::UpdateIncludeClCreditor', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::UpdateIncludeClCreditor', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::UpdateIncludeClCreditor', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::UpdateIncludeClCreditor');
    }
    

    private static mapInput(input: Data.ClientFile.UpdateIncludeClCreditorInput): ServerData.ClientFileWebWriter.UpdateIncludeClCreditor$Input {
        return new UpdateIncludeClCreditor$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new UpdateIncludeClCreditor$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class UpdateIncludeClCreditor$Mapper {

    public mapInput(input: Data.ClientFile.UpdateIncludeClCreditorInput): ServerData.ClientFileWebWriter.UpdateIncludeClCreditor$Input {
        return ServerData.ClientFileWebWriter.UpdateIncludeClCreditor$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class UpdateIncludeClCreditor$MapperCode extends UpdateIncludeClCreditor$Mapper {

}

       


export class UpdateIncludeClCreditorForAmendment {

    

    

    public static execute(input: Data.ClientFile.UpdateIncludeClCreditorForAmendmentForm, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        AmendmentWriterService.updateIncludeClCreditor(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::UpdateIncludeClCreditorForAmendment', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::UpdateIncludeClCreditorForAmendment', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::UpdateIncludeClCreditorForAmendment', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::UpdateIncludeClCreditorForAmendment');
    }
    

    private static mapInput(input: Data.ClientFile.UpdateIncludeClCreditorForAmendmentForm): ServerData.AmendmentWebWriter.UpdateIncludeClCreditor$Input {
        return new UpdateIncludeClCreditorForAmendment$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new UpdateIncludeClCreditorForAmendment$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class UpdateIncludeClCreditorForAmendment$Mapper {

    public mapInput(input: Data.ClientFile.UpdateIncludeClCreditorForAmendmentForm): ServerData.AmendmentWebWriter.UpdateIncludeClCreditor$Input {
        return ServerData.AmendmentWebWriter.UpdateIncludeClCreditor$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class UpdateIncludeClCreditorForAmendment$MapperCode extends UpdateIncludeClCreditorForAmendment$Mapper {

}

       


export class UpdateCreditInfo {

    

    

    public static execute(input: Data.ClientFile.UpdateCreditInfoInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.updateCreditInfo(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::UpdateCreditInfo', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::UpdateCreditInfo', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::UpdateCreditInfo', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::UpdateCreditInfo');
    }
    

    private static mapInput(input: Data.ClientFile.UpdateCreditInfoInput): ServerData.ClientFileWebWriter.UpdateCreditInfo$Input {
        return new UpdateCreditInfo$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new UpdateCreditInfo$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class UpdateCreditInfo$Mapper {

    public mapInput(input: Data.ClientFile.UpdateCreditInfoInput): ServerData.ClientFileWebWriter.UpdateCreditInfo$Input {
        return ServerData.ClientFileWebWriter.UpdateCreditInfo$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class UpdateCreditInfo$MapperCode extends UpdateCreditInfo$Mapper {

}

       


export class UpdateBudgetInfo {

    

    

    public static execute(input: Data.ClientFile.UpdateBudgetInfoInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.updateBudgetInfo(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::UpdateBudgetInfo', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::UpdateBudgetInfo', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::UpdateBudgetInfo', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::UpdateBudgetInfo');
    }
    

    private static mapInput(input: Data.ClientFile.UpdateBudgetInfoInput): ServerData.ClientFileWebWriter.UpdateBudgetInfo$Input {
        return new UpdateBudgetInfo$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new UpdateBudgetInfo$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class UpdateBudgetInfo$Mapper {

    public mapInput(input: Data.ClientFile.UpdateBudgetInfoInput): ServerData.ClientFileWebWriter.UpdateBudgetInfo$Input {
        return ServerData.ClientFileWebWriter.UpdateBudgetInfo$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class UpdateBudgetInfo$MapperCode extends UpdateBudgetInfo$Mapper {

}

       


export class DraftPSPlanForPM {

    

    

    public static execute(input: Data.ClientFile.DraftPSPlanForPMInput, f: (output: ServerData.PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        PaymentSchedulePlanWriterService.draftPSPlanForPM(axoisInstance, this.mapInput(input),
            ((output: ServerData.PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::DraftPSPlanForPM', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: ServerData.PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Output) => void): (output: ServerData.PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Output) => void {
        EventBus.$on('ClientFile::DraftPSPlanForPM', f);
        return f;
    }

    public static notInterested(f: (output: ServerData.PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Output) => void): void {
        EventBus.$off('ClientFile::DraftPSPlanForPM', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::DraftPSPlanForPM');
    }
    

    private static mapInput(input: Data.ClientFile.DraftPSPlanForPMInput): ServerData.PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Input {
        return new DraftPSPlanForPM$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Output): ServerData.PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Output {
        return new DraftPSPlanForPM$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class DraftPSPlanForPM$Mapper {

    public mapInput(input: Data.ClientFile.DraftPSPlanForPMInput): ServerData.PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Input {
        return ServerData.PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Output): ServerData.PaymentSchedulePlanWebWriter.DraftPSPlanForPM$Output {
        return output
    }

}

class DraftPSPlanForPM$MapperCode extends DraftPSPlanForPM$Mapper {

}

       


export class AddPSEntry {

    

    

    public static execute(input: Data.ClientFile.AddPSEntryInput, f: (output: ServerData.PaymentScheduleEntryWebWriter.AddPSEntry$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        PaymentScheduleEntryWriterService.addPSEntry(axoisInstance, this.mapInput(input),
            ((output: ServerData.PaymentScheduleEntryWebWriter.AddPSEntry$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::AddPSEntry', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: ServerData.PaymentScheduleEntryWebWriter.AddPSEntry$Output) => void): (output: ServerData.PaymentScheduleEntryWebWriter.AddPSEntry$Output) => void {
        EventBus.$on('ClientFile::AddPSEntry', f);
        return f;
    }

    public static notInterested(f: (output: ServerData.PaymentScheduleEntryWebWriter.AddPSEntry$Output) => void): void {
        EventBus.$off('ClientFile::AddPSEntry', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::AddPSEntry');
    }
    

    private static mapInput(input: Data.ClientFile.AddPSEntryInput): ServerData.PaymentScheduleEntryWebWriter.AddPSEntry$Input {
        return new AddPSEntry$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PaymentScheduleEntryWebWriter.AddPSEntry$Output): ServerData.PaymentScheduleEntryWebWriter.AddPSEntry$Output {
        return new AddPSEntry$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class AddPSEntry$Mapper {

    public mapInput(input: Data.ClientFile.AddPSEntryInput): ServerData.PaymentScheduleEntryWebWriter.AddPSEntry$Input {
        return ServerData.PaymentScheduleEntryWebWriter.AddPSEntry$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.PaymentScheduleEntryWebWriter.AddPSEntry$Output): ServerData.PaymentScheduleEntryWebWriter.AddPSEntry$Output {
        return output
    }

}

class AddPSEntry$MapperCode extends AddPSEntry$Mapper {

}

       


export class RecalculatePSPlanForPM {

    

    

    public static execute(input: Data.ClientFile.RecalculatePSPlanForPMInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        PaymentSchedulePlanWriterService.recalculatePSPlanForPM(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::RecalculatePSPlanForPM', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::RecalculatePSPlanForPM', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::RecalculatePSPlanForPM', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::RecalculatePSPlanForPM');
    }
    

    private static mapInput(input: Data.ClientFile.RecalculatePSPlanForPMInput): ServerData.PaymentSchedulePlanWebWriter.RecalculatePSPlanForPM$Input {
        return new RecalculatePSPlanForPM$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new RecalculatePSPlanForPM$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class RecalculatePSPlanForPM$Mapper {

    public mapInput(input: Data.ClientFile.RecalculatePSPlanForPMInput): ServerData.PaymentSchedulePlanWebWriter.RecalculatePSPlanForPM$Input {
        return ServerData.PaymentSchedulePlanWebWriter.RecalculatePSPlanForPM$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class RecalculatePSPlanForPM$MapperCode extends RecalculatePSPlanForPM$Mapper {

}

       


export class CheckPaymentStatus {

    
    public static execute1(paymentId: string, f: (output: ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      CheckPaymentStatus.execute(new ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Input(paymentId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Input, f: (output: ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        PaymentWriterService.checkAndUpdatePaymentStatus(axoisInstance, this.mapInput(input),
            ((output: ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::CheckPaymentStatus', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Output) => void): (output: ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Output) => void {
        EventBus.$on('ClientFile::CheckPaymentStatus', f);
        return f;
    }

    public static notInterested(f: (output: ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Output) => void): void {
        EventBus.$off('ClientFile::CheckPaymentStatus', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::CheckPaymentStatus');
    }
    

    private static mapInput(input: ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Input): ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Input {
        return new CheckPaymentStatus$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Output): ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Output {
        return new CheckPaymentStatus$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class CheckPaymentStatus$Mapper {

    public mapInput(input: ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Input): ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Input {
        return input; 
    }

    public mapOutput(output: ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Output): ServerData.PaymentWebWriter.CheckAndUpdatePaymentStatus$Output {
        return output
    }

}

class CheckPaymentStatus$MapperCode extends CheckPaymentStatus$Mapper {

}

       


export class RequestFundSplit {

    
    public static execute1(paymentId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      RequestFundSplit.execute(new ServerData.PaymentWebWriter.RequestFundSplit$Input(paymentId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.PaymentWebWriter.RequestFundSplit$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        PaymentWriterService.requestFundSplit(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::RequestFundSplit', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::RequestFundSplit', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::RequestFundSplit', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::RequestFundSplit');
    }
    

    private static mapInput(input: ServerData.PaymentWebWriter.RequestFundSplit$Input): ServerData.PaymentWebWriter.RequestFundSplit$Input {
        return new RequestFundSplit$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new RequestFundSplit$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class RequestFundSplit$Mapper {

    public mapInput(input: ServerData.PaymentWebWriter.RequestFundSplit$Input): ServerData.PaymentWebWriter.RequestFundSplit$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class RequestFundSplit$MapperCode extends RequestFundSplit$Mapper {

}

       


export class UpdateFundSplitStatus {

    
    public static execute1(paymentId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      UpdateFundSplitStatus.execute(new ServerData.PaymentWebWriter.UpdateFundSplitStatus$Input(paymentId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.PaymentWebWriter.UpdateFundSplitStatus$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        PaymentWriterService.updateFundSplitStatus(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::UpdateFundSplitStatus', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::UpdateFundSplitStatus', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::UpdateFundSplitStatus', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::UpdateFundSplitStatus');
    }
    

    private static mapInput(input: ServerData.PaymentWebWriter.UpdateFundSplitStatus$Input): ServerData.PaymentWebWriter.UpdateFundSplitStatus$Input {
        return new UpdateFundSplitStatus$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new UpdateFundSplitStatus$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class UpdateFundSplitStatus$Mapper {

    public mapInput(input: ServerData.PaymentWebWriter.UpdateFundSplitStatus$Input): ServerData.PaymentWebWriter.UpdateFundSplitStatus$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class UpdateFundSplitStatus$MapperCode extends UpdateFundSplitStatus$Mapper {

}

       


export class CancelPayment {

    

    
    public static execute2(paymentId: string, reason: string|undefined, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      CancelPayment.execute(new ServerData.PaymentWebWriter.CancelPayment$Input(paymentId, reason), f, eh, axoisInstance);
    } 

    public static execute(input: ServerData.PaymentWebWriter.CancelPayment$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        PaymentWriterService.cancelPayment(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::CancelPayment', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::CancelPayment', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::CancelPayment', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::CancelPayment');
    }
    

    private static mapInput(input: ServerData.PaymentWebWriter.CancelPayment$Input): ServerData.PaymentWebWriter.CancelPayment$Input {
        return new CancelPayment$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new CancelPayment$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class CancelPayment$Mapper {

    public mapInput(input: ServerData.PaymentWebWriter.CancelPayment$Input): ServerData.PaymentWebWriter.CancelPayment$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class CancelPayment$MapperCode extends CancelPayment$Mapper {

}

       


export class PresentPSEntry {

    
    public static execute1(psEntryId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      PresentPSEntry.execute(new Data.ClientFile.PresentPSEntryInput(psEntryId), f, eh, axoisInstance);
    } 

    

    public static execute(input: Data.ClientFile.PresentPSEntryInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        PaymentScheduleEntryWriterService.presentPSEntry(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::PresentPSEntry', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::PresentPSEntry', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::PresentPSEntry', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::PresentPSEntry');
    }
    

    private static mapInput(input: Data.ClientFile.PresentPSEntryInput): ServerData.PaymentScheduleEntryWebWriter.PresentPSEntry$Input {
        return new PresentPSEntry$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new PresentPSEntry$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class PresentPSEntry$Mapper {

    public mapInput(input: Data.ClientFile.PresentPSEntryInput): ServerData.PaymentScheduleEntryWebWriter.PresentPSEntry$Input {
        return ServerData.PaymentScheduleEntryWebWriter.PresentPSEntry$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class PresentPSEntry$MapperCode extends PresentPSEntry$Mapper {

}

       


export class RemovePSEntryList {

    
    public static execute1(psEntryIdList: string[], f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      RemovePSEntryList.execute(new ServerData.PaymentScheduleEntryWebWriter.RemovePSEntryList$Input(psEntryIdList), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.PaymentScheduleEntryWebWriter.RemovePSEntryList$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        PaymentScheduleEntryWriterService.removePSEntryList(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::RemovePSEntryList', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::RemovePSEntryList', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::RemovePSEntryList', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::RemovePSEntryList');
    }
    

    private static mapInput(input: ServerData.PaymentScheduleEntryWebWriter.RemovePSEntryList$Input): ServerData.PaymentScheduleEntryWebWriter.RemovePSEntryList$Input {
        return new RemovePSEntryList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new RemovePSEntryList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class RemovePSEntryList$Mapper {

    public mapInput(input: ServerData.PaymentScheduleEntryWebWriter.RemovePSEntryList$Input): ServerData.PaymentScheduleEntryWebWriter.RemovePSEntryList$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class RemovePSEntryList$MapperCode extends RemovePSEntryList$Mapper {

}

       


export class ModifyAmountWithFixedTenure {

    

    

    public static execute(input: Data.ClientFile.ModifyAmountWithFixedTenureInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        PaymentScheduleEntryWriterService.modifyAmountWithFixedTenure(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::ModifyAmountWithFixedTenure', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::ModifyAmountWithFixedTenure', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::ModifyAmountWithFixedTenure', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::ModifyAmountWithFixedTenure');
    }
    

    private static mapInput(input: Data.ClientFile.ModifyAmountWithFixedTenureInput): ServerData.PaymentScheduleEntryWebWriter.ModifyAmountWithFixedTenure$Input {
        return new ModifyAmountWithFixedTenure$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new ModifyAmountWithFixedTenure$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class ModifyAmountWithFixedTenure$Mapper {

    public mapInput(input: Data.ClientFile.ModifyAmountWithFixedTenureInput): ServerData.PaymentScheduleEntryWebWriter.ModifyAmountWithFixedTenure$Input {
        return ServerData.PaymentScheduleEntryWebWriter.ModifyAmountWithFixedTenure$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class ModifyAmountWithFixedTenure$MapperCode extends ModifyAmountWithFixedTenure$Mapper {

}

       


export class UpdateBankInfo {

    

    

    public static execute(input: Data.ClientFile.UpdateBankInfoInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.updateBankInfo(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::UpdateBankInfo', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::UpdateBankInfo', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::UpdateBankInfo', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::UpdateBankInfo');
    }
    

    private static mapInput(input: Data.ClientFile.UpdateBankInfoInput): ServerData.ClientFileWebWriter.UpdateBankInfo$Input {
        return new UpdateBankInfo$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new UpdateBankInfo$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class UpdateBankInfo$Mapper {

    public mapInput(input: Data.ClientFile.UpdateBankInfoInput): ServerData.ClientFileWebWriter.UpdateBankInfo$Input {
        return ServerData.ClientFileWebWriter.UpdateBankInfo$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class UpdateBankInfo$MapperCode extends UpdateBankInfo$Mapper {

}

       


export class Skip {

    
    public static execute1(psEntryId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      Skip.execute(new Data.ClientFile.SkipInput(psEntryId), f, eh, axoisInstance);
    } 

    

    public static execute(input: Data.ClientFile.SkipInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        PaymentScheduleEntryWriterService.skip(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::Skip', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::Skip', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::Skip', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::Skip');
    }
    

    private static mapInput(input: Data.ClientFile.SkipInput): ServerData.PaymentScheduleEntryWebWriter.Skip$Input {
        return new Skip$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new Skip$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class Skip$Mapper {

    public mapInput(input: Data.ClientFile.SkipInput): ServerData.PaymentScheduleEntryWebWriter.Skip$Input {
        return ServerData.PaymentScheduleEntryWebWriter.Skip$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class Skip$MapperCode extends Skip$Mapper {

}

       


export class GenerateAgreement {

    
    public static execute1(clientFileId: string, f: (output: ServerData.ClientFileWebWriter.GenerateAgreement$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      GenerateAgreement.execute(new ServerData.ClientFileWebWriter.GenerateAgreement$Input(clientFileId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebWriter.GenerateAgreement$Input, f: (output: ServerData.ClientFileWebWriter.GenerateAgreement$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.generateAgreement(axoisInstance, this.mapInput(input),
            ((output: ServerData.ClientFileWebWriter.GenerateAgreement$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::GenerateAgreement', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: ServerData.ClientFileWebWriter.GenerateAgreement$Output) => void): (output: ServerData.ClientFileWebWriter.GenerateAgreement$Output) => void {
        EventBus.$on('ClientFile::GenerateAgreement', f);
        return f;
    }

    public static notInterested(f: (output: ServerData.ClientFileWebWriter.GenerateAgreement$Output) => void): void {
        EventBus.$off('ClientFile::GenerateAgreement', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::GenerateAgreement');
    }
    

    private static mapInput(input: ServerData.ClientFileWebWriter.GenerateAgreement$Input): ServerData.ClientFileWebWriter.GenerateAgreement$Input {
        return new GenerateAgreement$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.ClientFileWebWriter.GenerateAgreement$Output): ServerData.ClientFileWebWriter.GenerateAgreement$Output {
        return new GenerateAgreement$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class GenerateAgreement$Mapper {

    public mapInput(input: ServerData.ClientFileWebWriter.GenerateAgreement$Input): ServerData.ClientFileWebWriter.GenerateAgreement$Input {
        return input; 
    }

    public mapOutput(output: ServerData.ClientFileWebWriter.GenerateAgreement$Output): ServerData.ClientFileWebWriter.GenerateAgreement$Output {
        return output
    }

}

class GenerateAgreement$MapperCode extends GenerateAgreement$Mapper {

}

       


export class DraftAndPresentFirstMSFThroughCashfree {

    

    
    public static execute2(clientFileId: string, discountCode: number|undefined, f: (output: ServerData.MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      DraftAndPresentFirstMSFThroughCashfree.execute(new Data.ClientFile.DraftFirstMSFThroughCashfreeInput(clientFileId, discountCode), f, eh, axoisInstance);
    } 

    public static execute(input: Data.ClientFile.DraftFirstMSFThroughCashfreeInput, f: (output: ServerData.MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        MSFScheduleEntryWriterService.draftAndPresentFirstMSFThroughCashfree(axoisInstance, this.mapInput(input),
            ((output: ServerData.MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::DraftAndPresentFirstMSFThroughCashfree', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: ServerData.MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Output) => void): (output: ServerData.MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Output) => void {
        EventBus.$on('ClientFile::DraftAndPresentFirstMSFThroughCashfree', f);
        return f;
    }

    public static notInterested(f: (output: ServerData.MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Output) => void): void {
        EventBus.$off('ClientFile::DraftAndPresentFirstMSFThroughCashfree', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::DraftAndPresentFirstMSFThroughCashfree');
    }
    

    private static mapInput(input: Data.ClientFile.DraftFirstMSFThroughCashfreeInput): ServerData.MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Input {
        return new DraftAndPresentFirstMSFThroughCashfree$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Output): ServerData.MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Output {
        return new DraftAndPresentFirstMSFThroughCashfree$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class DraftAndPresentFirstMSFThroughCashfree$Mapper {

    public mapInput(input: Data.ClientFile.DraftFirstMSFThroughCashfreeInput): ServerData.MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Input {
        return ServerData.MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Output): ServerData.MSFScheduleEntryWebWriter.DraftAndPresentFirstMSFThroughCashfree$Output {
        return output
    }

}

class DraftAndPresentFirstMSFThroughCashfree$MapperCode extends DraftAndPresentFirstMSFThroughCashfree$Mapper {

}

       


export class InitiateEMandate {

    

    

    public static execute(input: Data.ClientFile.InitiateEMandateInput, f: (output: ServerData.ClientFileWebWriter.InitiateEMandate$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.initiateEMandate(axoisInstance, this.mapInput(input),
            ((output: ServerData.ClientFileWebWriter.InitiateEMandate$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::InitiateEMandate', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: ServerData.ClientFileWebWriter.InitiateEMandate$Output) => void): (output: ServerData.ClientFileWebWriter.InitiateEMandate$Output) => void {
        EventBus.$on('ClientFile::InitiateEMandate', f);
        return f;
    }

    public static notInterested(f: (output: ServerData.ClientFileWebWriter.InitiateEMandate$Output) => void): void {
        EventBus.$off('ClientFile::InitiateEMandate', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::InitiateEMandate');
    }
    

    private static mapInput(input: Data.ClientFile.InitiateEMandateInput): ServerData.ClientFileWebWriter.InitiateEMandate$Input {
        return new InitiateEMandate$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.ClientFileWebWriter.InitiateEMandate$Output): ServerData.ClientFileWebWriter.InitiateEMandate$Output {
        return new InitiateEMandate$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class InitiateEMandate$Mapper {

    public mapInput(input: Data.ClientFile.InitiateEMandateInput): ServerData.ClientFileWebWriter.InitiateEMandate$Input {
        return ServerData.ClientFileWebWriter.InitiateEMandate$Input.fromJson(input.toJson());
    }

    public mapOutput(output: ServerData.ClientFileWebWriter.InitiateEMandate$Output): ServerData.ClientFileWebWriter.InitiateEMandate$Output {
        return output
    }

}

class InitiateEMandate$MapperCode extends InitiateEMandate$Mapper {

}

       


export class CheckAndUpdateEMandate {

    
    public static execute1(eMandateId: string, f: (output: ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      CheckAndUpdateEMandate.execute(new ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Input(eMandateId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Input, f: (output: ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Output) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.checkAndUpdateEMandate(axoisInstance, this.mapInput(input),
            ((output: ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Output) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::CheckAndUpdateEMandate', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Output) => void): (output: ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Output) => void {
        EventBus.$on('ClientFile::CheckAndUpdateEMandate', f);
        return f;
    }

    public static notInterested(f: (output: ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Output) => void): void {
        EventBus.$off('ClientFile::CheckAndUpdateEMandate', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::CheckAndUpdateEMandate');
    }
    

    private static mapInput(input: ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Input): ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Input {
        return new CheckAndUpdateEMandate$MapperCode().mapInput(input);
    }

    private static mapOutput(output: ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Output): ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Output {
        return new CheckAndUpdateEMandate$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class CheckAndUpdateEMandate$Mapper {

    public mapInput(input: ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Input): ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Input {
        return input; 
    }

    public mapOutput(output: ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Output): ServerData.ClientFileWebWriter.CheckAndUpdateEMandate$Output {
        return output
    }

}

class CheckAndUpdateEMandate$MapperCode extends CheckAndUpdateEMandate$Mapper {

}

       


export class SendEmandate {

    

    

    public static execute(input: Data.ClientFile.SendEmandateInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.sendEmandate(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: Data.ClientFile.SendEmandateInput): ServerData.ClientFileWebWriter.SendEmandate$Input {
        return new SendEmandate$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new SendEmandate$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class SendEmandate$Mapper {

    public mapInput(input: Data.ClientFile.SendEmandateInput): ServerData.ClientFileWebWriter.SendEmandate$Input {
        return ServerData.ClientFileWebWriter.SendEmandate$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class SendEmandate$MapperCode extends SendEmandate$Mapper {

}

       


export class MarkEMandateAsDefault {

    
    public static execute1(fiEMandateId: string, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {
      MarkEMandateAsDefault.execute(new ServerData.ClientFileWebWriter.MarkEMandateAsDefault$Input(fiEMandateId), f, eh, axoisInstance);
    } 

    

    public static execute(input: ServerData.ClientFileWebWriter.MarkEMandateAsDefault$Input, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.markEMandateAsDefault(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             
            }), eh);

    }

    

    private static mapInput(input: ServerData.ClientFileWebWriter.MarkEMandateAsDefault$Input): ServerData.ClientFileWebWriter.MarkEMandateAsDefault$Input {
        return new MarkEMandateAsDefault$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new MarkEMandateAsDefault$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class MarkEMandateAsDefault$Mapper {

    public mapInput(input: ServerData.ClientFileWebWriter.MarkEMandateAsDefault$Input): ServerData.ClientFileWebWriter.MarkEMandateAsDefault$Input {
        return input; 
    }

    public mapOutput(output: string): string {
        return output
    }

}

class MarkEMandateAsDefault$MapperCode extends MarkEMandateAsDefault$Mapper {

}

       


export class UpdateExceptionTakenList {

    

    

    public static execute(input: Data.ClientFile.UpdateExceptionTakenListInput, f: (output: string) => void, eh: ((error: AxiosError) => void)|undefined = undefined, axoisInstance: AxiosInstance = RemoteApiPoint.SpineApi): void {

    

    

        ClientFileWriterService.updateExceptionTakenList(axoisInstance, this.mapInput(input),
            ((output: string) => {
                      const mappedOutput = this.mapOutput(output);

           

           
             f(mappedOutput);
             EventBus.$emit('ClientFile::UpdateExceptionTakenList', mappedOutput);
            }), eh);

    }

    
    public static interested(f: (output: string) => void): (output: string) => void {
        EventBus.$on('ClientFile::UpdateExceptionTakenList', f);
        return f;
    }

    public static notInterested(f: (output: string) => void): void {
        EventBus.$off('ClientFile::UpdateExceptionTakenList', f);
    }


    public static nobodyInterested(): void  {
        EventBus.$off('ClientFile::UpdateExceptionTakenList');
    }
    

    private static mapInput(input: Data.ClientFile.UpdateExceptionTakenListInput): ServerData.ClientFileWebWriter.UpdateExceptionTakenList$Input {
        return new UpdateExceptionTakenList$MapperCode().mapInput(input);
    }

    private static mapOutput(output: string): string {
        return new UpdateExceptionTakenList$MapperCode().mapOutput(output);
    }

    private static handleError(error: AxiosError): void {
         console.log('got error' + error.response);
    }
}

abstract class UpdateExceptionTakenList$Mapper {

    public mapInput(input: Data.ClientFile.UpdateExceptionTakenListInput): ServerData.ClientFileWebWriter.UpdateExceptionTakenList$Input {
        return ServerData.ClientFileWebWriter.UpdateExceptionTakenList$Input.fromJson(input.toJson());
    }

    public mapOutput(output: string): string {
        return output
    }

}

class UpdateExceptionTakenList$MapperCode extends UpdateExceptionTakenList$Mapper {

}

       

     