
import Vuex, { GetterTree, MutationTree } from 'vuex';
import {namespace} from 'vuex-class';
import * as Data from './../data';
// tslint:disable


import {FiNoteStoreInit} from './../../src/section/affiliateapp/store/finote/FiNoteStoreGettersCode';
import {FiNoteStoreGettersCode} from './../../src/section/affiliateapp/store/finote/FiNoteStoreGettersCode';
import {FiNoteStoreMutationsCode} from './../../src/section/affiliateapp/store/finote/FiNoteStoreMutationsCode';
import {FiNoteStoreGetters} from './finote-store-support';
import {FiNoteStoreMutations} from './finote-store-support';


import store from './../store';


const fiNoteStoreGetters: FiNoteStoreGetters = new FiNoteStoreGettersCode();
const FiNoteStoreGetterTree: GetterTree<Data.FiNote.FiNoteStore, any> = {
  fiNoteList : (state: Data.FiNote.FiNoteStore) => fiNoteStoreGetters.fiNoteList(state),
fiHighlightedNoteList : (state: Data.FiNote.FiNoteStore) => fiNoteStoreGetters.fiHighlightedNoteList(state)
};

const fiNoteStoreMutations: FiNoteStoreMutations = new FiNoteStoreMutationsCode();
const FiNoteStoreMutationTree: MutationTree<Data.FiNote.FiNoteStore> = {
  updateFiNoteList : (state: Data.FiNote.FiNoteStore, fiNoteList: Data.FiNote.FiNote[] ) => fiNoteStoreMutations.updateFiNoteList(state , fiNoteList  ) ,
updateFiHighlightedNoteList : (state: Data.FiNote.FiNoteStore, fiNoteList: Data.FiNote.FiNote[] ) => fiNoteStoreMutations.updateFiHighlightedNoteList(state , fiNoteList  ) 
};

const storeFiNoteStore = {
  namespaced: true,
  state: FiNoteStoreInit.initState(),
  mutations : FiNoteStoreMutationTree,
  getters: FiNoteStoreGetterTree
};

const FiNoteStoreSpace = namespace('FiNote/FiNoteStore');
const FiNoteStoreGetter = {
    fiNoteList : FiNoteStoreSpace.Getter('fiNoteList') ,
fiHighlightedNoteList : FiNoteStoreSpace.Getter('fiHighlightedNoteList') 
};

class FiNoteStoreMutationAPI {

  public static UPDATE_FI_NOTE_LIST(fiNoteList: Data.FiNote.FiNote[] ): void {
    store.commit('FiNote/FiNoteStore/updateFiNoteList' , fiNoteList );
  }


  public static UPDATE_FI_HIGHLIGHTED_NOTE_LIST(fiNoteList: Data.FiNote.FiNote[] ): void {
    store.commit('FiNote/FiNoteStore/updateFiHighlightedNoteList' , fiNoteList );
  }

}




export const FiNoteStoreInfo = {
     namespaced: true,
     modules: {
      FiNoteStore: storeFiNoteStore
    }
};


export const FiNoteGetter = {
FiNoteStore : FiNoteStoreGetter
};

export const FiNoteMutation = {
FiNoteStore : FiNoteStoreMutationAPI
};

