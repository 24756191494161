
import axios, { AxiosError, AxiosInstance } from 'axios';
import ErrorResponse from './../../../src/error-response';

import * as ServerData from './../../server-data';

export default class ClientFileReaderService {

   public static getFiPresignedURLForCreditReportTempUpload(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Input,
           f: (output: ServerData.ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/get-fi-presigned-u-r-l-for-credit-report-temp-upload',
            {params : input.toJson()}
        ).then((response) => ServerData.ClientFileWebReader.GetFiPresignedURLForCreditReportTempUpload$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getFiPresignedURLForUpload(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.GetFiPresignedURLForUpload$Input,
           f: (output: ServerData.ClientFileWebReader.GetFiPresignedURLForUpload$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/get-fi-presigned-u-r-l-for-upload',
            {params : input.toJson()}
        ).then((response) => ServerData.ClientFileWebReader.GetFiPresignedURLForUpload$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getFileUrl(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.GetFileUrl$Input,
           f: (output: ServerData.ClientFileWebReader.GetFileUrl$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/get-file-url',
            {params : input.toJson()}
        ).then((response) => ServerData.ClientFileWebReader.GetFileUrl$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getEMandateList(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.GetEMandateList$Input,
           f: (output: ServerData.SpineData$FiEMandate[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/get-e-mandate-list',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.SpineData$FiEMandate.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getClientFileBasicInfo(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.GetClientFileBasicInfo$Input,
           f: (output: ServerData.SpineData$ClientFileBasicInfo) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/get-client-file-basic-info',
            {params : input.toJson()}
        ).then((response) => ServerData.SpineData$ClientFileBasicInfo.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getCFBasicInfo(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.GetCFBasicInfo$Input,
           f: (output: ServerData.SpineData$ClientFileBasicInfo) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/get-c-f-basic-info',
            {params : input.toJson()}
        ).then((response) => ServerData.SpineData$ClientFileBasicInfo.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getMyClientFileList(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.GetMyClientFileList$Input,
           f: (output: ServerData.SpineData$MyClientFile[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/get-my-client-file-list',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.SpineData$MyClientFile.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static findDocumentList(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.FindDocumentList$Input,
           f: (output: ServerData.SpineData$FiDocument[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/find-document-list',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.SpineData$FiDocument.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getClientFileSummary(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.GetClientFileSummary$Input,
           f: (output: ServerData.SpineData$ClientFileSummary) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/get-client-file-summary',
            {params : input.toJson()}
        ).then((response) => ServerData.SpineData$ClientFileSummary.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getClientFileEnrollmentSummary(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.GetClientFileEnrollmentSummary$Input,
           f: (output: ServerData.SpineData$ClientFileEnrollmentSummary) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/get-client-file-enrollment-summary',
            {params : input.toJson()}
        ).then((response) => ServerData.SpineData$ClientFileEnrollmentSummary.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static findCreditorInfo(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.FindCreditorInfo$Input,
           f: (output: ServerData.SpineData$FiCreditorInfo|undefined) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/find-creditor-info',
            {params : input.toJson()}
        ).then((response) => (response.data != null) ? ServerData.SpineData$FiCreditorInfo.fromJson(response.data) : undefined)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static findBudgetInfo(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.FindBudgetInfo$Input,
           f: (output: ServerData.SpineData$FiBudgetInfo|undefined) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/find-budget-info',
            {params : input.toJson()}
        ).then((response) => (response.data != null) ? ServerData.SpineData$FiBudgetInfo.fromJson(response.data) : undefined)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static findPaymentPlan(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.FindPaymentPlan$Input,
           f: (output: ServerData.SpineData$FiPaymentPlan|undefined) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/find-payment-plan',
            {params : input.toJson()}
        ).then((response) => (response.data != null) ? ServerData.SpineData$FiPaymentPlan.fromJson(response.data) : undefined)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static findFiBankInfo(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.FindFiBankInfo$Input,
           f: (output: ServerData.SpineData$FiBankInfo|undefined) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/find-fi-bank-info',
            {params : input.toJson()}
        ).then((response) => (response.data != null) ? ServerData.SpineData$FiBankInfo.fromJson(response.data) : undefined)
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getAllSignAgreementList(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.GetAllSignAgreementList$Input,
           f: (output: ServerData.SpineData$FiSSASummary[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/get-all-sign-agreement-list',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.SpineData$FiSSASummary.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getEMandateDetails(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.GetEMandateDetails$Input,
           f: (output: ServerData.SpineData$FiEMandateSummary) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/get-e-mandate-details',
            {params : input.toJson()}
        ).then((response) => ServerData.SpineData$FiEMandateSummary.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getAllEMandateList(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.GetAllEMandateList$Input,
           f: (output: ServerData.SpineData$FiEMandateSummary[]) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/get-all-e-mandate-list',
            {params : input.toJson()}
        ).then((response) => (response.data as object[]).map((x) => ServerData.SpineData$FiEMandateSummary.fromJson(x)))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static getExceptionTakenList(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.GetExceptionTakenList$Input,
           f: (output: ServerData.ClientFileWebReader.GetExceptionTakenList$Output) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/get-exception-taken-list',
            {params : input.toJson()}
        ).then((response) => ServerData.ClientFileWebReader.GetExceptionTakenList$Output.fromJson(response.data))
       .then((output) => f(output))
       .catch(theEh);
   }


   public static findClPersonalInfo(
           axiosInstance: AxiosInstance, input: ServerData.ClientFileWebReader.FindClPersonalInfo$Input,
           f: (output: ServerData.SpineData$ClPersonalInfo|undefined) => void,
           eh: ((error: AxiosError) => void)|undefined)  {
        const theEh = (eh === undefined) ?  ((error: AxiosError) => {ErrorResponse.handle(error); }) : eh;
        const config = {
            params : input.toJson()

         };
        return axiosInstance.get(
            'spineapi/clientfile/find-cl-personal-info',
            {params : input.toJson()}
        ).then((response) => (response.data != null) ? ServerData.SpineData$ClPersonalInfo.fromJson(response.data) : undefined)
       .then((output) => f(output))
       .catch(theEh);
   }


}

